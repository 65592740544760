import React from 'react';
import { MenuProps } from 'react-select';
import { OptionType } from 'UI/types';
import { StyledMenu } from './styled';

function Menu({ children, innerProps, ...rest }: MenuProps<OptionType, true>) {
  const isTopMenu = rest.selectProps.menuPlacement === 'top';

  return (
    <StyledMenu {...innerProps} $isTop={isTopMenu}>
      {children}
    </StyledMenu>
  );
}

export default Menu;
