import { Clock } from 'assets/svg';
import React, { useRef, useState } from 'react';
import { Field } from 'react-final-form';
import { FieldWrapper } from 'UI';
import dayjs from 'dayjs';
import { BodyThree } from 'styled';
import { Container, ContainerCSS, FieldContainerCSS } from './styled';
import { useOnClickOutside } from 'hooks';
import { TimePopup } from './components';
import { FormApi } from 'final-form';

type Props = {
  startFieldName: string;
  endFieldName: string;
  formApi: FormApi<any>;
};

function DoubleTimeField({ startFieldName, endFieldName, formApi }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <FieldWrapper
      label="Time"
      Icon={<Clock />}
      containerCSS={ContainerCSS}
      containerRef={ref}
      fieldContainerCSS={FieldContainerCSS}
    >
      <>
        <Container onClick={() => setIsOpen(!isOpen)}>
          <Field
            name={startFieldName}
            render={({ input }) => (
              <BodyThree>{`${dayjs(input.value).format(
                'hh:mm A'
              )} -`}</BodyThree>
            )}
          />
          <Field
            name={endFieldName}
            render={({ input }) => (
              <BodyThree>
                &nbsp;{`${dayjs(input.value).format('hh:mm A')}`}
              </BodyThree>
            )}
          />
        </Container>
        {isOpen && (
          <TimePopup
            startFieldName={startFieldName}
            endFieldName={endFieldName}
            formApi={formApi}
            onSelect={() => setIsOpen(false)}
          />
        )}
      </>
    </FieldWrapper>
  );
}

export default DoubleTimeField;
