import React, { useState } from 'react';
import { LoadImageField, TextField, TextAreaField } from 'components';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'store/ducks/auth';
import { UpdateCoachDto } from 'types';
import {
  composeValidators,
  isRequired,
  isNumber,
  isRequiredLength,
} from 'utils/validation';
import {
  FormEditContainer,
  FlexFieldContainer,
  ProfileEditSection,
  FieldImageWrapper,
} from './styled';
import { name } from 'utils/validation';
import { Button } from 'UI';
import { convertBaseToFile, setValuesUpdateCoach, uploadFileS3 } from 'utils';
import Toast from 'services/Toast';
import { FormApi } from 'final-form';
import { captureException } from '@sentry/minimal';
import { AppDispatch } from 'store';

function parseValue<T>(value: T): T | null {
  if (value) {
    return value;
  } else {
    return null;
  }
}

const MAX_IMAGE_SIZE = 15 * 1024 * 1024;

function ProfileEdit() {
  const user = useSelector(selectors.getUserInfo);
  const dispatch = useDispatch<AppDispatch>();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (
    values: UpdateCoachDto,
    form: FormApi<UpdateCoachDto>
  ) => {
    try {
      setIsLoading(true);
      const isAvatarNew =
        form.getFieldState('avatarUrl')?.dirty && !!values.avatarUrl;

      let avatarUrl = values.avatarUrl;
      if (isAvatarNew) {
        avatarUrl = await uploadFileS3(
          await convertBaseToFile(
            values.avatarUrl as string,
            `${user.firstName}-avatar`
          )
        );
      }

      await dispatch(
        actions.updateUser(
          setValuesUpdateCoach({
            ...values,
            avatarUrl,
          })
        )
      );
      Toast.success('Profile edited!');
    } catch (error) {
      Toast.error('Something went wrong...');
      captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ProfileEditSection>
      <FormEditContainer>
        <Form<UpdateCoachDto>
          onSubmit={onSubmit}
          initialValues={{
            firstName: user?.firstName,
            lastName: user?.lastName,
            experience: user?.experience ? +user.experience : undefined,
            avatarUrl: user.avatarUrl || null,
            description: user.description || null,
          }}
          render={({ handleSubmit, hasValidationErrors, dirty }) => (
            <form onSubmit={handleSubmit}>
              <FieldImageWrapper>
                <Field
                  parse={parseValue}
                  name="avatarUrl"
                  component={LoadImageField}
                  cropRatio={4 / 3}
                  maxSize={MAX_IMAGE_SIZE}
                  accept={['image/*']}
                />
              </FieldImageWrapper>
              <FlexFieldContainer>
                <Field
                  label="First name*"
                  name="firstName"
                  validate={composeValidators(name, isRequired())}
                  component={TextField}
                />
                <Field
                  label="Last name*"
                  name="lastName"
                  validate={composeValidators(name, isRequired())}
                  component={TextField}
                />
              </FlexFieldContainer>
              <Field
                label="Experience, years*"
                validate={composeValidators(isNumber, isRequired())}
                name="experience"
                component={TextField}
              />
              <Field
                label="About you"
                subLabel="(at least 80 characters)"
                name="description"
                inputCSS={{ height: '159px' }}
                validate={isRequiredLength(400, 80)}
                maxLength={400}
                component={TextAreaField}
                parse={parseValue}
              />
              <Button
                text="Save profile"
                theme="primary"
                type="submit"
                $margin="24px 0 0 0"
                isLoading={isLoading}
                disabled={hasValidationErrors || !dirty}
              />
            </form>
          )}
        />
      </FormEditContainer>
    </ProfileEditSection>
  );
}

export default ProfileEdit;
