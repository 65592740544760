import { Edit } from 'assets/svg';
import React from 'react';
import { FlexStart, JustifyBetween, SubheadTwo, TitleFive } from 'styled';
import { Button, IconButton } from 'UI';
import { BackdropContainer, ButtonsContainer, ImageWrapper } from './styled';

type Props = {
  imageUrl: string;
  workoutsCount: number;
  weekCount: number;
  name: string;
  description: string;

  onAddWorkout: () => void;
  onEdit: () => void;
};

function Header({
  imageUrl,
  workoutsCount,
  weekCount,
  name,
  description,
  onAddWorkout,
  onEdit,
}: Props) {
  return (
    <ImageWrapper $image={imageUrl}>
      <JustifyBetween>
        <div>
          <BackdropContainer>
            <SubheadTwo $color="white">
              {`${weekCount} weeks`} &bull; {`${workoutsCount} workouts`}
            </SubheadTwo>
          </BackdropContainer>
          <TitleFive $color="white" $marginBottom={6}>
            {name}
          </TitleFive>
          <SubheadTwo $color="white">{description}</SubheadTwo>
        </div>
        <ButtonsContainer>
          <FlexStart>
            <Button
              text="Add workout"
              $margin="0px 12px 0px 0px"
              onClick={onAddWorkout}
            />
            <IconButton
              backgroundColor="white"
              hoverColor="#E1E0E5"
              onClick={onEdit}
            >
              <Edit />
            </IconButton>
          </FlexStart>
        </ButtonsContainer>
      </JustifyBetween>
    </ImageWrapper>
  );
}

export default Header;
