import { FlexCenter } from 'styled';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 20px;
`;

export const SelectedFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: auto 140px;
  grid-template-rows: 48px;

  & > div:last-child {
    padding-bottom: 0;
  }
`;

export const SelectedField = styled(FlexCenter)`
  position: relative;

  height: 100%;

  background: #f2f7ff;
  border-radius: 8px;

  padding: 0px 8px;

  &:not(:last-child) {
    margin-right: 16px;
  }
`;
