export default {
  bgBlue: '#F2F7FF',
  primaryPurple: '#895FC6',
  secondaryPurple: '#F3EFF9',
  secondaryPink: '#D555B1',
  silverLight: '#190742',
  silverDevider: '#E1E0E5',
  textPrimary: '#201E3F',
  textSecondary: '#67667B',
  redPrimary: '#FF5934',
};
