import * as React from 'react';

function SvgPlayVideo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32 64C14.326 64 0 49.674 0 32S14.326 0 32 0s32 14.326 32 32-14.326 32-32 32zm-4.41-43.472a1.28 1.28 0 00-1.99 1.062v20.82a1.28 1.28 0 001.99 1.062l15.613-10.406a1.279 1.279 0 000-2.132L27.587 20.528h.003z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgPlayVideo;
