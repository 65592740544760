import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { OptionsType } from 'react-select';
import { AsyncSelectDropdown } from 'UI';
import { AsyncSelectProps, OptionType } from 'UI/types';

type Props<ResponseType> = FieldRenderProps<OptionsType<OptionType>> &
  AsyncSelectProps<ResponseType>;

function AsyncSelectField<ResponseType = any>({
  input,
  meta,
  getOptions,
  normalizeOptions,
  ...rest
}: Props<ResponseType>) {
  const error = meta.touched && meta.error;

  return (
    <AsyncSelectDropdown<ResponseType>
      {...input}
      {...rest}
      error={error}
      getOptions={getOptions}
      normalizeOptions={normalizeOptions}
    />
  );
}

export default AsyncSelectField;
