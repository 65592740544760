import styled from 'styled-components';

const BORDER_SIZE_RATIO = 8;

const getPixels = (size: number) => {
  return `${size}px`;
};
const getColor = (color?: string) => color;
const Spinner = styled.div<{ $size: number; $color?: string }>`
  &,
  &::after {
    border-radius: 50%;
    width: ${({ $size }) => getPixels($size)};
    height: ${({ $size }) => getPixels($size)};
  }

  font-size: 10px;
  position: relative;
  text-indent: -9999em;

  border-top: ${({ $size }) => getPixels($size / BORDER_SIZE_RATIO)} solid
    ${({ $color }) => getColor($color)};

  border-right: ${({ $size }) => getPixels($size / BORDER_SIZE_RATIO)} solid
    ${({ $color }) => getColor($color)};

  border-bottom: ${({ $size }) => getPixels($size / BORDER_SIZE_RATIO)} solid
    ${({ $color }) => getColor($color)};

  border-left: ${({ $size }) => getPixels($size / BORDER_SIZE_RATIO)} solid
    transparent;

  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

Spinner.defaultProps = {
  $color: 'rgba(137, 95, 198, 0.8)',
};

export default Spinner;
