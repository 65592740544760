import * as React from 'react';

function SvgLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={151}
      height={44}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#Logo_svg__clip0)">
        <path
          d="M145.162 19.333c-1.334-.276-2.621-.92-3.747-1.908l-.345-.3 1.586-1.792.345.299c.827.712 1.747 1.195 2.643 1.379h.023c.276.046.529.092.805.092.46 0 .874-.092 1.195-.253.437-.184.943-.575.943-1.31 0-.391-.207-.69-.621-.943-.505-.299-1.195-.46-1.678-.551-.207-.046-2.046-.414-2.896-.874a3.83 3.83 0 01-1.288-1.287 3.454 3.454 0 01-.46-1.725c0-.69.207-1.356.621-2 .391-.528.943-.942 1.586-1.264a5.024 5.024 0 012.138-.46c.299 0 .621.046.943.07 1.195.206 2.345.758 3.379 1.608l.345.3-1.517 1.861-.345-.298c-1.173-.943-2.184-1.173-2.828-1.173-.414 0-.781.092-1.103.23-.506.23-.828.667-.828 1.103 0 .391.138.644.483.874.345.23.874.414 1.678.598.115.023.253.046.391.092.759.138 1.77.367 2.46.781.62.322 1.08.782 1.402 1.288.322.528.483 1.126.483 1.77 0 .804-.207 1.517-.644 2.138-.368.528-.942.965-1.678 1.333-.621.322-1.333.46-2.207.46-.368 0-.805-.046-1.264-.138zM77.925 13.056c0-3.7 3.011-6.712 6.69-6.712a6.72 6.72 0 016.711 6.712c0 3.678-3.01 6.667-6.712 6.667-3.678 0-6.689-2.989-6.689-6.666zm2.414 0c0 2.368 1.93 4.299 4.275 4.299 2.345 0 4.276-1.93 4.276-4.299 0-2.39-1.931-4.321-4.276-4.321-2.367 0-4.275 1.93-4.275 4.322zM101.28 19.54l-1.31-3.449h-4.253l-1.333 3.449h-2.575l4.92-12.805h2.183l4.943 12.805h-2.575zm-2.23-5.84l-1.195-3.103-1.195 3.104h2.39zM126.658 19.54v-6.207h-5.196v6.207h-2.414V6.735h2.414v4.253h5.196V6.735h2.413V19.54h-2.413zM131.392 19.54V6.735h8.437V9.08h-6.023v1.885h5.242v2.39h-5.242v3.817h5.862v2.368h-8.276z"
          fill="#000"
        />
        <path
          d="M92.063 36.343l-5.678-8.39v8.39H83.97V23.538h2.344l5.656 8.368v-8.368h2.413v12.805h-2.321z"
          fill="url(#Logo_svg__paint0_linear)"
        />
        <path
          d="M100.935 36.343V23.538h2.414v10.437h6.528v2.345h-8.942v.023z"
          fill="url(#Logo_svg__paint1_linear)"
        />
        <path
          d="M115.899 36.343V23.538h2.414v12.805h-2.414z"
          fill="url(#Logo_svg__paint2_linear)"
        />
        <path
          d="M133.416 36.343l-5.678-8.39v8.39h-2.414V23.538h2.345l5.655 8.368v-8.368h2.414v12.805h-2.322z"
          fill="url(#Logo_svg__paint3_linear)"
        />
        <path
          d="M142.564 36.343V23.538h8.437v2.345h-6.023v1.885h5.241v2.391h-5.241v3.816h5.862v2.368h-8.276z"
          fill="url(#Logo_svg__paint4_linear)"
        />
        <path
          d="M59.007 0h-1.402v43.997h1.402V0zM23.745 28.274l-.345.437c-1.907 2.529-4.804 3.977-7.953 3.977-5.47 0-9.93-4.483-9.93-9.977 0-5.47 4.482-9.907 9.93-9.907 3.195 0 6.092 1.448 7.954 3.977l.597.781a15.57 15.57 0 011.425-2.666 15.62 15.62 0 011.747-2.16c-1.333-1.564-2.988-2.897-4.804-3.794a15.27 15.27 0 00-6.919-1.655C6.965 7.287 0 14.207 0 22.69c0 2.114.414 4.091 1.241 5.999a13.98 13.98 0 003.31 4.92 15.487 15.487 0 004.897 3.31 15.784 15.784 0 006 1.195c2.413 0 4.757-.53 6.872-1.61a15.34 15.34 0 004.322-3.24c-.322-.368-.621-.736-.897-1.15a13.904 13.904 0 01-2-3.839z"
          fill="#000"
        />
        <path
          d="M52.548 25.86c-.46 2.552-1.517 4.827-3.172 6.804-.966 1.172-2.115 2.16-3.402 2.988-2.07 1.334-4.322 2.07-6.782 2.276-2.068.161-4.091-.138-6.045-.85-2.827-1.058-5.126-2.828-6.873-5.287a14.17 14.17 0 01-2.437-5.632 15.097 15.097 0 01-.276-3.172c-.046-1.333.207-2.828.69-4.276a15.035 15.035 0 011.701-3.448 15.726 15.726 0 012.643-3.011c.552-.483 1.104-.943 1.748-1.287 1.057-.575 2.252-.23 2.873.781a2.161 2.161 0 01-.644 2.92c-1.632 1.08-2.896 2.482-3.747 4.252-1.011 2.092-1.195 4.276-.712 6.528.299 1.448.92 2.782 1.793 3.977 1.333 1.77 3.034 3.057 5.172 3.7 2.827.851 5.585.599 8.137-.873 2.712-1.586 4.413-3.93 5.011-7.034.437-2.275.184-4.459-.896-6.528a10.54 10.54 0 00-3.563-3.953c-.85-.552-1.196-1.724-.85-2.598.482-1.172 1.631-1.586 2.574-1.333.413.115.758.345 1.08.597 1.954 1.449 3.54 3.219 4.643 5.402 1.495 2.85 1.885 5.885 1.334 9.057z"
          fill="#fff"
        />
        <path
          d="M46.594 11.355c-.345-.252-.69-.482-1.08-.597-.943-.253-2.07.16-2.575 1.333-.368.873 0 2.046.85 2.598 1.54 1.01 2.736 2.321 3.564 3.953 1.08 2.069 1.356 4.253.896 6.529-.598 3.103-2.299 5.47-5.011 7.033-2.575 1.495-5.31 1.724-8.137.874-2.138-.644-3.84-1.908-5.172-3.7a9.76 9.76 0 01-1.793-3.978c-.46-2.252-.3-4.436.712-6.528.85-1.747 2.115-3.15 3.747-4.252a2.166 2.166 0 00.644-2.92c-.62-1.011-1.816-1.356-2.874-.781-.643.345-1.218.804-1.747 1.287a15.728 15.728 0 00-2.643 3.011 14.16 14.16 0 00-1.701 3.448c-.483 1.448-.759 2.943-.69 4.276 0 1.195.092 2.184.276 3.172.391 2.069 1.218 3.93 2.437 5.632 1.747 2.46 4.045 4.23 6.873 5.287 1.954.735 3.977 1.034 6.045.85a14.36 14.36 0 006.781-2.276 15.068 15.068 0 003.402-2.988c1.655-1.977 2.713-4.252 3.173-6.804.574-3.172.184-6.206-1.288-9.103-1.149-2.137-2.735-3.907-4.689-5.356z"
          fill="#fff"
        />
        <path
          d="M46.594 11.355c-.345-.252-.69-.482-1.08-.597-.943-.253-2.07.16-2.575 1.333-.368.873 0 2.046.85 2.598 1.54 1.01 2.736 2.321 3.564 3.953 1.08 2.069 1.356 4.253.896 6.529-.598 3.103-2.299 5.47-5.011 7.033-2.575 1.495-5.31 1.724-8.137.874-2.138-.644-3.84-1.908-5.172-3.7a9.76 9.76 0 01-1.793-3.978c-.46-2.252-.3-4.436.712-6.528.85-1.747 2.115-3.15 3.747-4.252a2.166 2.166 0 00.644-2.92c-.62-1.011-1.816-1.356-2.874-.781-.643.345-1.218.804-1.747 1.287a15.728 15.728 0 00-2.643 3.011 14.16 14.16 0 00-1.701 3.448c-.483 1.448-.759 2.943-.69 4.276 0 1.195.092 2.184.276 3.172.391 2.069 1.218 3.93 2.437 5.632 1.747 2.46 4.045 4.23 6.873 5.287 1.954.735 3.977 1.034 6.045.85a14.36 14.36 0 006.781-2.276 15.068 15.068 0 003.402-2.988c1.655-1.977 2.713-4.252 3.173-6.804.574-3.172.184-6.206-1.288-9.103-1.149-2.137-2.735-3.907-4.689-5.356z"
          fill="url(#Logo_svg__paint5_linear)"
        />
        <path
          d="M36.342 7.126a2.222 2.222 0 012.299-.966c.896.207 1.586.966 1.678 1.862.023.3.023.598.023.897v12.758c0 .597-.07 1.172-.483 1.655-.598.712-1.356 1.034-2.276.758-.873-.253-1.402-.85-1.563-1.77-.046-.23-.046-.483-.046-.712V8.78c0-.552.023-1.126.368-1.655z"
          fill="#fff"
        />
        <path
          d="M36.02 22.366c.161.897.69 1.494 1.563 1.77.92.276 1.678-.046 2.276-.758.414-.483.483-1.058.483-1.655v-6.506-6.252c0-.299 0-.598-.023-.897a2.15 2.15 0 00-1.678-1.862c-.874-.206-1.816.207-2.299.966-.322.529-.368 1.103-.368 1.7V21.7c0 .206 0 .436.046.666z"
          fill="#fff"
        />
        <path
          d="M36.02 22.366c.161.897.69 1.494 1.563 1.77.92.276 1.678-.046 2.276-.758.414-.483.483-1.058.483-1.655v-6.506-6.252c0-.299 0-.598-.023-.897a2.15 2.15 0 00-1.678-1.862c-.874-.206-1.816.207-2.299.966-.322.529-.368 1.103-.368 1.7V21.7c0 .206 0 .436.046.666z"
          fill="url(#Logo_svg__paint6_linear)"
        />
        <path
          d="M75.328 15.838l-.161.207a4.55 4.55 0 01-3.632 1.816c-2.506 0-4.551-2.046-4.551-4.575 0-2.505 2.045-4.528 4.55-4.528 1.449 0 2.782.667 3.633 1.816l.276.368c.183-.437.39-.828.666-1.219.23-.344.506-.69.805-.988-.598-.713-1.356-1.333-2.207-1.724a7.164 7.164 0 00-3.172-.759c-3.885 0-7.057 3.173-7.057 7.057 0 .966.184 1.885.575 2.736a6.5 6.5 0 001.517 2.252 7.376 7.376 0 002.23 1.518 7.007 7.007 0 002.735.551 7.046 7.046 0 003.149-.735c.759-.368 1.402-.874 1.977-1.494a10.64 10.64 0 01-.414-.53 6.835 6.835 0 01-.92-1.77zM115.439 15.838l-.16.207a4.552 4.552 0 01-3.632 1.816c-2.506 0-4.552-2.046-4.552-4.575 0-2.505 2.046-4.528 4.552-4.528 1.448 0 2.781.667 3.632 1.816l.275.368c.184-.437.391-.828.667-1.219.23-.344.506-.69.805-.988-.598-.713-1.357-1.333-2.207-1.724a7.166 7.166 0 00-3.172-.759c-3.885 0-7.057 3.173-7.057 7.057 0 .966.184 1.885.574 2.736a6.5 6.5 0 001.518 2.252 7.372 7.372 0 002.229 1.518 7.01 7.01 0 002.736.551 7.048 7.048 0 003.149-.735c.758-.368 1.402-.874 1.977-1.494-.138-.161-.276-.345-.414-.53a6.837 6.837 0 01-.92-1.77z"
          fill="#000"
        />
        <path
          d="M72.04 37.285c-.804 0-1.609-.138-2.413-.437a6.862 6.862 0 01-3.333-2.575 7.423 7.423 0 01-1.195-2.735 8.34 8.34 0 01-.138-1.54c-.023-.62.092-1.334.344-2.092.207-.598.483-1.15.828-1.678a7.651 7.651 0 011.287-1.448c.23-.207.529-.46.874-.644a1.25 1.25 0 01.62-.16c.437 0 .85.229 1.104.62.367.598.184 1.356-.391 1.747a4.754 4.754 0 00-1.655 1.862c-.437.873-.529 1.816-.322 2.873a4.6 4.6 0 00.782 1.747c.597.805 1.379 1.356 2.275 1.632.483.138.943.207 1.403.207.758 0 1.494-.207 2.183-.598 1.196-.69 1.954-1.747 2.207-3.103a4.273 4.273 0 00-.39-2.873 4.754 4.754 0 00-1.564-1.747c-.506-.345-.736-1.012-.506-1.563.207-.506.667-.828 1.196-.828.115 0 .23.023.344.046.23.07.414.184.598.322.966.713 1.724 1.586 2.253 2.62.69 1.38.92 2.874.62 4.437a7.09 7.09 0 01-1.54 3.31 6.701 6.701 0 01-1.655 1.448 6.916 6.916 0 01-3.287 1.104c-.16.046-.345.046-.528.046z"
          fill="url(#Logo_svg__paint7_linear)"
        />
        <path
          d="M65.26 29.998c-.024-.62.091-1.31.321-2 .184-.575.46-1.104.805-1.61a6.55 6.55 0 011.241-1.401c.253-.23.529-.437.828-.598.482-.253 1.057-.115 1.333.368a.995.995 0 01-.3 1.356 4.99 4.99 0 00-1.746 1.977c-.483.965-.552 2-.345 3.057a4.43 4.43 0 00.85 1.862c.621.828 1.426 1.425 2.414 1.724 1.334.39 2.598.276 3.793-.414 1.264-.735 2.069-1.839 2.345-3.287.207-1.057.092-2.092-.414-3.057a4.786 4.786 0 00-1.678-1.84c-.39-.252-.575-.804-.39-1.217.229-.552.758-.736 1.195-.621.183.046.344.16.505.276.92.666 1.655 1.494 2.16 2.528.69 1.357.874 2.759.599 4.253a6.726 6.726 0 01-1.472 3.172 7.088 7.088 0 01-1.586 1.402 6.762 6.762 0 01-3.172 1.058 6.62 6.62 0 01-2.827-.414 6.801 6.801 0 01-3.218-2.46 6.977 6.977 0 01-1.15-2.643c-.046-.46-.092-.92-.092-1.471z"
          fill="url(#Logo_svg__paint8_linear)"
        />
        <path
          d="M72.11 29.791a1.17 1.17 0 01-1.15-.965c-.023-.092-.023-.207-.023-.345v-5.264c0-.253.023-.552.184-.828a1.16 1.16 0 01.966-.529c.091 0 .16 0 .252.023.483.115.828.506.874.989.023.138.023.276.023.39v5.242c0 .276-.023.575-.253.85-.253.277-.552.437-.874.437z"
          fill="url(#Logo_svg__paint9_linear)"
        />
        <path
          d="M72.96 25.814v2.667c0 .253-.023.483-.207.69-.253.299-.552.413-.942.322-.345-.115-.575-.345-.644-.713-.023-.092-.023-.184-.023-.299v-5.264c0-.253.023-.483.161-.713a.865.865 0 01.942-.39c.368.091.644.39.69.758.023.115.023.253.023.368v2.574z"
          fill="url(#Logo_svg__paint10_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="Logo_svg__paint0_linear"
          x1={89.173}
          y1={36.468}
          x2={89.173}
          y2={24.084}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E07F6" />
          <stop offset={1} stopColor="#AE56F6" />
        </linearGradient>
        <linearGradient
          id="Logo_svg__paint1_linear"
          x1={105.403}
          y1={36.468}
          x2={105.403}
          y2={24.084}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E07F6" />
          <stop offset={1} stopColor="#AE56F6" />
        </linearGradient>
        <linearGradient
          id="Logo_svg__paint2_linear"
          x1={117.112}
          y1={36.468}
          x2={117.112}
          y2={24.084}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E07F6" />
          <stop offset={1} stopColor="#AE56F6" />
        </linearGradient>
        <linearGradient
          id="Logo_svg__paint3_linear"
          x1={130.53}
          y1={36.468}
          x2={130.53}
          y2={24.084}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E07F6" />
          <stop offset={1} stopColor="#AE56F6" />
        </linearGradient>
        <linearGradient
          id="Logo_svg__paint4_linear"
          x1={146.778}
          y1={36.468}
          x2={146.778}
          y2={24.084}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E07F6" />
          <stop offset={1} stopColor="#AE56F6" />
        </linearGradient>
        <linearGradient
          id="Logo_svg__paint5_linear"
          x1={38.166}
          y1={37.975}
          x2={38.166}
          y2={10.693}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E07F6" />
          <stop offset={1} stopColor="#AE56F6" />
        </linearGradient>
        <linearGradient
          id="Logo_svg__paint6_linear"
          x1={38.148}
          y1={24.228}
          x2={38.148}
          y2={6.115}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E07F6" />
          <stop offset={1} stopColor="#AE56F6" />
        </linearGradient>
        <linearGradient
          id="Logo_svg__paint7_linear"
          x1={72.081}
          y1={37.287}
          x2={72.081}
          y2={23.98}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E07F6" />
          <stop offset={1} stopColor="#AE56F6" />
        </linearGradient>
        <linearGradient
          id="Logo_svg__paint8_linear"
          x1={72.081}
          y1={37.004}
          x2={72.081}
          y2={24.263}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E07F6" />
          <stop offset={1} stopColor="#AE56F6" />
        </linearGradient>
        <linearGradient
          id="Logo_svg__paint9_linear"
          x1={72.073}
          y1={29.786}
          x2={72.073}
          y2={21.843}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E07F6" />
          <stop offset={1} stopColor="#AE56F6" />
        </linearGradient>
        <linearGradient
          id="Logo_svg__paint10_linear"
          x1={72.073}
          y1={29.537}
          x2={72.073}
          y2={22.091}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E07F6" />
          <stop offset={1} stopColor="#AE56F6" />
        </linearGradient>
        <clipPath id="Logo_svg__clip0">
          <path fill="#fff" d="M0 0h151v44H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgLogo;
