import React, { useState } from 'react';
import { ModalWrapper, TextField } from 'components';
import { useAsyncCallback } from 'hooks';
import { ModalBaseProps } from 'interfaces/common';
import { Field, Form } from 'react-final-form';
import { createInvite } from 'services/api';
import Toast from 'services/Toast';
import { BodyOne, FootnoteOne } from 'styled';
import { RelationUsers } from 'types';
import { Button } from 'UI';
import { isEmail, isRequired } from 'utils/validation';
import { SuccessInviteContainer } from './styled';
import { AxiosError } from 'axios';

type Props = ModalBaseProps & {
  onSuccess: (newRelation: RelationUsers) => void;
};

type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
};

function InviteClientModal({ onSuccess, ...rest }: Props) {
  const [inviteLink, setInviteLink] = useState<string | null>(null);

  const [onSubmit, isLoading] = useAsyncCallback(async (values: FormValues) => {
    try {
      const { data } = await createInvite({ user: values });
      onSuccess(data.newRelation);
      setInviteLink(data.inviteLink);
    } catch (e) {
      Toast.error((e as AxiosError).message || 'Something went wrong..');
    }
  });

  const renderModalBody = () => {
    if (inviteLink) {
      return (
        <SuccessInviteContainer>
          <BodyOne $size={18} $marginBottom={12}>
            Client is successfully invited. We send e-mail with invite link to
            him. Or you can manually send it to client, by copy link down below:
          </BodyOne>
          <FootnoteOne $size={16} $color="#D555B1">
            {inviteLink}
          </FootnoteOne>
        </SuccessInviteContainer>
      );
    }

    return (
      <Form<FormValues>
        onSubmit={onSubmit}
        render={({ handleSubmit, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="email"
              label="E-mail"
              placeholder="example@email.com"
              validate={isEmail}
              component={TextField}
            />
            <Field
              name="firstName"
              label="First name"
              placeholder="John"
              validate={isRequired()}
              component={TextField}
            />
            <Field
              name="lastName"
              label="Last name"
              placeholder="Doe"
              validate={isRequired()}
              component={TextField}
            />
            <Button
              text="Send invite"
              type="submit"
              disabled={hasValidationErrors}
              isLoading={isLoading}
            />
          </form>
        )}
      />
    );
  };

  return (
    <ModalWrapper width={600} title="Invite client" {...rest}>
      {renderModalBody()}
    </ModalWrapper>
  );
}

export default InviteClientModal;
