import { useAsyncCallback } from 'hooks';
import React, { useEffect, useState } from 'react';
import { getExercises } from 'services/api';
import Toast from 'services/Toast';
import { FlexCenter, SubheadOne } from 'styled';
import { Exercise } from 'types';
import { Spinner } from 'UI';
import { Container, ExerciseCard, Image } from './styled';

type Props = {
  onPickExercise: (exercise: Exercise) => void;
};

function ExercisesList({ onPickExercise }: Props) {
  const [exercises, setExercises] = useState<Exercise[]>([]);

  const [fetchExercises, isLoading] = useAsyncCallback(async () => {
    try {
      const { data } = await getExercises({ page: 1, limit: 1000 });
      setExercises(data.data);
    } catch (e) {
      Toast.error();
    }
  });

  useEffect(() => {
    fetchExercises();
  }, []);

  if (isLoading) {
    return (
      <FlexCenter>
        <Spinner $size={35} />
      </FlexCenter>
    );
  }

  return (
    <Container>
      {exercises.map((exercise) => (
        <ExerciseCard
          onClick={() => onPickExercise(exercise)}
          key={exercise.id}
        >
          <Image src={exercise.images[0]} />
          <SubheadOne>{exercise.name}</SubheadOne>
        </ExerciseCard>
      ))}
    </Container>
  );
}

export default ExercisesList;
