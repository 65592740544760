import React from 'react';
import { Header, Sidebar } from './components';
import { LayoutProps } from 'interfaces/common';
import { Container, MainWrapper, Main } from './styled';

function MainLayout({ children, className, withBack = false }: LayoutProps) {
  return (
    <Container className={className}>
      <Sidebar />
      <MainWrapper>
        <Header withBack={withBack} />
        <Main>{children}</Main>
      </MainWrapper>
    </Container>
  );
}

export default MainLayout;
