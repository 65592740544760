import styled, { css } from 'styled-components';

export const StyledClientItem = styled.li`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid #e1e0e5;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &:first-child {
    padding-top: 0;
  }

  & > div:first-child {
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`;

export const NameCSS = css`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
