import { captureException } from '@sentry/minimal';
import { Dispatch as ReactDispatch } from 'react';
import {
  createProgramWorkout as apiCreateProgramWorkout,
  deleteWorkoutExercise,
  deleteProgramWorkout as apiDeleteProgramWorkout,
  getExercisesForWorkout,
  getOneProgramWithWorkouts,
  updateWorkoutExercise,
  updateProgram as apiUpdateProgram,
  updateWorkout,
} from 'services/api';
import Toast from 'services/Toast';
import {
  Program,
  ProgramWorkout,
  WorkoutExercise,
  UpdateWorkoutDto,
  Workout,
  UpdateProgramBody,
  UpdateWorkoutExerciseDto,
  CreateProgramWorkoutDto,
} from 'types';

type RootProgramType = Program;
type AdditionalType = {
  isProgramLoading: boolean;
  programWorkouts: ProgramWorkout[];
  workoutExercises: WorkoutExercise[];
  fetchPlanExercisesStatusMap: Record<string, boolean>;
};

export const initialState = {
  isProgramLoading: false,
  programWorkouts: [] as ProgramWorkout[],
  workoutExercises: [] as WorkoutExercise[],
} as RootProgramType & AdditionalType;

type State = typeof initialState;

type FETCH_PROGRAM = { type: 'FETCH_PROGRAM' };
type FETCH_PROGRAM_SUCCESS = {
  type: 'FETCH_PROGRAM_SUCCESS';
  payload: State;
};
type FETCH_PROGRAM_FAILURE = { type: 'FETCH_PROGRAM_FAILURE' };
type UPDATE_PROGRAM = { type: 'UPDATE_PROGRAM' };
type UPDATE_PROGRAM_SUCCESS = {
  type: 'UPDATE_PROGRAM_SUCCESS';
  payload: Program;
};
type UPDATE_PROGRAM_FAILURE = { type: 'UPDATE_PROGRAM_FAILURE' };
type CREATE_WORKOUT = { type: 'CREATE_WORKOUT' };
type CREATE_WORKOUT_SUCCESS = {
  type: 'CREATE_WORKOUT_SUCCESS';
  payload: ProgramWorkout;
};
type CREATE_WORKOUT_FAILURE = { type: 'CREATE_WORKOUT_FAILURE' };
type UPDATE_WORKOUT = { type: 'UPDATE_WORKOUT' };
type UPDATE_WORKOUT_SUCCESS = {
  type: 'UPDATE_WORKOUT_SUCCESS';
  payload: { workout: Workout; exercisesToDelete: string[] };
};
type UPDATE_WORKOUT_FAILURE = { type: 'UPDATE_WORKOUT_FAILURE' };
type DELETE_WORKOUT = { type: 'DELETE_WORKOUT' };
type DELETE_WORKOUT_SUCCESS = {
  type: 'DELETE_WORKOUT_SUCCESS';
  payload: string;
};
type DELETE_WORKOUT_FAILURE = { type: 'DELETE_WORKOUT_FAILURE' };
type UPDATE_EXERCISE = { type: 'UPDATE_EXERCISE' };
type UPDATE_EXERCISE_SUCCESS = {
  type: 'UPDATE_EXERCISE_SUCCESS';
  payload: WorkoutExercise;
};
type UPDATE_EXERCISE_FAILURE = { type: 'UPDATE_EXERCISE_FAILURE' };
type DELETE_EXERCISE = { type: 'DELETE_EXERCISE' };
type DELETE_EXERCISE_SUCCESS = {
  type: 'DELETE_EXERCISE_SUCCESS';
  payload: string;
};
type DELETE_EXERCISE_FAILURE = { type: 'DELETE_EXERCISE_FAILURE' };
type FETCH_PLAN_EXERCISES = { type: 'FETCH_PLAN_EXERCISES' };
type FETCH_PLAN_EXERCISES_SUCCESS = {
  type: 'FETCH_PLAN_EXERCISES_SUCCESS';
  payload: {
    exercises: WorkoutExercise[];
    planId: string;
  };
};
type FETCH_PLAN_EXERCISES_FAILURE = { type: 'FETCH_PLAN_EXERCISES_FAILURE' };

type Action =
  | FETCH_PROGRAM
  | FETCH_PROGRAM_SUCCESS
  | FETCH_PROGRAM_FAILURE
  | CREATE_WORKOUT
  | CREATE_WORKOUT_SUCCESS
  | CREATE_WORKOUT_FAILURE
  | DELETE_WORKOUT
  | DELETE_WORKOUT_SUCCESS
  | DELETE_WORKOUT_FAILURE
  | DELETE_EXERCISE
  | DELETE_EXERCISE_SUCCESS
  | DELETE_EXERCISE_FAILURE
  | FETCH_PLAN_EXERCISES
  | FETCH_PLAN_EXERCISES_SUCCESS
  | FETCH_PLAN_EXERCISES_FAILURE
  | UPDATE_WORKOUT
  | UPDATE_WORKOUT_SUCCESS
  | UPDATE_WORKOUT_FAILURE
  | UPDATE_PROGRAM
  | UPDATE_PROGRAM_SUCCESS
  | UPDATE_PROGRAM_FAILURE
  | UPDATE_EXERCISE
  | UPDATE_EXERCISE_SUCCESS
  | UPDATE_EXERCISE_FAILURE;

type Dispatch = ReactDispatch<Action>;

export const fetchProgram = async (dispatch: Dispatch, id: string) => {
  dispatch({ type: 'FETCH_PROGRAM' });

  try {
    const { data } = await getOneProgramWithWorkouts(id);
    const { programWorkouts, ...rest } = data;

    const workouts = programWorkouts?.map((el) => el.workout);
    const workoutExercises = workouts?.map((el) => el.workoutExercises || []);
    const flatWorkoutExercises = workoutExercises?.reduce(
      (prev, cur) => [...cur, ...prev],
      []
    );
    const fetchExercisesStatuses = workouts?.reduce(
      (prev, cur) => ({ ...prev, [cur.id]: false }),
      {}
    );

    const payload = {
      ...rest,
      isProgramLoading: false,
      programWorkouts: programWorkouts || [],
      workoutExercises: flatWorkoutExercises || [],
      fetchPlanExercisesStatusMap: fetchExercisesStatuses,
    } as State;
    dispatch({ type: 'FETCH_PROGRAM_SUCCESS', payload });
  } catch (err) {
    dispatch({ type: 'FETCH_PROGRAM_FAILURE' });
    captureException(err);
  }
};

export const updateProgram = async (
  dispatch: Dispatch,
  id: string,
  body: UpdateProgramBody
) => {
  dispatch({ type: 'UPDATE_PROGRAM' });

  try {
    const { data } = await apiUpdateProgram(id, body);
    dispatch({ type: 'UPDATE_PROGRAM_SUCCESS', payload: data as Program });
    Toast.success('Program updated!');
  } catch (e) {
    dispatch({ type: 'UPDATE_PROGRAM_FAILURE' });
    Toast.error();
  }
};

export const createProgramWorkout = async (
  dispatch: Dispatch,
  body: CreateProgramWorkoutDto
) => {
  dispatch({ type: 'CREATE_WORKOUT' });

  try {
    const { data } = await apiCreateProgramWorkout(body);
    const newProgram = data as ProgramWorkout;
    dispatch({ type: 'CREATE_WORKOUT_SUCCESS', payload: newProgram });

    Toast.success('Workout created!');
  } catch (err) {
    dispatch({ type: 'CREATE_WORKOUT_FAILURE' });
    Toast.error('Cant create workout...');
    captureException(err);
  }
};

export const updateProgramWorkout = async (
  dispatch: Dispatch,
  id: string,
  body: UpdateWorkoutDto,
  exercisesToDelete: string[]
) => {
  dispatch({ type: 'UPDATE_WORKOUT' });

  try {
    const { data } = await updateWorkout(id, body);
    dispatch({
      type: 'UPDATE_WORKOUT_SUCCESS',
      payload: { workout: data, exercisesToDelete },
    });
    Toast.success('Workout has been updated!', 'Workouts');
  } catch (err) {
    dispatch({ type: 'UPDATE_WORKOUT_FAILURE' });
    Toast.error();
    captureException(err);
  }
};

export const deleteProgramWorkout = async (dispatch: Dispatch, id: string) => {
  dispatch({ type: 'DELETE_WORKOUT' });

  try {
    await apiDeleteProgramWorkout(id);
    dispatch({ type: 'DELETE_WORKOUT_SUCCESS', payload: id });
    Toast.success('Workout deleted!');
  } catch (err) {
    dispatch({ type: 'DELETE_WORKOUT_FAILURE' });
    Toast.error('Something went wrong...');
    captureException(err);
  }
};

export const fetchPlanExercises = async (
  dispatch: Dispatch,
  planId: string
): Promise<WorkoutExercise[]> => {
  dispatch({ type: 'FETCH_PLAN_EXERCISES' });

  try {
    const { data } = await getExercisesForWorkout(planId);
    dispatch({
      type: 'FETCH_PLAN_EXERCISES_SUCCESS',
      payload: {
        exercises: data,
        planId,
      },
    });
    return data;
  } catch (err) {
    dispatch({ type: 'FETCH_PLAN_EXERCISES_FAILURE' });
    captureException(err);
  }
  return [];
};

export const updateExercise = async (
  dispatch: Dispatch,
  workoutExerciseId: string,
  body: UpdateWorkoutExerciseDto
) => {
  dispatch({ type: 'UPDATE_EXERCISE' });

  try {
    const { data } = await updateWorkoutExercise(workoutExerciseId, body);
    dispatch({
      type: 'UPDATE_EXERCISE_SUCCESS',
      payload: data,
    });
    Toast.success('Exercises updated!', 'Exercises');
  } catch (e) {
    dispatch({ type: 'UPDATE_EXERCISE_FAILURE' });
    Toast.error();
  }
};

export const deleteExercise = async (
  dispatch: Dispatch,
  workoutExerciseId: string
) => {
  dispatch({ type: 'DELETE_EXERCISE' });

  try {
    await deleteWorkoutExercise(workoutExerciseId);
    dispatch({
      type: 'DELETE_EXERCISE_SUCCESS',
      payload: workoutExerciseId,
    });
    Toast.success('Exercise deleted!');
  } catch (err) {
    dispatch({ type: 'DELETE_EXERCISE_FAILURE' });
    Toast.error('Something went wrong...');
    captureException(err);
  }
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'FETCH_PROGRAM':
      return { ...state, isProgramLoading: true };
    case 'FETCH_PROGRAM_SUCCESS':
      return { ...state, ...action.payload, isProgramLoading: false };
    case 'FETCH_PROGRAM_FAILURE':
      return { ...state, isProgramLoading: false };
    case 'UPDATE_PROGRAM_SUCCESS':
      return {
        ...state,
        ...action.payload,
        programWorkouts: action.payload.programWorkouts || [],
      };
    case 'CREATE_WORKOUT_SUCCESS':
      return {
        ...state,
        programWorkouts: [...state.programWorkouts, action.payload],
        fetchPlanExercisesStatusMap: {
          ...state.fetchPlanExercisesStatusMap,
          [action.payload.workoutId]: false,
        },
      };
    case 'UPDATE_WORKOUT_SUCCESS': {
      return {
        ...state,
        programWorkouts: state.programWorkouts.map((el) =>
          el.workoutId === action.payload.workout.id
            ? { ...el, workout: action.payload.workout }
            : el
        ),
        fetchPlanExercisesStatusMap: {
          ...state.fetchPlanExercisesStatusMap,
        },
        workoutExercises: [
          ...state.workoutExercises.filter(
            (el) => el.workoutId !== action.payload.workout.id
          ),
          ...(action.payload.workout.workoutExercises || []),
        ],
      };
    }
    case 'DELETE_WORKOUT_SUCCESS':
      return {
        ...state,
        programWorkouts: state.programWorkouts.filter(
          (el) => el.id !== action.payload
        ),
      };
    case 'FETCH_PLAN_EXERCISES_SUCCESS': {
      return {
        ...state,
        workoutExercises: [
          ...state.workoutExercises,
          ...action.payload.exercises,
        ],
        fetchPlanExercisesStatusMap: {
          ...state.fetchPlanExercisesStatusMap,
          [action.payload.planId]: true,
        },
      };
    }
    case 'UPDATE_EXERCISE_SUCCESS':
      return {
        ...state,
        workoutExercises: state.workoutExercises.map((el) =>
          el.id === action.payload.id ? action.payload : el
        ),
      };
    case 'DELETE_EXERCISE_SUCCESS': {
      return {
        ...state,
        workoutExercises: state.workoutExercises.filter(
          (el) => el.id !== action.payload
        ),
      };
    }
    default:
      return state;
  }
};

export default reducer;
