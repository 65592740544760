import { JustifyBetween } from 'styled';
import styled, { css } from 'styled-components';

const getSelectedStyles = (isSelected: boolean) => {
  if (isSelected) {
    return css`
      background-color: #fbfafd;
    `;
  }
};

export const Container = styled(JustifyBetween)<{ $isSelected: boolean }>`
  padding-bottom: 24px;
  padding-top: 12px;

  padding-left: 24px;
  padding-right: 72px;

  position: relative;
  cursor: pointer;

  ${({ $isSelected }) => getSelectedStyles($isSelected)};
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const CheckboxContainer = styled.div`
  position: absolute;

  top: 50%;
  right: 26px;

  transform: translateY(-50%);
`;

export const AttachmentsContainer = styled.div`
  margin-top: 8px;

  max-width: 100%;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Image = styled.img`
  object-fit: cover;

  width: 330px;
  height: 330px;

  border-radius: 12px;

  &:not(:last-child) {
    margin-right: 8px;
  }

  margin-bottom: 8px;
`;

export const DocumentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Document = styled.div`
  display: flex;
  align-items: center;

  max-width: 100%;
  overflow: hidden;

  & > svg {
    margin-right: 8px;
  }

  & > a {
    text-decoration: none;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: underline;
    }
  }

  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export const VideoContainer = styled.div<{ $imageUrl: string }>`
  cursor: pointer;

  position: relative;

  border-radius: 12px;

  min-width: 330px;
  max-width: 330px;

  min-height: 330px;
  max-height: 330px;

  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-size: cover;
  background-position: center center;

  display: flex;
  align-items: center;
  justify-content: center;
`;
