import styled from 'styled-components';

export const Container = styled.div<{ $width: number }>`
  position: relative;
  width: 100%;

  max-width: ${({ $width }) => `${$width}px`};
  min-width: ${({ $width }) => `${$width}px`};

  background-color: #f3eff9;
  height: 15px;
  border-radius: 5px;
  overflow: hidden;
`;

export const ProgressFill = styled.div<{ $width: number }>`
  position: absolute;

  left: 0;
  top: 0;
  height: 100%;

  width: ${({ $width }) => `${$width}%`};
  background-color: #d455b1;
`;
