import {
  DEFAULT_AVATAR,
  DEFAULT_VIDEO_PREVIEW,
  FITNESS_APP_VIDEO_PREFIX,
  ROUTE_PATH,
} from '@constants';
import { Search } from 'assets/svg';
import {
  ConfirmModal,
  EditVideoModal,
  ShareModal,
  SpinnerContainer,
  UploadVideoModal,
  VideoCard,
} from 'components';
import debounce from 'debounce-promise';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  createVideo,
  deleteVideo,
  getVideos,
  sendShareMessage,
  updateVideo,
} from 'services/api';
import Toast from 'services/Toast';
import { selectors } from 'store/ducks';
import { JustifyBetween } from 'styled';
import {
  ChatMessageDto,
  ShareVideoDto,
  UpdateVideoDto,
  Video,
  VideoHostingDto,
} from 'types';
import { BlockTabs, Button, TextInput } from 'UI';
import { buildShareVideoUrl } from 'utils';
import { Container, VideosContainer, InputCSS } from './styled';

enum TabValue {
  OwnVideos = 'ownVideos',
  AllVideos = 'allVideos',
}

const debounceFetch = debounce(getVideos, 500);

const VIDEOS_LIMIT = 15;

function Videos() {
  const history = useHistory();

  const userId = useSelector(selectors.auth.getUserId);

  const [activeTab, setActiveTab] = useState<TabValue>(TabValue.OwnVideos);
  const [inputValue, setInputValue] = useState('');
  const [videos, setVideos] = useState<Video[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [videoIdToShare, setVideoIdToShare] = useState<string | null>(null);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [videoIdToEdit, setVideoIdToEdit] = useState<string | null>(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [videoIdToDelete, setVideoIdToDelete] = useState<string | null>(null);

  const getOwnVideos = useCallback(
    async (searchValue?: string) => {
      setIsLoading(true);
      const { data } = await debounceFetch({
        page,
        limit: VIDEOS_LIMIT,
        videoName: searchValue,
        authorId: userId,
      });
      setVideos(data.data);
      setTotal(data.total);
      setIsLoading(false);
    },
    [page, userId]
  );

  const getAllVideos = useCallback(
    async (searchValue?: string) => {
      setIsLoading(true);
      const { data } = await debounceFetch({
        page,
        limit: VIDEOS_LIMIT,
        videoName: searchValue,
      });

      const publisedVideos = data.data.filter((video) => video.publish);
      setVideos(publisedVideos);
      setTotal(data.total);
      setIsLoading(false);
    },
    [page]
  );

  useEffect(() => {
    setPage(1);
  }, [activeTab]);

  useEffect(() => {
    setInputValue('');
    const fetchMethod =
      activeTab === TabValue.AllVideos ? getAllVideos : getOwnVideos;
    fetchMethod();
  }, [activeTab, page]);

  useEffect(() => {
    const fetchMethod =
      activeTab === TabValue.AllVideos ? getAllVideos : getOwnVideos;
    fetchMethod(inputValue);
  }, [inputValue, activeTab]);

  const handleShare = (id: string) => {
    const video = videos.find((video) => video.id === id)!;

    if (!video.videoImageUrl) {
      Toast.error(
        'You cant share this video, because is not uploaded',
        'Videos'
      );
      return;
    }

    setVideoIdToShare(id);
    setIsShareModalOpen(true);
  };

  const handleCreate = async (dto: VideoHostingDto) => {
    try {
      const { data } = await createVideo(dto);
      setVideos((videos) => [...videos, data]);
      setIsUploadModalOpen(false);
      Toast.success('Video uploaded', 'Videos');
    } catch (e) {
      Toast.error();
    }
  };

  const submitShare = async (values: ShareVideoDto) => {
    try {
      const attachmentVideoUrl = buildShareVideoUrl(
        values.id,
        values.vimeoId,
        values.previewUrl
      );

      const message: ChatMessageDto = {
        body: values.body || '',
        attachments: [{ attachmentUrl: attachmentVideoUrl }],
      };

      await Promise.all(
        values.usersIds.map(async (userId) => {
          await sendShareMessage(`${userId}`, message);
        })
      );

      setVideoIdToShare(null);
      setIsShareModalOpen(false);
      Toast.success('Video shared', 'Videos');
    } catch (e) {
      Toast.error();
    }
  };

  const handleEdit = (id: string) => {
    setVideoIdToEdit(id);
    setIsEditModalOpen(true);
  };

  const submitEdit = async (values: UpdateVideoDto) => {
    if (videoIdToEdit) {
      try {
        const { data } = await updateVideo(videoIdToEdit, values);
        setVideos((videos) =>
          videos.map((video) => (video.id === videoIdToEdit ? data : video))
        );
        setVideoIdToEdit(null);
        setIsEditModalOpen(false);
        Toast.success('Video updated', 'Videos');
      } catch (e) {
        Toast.error();
      }
    }
  };

  const handleDelete = (id: string) => {
    setVideoIdToDelete(id);
    setIsDeleteModalOpen(true);
  };

  const submitDelete = async () => {
    if (videoIdToDelete) {
      try {
        await deleteVideo(videoIdToDelete);
        setVideos((videos) =>
          videos.filter((video) => video.id !== videoIdToDelete)
        );
        setIsDeleteModalOpen(false);
        setVideoIdToDelete(null);
        Toast.success('Video deleted');
      } catch (e) {
        Toast.error();
      }
    }
  };

  return (
    <Container>
      <UploadVideoModal
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        onSubmit={(values) => handleCreate(values)}
      />
      <ShareModal
        title="Share video"
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        onSubmit={async (values) => {
          const videoToShare = videos.find(
            (video) => video.id === videoIdToShare
          )!;
          await submitShare({
            usersIds: values.users.map((el) => el.value),
            body: values.body,
            id: videoToShare.id,
            vimeoId: videoToShare.videoUrl!,
            previewUrl: videoToShare.videoImageUrl!,
          });
        }}
      />
      <ConfirmModal
        title="Delete video"
        description="Do you really want to delete this video?"
        agreeText="Delete"
        disagreeText="Cancel"
        isOpen={isDeleteModalOpen}
        onAgree={submitDelete}
        onClose={() => setIsDeleteModalOpen(false)}
      />
      {videoIdToEdit && (
        <EditVideoModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          initialEntity={videos.find((el) => el.id === videoIdToEdit)!}
          onSubmit={submitEdit}
        />
      )}
      <JustifyBetween $marginBottom={24}>
        <BlockTabs<TabValue>
          activeTabValue={activeTab}
          tabs={[
            { value: TabValue.OwnVideos, label: 'Your videos' },
            { value: TabValue.AllVideos, label: 'All videos' },
          ]}
          containerWidth={391}
          onChange={setActiveTab}
        />
        <Button text="Add video" onClick={() => setIsUploadModalOpen(true)} />
      </JustifyBetween>
      <TextInput
        theme="secondary"
        placeholder="Search"
        value={inputValue}
        leftChild={<Search fill="#A4A3B0" />}
        inputCSS={InputCSS}
        onChange={(e) => setInputValue(e.target.value)}
      />
      {isLoading ? (
        <SpinnerContainer />
      ) : (
        <VideosContainer>
          {videos.map((el) => (
            <VideoCard
              previewUrl={el.videoImageUrl || DEFAULT_VIDEO_PREVIEW}
              authorAvatar={el.author.avatarUrl || DEFAULT_AVATAR}
              authorName={`${el.author.firstName} ${el.author.lastName}`}
              date={el.createdAt}
              name={el.name}
              isEditAndDeleteVisible={activeTab === TabValue.OwnVideos}
              onShare={() => handleShare(el.id)}
              onEdit={() => handleEdit(el.id)}
              onDelete={() => handleDelete(el.id)}
              onClick={() => history.push(ROUTE_PATH.video(el.id))}
              key={el.id}
            />
          ))}
        </VideosContainer>
      )}
    </Container>
  );
}

export default Videos;
