import dayjs from 'dayjs';

export const formatDate = (date: string, format: string) => {
  return dayjs(date).format(format);
};

export const formatUnix = (date: number, format: string) => {
  return dayjs.unix(date).format(format);
};

export const isValidDate = (date: string, format?: string) => {
  return dayjs(date, format).isValid();
};
