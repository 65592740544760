import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $width: number;
  $height: number;
  $marginTop: number;
}>`
  z-index: 5;

  background: #ffffff;

  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 16px;

  padding: 10px;

  cursor: pointer;

  width: ${({ $width }) => `${$width}%`};
  height: calc(${({ $height }) => `${$height}%`} + 8px);
  margin-top: calc(${({ $marginTop }) => `${$marginTop}%`} - 4px);

  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ClientNameCSS = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-size: 12px;
  font-weight: bold;
`;

export const TrainingTitleCSS = css`
  display: -webkit-box;
  max-height: 3em;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const JustifyInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
