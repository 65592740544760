import { PusherEvents, ROUTE_PATH } from '@constants';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { PusherService } from 'services/Pusher';
import Toast from 'services/Toast';
import { actions, selectors } from 'store/ducks';
import { ChatMessage } from 'types';

export const useChatNotifications = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const userId = useSelector(selectors.auth.getUserId);

  const pusher = PusherService.pusherInstance!;

  useEffect(() => {
    const channelName = `private-message-${userId.replace('|', '@')}`;
    const privateChannel = pusher.subscribe(channelName);

    privateChannel.bind(PusherEvents.SUBSCRIPTION_SUCCESS, () => {
      console.log('subscribe to private channel');
    });

    privateChannel.bind(PusherEvents.NEW_MESSAGE, (data: ChatMessage) => {
      const isUserInChatroom = pathname.includes(`messages/${data.chatRoomId}`);

      if (!isUserInChatroom) {
        const userName = `${data.user.firstName} ${data.user.lastName}`;

        const handleRedirect = () => {
          history.push(ROUTE_PATH.chatRoom(`${data.chatRoomId}`));
        };

        dispatch(
          actions.chatRooms.updateLastMessage({
            chatRoomId: data.chatRoomId,
            message: data,
          })
        );

        Toast.message(
          data.body,
          `New message from: ${userName}`,
          handleRedirect
        );
      }
    });

    const unsubscribe = () => {
      privateChannel.unsubscribe();
    };

    window.addEventListener('unload', unsubscribe);

    return () => {
      privateChannel.unbind_all();
      window.removeEventListener('unload', unsubscribe);
    };
  }, [userId, pathname]);
};
