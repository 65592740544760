import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 14px;

  height: calc(100% - 54px);
  display: flex;
  flex-direction: column;

  flex: 1;
`;
