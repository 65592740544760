export const {
  REACT_APP_API_URL: BASE_URL,
  REACT_APP_PUSHER_APP_KEY: PUSHER_APP_KEY,
  REACT_APP_PUSHER_APP_CLUSTER: PUSHER_APP_CLUSTER,
  REACT_APP_PUSHER_AUTH_ROUTE: PUSHER_AUTH_ROUTE,
  REACT_APP_SENTRY_DSN: SENTRY_DSN,
  REACT_APP_PRODUCTION_MODE: PRODUCTION_MODE,
  REACT_APP_STRIPE_PUBLIC: STRIPE_PUBLIC,
  REACT_APP_STRIPE_RESTRICT_KEY: STRIPE_RESTRICT_KEY,
  REACT_APP_INTERCOM_APP_ID: INTERCOM_APP_ADD,
} = process.env;
