import React, { useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Headline, SubheadTwo } from 'styled';
import { DeleteAccountSection } from './styled';
import { Button } from 'UI';
import { deleteUser } from 'services/api';
import { useAsyncCallback } from 'hooks';
import { useAuth0 } from '@auth0/auth0-react';
import Toast from 'services/Toast';
import { ConfirmModal } from 'components';

dayjs.extend(relativeTime);

interface Props {
  userId: string;
}

function DeleteAccount({ userId }: Props) {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const { logout } = useAuth0();
  const handleDelete = async () => {
    try {
      await deleteUser(userId);
      logout({clientId: '', logoutParams: {
        returnTo: window.location.origin
      }});
    } catch (e) {
      Toast.error();
    }
  };

  const [asyncHandleDelete, isLoading] = useAsyncCallback(handleDelete);

  return (
    <DeleteAccountSection>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onAgree={() => asyncHandleDelete()}
        agreeText="Delete"
        disagreeText="Cancel"
        title="Delete account"
        description="This action will delete your account and all your data. Are you sure?"
      />
      <Headline $marginBottom={16}>Delete your account</Headline>
      <SubheadTwo $marginBottom={16}>
        Deleting your account will permanently remove all of your data
      </SubheadTwo>
      <Button
        text="Delete account"
        backgroundColor="#FFEEEB"
        textColor="#FF5934"
        hoverColor="#f8f1ef"
        onClick={() => setIsConfirmModalOpen(true)}
        isLoading={isLoading}
      />
    </DeleteAccountSection>
  );
}

export default DeleteAccount;
