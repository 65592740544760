import styled from 'styled-components';

export const LogoWrapper = styled.div`
  margin-bottom: 40px;
`;

export const NavigationWrapper = styled.nav`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const Aside = styled.aside`
  /* max-height: 100%; */

  width: 100%;
  max-width: 273px;
  min-width: 273px;
  box-sizing: border-box;

  padding: 28px 20px;

  background: #f9fbfd;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.span`
  color: #895fc6;
  font-weight: bold;
  font-size: 34px;
  line-height: 44px;

  cursor: pointer;
`;

export const Button = styled.button<{ $isActive?: boolean }>`
  position: relative;

  cursor: pointer;
  width: 100%;
  padding: 18px 26px;

  font-size: 14px;
  font-weight: bold;

  display: flex;
  justify-content: space-between;

  border-radius: 12px;
  border: none;

  line-height: 18px;
  background-color: ${({ $isActive }) =>
    $isActive ? '#895FC6' : 'transparent'};
  color: ${({ $isActive }) => ($isActive ? '#FFFFFF' : '#BFBFC9')};

  outline: none;

  &:not(:last-child) {
    margin-bottom: 11px;
  }

  transition: all 0.4s;

  &:hover {
    background-color: ${({ $isActive }) =>
      $isActive ? '#895FC6' : 'rgba(137, 95, 198, 0.5)'};
    color: white;

    & path {
      fill: white;
    }
  }
`;

export const IconWrapper = styled.i<{ $isActive?: boolean }>`
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 14px;

  & path {
    fill: ${({ $isActive }) => ($isActive ? '#FFFFFF' : '#BFBFC9')};
  }
`;

export const UnreadRoomsCount = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #d555b1;
  border-radius: 50%;

  right: 20px;

  width: 20px;
  height: 20px;

  color: white;

  font-weight: normal;
  font-size: 10px;
  line-height: 10px;
`;
