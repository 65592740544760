import React from 'react';
import { SingleValueProps } from 'react-select';
import { FlexCenter, SubheadTwo } from 'styled';
import { OptionType } from 'UI/types';
import { IconContainer } from './styled';

function SingleValue({
  children,
  data,
  innerProps,
  ...rest
}: SingleValueProps<OptionType, any>) {
  const { label, Icon } = data;

  return (
    <FlexCenter {...innerProps}>
      <IconContainer>{Icon}</IconContainer>
      <SubheadTwo>{label}</SubheadTwo>
    </FlexCenter>
  );
}

export default SingleValue;
