import React from 'react';
import { ButtonBaseProps } from 'UI/types';
import { StyledButton, Props as StyledButtonProps } from './styled';

interface Props extends ButtonBaseProps, StyledButtonProps {
  children: React.ReactNode;
}

function IconButton({ children, theme = 'primary', ...rest }: Props) {
  return (
    <StyledButton theme={theme} {...rest}>
      {children}
    </StyledButton>
  );
}

export default IconButton;
