import styled from 'styled-components';

export const ImageFieldContainer = styled.div`
  height: 234px;
  margin-bottom: 10px;

  & > div,
  & > div > div {
    height: 100%;
  }
`;

export const TwoColumnFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  align-items: flex-start;

  grid-column-gap: 86px;
  grid-row-gap: 5px;

  & > div {
    margin-bottom: 0 !important;

    & input {
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }
`;
