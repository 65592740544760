import styled, { css } from 'styled-components';

export const Overlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  position: fixed;
  background: rgba(25, 7, 66, 0.64);
  z-index: 10;

  overflow: scroll;
`;

export const getBigModalStyles = (isBig: boolean) => {
  if (isBig) {
    return css`
      top: 24px;
      left: 50%;
      transform: translateX(-50%);
      overflow: visible;
    `;
  }

  return css`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100vh - 96px);
    min-height: 500px;
    overflow: visible;
  `;
};

export const Modal = styled.div<{ $width: number; $isBig: boolean }>`
  position: absolute;

  background-color: white;

  width: ${(props) => props.$width}px;

  height: fit-content;

  border-radius: 12px;

  padding: 24px;

  display: flex;
  flex-direction: column;

  ${({ $isBig }) => getBigModalStyles($isBig)}
`;

export const Header = styled.div`
  margin: 0 -24px;
  padding: 0 24px 24px;

  border-bottom: 1px solid #e1e0e5;
`;

export const Close = styled.div`
  position: absolute;
  z-index: 10;

  top: -15px;
  right: -15px;

  & > button {
    transition: background-color 0.3s;
  }

  &:hover {
    & > button {
      background-color: #ac5b95;
    }
  }
`;

export const getBigModalChildrenStyles = (isBig: boolean) => {
  if (!isBig) {
    return css`
      max-height: calc(100vh - 168px);
    `;
  }
};

export const ChildrenContainer = styled.div<{ $isBig: boolean }>`
  padding-top: 24px;

  margin: 0 -24px;
  padding-left: 24px;
  padding-right: 24px;

  overflow: visible;

  ${({ $isBig }) => getBigModalChildrenStyles($isBig)}
`;
