import { FootnoteTwo, JustifyBetween } from 'styled';
import styled, { css } from 'styled-components';

export const ButtonsContainer = styled.div`
  display: none;

  align-items: center;

  & > svg {
    cursor: pointer;
  }

  & svg:last-child {
    margin-left: 24px;
  }

  & path {
    fill: #67667b;
    opacity: 1;
  }
`;

const getHoverStyles = (withHover?: boolean) => {
  if (withHover) {
    return css`
      &:hover {
        background-color: rgba(243, 239, 249, 0.3);

        & > ${ButtonsContainer} {
          display: flex;
        }
      }
    `;
  }

  return css`
    & > ${ButtonsContainer} {
      display: flex;
    }
  `;
};

export const Container = styled(JustifyBetween)<{ $withHover?: boolean }>`
  border-radius: 14px;

  padding-right: 24px;
  ${({ $withHover }) => getHoverStyles($withHover)}
`;

export const Preview = styled.div<{ $url: string }>`
  width: 56px;
  height: 56px;
  border-radius: 14px;

  background-image: url(${({ $url }) => $url});
  background-size: cover;
  background-position: center center;

  margin-right: 16px;
`;

export const Duration = styled(FootnoteTwo)`
  text-align: center;
  width: 60px;
  padding: 4px 0px;

  color: #d555b1;
  background: rgba(212, 85, 177, 0.08);
  border-radius: 10px;

  margin-right: 20px;
`;
