import * as React from 'react';

function SvgFile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.5 7.5h5a.833.833 0 00.833-.833v-5h8.335c.46 0 .832.38.832.827v15.013a.828.828 0 01-.828.827H3.329a.834.834 0 01-.828-.84V7.5zm0-1.666l4.167-4.165v4.165H2.5z"
        fill="#67667B"
        opacity={0.6}
      />
    </svg>
  );
}

export default SvgFile;
