import styled from 'styled-components';

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > div {
    width: 50%;
  }

  & > div:first-child {
    margin-right: 24px;
  }

  margin-bottom: 24px;
`;
