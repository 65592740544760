import React, { useRef, useState } from 'react';
import {
  CircleAvatar,
  FlexStart,
  FootnoteTwo,
  JustifyBetween,
  SubheadOne,
  SubheadTwo,
} from 'styled';
import {
  Preview,
  Container,
  InfoContainer,
  ButtonContainer,
  ButtonsContainer,
  MoreButton,
} from './styled';
import dayjs from 'dayjs';
import { Delete, Edit, More, Share } from 'assets/svg';
import { useOnClickOutside } from 'hooks';

type Props = {
  previewUrl: string;
  authorAvatar: string;
  authorName: string;
  date: string;
  name: string;

  isEditAndDeleteVisible?: boolean;

  onClick?: () => void;
  onShare: () => void;
  onEdit: () => void;
  onDelete: () => void;
};

function VideoCard({
  previewUrl,
  authorAvatar,
  authorName,
  date,
  name,
  isEditAndDeleteVisible = false,
  onClick,
  onShare,
  onEdit,
  onDelete,
}: Props) {
  const [isButtonsVisible, setIsButtonsVisible] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsButtonsVisible(false));
  const moreButtons = (
    <ButtonsContainer>
      <ButtonContainer onClick={onShare}>
        <Share />
        <SubheadTwo $margin="0px 0px 0px 10px">Share</SubheadTwo>
      </ButtonContainer>
      {isEditAndDeleteVisible && (
        <React.Fragment>
          <ButtonContainer onClick={onEdit}>
            <Edit />
            <SubheadTwo $margin="0px 0px 0px 10px">Edit</SubheadTwo>
          </ButtonContainer>
          <ButtonContainer onClick={onDelete}>
            <Delete />
            <SubheadTwo $margin="0px 0px 0px 10px">Delete</SubheadTwo>
          </ButtonContainer>
        </React.Fragment>
      )}
    </ButtonsContainer>
  );

  return (
    <Container>
      <Preview
        src={previewUrl}
        onClick={onClick}
        $withVideo={Boolean(onClick)}
      />
      <InfoContainer>
        <FlexStart>
          <CircleAvatar $url={authorAvatar} $size={24} $marginRight={8} />
          <SubheadTwo>{authorName}</SubheadTwo>
        </FlexStart>
        <FootnoteTwo $color="#67667B">
          {dayjs(date).format('DD.MM.YYYY')}
        </FootnoteTwo>
      </InfoContainer>
      <JustifyBetween>
        <SubheadOne>{name}</SubheadOne>
        <MoreButton ref={ref} onClick={() => setIsButtonsVisible(true)}>
          <More />
          {isButtonsVisible && moreButtons}
        </MoreButton>
      </JustifyBetween>
    </Container>
  );
}

export default VideoCard;
