import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { OptionsType } from 'react-select';
import { FlexStart, FootnoteOne, SubheadOne } from 'styled';
import { Exercise, TrackingFieldsValue } from 'types';
import { SelectDropdown, SmallRemoveButton } from 'UI';
import { OptionType } from 'UI/types';
import { uniqueArray } from 'utils';
import { SelectedField, SelectedFieldsContainer, Container } from './styled';

type Props = FieldRenderProps<Exercise['trackingFields']> & {
  disabled?: boolean;
};

const options: { value: TrackingFieldsValue; label: string }[] = [
  { value: 'cadence', label: 'Cadence' },
  { value: 'calories', label: 'Calories' },
  { value: 'distanceLong', label: 'Distance long' },
  { value: 'distanceShort', label: 'Distance short' },
  { value: 'heartRate', label: 'Heart rate' },
  { value: 'reps', label: 'Reps' },
  { value: 'rest', label: 'Rest' },
  { value: 'rm', label: 'RM' },
  { value: 'rpe', label: 'RPE' },
  { value: 'speed', label: 'Speed' },
  { value: 'time', label: 'Time' },
  { value: 'weight', label: 'Weight' },
];

const dropdownValue = [];

function TrackingFieldsField({ input, meta, disabled }: Props) {
  const error = meta.touched && meta.error;
  const values = input.value || [];

  const handleAddTrackingField = (newValues: OptionsType<OptionType>) => {
    const newSelectedDropdown = newValues.map((value) => value.value);
    const newFieldValue = [...values, ...newSelectedDropdown];

    const unique = uniqueArray(newFieldValue, (a, b) => a === b);

    input.onChange(unique);
  };

  const removeField = (fieldToRemove: TrackingFieldsValue) => {
    if (!disabled) {
      const newFieldValue = values.filter((value) => value !== fieldToRemove);
      input.onChange(newFieldValue);
    }
  };

  const getValueLabel = (value: TrackingFieldsValue) => {
    return options.find((option) => option.value === value)!.label;
  };

  return (
    <Container>
      <SubheadOne $marginBottom={8} $color={disabled ? '#E1E0E5' : '#201E3F'}>
        Tracking Fields
      </SubheadOne>
      <SelectedFieldsContainer>
        <FlexStart>
          {values.map((value) => (
            <SelectedField key={value}>
              {getValueLabel(value)}
              <SmallRemoveButton onClick={() => removeField(value)} />
            </SelectedField>
          ))}
        </FlexStart>
        <SelectDropdown
          options={options}
          isMulti
          value={dropdownValue}
          disabled={values.length === 3 || disabled}
          onChange={handleAddTrackingField}
        />
      </SelectedFieldsContainer>
      {error && <FootnoteOne $color="#FF5934">{error}</FootnoteOne>}
    </Container>
  );
}

export default TrackingFieldsField;
