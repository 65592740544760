import React from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DEFAULT_AVATAR } from '@constants';
import { Edit } from 'assets/svg';
import {
  CircleAvatar,
  Headline,
  TitleFive,
  SubheadTwo,
  BodyOne,
  colors,
} from 'styled';
import { User } from 'types';
import IconButton from 'UI/IconButton';
import {
  ProfileInfoSection,
  InfoContainer,
  Info,
  DescriptionContainer,
  ButtonContainer,
} from './styled';

dayjs.extend(relativeTime);

interface ProfileInfoProps {
  user: User;
}

function ProfileInfo({ user }: ProfileInfoProps) {
  const { push } = useHistory();

  return (
    <ProfileInfoSection>
      <Headline>My profile</Headline>
      <InfoContainer>
        <CircleAvatar
          $url={user.avatarUrl ? user.avatarUrl : DEFAULT_AVATAR}
          $size={80}
        />
        <Info>
          <TitleFive>
            {user.firstName} {user.lastName}
          </TitleFive>
          {user.experience && (
            <SubheadTwo>{user.experience} years experience</SubheadTwo>
          )}
          <DescriptionContainer>
            <BodyOne>About me</BodyOne>
            <SubheadTwo>{user.description}</SubheadTwo>
          </DescriptionContainer>
        </Info>
        <ButtonContainer>
          <IconButton
            theme="secondary"
            onClick={() => push('/profile/edit', user)}
          >
            <Edit fill={colors.primaryPurple} />
          </IconButton>
        </ButtonContainer>
      </InfoContainer>
    </ProfileInfoSection>
  );
}

export default ProfileInfo;
