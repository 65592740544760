import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { selectors } from 'store/ducks/auth';
import image from 'assets/images/background.png';
import { CreateAccountForm } from './components';
import { Root, Header, Logo, Main, RootColor } from './styled';

function CreateAccount() {
  const user = useSelector(selectors.getUserInfo);
  const { logout } = useAuth0();
  const { push } = useHistory();
  const handleLogoClick = () => {
    if (user.createdAt === user.updatedAt) {
      logout({clientId: '', logoutParams: {returnTo: window.location.origin}});
    } else {
      push('/');
    }
  };

  return (
    <Root
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <RootColor>
        <Header>
          <Logo onClick={handleLogoClick}>Fitness app</Logo>
        </Header>
        <Main>
          <CreateAccountForm user={user} />
        </Main>
      </RootColor>
    </Root>
  );
}

export default CreateAccount;
