import { ToastContainer } from 'react-toastify';
import { FlexCenter } from 'styled';
import styled from 'styled-components';

export const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
})`
  bottom: 24px;
  left: 24px;

  padding: 0;

  .toast {
    width: 355px;
    padding: 16px 16px 12px 20px;
    background-color: #ffffff;
    box-shadow: 0px 4px 32px rgba(32, 30, 63, 0.1);
    border-radius: 12px;
    box-sizing: border-box;
    overflow: visible;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Icon = styled(FlexCenter)`
  width: 40px;
  height: 40px;
  margin-right: 16px;
`;

export const ToastMessageContainer = styled.div`
  flex-grow: 1;
`;

export const ButtonClose = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
`;
