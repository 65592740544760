import styled from 'styled-components';

export const Container = styled.div``;

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 652px) repeat(3, minmax(160px, 1fr));
  grid-column-gap: 32px;

  justify-content: space-between;
`;

export const GridTopRow = styled(GridRow)`
  margin-bottom: 20px;
  justify-content: center;

  & > div:not(:first-child):not(:last-child) {
    justify-content: center;
  }

  & > div:last-child {
    justify-content: flex-end;
  }
`;

export const GridDataRow = styled(GridRow)`
  cursor: pointer;

  position: relative;

  border-radius: 14px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  & > div:not(:first-child) {
    text-align: center;
  }

  &:hover {
    background-color: #f3eff9;
  }

  & svg {
    transform: scale(0.6);
  }
`;

export const MoreButtonContainer = styled.div`
  cursor: pointer;

  position: absolute;

  right: 40px;
  top: 50%;
  transform: translateY(-50%);

  & > svg {
    & path {
      fill: #c1c1c1;
    }
  }
`;

export const PopupContainer = styled.div`
  z-index: 3;

  & > div {
    top: 45px;
    right: 40px;
  }
`;

export const Image = styled.img`
  object-fit: cover;

  width: 56px;
  height: 56px;
  border-radius: 14px;

  margin-right: 30px;
`;

export const AuthorContainer = styled.div`
  position: relative;

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const CoachesLogoContainer = styled.div`
  position: absolute;
  right: -35px;
  top: 5px;

  & svg {
    transform: scale(0.5);
  }
`;
