import React, { useRef, useState } from 'react';
import { Calendar } from 'assets/svg';
import { FieldRenderProps } from 'react-final-form';
import { FieldWrapper } from 'UI';
import { Datepicker } from 'components';
import { BodyThree, FilledArrow } from 'styled';
import dayjs from 'dayjs';
import { useOnClickOutside } from 'hooks';
import {
  Container,
  DatepickerCSS,
  FieldWrapperCSS,
  InputContainer,
} from './styled';

export type DateRange = {
  startDate: string;
  endDate: string;
};

export function DateStartField({ input, meta }: FieldRenderProps<DateRange>) {
  const handleChange = (newDate: string) => {
    input.onChange(newDate);
  };

  return (
    <DateField
      input={{ ...input, value: input.value.startDate, onChange: handleChange }}
      meta={{
        ...meta,
        initial: meta.initial?.startDate,
      }}
    />
  );
}

function DateField({ input }: FieldRenderProps<string>) {
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const handleChange = (newDate: string) => {
    input.onChange(newDate);
    setIsPickerOpen(false);
  };

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => setIsPickerOpen(false));

  return (
    <FieldWrapper
      label="Date"
      Icon={<Calendar />}
      iconColor="#CACDD7"
      containerCSS={FieldWrapperCSS}
      isArrowActive={isPickerOpen}
    >
      <Container ref={ref}>
        <InputContainer onClick={() => setIsPickerOpen(!isPickerOpen)}>
          <BodyThree>{dayjs(input.value).format('MMMM DD')}</BodyThree>
          <FilledArrow $color="#201E3F" $width={6} $isActive={isPickerOpen} />
        </InputContainer>
        <Datepicker
          date={input.value}
          isOpen={isPickerOpen}
          onChange={handleChange}
          containerCSS={DatepickerCSS}
        />
      </Container>
    </FieldWrapper>
  );
}

export default DateField;
