import styled from 'styled-components';

export const PairFields = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: 24px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;

  & > button {
    /* max-width: fit-content; */
    padding-left: 40px;
    padding-right: 40px;
  }

  & > button:first-child {
    margin-right: 24px;
  }
`;
