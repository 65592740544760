import * as React from 'react';

function SvgVideo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.334 3.333a.833.833 0 01.833.833v3.5l4.344-3.041a.417.417 0 01.656.341v10.067a.416.416 0 01-.656.342l-4.344-3.042v3.5a.834.834 0 01-.833.833H1.667a.834.834 0 01-.834-.833V4.166a.833.833 0 01.834-.833h11.666zM6.167 7.357a.333.333 0 00-.327.267l-.006.066v4.618a.333.333 0 00.451.312l.061-.03 3.63-2.31a.333.333 0 00.052-.52l-.053-.042-3.629-2.31a.333.333 0 00-.18-.05z"
        fill="#67667B"
        opacity={0.6}
      />
    </svg>
  );
}

export default SvgVideo;
