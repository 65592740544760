import * as React from 'react'

function SvgCloseEx(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width={12}
			height={12}
			fill='#fff'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M6 4.815L10.15.667l1.185 1.185L7.185 6l4.149 4.148-1.185 1.185L6 7.185l-4.148 4.148-1.185-1.185L4.815 6 .667 1.852 1.852.667 6 4.815z'
				fill='inherit'
			/>
		</svg>
	)
}

export default SvgCloseEx
