import { CloseEx } from 'assets/svg';
import React from 'react';
import { MultiValueProps } from 'react-select';
import { FlexCenter, SubheadTwo } from 'styled';
import { OptionType } from 'UI/types';
import { MultiValueContainer } from './styled';

function MultiValue({
  children,
  innerProps,
  ...rest
}: MultiValueProps<OptionType, any>) {
  return (
    <MultiValueContainer {...innerProps}>
      <SubheadTwo $margin="0px 20px 0px 0px">{children}</SubheadTwo>{' '}
      <FlexCenter {...rest.removeProps}>
        <CloseEx />
      </FlexCenter>
    </MultiValueContainer>
  );
}

export default MultiValue;
