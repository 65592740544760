import React, { useMemo, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Container, LabelDrop, LoadingContainer } from './styled';
import { File } from 'assets/svg';
import { BodyThree, Headline } from 'styled';
import Toast from 'services/Toast';
import { getLoadFileErrorMap } from '@constants';
import { uploadFileS3 } from 'utils';
import { ProgressBar } from 'UI';

type Props = FieldRenderProps<string>;

function LoadVideoField({
  input,
  maxMegaBytes = 512 * 1024 * 1024,
  accept = [
    'video/mp4',
    'video/quicktime',
    'video/x-ms-wmv',
    'video/x-msvideo',
  ],
}: Props) {
  const [progress, setProgress] = useState<number | null>(null);

  const errorMap = useMemo(() => {
    return getLoadFileErrorMap({ maxMegaBytes, accept });
  }, [maxMegaBytes, accept]);

  const handleUploadFiles = async (
    files: File[],
    rejections: FileRejection[]
  ) => {
    if (files && files.length > 0) {
      const video = files[0];
      const url = await uploadFileS3(video, (progress) =>
        setProgress(progress)
      );
      input.onChange(url);
    }
    if (rejections) {
      rejections.forEach((e) => {
        Toast.error(errorMap[e.errors[0].code], `File: ${e.file.name}`);
      });
    }
  };

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    maxSize: maxMegaBytes,
    onDrop: (files, rejections) => handleUploadFiles(files, rejections),
    accept,
    maxFiles: 1,
  });

  const renderContent = () => {
    if (!progress) {
      return (
        <>
          <LabelDrop onClick={(e) => e.stopPropagation()}>
            <label>
              <input type="file" {...getInputProps()} />
              <File />
            </label>
          </LabelDrop>
          <BodyThree>Drag and drop the necessary files here</BodyThree>
        </>
      );
    }

    return (
      <LoadingContainer>
        {progress !== 100 ? (
          <React.Fragment>
            <Headline $marginBottom={12}>{`Upload ${progress}%`}</Headline>
            <ProgressBar progress={progress} width={200} />
          </React.Fragment>
        ) : (
          <Headline>Uploaded</Headline>
        )}
      </LoadingContainer>
    );
  };

  return (
    <Container {...getRootProps()} $isDragActive={isDragActive}>
      {renderContent()}
    </Container>
  );
}

export default LoadVideoField;
