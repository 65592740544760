import React, { useState, useEffect, useCallback } from 'react';
import ClientInfo from './components/ClientInfo';
import CalendarPlanList from './components/CalendarPlanList';
import { DEFAULT_AVATAR, ROUTE_PATH } from '@constants';
import {
  CalendarEvent,
  CreateCalendarEventBody,
  Program,
  RelationUsers,
  Workout,
  ProgramWorkout,
  User,
} from 'types';
import { Wrapper } from './styled';
import { SpinnerContainer, CalendarEventModal } from 'components';
import {
  createCalendarEvent,
  getCoachCalendarEvents,
  getOneUser,
  getOneUserRelation,
  initiateChat,
  updateUsersRelation,
} from 'services/api';
import { useHistory, useParams } from 'react-router';
import Toast from 'services/Toast';
import { captureException } from '@sentry/minimal';
import { AxiosError } from 'axios';

function ClientInformation() {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const [isLoadingEvents, setLoadingEvents] = useState<boolean>(false);
  const [isLoadingClient, setLoadingClient] = useState<boolean>(false);
  const [client, setClient] = useState<User | null>();
  const [relation, setRelation] = useState<RelationUsers | null>(null);
  const [workoutEvents, setWorkoutEvents] = useState<CalendarEvent[]>([]);

  const [isAddWorkoutOpen, setIsAddWorkoutOpen] = useState(false);

  const fetchWorkouts = useCallback(async () => {
    setLoadingEvents(true);
    try {
      const { data } = await getCoachCalendarEvents({
        clientId: id,
        startSearchDate: new Date().toUTCString(),
      });
      setWorkoutEvents(data);
      setLoadingEvents(false);
    } catch (err) {
      captureException(err);
      setLoadingEvents(false);
    }
  }, []);

  const fetchClient = useCallback(async () => {
    setLoadingClient(true);
    try {
      const { data: client } = await getOneUser(id);
      setClient(client);

      try {
        const { data: relation } = await getOneUserRelation(id);
        setRelation(relation);
      } catch (e) {
        setRelation(null);
      }

      setLoadingClient(false);
    } catch (err) {
      captureException(err);
      setLoadingClient(false);
    }
  }, [id]);

  useEffect(() => {
    fetchWorkouts();
    fetchClient();
  }, [id]);

  const handleAddWorkout = useCallback(
    async (body: CreateCalendarEventBody) => {
      try {
        const { data } = await createCalendarEvent(body);
        const calendarEvent: CalendarEvent = {
          ...data,
          client: data.client as User,
          author: {} as User,
          authorId: '',
        } as CalendarEvent;
        setWorkoutEvents([...workoutEvents, calendarEvent]);
        setIsAddWorkoutOpen(false);
        Toast.success('Workout created!');
      } catch (err) {
        Toast.error((err as AxiosError).message || 'Something went wrong');
      }
    },
    [workoutEvents]
  );

  const handleSendMessage = async () => {
    try {
      if (client) {
        const { data } = await initiateChat(client?.id);
        history.push(ROUTE_PATH.chatRoom(`${data.id}`));
      }
    } catch (e) {
      captureException(e);
      Toast.error();
    }
  };

  const handleArchive = async () => {
    try {
      if (client) {
        await updateUsersRelation(client.id, {
          archived: !relation?.archived,
          status: 'accept',
        });
        setRelation({ ...relation!, archived: !relation?.archived });
      }
    } catch (e) {
      Toast.error();
    }
  };

  if (isLoadingClient || isLoadingEvents || !client) {
    return (
      <Wrapper>
        <SpinnerContainer />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {isAddWorkoutOpen && (
        <CalendarEventModal
          isOpen={isAddWorkoutOpen}
          onClose={() => setIsAddWorkoutOpen(false)}
          onSubmit={(values) =>
            handleAddWorkout({
              clientId: values.clientId.value,
              workoutId: values.workoutId.value,
              startDateTime: values.startDate,
              endDateTime: values.endDate,
              workoutMetaDto: values.workoutMeta,
            })
          }
          initialEntity={
            { client: client, clientId: client?.id } as CalendarEvent
          }
          isAddForSpecificClientModal
        />
      )}
      <ClientInfo
        firstName={client.firstName!}
        lastName={client.lastName!}
        dateBirth={client.birthday!}
        avatarUrl={client.avatarUrl || DEFAULT_AVATAR}
        email={client.email}
        height={client.height!}
        weight={client.weight!}
        isArchived={relation?.archived}
        onClickButtonMessage={
          relation?.status === 'accept' ? () => handleSendMessage() : undefined
        }
        onClickButtonArchive={
          relation?.status === 'accept' ? () => handleArchive() : undefined
        }
      />
      {relation?.status === 'accept' && workoutEvents && (
        <CalendarPlanList
          workoutEvents={workoutEvents}
          onClick={() => setIsAddWorkoutOpen(true)}
        />
      )}
    </Wrapper>
  );
}

export default ClientInformation;
