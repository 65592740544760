import { ROUTE_PATH } from '@constants';
import { Alert, Back, CloseEx, Copy, Delete } from 'assets/svg';
import React from 'react';
import { useHistory } from 'react-router';
import { CircleAvatar, FlexStart, FootnoteTwo, SubheadOne } from 'styled';
import { IconButton } from 'UI';
import {
  Container,
  LeftIconContainer,
  RightButtonsContainer,
  JustifyWrapper,
  CopyButtonCSS,
  DeleteButtonCSS,
  AlertButtonCSS,
  RecipientNameCSS,
} from './styled';

type Props = {
  id: string;
  userAvatarUrl: string;
  userName: string;
  onlineStatus: string;

  selectedMessagesCount: number;

  onCancelSelect: () => void;
  onCopy: () => void;
  onDelete: () => void;
  onReport: () => void;
};

function Head({
  id,
  userAvatarUrl,
  userName,
  onlineStatus,
  selectedMessagesCount,
  onCancelSelect,
  onCopy,
  onDelete,
  onReport,
}: Props) {
  const history = useHistory();

  if (selectedMessagesCount !== 0) {
    return (
      <Container>
        <JustifyWrapper>
          <FlexStart>
            <LeftIconContainer>
              <CloseEx
                style={{ transform: 'scale(1.2)' }}
                onClick={onCancelSelect}
              />
            </LeftIconContainer>
            <SubheadOne>{`${selectedMessagesCount} messages`}</SubheadOne>
          </FlexStart>
          <RightButtonsContainer>
            {selectedMessagesCount === 1 && (
              <IconButton $CSS={CopyButtonCSS} onClick={onCopy}>
                <Copy />
              </IconButton>
            )}
            <IconButton $CSS={DeleteButtonCSS} onClick={onDelete}>
              <Delete />
            </IconButton>
          </RightButtonsContainer>
        </JustifyWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <JustifyWrapper>
        <FlexStart>
          <LeftIconContainer>
            <Back onClick={history.goBack} />
          </LeftIconContainer>
          <CircleAvatar
            $url={userAvatarUrl}
            $size={44}
            $marginRight={12}
            $withPointerCursor
            onClick={() => history.push(ROUTE_PATH.userProfile(id))}
          />
          <div>
            <SubheadOne
              $marginBottom={4}
              $CSS={RecipientNameCSS}
              onClick={() => history.push(ROUTE_PATH.userProfile(id))}
            >
              {userName}
            </SubheadOne>
            <FootnoteTwo $color="#67667B">{onlineStatus}</FootnoteTwo>
          </div>
        </FlexStart>
      </JustifyWrapper>
      <IconButton $CSS={AlertButtonCSS} onClick={onReport}>
        <Alert />
      </IconButton>
    </Container>
  );
}

export default Head;
