import styled from 'styled-components';

export const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 88px;
`;

export const PageNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BackArrowContainer = styled.div`
  margin-left: 24px;
  padding-left: 24px;
  border-left: 1px solid #e1e0e5;

  display: flex;
  align-items: center;
  max-height: 20px;

  & svg {
    cursor: pointer;

    & path {
      transition: all 0.2s;
    }
    &:hover {
      & path {
        fill: #67667b;
      }
    }
  }
`;
