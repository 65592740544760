import React, { useEffect, useMemo, useState } from 'react';
import { SelectField, TextField } from 'components';
import ModalWrapper from 'components/ModalWrapper';
import { useAsyncCallback } from 'hooks';
import { ModalWithForm } from 'interfaces/common';
import { Field, Form } from 'react-final-form';
import { getVideos } from 'services/api';
import Toast from 'services/Toast';
import { FlexStart, JustifyBetween } from 'styled';
import {
  Exercise,
  ExerciseBodyPartValue,
  ExerciseDifficultyValue,
  ExerciseEquipmentValue,
  TrackingFieldsValue,
  Video,
} from 'types';
import { Button } from 'UI';
import { OptionType } from 'UI/types';
import { isRequired, isRequiredLength } from 'utils/validation';
import { MultiImageField, TrackingFieldsField } from './components';
import { TwoColumnFieldsContainer } from './styled';
import {
  equipmentOptions,
  difficultyOptions,
  bodyPartOptions,
} from './constants';

type FormValues = {
  name: string;
  description?: string;
  instructions?: string;
  difficulty: OptionType<ExerciseDifficultyValue>;
  equipment: OptionType<ExerciseEquipmentValue>[];
  bodyPart: OptionType<ExerciseBodyPartValue>;
  trackingFields: TrackingFieldsValue[];
  images: string[];
  videoId?: OptionType<string>;
};

function mapEntityValueToOption<T>(valueOrArrayValue: T, options: any[]) {
  function mapValueToOption(value: T) {
    const option = options.find((opt) => opt.value === value);
    return option;
  }

  const isArray = Array.isArray(valueOrArrayValue);
  if (isArray) {
    return (valueOrArrayValue as any).map(mapValueToOption);
  }

  return mapValueToOption(valueOrArrayValue);
}

type Props = ModalWithForm<FormValues> & {
  initialEntity?: Exercise;
  disabled?: boolean;

  onDelete?: () => void;
};

function ExerciseModal({
  onSubmit,
  initialEntity,
  disabled = false,
  onDelete,
  ...rest
}: Props) {
  const [asyncSubmit, isSubmitLoading] = useAsyncCallback(onSubmit);
  const [asyncDelete, isDeleteLoading] = useAsyncCallback(onDelete);

  const [videos, setVideos] = useState<Video[]>([]);

  const videosOptions = useMemo(
    () =>
      videos.map((video) => ({
        value: video.id,
        label: video.name,
      })),
    [videos]
  );

  const [fetchVideos, isFetchVideosLoading] = useAsyncCallback(async () => {
    try {
      const { data } = await getVideos();
      setVideos(data.data);
    } catch (e) {
      Toast.error();
    }
  });

  useEffect(() => {
    fetchVideos();
  }, []);

  const initialValues = useMemo<FormValues | undefined>(() => {
    if (initialEntity) {
      return {
        name: initialEntity.name,
        description: initialEntity.description,
        instructions: initialEntity.instructions,
        bodyPart: mapEntityValueToOption(
          initialEntity.bodyPart,
          bodyPartOptions
        ),
        difficulty: mapEntityValueToOption(
          initialEntity.difficulty,
          difficultyOptions
        ),
        equipment: mapEntityValueToOption(
          initialEntity.equipment,
          equipmentOptions
        ),
        trackingFields: initialEntity.trackingFields,
        images: initialEntity.images || [],
        videoId: mapEntityValueToOption(initialEntity.videoId, videosOptions),
      };
    }
  }, [videosOptions]);

  if (!onSubmit) {
    return null;
  }

  const isEditModal = Boolean(initialEntity);

  return (
    <ModalWrapper
      title={isEditModal ? 'Edit exercise' : 'Add exercise'}
      isBigModal
      width={636}
      {...rest}
    >
      <Form<FormValues>
        onSubmit={asyncSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, hasValidationErrors, dirty }) => (
          <form onSubmit={handleSubmit}>
            <TwoColumnFieldsContainer>
              <Field
                name="name"
                label="Name"
                maxLength={60}
                validate={isRequired()}
                disabled={disabled}
                component={TextField}
              />
              <Field
                name="equipment"
                label="Equipment"
                isMulti
                hideSelectedOptions
                options={equipmentOptions}
                validate={isRequired()}
                disabled={disabled}
                component={SelectField}
              />
              <Field
                name="bodyPart"
                label="Primary Focus"
                options={bodyPartOptions}
                validate={isRequired()}
                disabled={disabled}
                component={SelectField}
              />
              <Field
                name="difficulty"
                label="Difficulty"
                options={difficultyOptions}
                validate={isRequired()}
                disabled={disabled}
                component={SelectField}
              />
            </TwoColumnFieldsContainer>
            <Field
              name="trackingFields"
              validate={isRequiredLength(3, 1)}
              disabled={disabled}
              component={TrackingFieldsField}
            />
            <Field
              name="description"
              label="Description"
              disabled={disabled}
              component={TextField}
            />
            <Field
              name="instructions"
              label="Instructions"
              disabled={disabled}
              component={TextField}
            />
            <Field
              name="videoId"
              label="Video"
              options={videosOptions}
              disabled={isFetchVideosLoading || disabled}
              isClearable
              parse={(value) => value || null}
              component={SelectField}
            />
            <Field
              name="images"
              options={videosOptions}
              validate={isRequiredLength(5, 1)}
              disabled={disabled}
              component={MultiImageField}
            />
            <JustifyBetween>
              <FlexStart>
                <Button
                  text={isEditModal ? 'Save exercise' : 'Add exercise'}
                  type="submit"
                  isLoading={isSubmitLoading}
                  disabled={
                    hasValidationErrors ||
                    (isEditModal && !dirty) ||
                    isDeleteLoading ||
                    disabled
                  }
                  $margin="0px 12px 0px 0px"
                />
                <Button
                  text="Cancel"
                  theme="secondary"
                  type="button"
                  disabled={isSubmitLoading || isDeleteLoading}
                  onClick={rest.onClose}
                />
              </FlexStart>
              <Button
                text="Delete"
                theme="delete"
                type="button"
                disabled={isSubmitLoading || disabled}
                isLoading={isDeleteLoading}
                onClick={() => asyncDelete()}
              />
            </JustifyBetween>
          </form>
        )}
      />
    </ModalWrapper>
  );
}

export default ExerciseModal;
