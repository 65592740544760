import { FlexCenter } from 'styled';
import styled, { css } from 'styled-components';

const getDragStyles = (isDragActive: boolean) => {
  if (isDragActive) {
    return css`
      opacity: 0.5;
    `;
  }
};

export const Container = styled(FlexCenter)<{ $isDragActive: boolean }>`
  cursor: pointer;
  box-sizing: border-box;
  min-height: 240px;

  background: #f3eff9;

  border: 1px dashed rgba(25, 7, 66, 0.64);
  box-sizing: border-box;
  border-radius: 12px;

  flex-direction: column;
  outline: none;

  transition: all 0.2s;
  ${({ $isDragActive }) => getDragStyles($isDragActive)}
`;

export const LabelDrop = styled(FlexCenter)`
  cursor: pointer;

  padding: 18px;
  background: #e7e0f3;
  border-radius: 12px;

  margin-bottom: 20px;

  & > label {
    cursor: pointer;
  }
`;

export const LoadingContainer = styled.div`
  text-align: center;

  & > div {
    background-color: #ded2ef;
    height: 8px;
  }
`;
