import styled, { css, CSSProp } from 'styled-components';
import { TextInputBaseProps } from 'UI/types';

export const Wrapper = styled.div<{ $CSS?: CSSProp }>`
  display: flex;
  align-items: center;

  position: relative;

  padding-bottom: 20px;

  ${({ $CSS }) => $CSS}
`;

export const Container = styled.div<{ $CSS?: CSSProp }>`
  display: flex;
  flex-direction: column;

  ${({ $CSS }) => $CSS}
`;

export const InputContainer = styled.div`
  position: relative;

  display: flex;
  width: 100%;
`;

export const LeftChildContainer = styled.div`
  position: absolute;
  left: 22px;
  top: 50%;
  transform: translateY(-50%);
`;

export const RightChildContainer = styled.div`
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
`;

const getThemeCSS = (
  theme: TextInputBaseProps['theme'],
  $withError?: boolean
): CSSProp => {
  switch (theme) {
    case 'primary':
      return css`
        background: ${$withError ? '#FFF8F7' : '#f2f7ff'};
      `;
    case 'secondary':
      return css`
        background: ${$withError ? '#FFF8F7' : '#ffffff'};
        box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
          0px 0px 4px rgba(34, 32, 65, 0.1);
      `;
    default:
      return '';
  }
};

export const TextArea = styled.textarea<{
  $theme: TextInputBaseProps['theme'];
  $withError?: boolean;
  $withLeftChild?: boolean;
  $withRightChild?: boolean;
  $CSS?: CSSProp;
}>`
  width: 100%;

  ${({ $theme, $withError }) => getThemeCSS($theme, $withError)}

  border-radius: 8px;
  border: 1px solid
    ${({ $withError }) => ($withError ? '#FF5934' : 'transparent')};
  color: ${({ $withError }) => ($withError ? '#FF5934' : '#201e3f')};

  outline: none;

  padding-top: 14px;
  padding-right: ${({ $withRightChild }) => ($withRightChild ? '27px' : '5px')};
  padding-bottom: 14px;
  padding-left: ${({ $withLeftChild }) => ($withLeftChild ? '42px' : '20px')};

  font-family: Inter, sans-serif;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.016em;

  box-sizing: border-box;
  min-height: 190px;
  resize: none;

  &:focus {
    border-color: rgba(25, 7, 66, 0.64);
  }

  &:disabled {
    background-color: #f7faff;
  }

  &::placeholder {
    color: #67667b;
  }

  ${({ $CSS }) => $CSS};
`;

export const RightSiblingContainer = styled.div`
  margin-left: 8px;
`;

export const ErrorContainer = styled.div`
  position: absolute;
  bottom: 0;
`;
