import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  max-width: 100%;

  overflow: hidden;

  & > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & > svg {
    cursor: pointer;

    transform: scale(1.1);

    & > path {
      fill: #67667b;
    }

    min-width: 16px;
    min-height: 16px;
  }
`;
