import React, { useEffect, useState } from 'react';
import { BodyThree, DefaultRelativeContainer, FlexCenter } from 'styled';
import {
  CreateExerciseDto,
  Exercise,
  SortDirection,
  UpdateExerciseDto,
} from 'types';
import { AbsoluteButton, EmptyExercisesContainer } from './styled';
import { Button } from 'UI';
import {
  createExercise,
  deleteExercise,
  getExercises,
  updateExercise,
} from 'services/api';
import Toast from 'services/Toast';
import { uniqueArray, uploadBaseS3Bulk } from 'utils';
import { isUrl } from 'utils/validation';
import { captureException } from '@sentry/minimal';
import { ProgramsEmpty } from 'assets/svg';
import { ExercisesGrid, ExerciseModal } from './components';
import { useSelector } from 'react-redux';
import { selectors } from 'store/ducks';

const EXERCISES_LIMIT = 12;

function ExercisesTab() {
  const currentUser = useSelector(selectors.auth.getUserInfo);

  const [exercises, setExercises] = useState<Exercise[]>([]);

  const [sortField, setSortField] = useState<string>('author.firstName');
  const [sortDirection, setSortDirection] = useState<SortDirection>('ASC');

  const [isAddExerciseModalOpen, setIsAddExerciseModalOpen] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [exerciseToEdit, setExerciseToEdit] = useState<Exercise | null>(null);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [isShowMoreLoading, setIsShowMoreLoading] = useState(false);

  const fetchExercises = async (
    setStateFunc: (prevState: Exercise[], response: Exercise[]) => Exercise[],
    customPage: number = page
  ) => {
    const { data } = await getExercises({
      page: customPage,
      limit: EXERCISES_LIMIT,
      sort: sortField ? [`${sortField},${sortDirection}`] : undefined,
      join: ['author'],
    });
    setTotal(data.total);
    setExercises((prevState) => setStateFunc(prevState, data.data));
  };

  useEffect(() => {
    if (page !== 1) {
      (async () => {
        setIsShowMoreLoading(true);
        await fetchExercises((prevState, newData) => {
          if (prevState) {
            const newState = [...prevState, ...newData];
            const unique = uniqueArray(newState, (a, b) => a.id === b.id);
            return unique;
          }
          return newData;
        });
        setIsShowMoreLoading(false);
      })();
    }
  }, [page]);

  useEffect(() => {
    (async () => {
      setPage(1);
      setIsLoading(true);
      await fetchExercises((_, newData) => newData, 1);
      setIsLoading(false);
    })();
  }, [sortDirection, sortField]);

  const submitAddExercise = async (values: CreateExerciseDto) => {
    try {
      const { images, ...body } = values;
      const imagesUrls = await uploadBaseS3Bulk(
        images!,
        `${body.name}-exercise-preview`
      );
      const { data } = await createExercise({ ...body, images: imagesUrls });
      setExercises([{ ...data, author: currentUser }, ...exercises]);
      setIsAddExerciseModalOpen(false);
      Toast.success('Exercise created!');
    } catch (err) {
      Toast.error();
      captureException(err);
    }
  };

  const handleEdit = (id: string) => {
    setExerciseToEdit(exercises!.find((exercise) => exercise.id === id)!);
    setIsEditModalOpen(true);
  };

  const submitEdit = async (id: string, values: UpdateExerciseDto) => {
    try {
      const { images, ...body } = values;

      const oldImagesUrls = images?.filter((image) => isUrl(image)) || [];

      const newImagesBases = images?.filter((image) => !isUrl(image)) || [];
      const newImagesUrls = await uploadBaseS3Bulk(
        newImagesBases,
        `${values.name}-preview-image`
      );

      const { data } = await updateExercise(id, {
        ...body,
        images: [...oldImagesUrls, ...newImagesUrls],
        videoId: body.videoId || null,
      });

      setExercises(
        exercises!.map((exercise) =>
          exercise.id === id ? { ...data, author: currentUser } : exercise
        )
      );
      setIsEditModalOpen(false);
      setExerciseToEdit(null);
      Toast.success('Exercise edited!');
    } catch (err) {
      Toast.error();
      captureException(err);
    }
  };

  const submitDelete = async (id: string) => {
    try {
      await deleteExercise(id);
      setExercises((exercises) => exercises!.filter((ex) => ex.id !== id));
      setTotal(total - 1);
      setIsEditModalOpen(false);
      setExerciseToEdit(null);
      Toast.success('Exercise deleted!');
    } catch (err) {
      Toast.error();
      captureException(err);
    }
  };

  const handleChangeSort = (newSort: string) => {
    if (sortField === newSort) {
      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    }

    setSortField(newSort);
  };

  const renderContent = () => {
    if (!isLoading && exercises.length === 0) {
      return (
        <EmptyExercisesContainer>
          <ProgramsEmpty />
          <BodyThree $marginBottom={80}>
            No exercises added. Let’s add one to start!
          </BodyThree>
          <Button
            text="Add an exercise"
            width="435px"
            onClick={() => setIsAddExerciseModalOpen(true)}
          />
        </EmptyExercisesContainer>
      );
    }

    return (
      <ExercisesGrid
        exercises={exercises}
        total={total}
        activeDirection={sortDirection}
        activeSort={sortField}
        isLoading={isLoading}
        onChangeSort={handleChangeSort}
        onEdit={handleEdit}
      />
    );
  };

  return (
    <DefaultRelativeContainer $marginTop={20}>
      <ExerciseModal
        isOpen={isAddExerciseModalOpen}
        onClose={() => setIsAddExerciseModalOpen(false)}
        onSubmit={async (values) =>
          await submitAddExercise({
            name: values.name,
            description: values.description,
            instructions: values.instructions,
            bodyPart: values.bodyPart.value,
            difficulty: values.difficulty.value,
            trackingFields: values.trackingFields,
            equipment: values.equipment.map((equip) => equip.value),
            images: values.images,
            videoId: values.videoId?.value,
          })
        }
      />
      {exerciseToEdit && (
        <ExerciseModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          initialEntity={exerciseToEdit}
          disabled={exerciseToEdit.authorId !== currentUser.id}
          onDelete={async () => await submitDelete(exerciseToEdit.id)}
          onSubmit={async (values) =>
            await submitEdit(exerciseToEdit.id, {
              name: values.name,
              description: values.description,
              instructions: values.instructions,
              bodyPart: values.bodyPart.value,
              difficulty: values.difficulty.value,
              trackingFields: values.trackingFields,
              equipment: values.equipment.map((equip) => equip.value),
              images: values.images,
              videoId: values.videoId?.value,
            })
          }
        />
      )}
      <AbsoluteButton>
        <Button
          text="Add exercise"
          onClick={() => setIsAddExerciseModalOpen(true)}
        />
      </AbsoluteButton>
      {renderContent()}
      {!isLoading && total > exercises.length && (
        <FlexCenter $marginTop={16}>
          <Button
            text="Show more"
            onClick={() => setPage((prev) => prev + 1)}
            isLoading={isShowMoreLoading}
          />
        </FlexCenter>
      )}
    </DefaultRelativeContainer>
  );
}

export default ExercisesTab;
