import { SpinnerContainer } from 'components';
import { useAsyncCallback } from 'hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { getOneWorkout } from 'services/api';
import Toast from 'services/Toast';
import { FlexCenter } from 'styled';
import { Workout } from 'types';
import { WorkoutRoutine } from './components';
import { FormExercise } from './components/WorkoutRoutine/types';

type Props = {
  workoutId: string;

  changeWorkoutMetaValues: (newFieldValues: FormExercise[]) => void;
};

function WorkoutMeta({ workoutId, changeWorkoutMetaValues }: Props) {
  const renderCount = useRef(0);

  const [workout, setWorkout] = useState<Workout | null>(null);

  const [fetchWorkout, isLoading] = useAsyncCallback(async () => {
    try {
      const { data } = await getOneWorkout(workoutId, {
        join: ['workoutExercises', 'workoutExercises.exercise'],
      });
      setWorkout(data);
    } catch (e) {
      Toast.error();
    }
  });

  useEffect(() => {
    fetchWorkout();
  }, [workoutId]);

  const pickedExercises = useMemo(
    () =>
      workout?.workoutExercises?.map(
        (workoutExercise) => workoutExercise.exercise
      ) || [],
    [workout]
  );

  const workoutTemplateValues: FormExercise[] = useMemo(
    () =>
      workout?.workoutExercises?.map((workoutExercise) => ({
        id: workoutExercise.id,
        exerciseId: workoutExercise.exerciseId,
        trackingFields: workoutExercise.exercise.trackingFields,
        trackingFieldsValues: workoutExercise.trackingFieldsValues,
        index: workoutExercise.order,
      })) || [],
    [workout]
  );

  useEffect(() => {
    if (renderCount.current >= 2) {
      changeWorkoutMetaValues(workoutTemplateValues);
    }

    renderCount.current++;
  }, [workoutTemplateValues]);

  if (isLoading || !workout) {
    return <SpinnerContainer />;
  }

  return (
    <FlexCenter>
      <FieldArray
        name="workoutMeta"
        initialValue={workoutTemplateValues}
        pickedExercises={pickedExercises}
        component={WorkoutRoutine}
      />
    </FlexCenter>
  );
}

export default WorkoutMeta;
