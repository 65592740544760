import { SelectField, TextField } from 'components';
import React, { useCallback } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { RowContainer, ButtonsContainer, Container } from './styled';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { CloseEx, Drag } from 'assets/svg';
import { OptionType } from 'UI/types';
import {
  minuteDurationOptions,
  repetitionsCountOptions,
  setsOptions,
} from '@constants';

type FieldValue = {
  id: string;
  name: string;
  setCount: OptionType;
  repetitionsCount: OptionType;
  durationMinutes: OptionType;
  __destroy: boolean;
};

function WorkoutsList({
  fields,
}: FieldArrayRenderProps<FieldValue, HTMLInputElement>) {
  const handleDropEnd: OnDragEndResponder = useCallback((result, _) => {
    const sourceIndex = result.source.index;
    const destinationIndex =
      result.destination?.index === undefined
        ? sourceIndex
        : result.destination?.index;

    fields.swap(sourceIndex, destinationIndex);
  }, []);

  const droppableId = 'edit-program-modal';

  if (fields.length === 0) {
    return null;
  }

  return (
    <DragDropContext onDragEnd={handleDropEnd}>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <Container ref={provided.innerRef} {...provided.droppableProps}>
            {fields.map((name, index) =>
              fields.value[index].__destroy ? null : (
                <Draggable draggableId={`${index}`} index={index} key={name}>
                  {(provided) => (
                    <RowContainer
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <Field
                        name={`${name}.name`}
                        label="Name"
                        disabled
                        component={TextField}
                      />
                      <Field
                        name={`${name}.setCount`}
                        label="Sets"
                        subscription={{ value: true }}
                        options={setsOptions}
                        disabled
                        component={SelectField}
                      />
                      <Field
                        name={`${name}.repetitionCount`}
                        label="Repetitions"
                        options={repetitionsCountOptions}
                        disabled
                        component={SelectField}
                      />
                      <Field
                        name={`${name}.durationMinutes`}
                        label="Time"
                        options={minuteDurationOptions}
                        disabled
                        component={SelectField}
                      />
                      <Field
                        name={`${name}.__destroy`}
                        render={({ input }) => (
                          <ButtonsContainer>
                            <i {...provided.dragHandleProps}>
                              <Drag />
                            </i>
                            <CloseEx onClick={() => input.onChange(true)} />
                          </ButtonsContainer>
                        )}
                      />
                    </RowContainer>
                  )}
                </Draggable>
              )
            )}
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default WorkoutsList;
