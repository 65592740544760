import React, { useMemo } from 'react';
import { ModalWrapper } from 'components';
import { ModalBaseProps } from 'interfaces/common';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Container, LabelDrop } from './styled';
import { File } from 'assets/svg';
import { BodyThree } from 'styled';
import Toast from 'services/Toast';
import { getLoadFileErrorMap } from '@constants';

type Props = ModalBaseProps & {
  onUpload: (filesUrls: File[]) => void;
  maxSize?: number;
  accept?: string[];
};

const DEFAULT_MAX_SIZE = 25 * 1024 * 1024;

function UploadFileModal({
  onUpload,
  maxSize = DEFAULT_MAX_SIZE,
  accept,
  ...rest
}: Props) {
  const maxMegaBytes = Math.floor(maxSize / (1024 * 1024));

  const errorMap = useMemo(() => {
    return getLoadFileErrorMap({ maxMegaBytes, accept });
  }, [maxMegaBytes, accept]);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    maxSize,
    accept,
    onDrop: (files, rejections: FileRejection[]) => {
      onUpload(files);

      if (rejections) {
        rejections.forEach((e) => {
          Toast.error(errorMap[e.errors[0].code], `File: ${e.file.name}`);
        });
      }
    },
  });

  return (
    <ModalWrapper title="Upload file" width={628} {...rest}>
      <Container {...getRootProps()} $isDragActive={isDragActive}>
        <LabelDrop>
          <label onClick={(e) => e.stopPropagation()}>
            <input type="file" {...getInputProps({})} />
            <File />
          </label>
        </LabelDrop>
        <BodyThree>Drag and drop the necessary files here</BodyThree>
      </Container>
    </ModalWrapper>
  );
}

export default UploadFileModal;
