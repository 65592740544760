import { DEFAULT_AVATAR } from '@constants'
import { SelectField, TextAreaField } from 'components'
import ModalWrapper from 'components/ModalWrapper'
import { useAsyncCallback } from 'hooks'
import { ModalWithForm } from 'interfaces/common'
import React, { useEffect, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { OptionsType } from 'react-select'
import { getUserRelations } from 'services/api'
import { selectors } from 'store/ducks'
import { CircleAvatar, FlexStart } from 'styled'
import { RelationUsers } from 'types'
import { Button } from 'UI'
import { OptionType } from 'UI/types'
import { isRequired } from 'utils/validation'

type FormValues = {
	body: string
	users: OptionsType<OptionType>
}

type Props = ModalWithForm<FormValues> & {
	title: string
}

function ShareModal({ onSubmit, title, ...rest }: Props) {
	const [asyncSubmit, isLoading] = useAsyncCallback(onSubmit)
	const [clients, setClients] = useState<RelationUsers[]>([])

	useEffect(() => {
		const fetchClients = async () => {
			const { data } = await getUserRelations()

			const acceptedRelations = data.filter(
				relation => relation.status === 'accept'
			)

			setClients(acceptedRelations)
		}

		fetchClients()
	}, [])

	const options = useMemo<OptionType[]>(() => {
		return clients.map(clientRelation => {
			const clientUser =
				clientRelation.initiatorUser || clientRelation.recipientUser

			return {
				value: clientUser.id,
				label: `${clientUser.firstName} ${clientUser.lastName}`,
				Icon: <CircleAvatar $url={clientUser.avatarUrl || DEFAULT_AVATAR} />,
			}
		})
	}, [clients])

	return (
		<ModalWrapper title={title} width={628} {...rest}>
			<Form
				onSubmit={asyncSubmit}
				render={({ handleSubmit, hasValidationErrors, form }) => {
					const { values } = form.getState()
					const hasChanged = Object.keys(values).some(
						key => values[key] && values[key].length > 0
					)

					return (
						<form onSubmit={handleSubmit}>
							<Field name='body' label='Comment' component={TextAreaField} />
							<Field
								name='users'
								validate={isRequired()}
								label='Recipients'
								options={options}
								isMulti
								withLeftCheckbox
								component={SelectField}
							/>
							<FlexStart>
								<Button
									text='Share'
									type='submit'
									isLoading={isLoading}
									disabled={hasValidationErrors || !hasChanged}
									$margin='0px 12px 0px 0px'
								/>
								<Button
									text='Cancel'
									theme='secondary'
									type='button'
									disabled={isLoading}
									onClick={rest.onClose}
								/>
							</FlexStart>
						</form>
					)
				}}
			/>
		</ModalWrapper>
	)
}

export default ShareModal
