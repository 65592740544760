import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { OptionsType } from 'react-select';
import { SelectDropdown } from 'UI';
import { OptionType, SyncSelectProps } from 'UI/types';

type Props = FieldRenderProps<OptionsType<OptionType>> & SyncSelectProps;

function SelectField({ input, meta, ...rest }: Props) {
  const error = meta.touched && meta.error;

  return <SelectDropdown error={error} {...input} {...rest} />;
}

export default SelectField;
