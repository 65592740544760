import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CalendarEventFormValues, InitialFormValues } from '../../types';
import { Field, Form } from 'react-final-form';
import { DateField, DoubleTimeField, SelectField } from 'components';
import { FieldsContainer } from './styled';
import dayjs from 'dayjs';
import { Button } from 'UI';
import { CircleAvatar, FlexCenter, JustifyBetween } from 'styled';
import { isRequired } from 'utils/validation';
import { getUserRelations, getWorkouts } from 'services/api';
import { DEFAULT_AVATAR } from '@constants';
import { RelationUsers, Workout } from 'types';
import Toast from 'services/Toast';
import { captureException } from '@sentry/minimal';
import { OptionType } from 'UI/types';
import { OptionsType } from 'react-select';
import { WorkoutMeta } from './components';
import arrayMutators from 'final-form-arrays';
import { FormApi } from 'final-form';

type WorkoutFormProps = {
  isSubmitLoading: boolean;
  isDeleteLoading: boolean;
  isEditModal: boolean;
  initialValue?: InitialFormValues;
  isAddForSpecificClientModal?: boolean;

  onSubmit: (
    values: CalendarEventFormValues,
    form: FormApi<CalendarEventFormValues>
  ) => void;
  onDelete?: () => void;
  onCancel?: () => void;
};

function CalendarEventForm({
  isSubmitLoading,
  isDeleteLoading,
  initialValue,
  isEditModal,
  isAddForSpecificClientModal,
  onSubmit,
  onDelete,
  onCancel,
}: WorkoutFormProps) {
  const [workouts, setWorkouts] = useState<Workout[]>([]);
  const [isWorkoutsLoading, setIsWorkoutsLoading] = useState(false);

  const today = dayjs().format('YYYY-MM-DD');
  const [clients, setClients] = useState<RelationUsers[]>([]);
  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const [selectDate, setSelectDate] = useState<string | undefined>(today);

  const validateDate = selectDate && selectDate >= today ? true : false;


  const fetchWorkouts = useCallback(async () => {
    try {
      setIsWorkoutsLoading(true);
      const { data } = await getWorkouts();
      setWorkouts(data);
    } catch (e) {
      Toast.error('Cant get your programs...');
      captureException(e);
    } finally {
      setIsWorkoutsLoading(false);
    }
  }, []);

  const fetchClients = useCallback(async () => {
    try {
      setIsClientsLoading(true);
      const { data } = await getUserRelations();
      const activeClients = data.filter(
        (el) => el.status === 'accept' && !el.archived
      );
      setClients(activeClients);
    } catch (e) {
      Toast.error('Cant get your clients...');
      captureException(e);
    } finally {
      setIsClientsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchWorkouts();
    fetchClients();
  }, [fetchWorkouts, fetchClients]);

  const initialStartDate = useMemo(() => new Date().toISOString(), []);
  const initialEndDate = useMemo(
    () => dayjs(initialStartDate).add(1, 'hour').toISOString(),
    [initialStartDate]
  );

  const validateTime = (values: CalendarEventFormValues) => {
    const { startDate, endDate } = values;
    const isEndBeforeStart = dayjs(endDate).isBefore(dayjs(startDate));
    if (isEndBeforeStart) {
      return {
        startDate: 'Workout start date must be before end date',
      };
    }

    const isLongerThanDay = dayjs(endDate).diff(dayjs(startDate), 'h') > 24;

    if (isLongerThanDay) {
      return { endDate: 'Workout cant be longer than 24 hours' };
    }

    return undefined;
  };

  const initialValues =
    isEditModal || isAddForSpecificClientModal
      ? initialValue
      : {
          startDate: initialStartDate,
          endDate: initialEndDate,
        };

  const workoutsOptions = useMemo<OptionsType<OptionType>>(() => {
    return workouts.map((workout) => ({
      label: workout.name,
      value: workout.id,
    }));
  }, [workouts]);

  const clientsOptions = useMemo<OptionsType<OptionType>>(
    () =>
      clients.map((el) => ({
        value: el.recipientUser ? el.recipientUserId : el.initiatorUserId,
        label: el.recipientUser
          ? `${el.recipientUser.firstName} ${el.recipientUser.lastName}`
          : `${el.initiatorUser.firstName} ${el.initiatorUser.lastName}`,
        Icon: (
          <CircleAvatar
            $url={
              (el.recipientUser
                ? el.recipientUser.avatarUrl
                : el.initiatorUser.avatarUrl) ?? DEFAULT_AVATAR
            }
            $size={20}
          />
        ),
      })),
    [clients]
  );

  return (
    <Form<CalendarEventFormValues>
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      validate={validateTime}
      render={({ handleSubmit, form, values, hasValidationErrors, dirty }) => (
        <form onSubmit={handleSubmit}>
          <FieldsContainer>
            <Field
              name="startDate"
              render={({ input, ...rest }) => (
                <DateField
                  {...rest}
                  input={{
                    ...input,
                    onChange: (e) => {
                      input.onChange(e);
                      setSelectDate(e);
                      form.change(
                        'endDate',
                        dayjs(e).add(1, 'hour').toISOString()
                      );
                    },
                  }}
                />
              )}
            />
            <DoubleTimeField
              startFieldName="startDate"
              endFieldName="endDate"
              formApi={form}
            />
            <Field
              name="workoutId"
              label="Workout"
              validate={isRequired('Required')}
              options={workoutsOptions}
              disabled={isWorkoutsLoading}
              component={SelectField}
            />
            <Field
              name="clientId"
              label="Client"
              validate={isRequired('Required')}
              options={clientsOptions}
              disabled={isClientsLoading || isAddForSpecificClientModal}
              render={SelectField}
            />
          </FieldsContainer>
          {values.workoutId?.value && (
            <WorkoutMeta
              workoutId={values.workoutId.value}
              changeWorkoutMetaValues={(values) =>
                form.change('workoutMeta', values)
              }
            />
          )}
          <JustifyBetween>
            <FlexCenter>
              <Button
                text="Submit"
                type="submit"
                theme="primary"
                $margin="0px 10px 0px 0px"
                disabled={
                  isDeleteLoading ||
                  hasValidationErrors ||
                  (isEditModal && !dirty) || !validateDate
                }
                isLoading={isSubmitLoading}
              />
              {isEditModal && (
                <Button
                  text="Cancel"
                  type="button"
                  theme="secondary"
                  disabled={isSubmitLoading}
                  onClick={onCancel}
                />
              )}
            </FlexCenter>
            {isEditModal && (
              <Button
                text="Delete"
                type="button"
                theme="secondary"
                backgroundColor="rgba(255, 89, 52, 0.1)"
                textColor="#FF5934"
                hoverColor="rgba(202, 81, 53, 0.1)"
                isLoading={isDeleteLoading}
                disabled={isSubmitLoading}
                onClick={onDelete}
              />
            )}
          </JustifyBetween>
        </form>
      )}
    />
  );
}

export default CalendarEventForm;
