import { FlexCenter, FlexStart } from 'styled';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 18px;
`;

export const ImagesContainer = styled(FlexStart)`
  flex-wrap: wrap;
`;

export const ImageContainer = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export const Image = styled.img`
  width: 74px;
  height: 74px;
  border-radius: 14px;

  object-fit: cover;
`;

export const UploadImageButton = styled(FlexCenter)`
  cursor: pointer;

  width: 74px;
  height: 74px;

  background-color: #f3eff9;
  border-radius: 14px;

  & > svg {
    transform: scale(0.5);
  }
`;
