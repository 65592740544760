import { useEffect, useRef } from 'react';

export const useScrollIntoView = (
  ref: React.RefObject<HTMLElement>,
  deps: any[] = []
) => {
  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    timeout.current = setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [ref, ref.current, ...deps]);
};
