import {
  DEFAULT_AVATAR,
  fileFormats,
  imageFormats,
  videoFormats,
} from '@constants';
import React from 'react';
import {
  CircleAvatar,
  FlexStart,
  FootnoteTwo,
  SubheadOne,
  SubheadTwo,
} from 'styled';
import {
  Container,
  UnreadSign,
  CloseContainer,
  TextCSS,
  ContentContainer,
  Wrapper,
} from './styled';
import dayjs from 'dayjs';
import { Attachment, CloseEx, Image, Video } from 'assets/svg';
import { getMessageFileTypeFromUrl } from 'utils';

const getMessagePreview = (message: string): React.ReactNode => {
  const messageType = getMessageFileTypeFromUrl(message);

  if (messageType === 'text') {
    return (
      <FootnoteTwo $color="#67667B" $CSS={TextCSS}>
        {message}
      </FootnoteTwo>
    );
  }

  if (messageType === 'image') {
    return (
      <FlexStart>
        <Image />
        <FootnoteTwo $color="#67667B" $margin="0px 0px 0px 6px">
          Photo
        </FootnoteTwo>
      </FlexStart>
    );
  }

  if (messageType === 'video') {
    return (
      <FlexStart>
        <Video transform="scale(0.9)" />
        <FootnoteTwo $color="#67667B" $margin="0px 0px 0px 6px">
          Video
        </FootnoteTwo>
      </FlexStart>
    );
  }

  if (messageType === 'document') {
    return (
      <FlexStart>
        <Attachment transform="scale(0.7)" />
        <FootnoteTwo $color="#67667B" $margin="0px 0px 0px 2px">
          File
        </FootnoteTwo>
      </FlexStart>
    );
  }

  return message;
};

type Props = {
  lastMessage: string;
  updatedAt: string;
  userAvatarUrl: string;
  userName: string;

  isUnread?: boolean;
  isCurrentUserLastMessage?: boolean;

  onClick: () => void;
  onDelete: () => void;
};

function Room({
  lastMessage,
  userName,
  userAvatarUrl,
  updatedAt,
  isUnread = false,
  isCurrentUserLastMessage = false,
  onClick,
  onDelete,
}: Props) {
  return (
    <Container onClick={onClick}>
      <Wrapper>
        <CircleAvatar
          $url={userAvatarUrl || DEFAULT_AVATAR}
          $size={44}
          $marginRight={12}
          $withPointerCursor
        />
        <ContentContainer>
          <SubheadOne $marginBottom={5}>{userName}</SubheadOne>
          <FlexStart>
            {isCurrentUserLastMessage && (
              <SubheadTwo $color="#67667B" $margin="0 4px 0 0">
                You:
              </SubheadTwo>
            )}
            {getMessagePreview(lastMessage)}
          </FlexStart>
        </ContentContainer>
      </Wrapper>
      <FootnoteTwo>{dayjs(updatedAt).format('DD.MM.YYYY')}</FootnoteTwo>
      {isUnread && <UnreadSign />}
      <CloseContainer
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
        title="Delete"
      >
        <CloseEx />
      </CloseContainer>
    </Container>
  );
}

export default Room;
