import React from 'react';
import { ControlProps } from 'react-select';
import { OptionType } from 'UI/types';
import { Control as StyledControl } from './styled';

function Control({
  children,
  innerRef,
  innerProps,
  isMulti,
  hasValue,
  ...rest
}: ControlProps<OptionType, true>) {
  return (
    <StyledControl
      $isMulti={isMulti && hasValue}
      ref={innerRef}
      {...innerProps}
    >
      {children}
    </StyledControl>
  );
}

export default Control;
