import React from 'react';
import { SortingFieldContainer } from './styled';
import { SubheadTwo } from 'styled';
import { SortDirection } from 'types';
import { Arrow } from 'UI';

type Props = {
  text: string;
  isActive: boolean;
  direction: SortDirection;

  onClick: () => void;
};

function SortingField({ text, isActive, direction, onClick }: Props) {
  return (
    <SortingFieldContainer onClick={onClick}>
      <SubheadTwo
        $weight="600"
        $height={22}
        $color={isActive ? '#000000' : '#BFBFC9'}
      >
        {text}
      </SubheadTwo>
      <Arrow
        $color={isActive ? '#000000' : '#BFBFC9'}
        $size={0.4}
        $rotate={isActive && direction === 'ASC' ? 225 : 45}
      />
    </SortingFieldContainer>
  );
}

export default SortingField;
