import * as React from 'react';

function SvgDelete(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.167 5h4.166v1.667h-1.666V17.5a.833.833 0 01-.834.833H4.167a.833.833 0 01-.834-.833V6.667H1.667V5h4.166V2.5a.833.833 0 01.834-.833h6.666a.833.833 0 01.834.833V5zM7.5 9.167v5h1.667v-5H7.5zm3.333 0v5H12.5v-5h-1.667zM7.5 3.333V5h5V3.333h-5z"
        fill="#67667B"
        opacity={0.6}
      />
    </svg>
  );
}

export default SvgDelete;
