import React, { useMemo, useState } from 'react';
import { weekCountOptions } from '@constants';
import { ImageField, ModalWrapper, SelectField, TextField } from 'components';
import { ModalWithForm } from 'interfaces/common';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { FlexStart, Headline, JustifyBetween } from 'styled';
import { Program } from 'types';
import { OptionType } from 'UI/types';
import { WorkoutsList } from './components';
import { ImageFieldContainer, RightFieldsContainer } from './styled';
import { Button } from 'UI';
import { isRequired } from 'utils/validation';
import { FormApi } from 'final-form';
import Toast from 'services/Toast';
import { convertBaseToFile, uploadFileS3 } from 'utils';

type FormValues = {
  imageUrl: string;
  name: string;
  weekCount: OptionType;
  description: string;
  programWorkouts: {
    id: string;
    name: string;
    __destroy: boolean;
  }[];
};

type Props = ModalWithForm<FormValues> & {
  initialEntity: Program;
};

function EditProgramModal({ initialEntity, onSubmit, ...rest }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = useMemo(() => {
    const workouts = initialEntity.programWorkouts?.map((el) => ({
      ...el.workout,
      id: el.id,
    }));

    const initialValues: FormValues = {
      name: initialEntity.name,
      imageUrl: initialEntity.imageUrl || '',
      description: initialEntity.description,
      weekCount: {
        value: `${initialEntity.weekCount}`,
        label: `${initialEntity.weekCount}`,
      },
      programWorkouts: workouts
        ? workouts.map((pl) => ({
            id: pl.id,
            name: pl.name,
            __destroy: false,
          }))
        : [],
    };
    return initialValues;
  }, [initialEntity]);

  if (!onSubmit) {
    return null;
  }

  const handleSubmit = async (
    values: FormValues,
    form: FormApi<FormValues>
  ) => {
    try {
      setIsLoading(true);
      const isNewPreview =
        form.getFieldState('imageUrl')?.dirty && !!values.imageUrl;

      let imageUrl = values.imageUrl;
      if (isNewPreview) {
        imageUrl = await uploadFileS3(
          await convertBaseToFile(
            values.imageUrl as string,
            `${values.name}-preview`
          )
        );
      }

      await onSubmit({ ...values, imageUrl });
    } catch (e) {
      Toast.error();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalWrapper title="Edit program" width={1062} isBigModal {...rest}>
      <Form<FormValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, hasValidationErrors, dirty }) => (
          <form onSubmit={handleSubmit}>
            <JustifyBetween $marginBottom={24}>
              <ImageFieldContainer>
                <Field name="imageUrl" label="Cover" component={ImageField} />
              </ImageFieldContainer>
              <RightFieldsContainer>
                <Field
                  name="name"
                  label="Name"
                  validate={isRequired()}
                  component={TextField}
                />
                <Field
                  name="weekCount"
                  options={weekCountOptions}
                  label="Weeks"
                  component={SelectField}
                />
                <Field
                  name="description"
                  label="Description"
                  component={TextField}
                />
              </RightFieldsContainer>
            </JustifyBetween>
            <Headline>Workouts</Headline>
            <FieldArray name="programWorkouts" component={WorkoutsList} />
            <FlexStart>
              <Button
                text="Save"
                type="submit"
                $margin="0px 24px 0px 0px"
                disabled={hasValidationErrors || !dirty}
                isLoading={isLoading}
              />
              <Button
                type="button"
                theme="secondary"
                text="Cancel"
                disabled={isLoading}
                onClick={rest.onClose}
              />
            </FlexStart>
          </form>
        )}
      />
    </ModalWrapper>
  );
}

export default EditProgramModal;
