import React, { useEffect, useRef, useState } from 'react';
import { TableBody, TableHead } from './components';
import { Wrapper } from './styled';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATH } from '@constants';
import { buildQuery } from 'utils';
import { CalendarEventModal } from 'components';
import {
  CalendarEvent,
  CreateCalendarEventBody,
  Program,
  Workout,
  ProgramWorkout,
  User,
  UpdateCalendarEventBody,
} from 'types';
import {
  createCalendarEvent,
  deleteCalendarEvent,
  getCoachCalendarEvents,
  updateCalendarEvent,
} from 'services/api';
import ToastService from 'services/Toast';
import Toast from 'services/Toast';
import { captureException } from '@sentry/minimal';

function Calendar() {
  const history = useHistory();
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [dateFilter, setDateFilter] = useState<string>(
    new Date().toISOString()
  );

  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const prevStartDate = useRef<string>('');
  const prevEndDate = useRef<string>('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const start = dayjs(dateFilter).weekday(0).hour(0).minute(0).toISOString();
    const end = dayjs(dateFilter).weekday(6).hour(0).minute(0).toISOString();
    history.push({
      pathname: ROUTE_PATH.calendar,
      search: buildQuery({ start, end }),
    });

    const fetchWorkouts = async () => {
      try {
        setIsLoading(true);
        const { data } = await getCoachCalendarEvents({
          startSearchDate: start,
          endSearchDate: end,
        });
        setEvents(data);
      } catch (err) {
        ToastService.error('Oops... Cant get workouts...');
        captureException(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (start !== prevStartDate.current || end !== prevEndDate.current) {
      fetchWorkouts();
      prevStartDate.current = start;
      prevEndDate.current = end;
    }
    setStartDate(start);
    setEndDate(end);
  }, [dateFilter, history]);

  const handleAddWorkout = async (body: CreateCalendarEventBody) => {
    try {
      const { data } = await createCalendarEvent(body);
      const calendarEvent: CalendarEvent = {
        ...data,
        author: {} as User,
        authorId: '',
      } as CalendarEvent;
      setEvents([...events, calendarEvent]);
      Toast.success(
        `Training session on ${dayjs(body.startDateTime).format(
          'DD MMMM'
        )} at ${dayjs(body.startDateTime).format(
          'hh:mm A'
        )} has been successfully added!`,
        'Training added'
      );
    } catch (err) {
      Toast.error();
      captureException(err);
    }
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [workoutToEdit, setWorkoutToEdit] = useState<CalendarEvent | null>(
    null
  );
  const handleEditWorkout = (eventId: string) => {
    setWorkoutToEdit(events.find((event) => event.id === eventId) || null);
    setIsEditModalOpen(true);
  };

  const submitEditEvent = async (
    id: string,
    values: UpdateCalendarEventBody
  ) => {
    const { data } = await updateCalendarEvent(id, values);
    const calendarEvent: CalendarEvent = {
      ...data,
      client: data.client as User,
      author: {} as User,
      authorId: '',
    } as CalendarEvent;
    setEvents((prev) =>
      prev.map((el) => (el.id === data.id ? calendarEvent : el))
    );
    setWorkoutToEdit(null);
    setIsEditModalOpen(false);
    Toast.info('Workout event updated.', 'Workouts');
  };

  const submitDelete = async (id: string) => {
    try {
      await deleteCalendarEvent(id);
      setEvents(events.filter((e) => e.id !== id));
      setIsEditModalOpen(false);
      setWorkoutToEdit(null);
      Toast.success('Workout deleted!');
    } catch (err) {
      Toast.error();
      captureException(err);
    }
  };

  return (
    <Wrapper>
      {workoutToEdit && (
        <CalendarEventModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          initialEntity={workoutToEdit}
          onSubmit={(values) =>
            submitEditEvent(workoutToEdit.id, {
              clientId: values.clientId.value,
              startDateTime: values.startDate,
              endDateTime: values.endDate,
              workoutId: values.workoutId.value,
              updateWorkoutMetaDto: {
                ...values.workoutMeta,
                workoutId: values.workoutId.value,
              },
            })
          }
          onCancel={() => setIsEditModalOpen(false)}
          onDelete={() => submitDelete(workoutToEdit.id)}
        />
      )}
      <TableHead
        date={dateFilter}
        onChangeDate={setDateFilter}
        onAddWorkout={handleAddWorkout}
      />
      <TableBody
        date={dateFilter}
        events={events}
        isLoading={isLoading}
        onClickEdit={handleEditWorkout}
      />
    </Wrapper>
  );
}

export default Calendar;
