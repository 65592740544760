import styled from 'styled-components';
import { TitleFour } from 'styled/fonts';

export const Root = styled.div`
  width: 628px;
  background: #ffffff;
  border: 1px solid #e1e0e5;
  border-radius: 22px;
  padding: 40px 24px;
  margin-bottom: 130px;
  margin-top: 30px;
`;

export const HeadForm = styled(TitleFour)`
  text-align: center;
`;

export const FormComponent = styled.form``;
