import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { State } from '../index';

const usersEntityAdapter = createEntityAdapter<string>({
  selectId: (entity) => entity,
});

const slice = createSlice({
  name: 'onlineUsers',
  initialState: usersEntityAdapter.getInitialState(),
  reducers: {
    setAll: usersEntityAdapter.setAll,
    addOne: usersEntityAdapter.addOne,
    removeOne: usersEntityAdapter.removeOne,
  },
  extraReducers: {},
});

export const { reducer } = slice;
export const actions = { ...slice.actions };
export const selectors = {
  ...usersEntityAdapter.getSelectors<State>((state) => state.onlineUsers),
};
