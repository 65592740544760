import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createAction,
} from '@reduxjs/toolkit';
import { captureException } from '@sentry/minimal';
import { getManyChatRooms, hideChatRoom } from 'services/api';
import Toast from 'services/Toast';
import { ChatMessage, ChatRoom } from 'types';
import { State } from '../index';

const chatRoomsAdapter = createEntityAdapter<ChatRoom>();

const fetchChatRooms = createAsyncThunk('chatRooms/fetchAll', async () => {
  try {
    const { data } = await getManyChatRooms();

    return data.data;
  } catch (e) {
    Toast.error();
    captureException(e);
  }
});

const removeChatRoom = createAsyncThunk(
  'chatRooms/deleteOne',
  async (id: string) => {
    try {
      await hideChatRoom(id);
      Toast.success('Chat room deleted', 'Chat');
      return id;
    } catch (e) {
      Toast.error();
      captureException(e);
    }
  }
);

const updateLastMessage = createAction<{
  chatRoomId: number;
  message: ChatMessage;
}>('chatRooms/updateLastMessage');

const updateLastReadMessage = createAction<{
  chatRoomId: number;
  userId: string;
  messageId: number;
}>('chatRooms/updateLastReadMessage');

const slice = createSlice({
  name: 'chatRooms',
  initialState: {
    ...chatRoomsAdapter.getInitialState({
      isFetchLoading: false,
      isDeleteLoading: false,
    }),
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchChatRooms.pending, (state) => {
      state.isFetchLoading = true;
    });
    builder.addCase(fetchChatRooms.fulfilled, (state, action) => {
      if (action.payload) {
        chatRoomsAdapter.setAll(state, action.payload);
      }
      state.isFetchLoading = false;
    });
    builder.addCase(fetchChatRooms.rejected, (state) => {
      state.isFetchLoading = false;
    });
    builder.addCase(removeChatRoom.pending, (state) => {
      state.isDeleteLoading = true;
    });
    builder.addCase(removeChatRoom.fulfilled, (state, action) => {
      if (action.payload) {
        chatRoomsAdapter.removeOne(state, action.payload!);
      }
      state.isDeleteLoading = false;
    });
    builder.addCase(removeChatRoom.rejected, (state) => {
      state.isDeleteLoading = false;
    });
    builder.addCase(updateLastMessage, (state, action) => {
      chatRoomsAdapter.updateOne(state, {
        id: action.payload.chatRoomId,
        changes: {
          lastMessage: action.payload.message,
          updatedAt: new Date().toUTCString(),
        },
      });
    });
    builder.addCase(updateLastReadMessage, (state, action) => {
      const chatRoom = state.entities[action.payload.chatRoomId];
      if (chatRoom) {
        const participants = chatRoom.participants.map((participant) =>
          participant.userId === action.payload.userId
            ? {
                ...participant,
                lastReadMessageId: action.payload.messageId,
              }
            : participant
        )!;

        chatRoomsAdapter.updateOne(state, {
          id: chatRoom?.id,
          changes: {
            participants,
          },
        });
      }
    });
  },
});

export const { reducer } = slice;
export const actions = {
  ...slice.actions,
  fetchChatRooms,
  removeChatRoom,
  updateLastMessage,
  updateLastReadMessage,
};
export const selectors = {
  ...chatRoomsAdapter.getSelectors<State>((state) => state.chatRooms),
  selectIsFetchLoading: (state: State) => state.chatRooms.isFetchLoading,
  selectIsDeleteLoading: (state: State) => state.chatRooms.isDeleteLoading,
};
