import { combineReducers } from 'redux';
import * as authDuck from './auth';
import * as chatRoomsDuck from './chatRooms';
import * as onlineUsersDuck from './onlineUsers';

export const selectors = {
  auth: authDuck.selectors,
  chatRooms: chatRoomsDuck.selectors,
  onlineUsers: onlineUsersDuck.selectors,
};
export const actions = {
  auth: authDuck.actions,
  chatRooms: chatRoomsDuck.actions,
  onlineUsers: onlineUsersDuck.actions,
};

export const rootReducer = combineReducers({
  auth: authDuck.reducer,
  chatRooms: chatRoomsDuck.reducer,
  onlineUsers: onlineUsersDuck.reducer,
});
