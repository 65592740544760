import React from 'react';
import { Spinner } from 'UI';
import { ButtonBaseProps } from 'UI/types';
import { StyledButton, Props as StyledButtonProps } from './styled';

interface Props extends ButtonBaseProps, StyledButtonProps {
  text: string;
  isLoading?: boolean;
}

function Button({ text, theme = 'primary', isLoading, ...rest }: Props) {
  const disabled = rest.disabled || isLoading;

  return (
    <StyledButton theme={theme} {...rest} disabled={disabled}>
      {isLoading ? (
        <Spinner
          $size={16}
          $color={theme === 'primary' ? 'white' : '#895FC6'}
        />
      ) : (
        text
      )}
    </StyledButton>
  );
}

export default Button;
