import styled, { css } from 'styled-components';

export const SearchContainer = styled.div`
  margin-top: 24px;
`;

export const SearchInputCSS = css`
  padding: 18px 56px;

  line-height: 18px;

  &::placeholder {
    color: #67667b;
    opacity: 0.6;
  }
`;

export const ButtonClear = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
`;
