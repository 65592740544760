import styled from 'styled-components';

export const SortingFieldContainer = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;

  color: #bfbfc9;
`;
