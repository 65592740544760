import React from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_AVATAR, ROUTE_PATH } from '@constants';
import { selectors } from 'store/ducks/auth';
import { useHistory } from 'react-router-dom';
import { Avatar, Name, Wrapper } from './styled';

function Profile() {
  const { push } = useHistory();
  const user = useSelector(selectors.getUserInfo);

  return (
    <Wrapper onClick={() => push(ROUTE_PATH.profile)}>
      <Avatar $imageUrl={user.avatarUrl || DEFAULT_AVATAR} />
      <Name>{`${user.firstName} ${user.lastName}`}</Name>
    </Wrapper>
  );
}

export default Profile;
