import * as React from 'react';

function SvgCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 10.172L16.192.979l1.415 1.414L7 13 .636 6.636 2.05 5.222 7 10.172z"
        fill="#201E3F"
      />
    </svg>
  );
}

export default SvgCheck;
