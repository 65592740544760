import React from 'react';
import { ContainerProps } from 'react-select';
import { OptionType } from 'UI/types';
import { Container } from './styled';

function SelectContainer({
  children,
  innerProps,
  ...rest
}: ContainerProps<OptionType, true>) {
  return <Container {...innerProps}>{children}</Container>;
}

export default SelectContainer;
