import * as React from 'react';

function SvgInfo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={36}
      height={36}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={18} cy={18} r={15} fill="#2BAEE0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 16.5a1.5 1.5 0 011.5 1.5v6a1.5 1.5 0 01-3 0v-6a1.5 1.5 0 011.5-1.5zM16.5 12a1.5 1.5 0 011.5-1.5h.015a1.5 1.5 0 010 3H18a1.5 1.5 0 01-1.5-1.5z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgInfo;
