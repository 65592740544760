import React from 'react';
import { Search, CloseEx } from 'assets/svg';
import { SearchContainer, SearchInputCSS, ButtonClear } from './styled';
import { colors } from 'styled';
import { TextInput } from 'UI';

interface SearchClientFormProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
}

function SearchClientForm({
  searchValue,
  setSearchValue,
}: SearchClientFormProps) {
  return (
    <SearchContainer>
      <TextInput
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        theme="secondary"
        placeholder="Search"
        inputCSS={SearchInputCSS}
        leftChild={<Search />}
        rightChild={
          searchValue && (
            <ButtonClear
              onClick={() => {
                setSearchValue('');
              }}
            >
              <CloseEx fill={colors.textSecondary} />
            </ButtonClear>
          )
        }
      />
    </SearchContainer>
  );
}

export default SearchClientForm;
