import React from 'react';
import { FlexCenter, Headline, BodyThree } from 'styled';
import { WorkoutExercise, Workout } from 'types';
import { Button } from 'UI';
import { WorkoutCard } from './components';
import { EmptyStateContainer } from './styled';

type Props = {
  workouts: Workout[];
  exercises: WorkoutExercise[];
  fetchStatuses: Record<string, boolean>;

  onFetchPlanExercises: (planId: string) => void;
  onCreatePlan: () => void;
  onDeletePlan: (id: string) => void;
  onEditPlan: (id: string) => void;

  onDeleteExercise: (exerciseTrainingPlanIdToDelete: string) => void;
  onEditExercise: (exerciseTrainingPlanIdToDelete: string) => void;
};

function TrainingPlansList({
  workouts,
  exercises,
  fetchStatuses,
  onFetchPlanExercises,
  onCreatePlan,
  onDeletePlan,
  onEditPlan,
  onDeleteExercise,
  onEditExercise,
}: Props) {
  if (workouts.length === 0) {
    return (
      <EmptyStateContainer>
        <BodyThree>
          Let’s add a training plan to this program! It’ll help you keep all
          programs in the proper way and assign trainings to your clients
        </BodyThree>
        <Button
          text="Add training plan"
          width="435px"
          onClick={() => onCreatePlan()}
        />
      </EmptyStateContainer>
    );
  }

  return (
    <div>
      <Headline $marginBottom={16}>Training plan</Headline>
      <div>
        {workouts.map((tp, index) => (
          <WorkoutCard
            id={tp.id}
            fetchExercises={async () => await onFetchPlanExercises(tp.id)}
            exercises={
              fetchStatuses[tp.id]
                ? exercises.filter((el) => el.workoutId === tp.id)
                : null
            }
            index={index + 1}
            name={tp.name}
            onEdit={() => onEditPlan(tp.id)}
            onDelete={() => onDeletePlan(tp.id)}
            onDeleteExercise={onDeleteExercise}
            onEditExercise={onEditExercise}
            key={tp.id}
          />
        ))}
      </div>
    </div>
  );
}

export default TrainingPlansList;
