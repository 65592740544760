import React, { useEffect, useState } from 'react';
import { SelectContainer } from './styled';
import { SpinnerContainer } from 'components';
import { useAsyncCallback } from 'hooks';
import {
  createProgramWorkout,
  getOneProgram,
  updateProgram,
} from 'services/api';
import Toast from 'services/Toast';
import { BodyThree, TitleFour } from 'styled';
import { Program, ProgramWorkout } from 'types';
import { SelectDropdown } from 'UI';
import { PickWorkoutModal, WorkoutsGrid } from './components';

type Props = {
  programId: string;

  onChangeWorkoutsCount: (programId: string, newCount: number) => void;
};

const weeksOptions = [...new Array(12)].map((_, index) => ({
  value: index + 1,
  label: `${index + 1}`,
}));

function ProgramInfo({ programId, onChangeWorkoutsCount }: Props) {
  const [program, setProgram] = useState<Program | null>(null);
  const [programWorkouts, setProgramWorkouts] = useState<ProgramWorkout[]>([]);
  const [workoutDayAndWeek, setWorkoutDayAndWeek] = useState<{
    weekNumber: number;
    dayNumber: number;
  } | null>(null);

  const [weekCount, setWeekCount] = useState<number | null>(null);

  const [fetchProgram, isLoading] = useAsyncCallback(async () => {
    try {
      const { data } = await getOneProgram(programId);
      setProgram(data);
      setWeekCount(data.weekCount);
      setProgramWorkouts(data.programWorkouts || []);
    } catch (e) {
      Toast.error();
    }
  });

  useEffect(() => {
    fetchProgram();
  }, []);

  const handleOpenPickWorkoutModal = (
    weekNumber: number,
    dayNumber: number
  ) => {
    setWorkoutDayAndWeek({ weekNumber, dayNumber });
  };

  const submitCreateWorkout = async (workoutId: string) => {
    try {
      const { data } = await createProgramWorkout({
        existedWorkoutId: workoutId,
        programId: programId,
        dayNumber: workoutDayAndWeek!.dayNumber,
        weekNumber: workoutDayAndWeek!.weekNumber,
      });
      setProgramWorkouts([...programWorkouts, data]);
      onChangeWorkoutsCount(programId, programWorkouts.length + 1);
      setWorkoutDayAndWeek(null);
      Toast.success('Workout added!', 'Programs');
    } catch (e) {
      Toast.error();
    }
  };

  const [handleChangeWeeksCount, isChangeWeekLoading] = useAsyncCallback(
    async (newWeeksCount: number) => {
      setWeekCount(newWeeksCount);
      await updateProgram(programId, { weekCount: newWeeksCount });
      setProgramWorkouts((prev) =>
        prev.filter((workout) => workout.weekNumber < newWeeksCount)
      );
    }
  );

  if (isLoading || !program) {
    return <SpinnerContainer />;
  }

  return (
    <section>
      {workoutDayAndWeek && (
        <PickWorkoutModal
          isOpen={Boolean(workoutDayAndWeek)}
          onClose={() => setWorkoutDayAndWeek(null)}
          onPick={(workoutId) => submitCreateWorkout(workoutId)}
        />
      )}
      <TitleFour>{program.name}</TitleFour>
      <BodyThree $marginBottom={8}>{program.description}</BodyThree>
      <SelectContainer>
        <SelectDropdown
          label="Weeks"
          value={{ value: weekCount, label: `${weekCount}` }}
          options={weeksOptions}
          onChange={(value) => handleChangeWeeksCount((value as any).value)}
          disabled={isChangeWeekLoading}
        />
      </SelectContainer>
      {isChangeWeekLoading ? (
        <SpinnerContainer />
      ) : (
        <WorkoutsGrid
          workouts={programWorkouts}
          weekCount={weekCount || 0}
          onOpenCreateModal={(week, day) =>
            handleOpenPickWorkoutModal(week, day)
          }
        />
      )}
    </section>
  );
}

export default ProgramInfo;
