import * as React from 'react';

function SvgVideos(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.333 3.333a.833.833 0 01.833.834v3.5l4.345-3.042a.417.417 0 01.655.342v10.066a.416.416 0 01-.655.342l-4.345-3.042v3.5a.834.834 0 01-.833.834H1.666a.833.833 0 01-.833-.834V4.167a.833.833 0 01.833-.834h11.667zM6.166 7.358a.333.333 0 00-.326.266l-.007.066v4.618a.333.333 0 00.452.312l.06-.03 3.63-2.31a.333.333 0 00.052-.52l-.052-.042-3.63-2.31a.333.333 0 00-.179-.05z"
        fill="#67667B"
      />
    </svg>
  );
}

export default SvgVideos;
