/* eslint-disable no-useless-escape */
import React, { useMemo, useState } from 'react';
import ModalWrapper from 'components/ModalWrapper';
import { ModalWithForm } from 'interfaces/common';
import { useAsyncCallback } from 'hooks';
import { CalendarEventForm } from './components';
import {
  CalendarEventFormValues,
  InitialFormValues,
  SubmitValue,
} from './types';
import { CalendarEvent, Workout, WorkoutMeta } from 'types';
import { CircleAvatar } from 'styled';
import { DEFAULT_AVATAR } from '@constants';
import dayjs from 'dayjs';
import { FormApi } from 'final-form';

type Props = ModalWithForm<SubmitValue> & {
  initialEntity?: CalendarEvent;
  isAddForSpecificClientModal?: boolean;

  onCancel?: () => void;
  onDelete?: () => Promise<void>;
};

const getWorkoutMeta = (
  workout: Workout,
  meta: WorkoutMeta
): CalendarEventFormValues['workoutMeta'] => {
  const { workoutExercises } = workout;

  return (
    workoutExercises?.map((workoutExercise) => ({
      id: workoutExercise.id,
      exerciseId: workoutExercise.exerciseId,
      index: workoutExercise.order,
      trackingFields: workoutExercise.exercise.trackingFields,
      trackingFieldsValues: {
        ...workoutExercise.trackingFieldsValues,
        ...(meta.exerciseTrackingFieldsDistinctValues
          ? meta.exerciseTrackingFieldsDistinctValues[workoutExercise.id]
          : {}),
      },
    })) || []
  );
};

const removeSquareBrackets = (str: string) =>
  str.replaceAll('[', '').replaceAll(']', '');

function CalendarEventModal({
  initialEntity,
  isAddForSpecificClientModal,
  onSubmit,
  onCancel,
  onDelete,
  ...rest
}: Props) {
  const [asyncSubmit, isSubmitLoading] = useAsyncCallback(
    async (
      values: CalendarEventFormValues,
      form: FormApi<CalendarEventFormValues>
    ) => {
      const { modified } = form.getState();

      let meta = {};

      if (modified) {
        const modifiedFieldsKeys = Object.keys(modified).filter(
          (key) => modified[key]
        );
        const modifiedTrackingFields = modifiedFieldsKeys.filter((fieldName) =>
          fieldName.includes('workoutMeta[')
        );

        meta = modifiedTrackingFields.reduce((accumulator, key) => {
          const modifiedFieldIndex = removeSquareBrackets(
            key.split('.')[0].match(/\[\d+\]/)![0]
          );

          const modifiedField =
            values.workoutMeta && values.workoutMeta[modifiedFieldIndex];

          const modifiedTrackingField = removeSquareBrackets(
            key.match(/\[[a-zA-z]+\]/)![0]
          );

          const workoutExerciseId = modifiedField.id;
          const metaContainer = accumulator[workoutExerciseId] || {};

          metaContainer[modifiedTrackingField] =
            modifiedField.trackingFieldsValues[modifiedTrackingField];

          accumulator[workoutExerciseId] = metaContainer;
          return accumulator;
        }, initialEntity?.workoutMeta?.exerciseTrackingFieldsDistinctValues || {});
      }

      await onSubmit({
        ...values,
        workoutMeta: { exerciseTrackingFieldsDistinctValues: meta },
      });
    }
  );
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const initialValues = useMemo<InitialFormValues>(() => {
    if (initialEntity) {
      return {
        clientId: {
          label: `${initialEntity.client.firstName} ${initialEntity.client.lastName}`,
          value: initialEntity.clientId,
          Icon: (
            <CircleAvatar
              $url={initialEntity.client.avatarUrl || DEFAULT_AVATAR}
              $size={20}
            />
          ),
        },
        workoutId: initialEntity.workout
          ? {
              label: initialEntity.workout.name,
              value: initialEntity.workout.id,
            }
          : undefined,
        workoutMeta: initialEntity.workoutMeta
          ? getWorkoutMeta(initialEntity.workout, initialEntity.workoutMeta)
          : undefined,
        startDate: initialEntity.startDateTime || dayjs().toISOString(),
        endDate:
          initialEntity.endDateTime || dayjs().add(1, 'hour').toISOString(),
      };
    }
    return undefined;
  }, [initialEntity]);

  const handleDelete = async () => {
    if (onDelete) {
      try {
        setIsDeleteLoading(true);
        await onDelete();
      } finally {
        setIsDeleteLoading(false);
      }
    }
  };

  if (!onSubmit) {
    return null;
  }

  const isEditModal = Boolean(initialValues) && !isAddForSpecificClientModal;

  return (
    <ModalWrapper
      {...rest}
      width={846}
      isBigModal
      title={isEditModal ? 'Edit workout' : 'Add workout'}
    >
      <CalendarEventForm
        isSubmitLoading={isSubmitLoading}
        isDeleteLoading={isDeleteLoading}
        isEditModal={isEditModal}
        initialValue={initialValues}
        isAddForSpecificClientModal={isAddForSpecificClientModal}
        onSubmit={asyncSubmit}
        onCancel={onCancel}
        onDelete={handleDelete}
      />
    </ModalWrapper>
  );
}

export default CalendarEventModal;
