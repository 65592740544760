import { DEFAULT_AVATAR } from '@constants';
import React from 'react';
import { BodyOne, CircleAvatar } from 'styled';
import { StyledClientItem, ButtonContainer, NameCSS } from './styled';

interface ClientItemProps {
  firstName: string;
  lastName: string;
  avatarUrl?: string | null;
  children: React.ReactNode;

  onClick: () => void;
}

function ClientItem({
  firstName,
  lastName,
  avatarUrl,
  children,
  onClick,
}: ClientItemProps) {
  return (
    <StyledClientItem>
      <CircleAvatar
        $url={avatarUrl || DEFAULT_AVATAR}
        $size={44}
        $marginRight={12}
        onClick={onClick}
      />
      <BodyOne $CSS={NameCSS} onClick={onClick}>
        {firstName} {lastName}
      </BodyOne>
      <ButtonContainer>{children}</ButtonContainer>
    </StyledClientItem>
  );
}

export default ClientItem;
