import styled from 'styled-components';

export const Container = styled.div`
  display: grid;

  position: relative;
`;

export const AbsoluteButton = styled.div`
  position: absolute;
  right: 0;
  top: calc(-24px - 56px);
`;
