import { FlexStart } from 'styled';
import styled, { CSSProp } from 'styled-components';

export const Container = styled.div<{ $CSS?: CSSProp }>`
  width: 100%;

  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 14px;

  padding: 8px 8px 18px 8px;

  ${({ $CSS }) => $CSS}
`;

export const Image = styled.div<{ $url: string }>`
  cursor: pointer;
  min-height: 161px;

  background-image: url(${({ $url }) => $url});
  background-size: cover;
  background-position: center center;

  border-radius: 14px;

  margin-bottom: 16px;
`;

export const MoreButton = styled.div`
  position: relative;

  & > svg {
    cursor: pointer;
  }

  & path {
    fill: #67667b;
  }
`;
