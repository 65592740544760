import { TrackingFieldsValue } from 'types';

export const trackingFieldNameMapping: {
  [key in TrackingFieldsValue]: string;
} = {
  cadence: 'Cadence',
  calories: 'Calories',
  distanceLong: 'Distance (long)',
  distanceShort: 'Distance (short)',
  heartRate: 'Heart rate',
  reps: 'Reps',
  rest: 'Rest',
  rm: 'RM',
  rpe: 'RPE',
  speed: 'Speed',
  time: 'Time',
  weight: 'Weight',
};
