import styled from 'styled-components';
import { CircleButton as CircleButtonUI } from 'UI';

export const RootBlock = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CircleButton = styled(CircleButtonUI)`
  top: 0px;
  right: 0px;
  position: absolute;
`;

export const ImageBlock = styled.div`
  width: 120px;
  height: 120px;
  position: relative;
  overflow: hidden;
`;

export const LoadBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 24px;
`;

export const AnnotationText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.016em;
  color: #67667b;
  margin-bottom: 12px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 65px;
`;

export const LoadButton = styled.a`
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 14px 0px;
  width: 155px;
  background: #f3eff9;
  border-radius: 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #895fc6;
`;

export const ImageContainer = styled.div`
  height: 100%;
  background: #f3eff9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 65px;
`;
