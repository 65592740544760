import React from 'react';
import { Check } from 'assets/svg';
import { Radio } from './styled';

type Props = {
  isActive: boolean;
};

function RadioButton({ isActive }: Props) {
  return (
    <Radio $isActive={isActive} as="button" type="button">
      {isActive && <Check />}
    </Radio>
  );
}

export default RadioButton;
