import React from 'react'
import { CloseEx, Drag } from 'assets/svg'
import { Field } from 'react-final-form'
import { SubheadOne } from 'styled'
import { CreateWorkoutDtoExercise, TrackingFieldsValue } from 'types'
import { FormExercise } from '../../types'
import { trackingFieldNameMapping } from '../../constants'
import {
	Container,
	ExerciseSetContainer,
	SetButtonsContainer,
	TrackingFieldContainer,
	TrackingFieldInputCSS,
} from './styled'
import { MaskedTextField } from 'components'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { numberRegExp } from 'utils/validation'

type Props = {
	exercises: FormExercise[]
	disabled?: boolean

	onSwap: (firstIndex: number, secondIndex: number) => void
	onRemove: (index: number) => void
}

export const getTrackingFieldOptions = (field: TrackingFieldsValue) => {
	switch (field) {
		case 'time': {
			return {
				component: MaskedTextField,
				theme: 'transparent',
				inputCSS: TrackingFieldInputCSS,
				type: 'time',
				parse: (value: any) => value || null,
			}
		}
		case 'rest': {
			return {
				component: MaskedTextField,
				theme: 'transparent',
				inputCSS: TrackingFieldInputCSS,
				type: 'time',
				parse: (value: any) => value || null,
			}
		}
		default:
			return {
				component: MaskedTextField,
				theme: 'transparent',
				placeholder: '10',
				inputCSS: TrackingFieldInputCSS,
				validateValue: (value: string) =>
					!value || numberRegExp.test(value) ? true : false,
				parse: (value: any) => value || null,
			}
	}
}

function ExerciseSets({ exercises, disabled, onSwap, onRemove }: Props) {
	const { exerciseId } = exercises[0]

	const renderTrackingFields = (
		trackingFields: CreateWorkoutDtoExercise['trackingFieldsValues'],
		index: number
	) => {
		if (trackingFields) {
			const activeFields = Object.keys(trackingFields) as TrackingFieldsValue[]

			return activeFields.map(field => (
				<TrackingFieldContainer key={`${index}${field}`}>
					<SubheadOne>{trackingFieldNameMapping[field]}</SubheadOne>
					<Field
						name={`workoutExercises[${index}].trackingFieldsValues[${field}]`}
						{...getTrackingFieldOptions(field)}
						disabled={disabled}
					/>
				</TrackingFieldContainer>
			))
		}

		return null
	}

	return (
		<DragDropContext
			onDragEnd={result => {
				result.destination &&
					onSwap(result.source.index, result.destination.index)
			}}
		>
			<Droppable droppableId={`exercises-drop-${exerciseId}`}>
				{provided => (
					<Container ref={provided.innerRef} {...provided.droppableProps}>
						{provided.placeholder}
						{exercises.map((exercise, listIndex) => (
							<Draggable
								draggableId={`${listIndex}`}
								isDragDisabled={disabled}
								index={exercise.index}
								key={`${exercise.exerciseId}-${exercise.index}`}
							>
								{provided => (
									<ExerciseSetContainer
										ref={provided.innerRef}
										{...provided.draggableProps}
									>
										{renderTrackingFields(
											exercise.trackingFieldsValues,
											exercise.index
										)}
										<SetButtonsContainer>
											<div {...provided.dragHandleProps}>
												<Drag />
											</div>
											<CloseEx
												style={{ transform: 'scale(1.3)' }}
												onClick={() => {
													if (!disabled) {
														onRemove(exercise.index)
													}
												}}
											/>
										</SetButtonsContainer>
									</ExerciseSetContainer>
								)}
							</Draggable>
						))}
					</Container>
				)}
			</Droppable>
		</DragDropContext>
	)
}

export default ExerciseSets
