import * as React from 'react';

function SvgUsersGroup(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.818 18.195a6.239 6.239 0 1112.478 0H1.818zm6.24-7.019a4.678 4.678 0 01-4.68-4.679 4.678 4.678 0 014.68-4.679 4.678 4.678 0 014.678 4.68 4.678 4.678 0 01-4.679 4.678zm5.741 1.742a5.853 5.853 0 014.383 5.277h-2.326c0-2.035-.78-3.888-2.057-5.277zm-1.577-1.775a6.224 6.224 0 002.074-4.646 6.21 6.21 0 00-.792-3.043 3.9 3.9 0 01-1.282 7.689z"
        fill="#67667B"
      />
    </svg>
  );
}

export default SvgUsersGroup;
