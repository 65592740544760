import { FlexCenter, SubheadTwo } from 'styled';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: calc(100% - 94px);
  border-top: 1px solid #e1e0e5;

  display: grid;
  grid-template-columns: 125px auto;
  grid-template-rows: 94px calc(100vh - 300px);
`;

export const CeilsWrapper = styled.div`
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 3;

  display: grid;
  grid-template-columns: 125px auto;
  grid-template-rows: 0;

  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
`;

export const TimeColumn = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;

  display: grid;
  grid-template-rows: repeat(24, 138px);

  border-top: 1px solid #e1e0e5;
  margin-top: -1px;
`;

export const TimeCell = styled(FlexCenter)<{ $row: number }>`
  align-items: flex-start;

  grid-row-start: ${({ $row }) => $row};
  grid-row-end: ${({ $row }) => $row + 1};

  border-bottom: 1px solid #e1e0e5;

  position: relative;

  &:first-child {
    & > p {
      top: 0px;
    }
  }
`;

export const TimeContainer = styled(SubheadTwo)`
  position: absolute;
  top: -10px;
  left: 0;
  background-color: white;
  z-index: 3;

  width: calc(100% - 25px);
  text-align: center;
`;

export const WeekdaysRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e1e0e5;

  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
`;

export const WeekdayCell = styled.div`
  width: 100%;
  padding: 14px 24px;

  &:not(:last-child) {
    border-right: 1px solid #e1e0e5;
  }

  &:first-child {
    border-left: 1px solid #e1e0e5;
  }
`;

export const WorkoutsGrid = styled.div`
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 3;

  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(24, 138px);

  overflow: hidden;
`;

export const WorkoutCell = styled.div<{ $column: number; $row: number }>`
  grid-column-start: ${({ $column }) => $column};
  grid-column-end: ${({ $column }) => $column + 1};

  grid-row-start: ${({ $row }) => $row};
  grid-row-end: ${({ $row }) => $row + 1};

  width: calc(100% - 4px * 2);
  padding: 4px;

  border-bottom: 1px solid #e1e0e5;
  border-left: 1px solid #e1e0e5;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  & > div {
    box-sizing: border-box;
  }
`;
