import React from 'react';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { SubheadOne } from 'styled';
import { Exercise } from 'types';
import {
  Container,
  GroupContainer,
  GroupHeadContainer,
  ExerciseImage,
} from './styled';
import { FormExercise } from './types';
import { ExerciseSets } from './components';

const groupExercises = (
  exercises: FormExercise[]
): Record<string, FormExercise[]> => {
  const notNull = exercises.filter(Boolean);

  const result = notNull.reduce((accumulator, currentExercise, index) => {
    const exerciseArray = accumulator[currentExercise.exerciseId];

    if (exerciseArray) {
      const newArray = [...exerciseArray, { ...currentExercise, index }];
      accumulator[currentExercise.exerciseId] = newArray;
      return accumulator;
    }

    accumulator[currentExercise.exerciseId] = [{ ...currentExercise, index }];
    return accumulator;
  }, {});

  return result;
};

type Props = FieldArrayRenderProps<FormExercise, HTMLInputElement> & {
  pickedExercises?: Exercise[];
};

function WorkoutRoutine({ fields, pickedExercises = [] }: Props) {
  const fieldsValue = fields.value;

  const groupedExercises = groupExercises(fieldsValue);

  const renderGroup = (exercisesGroup: FormExercise[]) => {
    if (exercisesGroup.length === 0) {
      return null;
    }

    const { exerciseId } = exercisesGroup[0];
    const exercise = pickedExercises.find(
      (exercise) => exercise.id === exerciseId
    );

    if (!exercise) {
      return null;
    }

    return (
      <GroupContainer key={exercise.id}>
        <GroupHeadContainer>
          <ExerciseImage src={exercise.images[0]} />
          <SubheadOne>{exercise.name}</SubheadOne>
        </GroupHeadContainer>
        <ExerciseSets exercises={exercisesGroup} />
      </GroupContainer>
    );
  };

  return (
    <Container>{Object.values(groupedExercises).map(renderGroup)}</Container>
  );
}

export default WorkoutRoutine;
