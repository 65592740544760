import { FlexCenter } from 'styled';
import styled from 'styled-components';

export const Wrapper = styled(FlexCenter)`
  justify-content: space-between;

  padding: 24px 24px 14px;
`;

export const DateFieldWrapper = styled.div`
  display: flex;

  width: 343px;
`;

export const DatepickerInput = styled(FlexCenter)`
  cursor: pointer;

  margin: 0px 12px;
`;

export const ArrowContainer = styled.div`
  position: relative;
  width: 15px;
  padding-bottom: 5px;
`;

export const DatepickerWrapper = styled.div`
  position: relative;
  left: 0;
  height: 0;

  z-index: 4;
`;
