import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;

  display: grid;

  grid-template-columns: 273px auto;
  grid-template-rows: auto;
  grid-column-gap: 24px;

  padding-right: 80px;
`;

export const MainWrapper = styled.div`
  display: grid;
  grid-template-rows: 88px auto;
  grid-row-gap: 20px;
`;

export const Main = styled.main``;
