import { FlexStart, JustifyBetween } from 'styled';
import styled, { css } from 'styled-components';

export const UnreadSign = styled.i`
  position: absolute;
  right: 25px;
  top: 35px;
  color: #895fc6;

  &::before {
    content: '\\2022';
    font-size: 36px;
  }
`;

export const CloseContainer = styled.div`
  display: none;
  position: absolute;
  right: 25px;
  top: 45px;

  & path {
    fill: #cdccd5;
  }
`;

export const Container = styled(JustifyBetween)`
  cursor: pointer;

  position: relative;
  align-items: flex-start;

  padding: 18px 24px;

  &:hover {
    background-color: #fbfafd;

    & > ${UnreadSign} {
      display: none;
    }

    & > ${CloseContainer} {
      display: block;
    }
  }
`;

export const Wrapper = styled(FlexStart)`
  width: 100%;
  overflow: hidden;
  margin-right: 12px;
`;

export const ContentContainer = styled.div`
  overflow: hidden;
`;

export const TextCSS = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
