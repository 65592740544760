import styled from 'styled-components';

export const ProfileSection = styled.section`
  padding-top: 20px;

  & > section {
    margin-bottom: 24px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
