import React, { useCallback } from 'react';
import { useAsyncCallback, useDebounce } from 'hooks';
import { useEffect } from 'react';
import { useState } from 'react';
import { User } from 'types';
import { Button } from 'UI';
import { ClientList, SearchClientForm, ClientItem } from '..';
import { WrapperList } from './styled';
import { createUsersRelation, getClientsUsers } from 'services/api';
import Toast from 'services/Toast';
import { useHistory } from 'react-router';
import { DEFAULT_AVATAR, ROUTE_PATH } from '@constants';
import { captureException } from '@sentry/minimal';
import { BodyThree, FlexCenter } from 'styled';
import useInfinityScroll from 'hooks/useInfinityScroll';
import { AxiosError } from 'axios';

const CLIENTS_LIMIT = 20;

function GlobalClients() {
  const [searchValue, setSearchValue] = useState('');

  const [globalClients, setGlobalClients] = useState<User[]>([]);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();


  const handleGetGlobalClients = useCallback(async (searchValue: string, page: number) => {
    try {
      setIsLoading(true);
      const { data } = await getClientsUsers({
        clientName: searchValue,
        limit: CLIENTS_LIMIT,
        page: page,
      });
      setTotal(data.total);
      if (page === 1) {
        setGlobalClients(data.data);
      } else {
        setGlobalClients((prev) => [...prev, ...data.data]);
      }
    } catch (err) {
      Toast.error();
      captureException(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debounceSearch = useDebounce(searchValue, 500);

  useEffect(() => {
    setPage(1);
    handleGetGlobalClients(debounceSearch, 1);
  }, [debounceSearch, handleGetGlobalClients]);
  
  const getAfterData = useCallback(async () => {
    if (globalClients.length < total && !isLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [globalClients.length, total, isLoading]);

  useEffect(() => {
    if (page > 1) {
      handleGetGlobalClients(debounceSearch, page);
    }
  }, [page, debounceSearch, handleGetGlobalClients]);

  const { listRef, handleScroll } = useInfinityScroll({
    getAfterData,
    hasAfterMore: globalClients.length < total,
    isLoading,
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);


  const [userIdToSend, setUserIdToSend] = useState<string | null>(null);
  const [sendClientRequest, isSendRequestLoading] = useAsyncCallback(
    async (recipientUserId: string) => {
      try {
        setUserIdToSend(recipientUserId);
        await createUsersRelation({ recipientUserId });
        setGlobalClients((prev) =>
          prev.filter((el) => el.id !== recipientUserId)
        );
        Toast.success('Request sended!', 'Clients');
      } catch (e) {
        Toast.error((e as AxiosError).message || 'Something went wrong...');
        captureException(e);
      } finally {
        setUserIdToSend(null);
      }
    }
  );

  const renderGlobalClientItem = useCallback(() => {
    return globalClients.map((client) => {
      return (
        <ClientItem
          firstName={client.firstName ?? ''}
          lastName={client.lastName ?? ''}
          avatarUrl={client.avatarUrl ?? DEFAULT_AVATAR}
          onClick={() => history.push(ROUTE_PATH.userProfile(client.id))}
          key={client.id}
        >
          <Button
            theme="secondary"
            width="123px"
            maxHeight={48}
            text="Add"
            onClick={() => sendClientRequest(client.id)}
            isLoading={userIdToSend === client.id && isSendRequestLoading}
          />
        </ClientItem>
      );
    });
  }, [globalClients, isSendRequestLoading]);

  const renderContent = () => {
    if (!isLoading && globalClients.length === 0) {
      return (
        <FlexCenter>
          <BodyThree $margin="24px 0 24px 0">
            Nobody found =( Let’s try another name!
          </BodyThree>
        </FlexCenter>
      );
    }

    return (
      <ClientList
        title="Global search clients"
        renderItem={renderGlobalClientItem}
        isLoading={isLoading}
      />
    );
  };

  return (
    <div style={{overflow: 'hidden'}}>
      <SearchClientForm
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <WrapperList ref={listRef}>{renderContent()}</WrapperList>
    </div>
  );
}

export default GlobalClients;
