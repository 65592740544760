import * as React from 'react';

function SvgArchiveUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#67667B"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 0l2 4v13a1 1 0 01-1 1H1a1 1 0 01-1-1V4.004L2 0h16zm-8 7l-4 4h3v4h2v-4h3l-4-4zm6.764-5H3.236l-.999 2h15.527l-1-2z"
        fill="inherit"
      />
    </svg>
  );
}

export default SvgArchiveUp;
