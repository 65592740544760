import React from 'react';
import { imageFormats } from '@constants';
import { Camera } from 'assets/svg';
import { useDropzone } from 'react-dropzone';
import { FieldRenderProps } from 'react-final-form';
import { FlexStart, SubheadOne } from 'styled';
import { convertFileToBase } from 'utils';
import {
  ImageContainer,
  Image,
  UploadImageButton,
  Container,
  ImagesContainer,
} from './styled';
import { SmallRemoveButton } from 'UI';

type Props = FieldRenderProps<string[]> & {
  disabled?: boolean;
};

const maxImageSize = 10 * 1024 * 1024;

function MultiImageField({ input, disabled }: Props) {
  const imagesBases = input.value || [];

  const handleAddImage = async (files: File[]) => {
    const basesFiles = await Promise.all(files.map(convertFileToBase));
    input.onChange([...imagesBases, ...basesFiles]);
  };

  const handleRemoveImage = (baseToRemove: string) => {
    const newFieldValue = imagesBases.filter((base) => base !== baseToRemove);
    input.onChange(newFieldValue);
  };

  const { getInputProps, getRootProps } = useDropzone({
    maxSize: maxImageSize,
    accept: imageFormats,
    onDrop: handleAddImage,
    disabled,
  });

  return (
    <Container>
      <SubheadOne $marginBottom={8} $color={disabled ? '#E1E0E5' : '#201E3F'}>
        Photo
      </SubheadOne>
      <ImagesContainer>
        {imagesBases.map((imageBase) => (
          <ImageContainer key={imageBase}>
            <Image src={imageBase} />
            <SmallRemoveButton onClick={() => handleRemoveImage(imageBase)} />
          </ImageContainer>
        ))}
        <UploadImageButton {...getRootProps()}>
          <Camera />
          <input type="file" {...getInputProps()} />
        </UploadImageButton>
      </ImagesContainer>
    </Container>
  );
}

export default MultiImageField;
