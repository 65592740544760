import styled, { css } from 'styled-components';

export const Container = styled.section`
  padding: 24px;

  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 14px;
`;

export const InputCSS = css`
  padding-left: 56px;
`;

export const VideosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -16px -16px 0;

  & > div {
    box-sizing: border-box;
    width: 33%;

    padding: 16px;
  }
`;
