import * as React from 'react';

function SvgCamera(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5 2.5h5l1.666 1.667H17.5a.833.833 0 01.833.833v11.667a.833.833 0 01-.833.833h-15a.833.833 0 01-.833-.833V5a.833.833 0 01.833-.833h3.333L7.5 2.5zM10 15.833a5 5 0 100-10 5 5 0 000 10zm0-1.666A3.333 3.333 0 1110 7.5a3.333 3.333 0 010 6.667z"
        fill="#67667B"
        opacity={0.6}
      />
    </svg>
  );
}

export default SvgCamera;
