import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ModalWrapper, SelectField, TextField } from 'components';
import { useAsyncCallback } from 'hooks';
import { ModalWithForm } from 'interfaces/common';
import { Field, Form } from 'react-final-form';
import { FlexStart } from 'styled';
import { RowContainer } from './styled';
import { OptionType } from 'UI/types';
import { Button, TextButton } from 'UI';
import { isRequired } from 'utils/validation';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import ExercisesList from './components';
import {
  minuteDurationOptions,
  repetitionsCountOptions,
  setsOptions,
} from '@constants';
import { Exercise, Workout } from 'types';
import dayjs from 'dayjs';
import { getExercises } from 'services/api';
import Toast from 'services/Toast';
import { captureException } from '@sentry/minimal';

type FormValues = {
  name: string;
  setCount: OptionType;
  exercises: {
    id: string;
    exerciseId: OptionType;
    __destroy: boolean;
  }[];
};

type Props = ModalWithForm<FormValues> & {
  initialEntity: Workout;
};

function EditWorkoutModal({ onSubmit, initialEntity, ...rest }: Props) {
  const [isExercisesLoading, setIsExercisesLoading] = useState(false);
  const [exercises, setExercises] = useState<Exercise[]>([]);
  const [asyncSubmit, isSubmitLoading] = useAsyncCallback(onSubmit);

  const initialValues = useMemo(() => {
    return (
      initialEntity && {
        name: initialEntity.name,
        exercises: initialEntity.workoutExercises!.map((el) => ({
          id: el.id,
          exerciseId: {
            value: `${el.exercise.id}`,
            label: `${el.exercise.name}`,
          },
          __destroy: false,
        })),
      }
    );
  }, [initialEntity]);

  const fetchExercises = useCallback(async () => {
    try {
      setIsExercisesLoading(true);
      const { data } = await getExercises({ page: 1, limit: 1000 });
      setExercises(data.data);
    } catch (e) {
      Toast.error();
      captureException(e);
    } finally {
      setIsExercisesLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchExercises();
  }, [rest.isOpen]);

  if (!onSubmit) {
    return null;
  }

  return (
    <ModalWrapper title="Edit training plan" width={1062} isBigModal {...rest}>
      <Form
        onSubmit={asyncSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        subscription={{
          submitFailed: true,
          hasValidationErrors: true,
          dirty: true,
        }}
        render={({
          handleSubmit,
          form: {
            mutators: { push },
          },
          hasValidationErrors,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <RowContainer>
              <Field
                name="name"
                label="Name"
                validate={isRequired()}
                component={TextField}
              />
              <Field
                name="setCount"
                label="Sets"
                options={setsOptions}
                validate={isRequired()}
                component={SelectField}
              />
              <Field
                name="repetitionCount"
                label="Repetitions"
                options={repetitionsCountOptions}
                validate={isRequired()}
                component={SelectField}
              />
              <Field
                name="durationMinutes"
                label="Time"
                options={minuteDurationOptions}
                validate={isRequired()}
                component={SelectField}
              />
            </RowContainer>
            <FieldArray
              name="exercises"
              subscription={{ length: true, value: true }}
              render={(props) => (
                <ExercisesList
                  exercisesOptions={exercises.map((el) => ({
                    value: el.id,
                    label: el.name,
                  }))}
                  {...props}
                />
              )}
            />
            <TextButton
              text="+ Add exercise"
              margin="16px 0px 24px 0px"
              color={isExercisesLoading ? 'grey' : '#D455B1'}
              type="button"
              onClick={() => push('exercises', { __destroy: false })}
            />
            <FlexStart>
              <Button
                text="Save"
                isLoading={isSubmitLoading}
                disabled={hasValidationErrors || !dirty}
                type="submit"
                $margin="0px 12px 0px 0px"
              />
              <Button text="Cancel" theme="secondary" onClick={rest.onClose} />
            </FlexStart>
          </form>
        )}
      />
    </ModalWrapper>
  );
}

export default EditWorkoutModal;
