import React, { useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import {
  TimeCell,
  TimeColumn,
  Wrapper,
  WeekdaysRow,
  WeekdayCell,
  WorkoutsGrid,
  WorkoutCell,
  CeilsWrapper,
  TimeContainer,
} from './styled';
import { SpinnerContainer } from 'components';
import { WorkoutCard } from './components';
import { SubheadTwo, TitleTwo } from 'styled';
import { CalendarEvent } from 'types';
import { DEFAULT_AVATAR, ROUTE_PATH } from '@constants';
import { useHistory } from 'react-router';
import { useScrollIntoView } from 'hooks';

const WEEKDAYS_COUNT = 7;
const HOURS_DELIMITERS_COUNT = 24;

type GroupedEvent = {
  event: CalendarEvent;
  weekdayIndex: number;
  hourIndex: number;
  heightPercent: number;
};

const formatWorkout = (workout: CalendarEvent): GroupedEvent => {
  const workoutStart = dayjs(workout.startDateTime);
  const workoutEnd = dayjs(workout.endDateTime);

  const weekday = workoutStart.weekday();
  const hour = workoutStart.hour();

  const height = Math.abs((workoutStart.diff(workoutEnd, 'minute') / 60) * 100);

  return {
    event: workout,
    weekdayIndex: weekday,
    hourIndex: hour,
    heightPercent: height,
  };
};

interface Props {
  date: string;
  events: CalendarEvent[];

  isLoading: boolean;

  onClickEdit: (eventId: string) => void;
}

function TableBody({ date, events, isLoading, onClickEdit }: Props) {
  const history = useHistory();
  const sixAMRowRef = useRef<HTMLDivElement>(null);

  useScrollIntoView(sixAMRowRef, [date]);

  const weekdays = useMemo(
    () =>
      [...new Array(WEEKDAYS_COUNT)].map((_, idx) => dayjs(date).weekday(idx)),
    [date]
  );

  const hours = useMemo(
    () => [...new Array(HOURS_DELIMITERS_COUNT)].map((_, idx) => idx),
    []
  );

  const groupByHourAndWeekday = useMemo(() => {
    return weekdays.map((weekday) => {
      const selectedWorkoutsIds = [] as string[];

      const weekdayWorkouts = hours.map((hour) => {
        const hourWorkouts = events.filter((workout) => {
          const workoutStart = dayjs(workout.startDateTime);

          const isCorrectDay = workoutStart.isSame(weekday, 'day');
          const isCorrectTime = hour >= workoutStart.hour();
          const isSelected = selectedWorkoutsIds.includes(workout.id);

          if (isCorrectDay && isCorrectTime && !isSelected) {
            selectedWorkoutsIds.push(workout.id);
            return true;
          }

          return false;
        });

        const formatted = hourWorkouts.map(formatWorkout);
        return formatted;
      });

      return weekdayWorkouts;
    });
  }, [events]);

  if (isLoading) {
    return <SpinnerContainer />;
  }

  const renderHourWorkouts = (
    workouts: GroupedEvent[],
    yIndex: number,
    xIndex: number
  ) => {
    return workouts.map((hourEvent) => {
      if (!hourEvent) {
        return null;
      }

      const width = 100 / workouts.length;
      const marginTop =
        (dayjs(hourEvent.event.startDateTime).minute() / 59) * 100;

      return (
        <WorkoutCard
          trainingTitle={hourEvent.event.workout.name}
          clientAvatar={hourEvent.event.client.avatarUrl || DEFAULT_AVATAR}
          clientName={`${hourEvent.event.client.firstName} ${hourEvent.event.client.lastName}`}
          startDate={hourEvent.event.startDateTime}
          endDate={hourEvent.event.endDateTime}
          width={width}
          height={hourEvent.heightPercent}
          marginTop={marginTop}
          onArrowClick={() =>
            history.push(ROUTE_PATH.userProfile(hourEvent.event.clientId))
          }
          onCardClick={() => onClickEdit(hourEvent.event.id)}
          key={`${yIndex} ${xIndex} ${hourEvent.event.id}`}
        />
      );
    });
  };

  return (
    <Wrapper>
      <WeekdaysRow>
        {weekdays.map((weekday) => (
          <WeekdayCell key={weekday.toISOString()}>
            <SubheadTwo $color="#67667B" $size={14}>
              {weekday.format('ddd')}
            </SubheadTwo>
            <TitleTwo>{weekday.format('DD')}</TitleTwo>
          </WeekdayCell>
        ))}
      </WeekdaysRow>
      <CeilsWrapper>
        <TimeColumn>
          {hours.map((hour, yIndex) => (
            <TimeCell
              $row={yIndex + 1}
              ref={yIndex === 5 ? sixAMRowRef : undefined}
              key={`${date} ${hour} ${yIndex}`}
            >
              <TimeContainer $color="#67667b" $size={14}>
                {dayjs().hour(hour).minute(0).format('hh:mm A')}
              </TimeContainer>
            </TimeCell>
          ))}
        </TimeColumn>
        <WorkoutsGrid>
          {groupByHourAndWeekday.map((weekdayWorkouts, yIndex) => {
            return (
              <React.Fragment key={`${yIndex}`}>
                {weekdayWorkouts.map((hourWorkouts, xIndex) => {
                  return (
                    <WorkoutCell
                      $column={yIndex + 1}
                      $row={xIndex + 1}
                      key={`${yIndex} ${xIndex}`}
                    >
                      {renderHourWorkouts(hourWorkouts, yIndex, xIndex)}
                    </WorkoutCell>
                  );
                })}
              </React.Fragment>
            );
          })}
        </WorkoutsGrid>
      </CeilsWrapper>
    </Wrapper>
  );
}

export default TableBody;
