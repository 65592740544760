import * as React from 'react';

function SvgShare(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.833 11.667H9.167a7.498 7.498 0 00-6.64 4.008 8.333 8.333 0 018.307-9.008V2.5l8.333 6.667-8.334 6.666v-4.166z"
        fill="#67667B"
        opacity={0.6}
      />
    </svg>
  );
}

export default SvgShare;
