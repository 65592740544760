import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;

  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 14px;

  max-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
