// eslint-disable-next-line no-useless-escape
const websiteRegExp = /^(www\.)+[a-zA-Z0-9\.\-\_]+(\.[a-zA-Z]{2,3})+(\/[a-zA-Z0-9\_\-\s\.\/\?\%\#\&\=]*)?$/;
const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const digitsAndCharsRegExp = /^[A-Za-z0-9]+$/i;
const atleastDigitRegexp = /^(?=.*?[0-9]).+$/i;
const nameRegExp = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
export const numberRegExp = /^\d+$/;
const MAX_FILE_SIZE_MB = 5;

export const composeValidators = (...validators: any[]) => (value: any) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const lessThan5MB = (bytes: number) => {
  const sizeInMB = bytes / 10 ** 6;

  return sizeInMB > MAX_FILE_SIZE_MB
    ? 'File size must be less than 5 mb'
    : undefined;
};

export const isRequired = (message = 'Required.') => <FieldValue>(
  value?: FieldValue
) =>
  value || (typeof value === 'number' && !Number.isNaN(value))
    ? undefined
    : message;

export const isRequiredLength = (maxLength?: number, minLength?: number) => <
  FieldValue extends string
>(
  value?: FieldValue
) => {
  if (!value) {
    return `Minimum length ${minLength} symbols`;
  }

  if (maxLength && value && value.length > maxLength) {
    return `Maximum length ${maxLength} symbols`;
  }

  if (minLength && value && value.length < minLength) {
    return `Minimum length ${minLength} symbols`;
  }

  return undefined;
};

export const maxLength100 = isRequiredLength(100);

export const notOnlySpaces = <FieldValue extends string>(value?: FieldValue) =>
  value && value.trim().length === 0
    ? 'The field must not contain only spaces'
    : undefined;

export const atleastDigit = <FieldValue extends string>(value?: FieldValue) =>
  value && !atleastDigitRegexp.test(value)
    ? 'Password must have atleast one digit'
    : undefined;

export const passwordLength = <FieldValue extends string>(value?: FieldValue) =>
  value && value.length < 6
    ? 'Minimum password length is 6 symbols'
    : undefined;

export const onlyDigitsAndChars = <FieldValue extends string>(
  value?: FieldValue
) =>
  value && !digitsAndCharsRegExp.test(value)
    ? 'Only digits and characters allowed'
    : undefined;

export const password = <FieldValue extends string>(value?: FieldValue) => {
  const validationErrors = [
    atleastDigit(value),
    passwordLength(value),
    onlyDigitsAndChars(value),
  ].filter((result) => result);

  return validationErrors.length ? validationErrors.join('\n') : undefined;
};

export const confirmPassword = <FieldValue extends string>(
  value: FieldValue,
  formValues
) =>
  formValues.password !== value
    ? 'Password and confirmation password do not match'
    : undefined;

export const isEmail = <FieldValue extends string>(value?: FieldValue) =>
  value && !emailRegExp.test(value) ? 'Incorrect email format' : undefined;

export const name = <FieldValue extends string>(value?: FieldValue) =>
  value && !nameRegExp.test(value) ? 'Incorrect name' : undefined;

export const isNumber = (value: string | number) =>
  isNaN(+value) ? 'Must be a number!' : undefined;

export const phoneNumberLength = <FieldValue extends string>(
  value?: FieldValue
) =>
  value && value.replace(/\+/, '').length < 11
    ? 'Phone number must be 11 digits length'
    : undefined;

export const webSiteLink = <FieldValue extends string>(value?: FieldValue) =>
  value && !websiteRegExp.test(value) ? 'Invalid website' : undefined;

export const isUrl = (str: string) => str.includes('http');
