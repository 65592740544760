import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 50%;
  padding: 24px;
  margin-top: 24px;

  background: #ffffff;

  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 16px;
`;

export const PlanList = styled.div`
  padding: 0 24px;
  margin: 0 -24px;
  list-style: none;

  overflow-y: scroll;

  max-height: 300px;
`;

export const PlanItem = styled.li`
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid #e1e0e5;

  &:last-child {
    border: none;
  }
`;

export const Avatar = styled.div`
  margin-right: 12px;
`;

export const PlanInformation = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export const InformationDateRange = styled.div`
  margin-left: auto;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const InformationTime = styled.div`
  width: 100%;
`;
