import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ExerciseCard = styled.div`
  cursor: pointer;

  width: calc(50% - 8px);

  &:nth-child(2n + 1) {
    margin-right: 8px;
  }

  &:nth-child(2n) {
    margin-left: 8px;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Image = styled.img`
  width: 100%;

  max-height: 116px;
  min-height: 116px;
  margin-bottom: 8px;

  border-radius: 8px;

  object-fit: cover;
`;
