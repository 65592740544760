import styled from 'styled-components';

export const ImageFieldContainer = styled.div`
  width: 100%;
  padding-bottom: 20px;

  & > div {
    height: 100%;
  }
`;

export const RightFieldsContainer = styled.div`
  min-width: 50%;
  margin-left: 24px;
`;
