import React from 'react';
import Select from 'react-select';
import * as customComponents from './components';
import { FieldWrapper } from 'UI';
import { OptionType, SyncSelectProps } from 'UI/types';
import styles, { FieldContainerCSS } from './styles';

function SelectDropdown({
  label,
  error,
  options = [],
  value,
  disabled,
  onChange,
  isMulti = false,
  isClearable,
  hideSelectedOptions = false,
  withLeftCheckbox,
  withRightCheckbox,
  closeOnSelect,
  menuPlacement,
}: SyncSelectProps) {
  return (
    <FieldWrapper
      label={label}
      error={error}
      disabled={disabled}
      fieldContainerCSS={FieldContainerCSS}
    >
      <Select<OptionType, true>
        options={options}
        value={value}
        onChange={onChange}
        placeholder="Select an option..."
        noOptionsMessage={() => 'No options :('}
        components={customComponents}
        styles={styles}
        isMulti={isMulti || undefined}
        hideSelectedOptions={hideSelectedOptions}
        withLeftCheckbox={withLeftCheckbox}
        withRightCheckbox={withRightCheckbox}
        closeOnSelect={closeOnSelect}
        menuPlacement={menuPlacement}
        isDisabled={disabled}
        isClearable={isClearable}
        maxMenuHeight={200}
      />
    </FieldWrapper>
  );
}

export default SelectDropdown;
