import { fileFormats, imageFormats } from '@constants';
import { captureException } from '@sentry/minimal';
import axios, { CancelTokenSource } from 'axios';
import { UploadFileModal } from 'components';
import React, { useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { uploadFileS3 } from 'utils';
import { LoadingAttachment, UploadedAttachments } from './components';
import { UploadsContainer } from './styled';

type Props = FieldRenderProps<string[], HTMLElement> & {
  isUploadImageModalOpen?: boolean;
  isUploadFileModalOpen?: boolean;
  setIsLoading?: (loading: boolean) => void;
};

type UploadProgressMapping = Record<string, number | undefined>;
type CancelRequestsMapping = Record<string, CancelTokenSource | undefined>;

function AttachmentsList({
  input,
  isUploadImageModalOpen = false,
  isUploadFileModalOpen = false,
  setIsLoading,
  onClose,
  ...rest
}: Props) {
  const [attachmentsToUpload, setAttachmentsToUpload] = useState<File[]>([]);
  const [
    attachmentProgressMap,
    setAttachmentProgressMap,
  ] = useState<UploadProgressMapping>({});
  const [
    attachmentCancelRequestMap,
    setAttachmentCancelRequestMap,
  ] = useState<CancelRequestsMapping>({});

  const handleSelectFiles = (files: File[]) => {
    setAttachmentsToUpload([...attachmentsToUpload, ...files]);
    onClose();
  };

  const uploadFiles = async () => {
    if (attachmentsToUpload.length > 0) {
      setIsLoading!(true);
      const promises = attachmentsToUpload.map(async (attachFile) => {
        try {
          const cancel = axios.CancelToken.source();

          setAttachmentCancelRequestMap((prev) => ({
            ...prev,
            [attachFile.name]: cancel,
          }));

          const url = await uploadFileS3(
            attachFile,
            (progress) => {
              setAttachmentProgressMap((prev) => ({
                ...prev,
                [attachFile.name]: progress,
              }));
            },
            cancel.token
          );

          return url;
        } catch (e) {
          captureException(e);
        }
      });
      const uploads = await Promise.all(promises);
      const successfulUploads = uploads.filter(Boolean);
      input.onChange([...input.value, ...successfulUploads]);
      setAttachmentsToUpload([]);
      setIsLoading!(false);
    }
  };

  useEffect(() => {
    uploadFiles();
  }, [attachmentsToUpload]);

  const handleCancelUpload = (fileName: string) => {
    attachmentCancelRequestMap[fileName]?.cancel();

    setAttachmentsToUpload((prev) => prev.filter((el) => el.name !== fileName));
    setAttachmentCancelRequestMap((prev) => ({
      ...prev,
      [fileName]: undefined,
    }));
    setAttachmentProgressMap((prev) => ({ ...prev, [fileName]: undefined }));
  };

  return (
    <div>
      <UploadFileModal
        isOpen={isUploadImageModalOpen}
        onClose={onClose}
        accept={imageFormats}
        maxSize={15 * 1024 * 1024}
        onUpload={handleSelectFiles}
      />
      <UploadFileModal
        isOpen={isUploadFileModalOpen}
        onClose={onClose}
        accept={fileFormats}
        maxSize={500 * 1024 * 1024}
        onUpload={handleSelectFiles}
      />
      {attachmentsToUpload.length > 0 && (
        <UploadsContainer>
          {attachmentsToUpload.map((el) => (
            <LoadingAttachment
              name={el.name}
              progress={attachmentProgressMap[el.name] ?? 0}
              onCancel={() => handleCancelUpload(el.name)}
              key={el.name}
            />
          ))}
        </UploadsContainer>
      )}
      {input.value?.length > 0 && (
        <UploadedAttachments
          attachmentsUrls={input.value}
          onDelete={(deleteAttachUrl) =>
            input.onChange(input.value.filter((el) => el !== deleteAttachUrl))
          }
        />
      )}
    </div>
  );
}

export default AttachmentsList;
