import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);
import { OptionType } from 'UI/types';

export { PusherEvents } from './pusher';

export const ROUTE_PATH = {
  createAccount: '/create-account',
  calendar: '/calendar',
  messages: '/messages',
  chatRoom: (id: string) => `/messages/${id}`,
  clients: '/clients',
  ['clients.globalClinets']: '/clients?activeTab=global',
  userProfile: (id: string) => `/clients/${id}`,
  library: '/library',
  ['library.programs']: '/library?activeTab=programs',
  ['library.programs.id']: (id: string) =>
    `/library?activeTab=programs&id=${id}`,
  ['library.workouts']: '/library?activeTab=workouts',
  ['library.exercise']: '/library?activeTab=exercise',
  program: (id: string) => `/programs/${id}`,
  videos: '/videos',
  video: (id: string) => `/videos/${id}`,
  settings: '/settings',
  profile: '/profile',
  subscriptions: 'subscriptions',
};

export const routesNamesMap = {
  calendar: 'Calendar',
  messages: 'Messages',
  clients: 'Clients',
  library: 'Library',
  programs: 'Programs',
  videos: 'Videos',
  settings: 'Settings',
  profile: 'Profile settings',
  'profile/edit': 'Profile',
};

export const DEFAULT_AVATAR =
  'https://izumator.storage.yandexcloud.net/22/image/49fd7e30-44d3-11eb-898e-b11009511b85';

export const DEFAULT_VIDEO_PREVIEW =
  'https://img2.storyblok.com/1440x0/f/47007/1200x628/9ce2617ca4/tracking-installs-uninstalls-meta_1-0.png';

export const weekCountOptions: OptionType[] = [
  ...new Array(20),
].map((_, i) => ({ value: i + 1, label: `${i + 1}` }));

export const setsOptions: OptionType[] = [...new Array(20)].map((_, i) => ({
  value: i + 1,
  label: `${i + 1}`,
}));

export const repetitionsCountOptions: OptionType[] = [...new Array(20)].map(
  (_, i) => ({
    value: (i + 1) * 5,
    label: `${(i + 1) * 5}`,
  })
);

export const minuteDurationOptions: OptionType[] = [...new Array(20)].map(
  (_, i) => ({
    value: (i + 1) * 5,
    label: `${(i + 1) * 5} min`,
  })
);

export const secondsDurationOptions: OptionType[] = [...new Array(30)].map(
  (_, i) => ({
    value: (i + 1) * 30,
    label: dayjs.duration((i + 1) * 30, 'seconds').format('mm:ss'),
  })
);

export const imageFormats = [
  '.img',
  '.gif',
  '.jpeg',
  '.jpg',
  '.jfif',
  '.heif',
  '.heic',
  '.png',
];
export const videoFormats = ['.mp4', '.mov', '.wmv', '.avi', '.flv'];
export const fileFormats = ['.pdf', '.docx', '.doc', '.xls', '.xlsx'];

export const getLoadFileErrorMap = ({
  maxMegaBytes = 15,
  accept = [],
}: {
  maxMegaBytes?: number;
  accept?: string[];
}) => ({
  'file-too-large': `File larger than ${maxMegaBytes} MB`,
  'file-invalid-type': `Invalid file extension. Allow file extensions: ${accept.join(
    ' ,'
  )}`,
});

export const FITNESS_APP_VIDEO_PREFIX = 'fitness-app-video|';

export const STRIPE_BASE_URL = 'https://api.stripe.com/v1/';
