import { ROUTE_PATH } from '@constants';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectors } from 'store/ducks';

export const useFilledAccount = () => {
  const history = useHistory();
  const user = useSelector(selectors.auth.getUserInfo);

  useEffect(() => {
    if (!user.role) {
      history.push(ROUTE_PATH.createAccount);
    }
  }, [user.role]);
};
