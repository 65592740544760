import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { PRODUCTION_MODE, SENTRY_DSN } from 'config';

Sentry.init({ dsn: SENTRY_DSN, enabled: Boolean(+(PRODUCTION_MODE ?? 0)) });

const container = document.getElementById('root');
if (!container) throw new Error('Root element not found');

const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_DOMAIN || ''}
      clientId={process.env.REACT_APP_CLIENT_ID || ''}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUDIENCE,
        scope: "openid profile email read:current_user update:current_user_metadata"
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
);
