import React from 'react';
import { Headline, BodyOne, SubheadTwo, FlexCenter } from 'styled';
import {
  Wrapper,
  PlanList,
  PlanItem,
  Avatar,
  PlanInformation,
  InformationDateRange,
  InformationTime,
} from './styled';
import { CircleAvatar } from 'styled/index';
import { Button } from 'UI';
import dayjs from 'dayjs';
import { CalendarEvent } from 'types';
import { DEFAULT_AVATAR } from '@constants';
interface CalendarPlanListProps {
  workoutEvents: CalendarEvent[];
  onClick: () => void;
}

function CalendarPlanList({ workoutEvents, onClick }: CalendarPlanListProps) {
  const now = dayjs();

  const futureWorkouts = workoutEvents.filter((workout) =>
    dayjs(workout.startDateTime).isAfter(now)
  );
  const sortedWorkouts = futureWorkouts.sort(
    (a, b) => dayjs(a.startDateTime).unix() - dayjs(b.startDateTime).unix()
  );

  const renderEvents = () => {
    if (sortedWorkouts.length === 0) {
      return (
        <FlexCenter>
          <BodyOne $margin="16px 0">
            You have no workouts for this client
          </BodyOne>
        </FlexCenter>
      );
    }

    return sortedWorkouts.map((event) => {
      const startDate = dayjs(event.startDateTime);
      const endDate = dayjs(event.endDateTime);

      const eventHours = endDate.diff(startDate, 'hour');
      const eventMin = endDate.diff(startDate, 'minute') - eventHours * 60;
      return (
        <PlanItem key={event.id}>
          <Avatar>
            <CircleAvatar
              $url={event.client.avatarUrl || DEFAULT_AVATAR}
              $size={40}
            />
          </Avatar>
          <PlanInformation>
            <BodyOne>{event.workout.name}</BodyOne>
            <InformationDateRange>
              <SubheadTwo>
                {dayjs(event.startDateTime).format('DD MMMM hh:mm A')} {' - '}
                {dayjs(event.endDateTime).format('hh:mm A')}
              </SubheadTwo>
            </InformationDateRange>
            <InformationTime>
              <SubheadTwo>
                {eventHours ? `${eventHours} hour ` : ''}
                {eventMin ? `${eventMin} min ` : ''}
              </SubheadTwo>
            </InformationTime>
          </PlanInformation>
        </PlanItem>
      );
    });
  };

  return (
    <Wrapper>
      <Headline $marginBottom={8}>Calendar plan</Headline>
      <PlanList>{renderEvents()}</PlanList>
      <Button
        text="Add a workout"
        $margin="8px 0 0 0"
        width="100%"
        onClick={onClick}
      />
    </Wrapper>
  );
}

export default CalendarPlanList;
