import React from 'react';
import { FootnoteOne, FootnoteTwo } from 'styled';
import { Wrapper, Icon, ToastMessageContainer } from './styled';

export declare type ToastType = 'success' | 'error';

interface ToastProps {
  title?: string;
  message: string;
  IconComponent: React.ReactNode;

  onClick?: () => void;
}

function Toast({ title, message, IconComponent, onClick }: ToastProps) {
  return (
    <Wrapper onClick={onClick}>
      <Icon>{IconComponent}</Icon>

      <ToastMessageContainer>
        {title && <FootnoteOne>{title}</FootnoteOne>}
        <FootnoteTwo>{message}</FootnoteTwo>
      </ToastMessageContainer>
    </Wrapper>
  );
}

export default Toast;
