import * as React from 'react';

function SvgCalendar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.667 9.167h16.667v7.5a.833.833 0 01-.834.833h-15a.833.833 0 01-.833-.833v-7.5zm12.5-6.667H17.5a.833.833 0 01.834.833V7.5H1.667V3.333A.833.833 0 012.5 2.5h3.334V.833H7.5V2.5h5V.833h1.667V2.5z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgCalendar;
