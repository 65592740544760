import React, { useMemo } from 'react';
import { repetitionsCountOptions, secondsDurationOptions } from '@constants';
import { AsyncSelectField, ModalWrapper, SelectField } from 'components';
import { useAsyncCallback } from 'hooks';
import { ModalWithForm } from 'interfaces/common';
import { Field, Form } from 'react-final-form';
import { getExercises } from 'services/api';
import { WorkoutExercise } from 'types';
import { Button } from 'UI';
import { OptionType } from 'UI/types';
import { PairFields, ButtonsContainer } from './styled';
import dayjs from 'dayjs';

type FormValues = {
  exercise: OptionType;
  repetitionCount: OptionType;
  durationSeconds: OptionType;
};

type Props = ModalWithForm<FormValues> & {
  initialEntity: WorkoutExercise;
};

function EditExerciseModal({ initialEntity, onSubmit, ...rest }: Props) {
  const [asyncSubmit, isLoading] = useAsyncCallback(onSubmit);

  const initialValues = useMemo(() => {
    const value: FormValues = {
      exercise: {
        value: initialEntity.exercise.id,
        label: initialEntity.exercise.name,
      },
      durationSeconds: {
        value: ``,
        label: dayjs.duration(666, 'seconds').format('mm:ss'),
      },
      repetitionCount: {
        value: ``,
        label: ``,
      },
    };
    return value;
  }, [initialEntity]);

  if (!onSubmit) {
    return null;
  }

  return (
    <ModalWrapper title="Edit exercise" width={628} {...rest}>
      <Form<FormValues>
        onSubmit={asyncSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, hasValidationErrors, dirty }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="exercise"
              label="Exercises"
              render={(props) => (
                <AsyncSelectField
                  getOptions={async () =>
                    getExercises({ page: 1, limit: 1000 })
                  }
                  normalizeOptions={(raw) =>
                    raw.data.map((el) => ({ value: el.id, label: el.name }))
                  }
                  {...props}
                />
              )}
            />
            <PairFields>
              <Field
                name="repetitionCount"
                options={repetitionsCountOptions}
                label="Repetitions"
                component={SelectField}
              />
              <Field
                name="durationSeconds"
                options={secondsDurationOptions}
                label="Time"
                component={SelectField}
              />
            </PairFields>
            <ButtonsContainer>
              <Button
                text="Save"
                type="submit"
                width="114px"
                isLoading={isLoading}
                disabled={hasValidationErrors || !dirty}
              />
              <Button
                text="Cancel"
                type="button"
                theme="secondary"
                disabled={isLoading}
                onClick={rest.onClose}
              />
            </ButtonsContainer>
          </form>
        )}
      />
    </ModalWrapper>
  );
}

export default EditExerciseModal;
