import { CloseEx, File } from 'assets/svg';
import React from 'react';
import { SubheadTwo } from 'styled';
import { CircleButton } from 'UI';
import { getMessageFileTypeFromUrl } from 'utils';
import {
  ImageContainer,
  ImageDeleteButton,
  DocumentContainer,
  ImagesContainer,
  DocumentsContainer,
} from './styled';

type AttachmentProps = {
  url: string;
  onDelete: () => void;
};

const ImageAttachment = (props: AttachmentProps) => {
  return (
    <ImageContainer $imageUrl={props.url}>
      <ImageDeleteButton>
        <CircleButton color="#D555B1" size={36} onClick={props.onDelete}>
          <CloseEx />
        </CircleButton>
      </ImageDeleteButton>
    </ImageContainer>
  );
};

const DocumentAttachment = (props: AttachmentProps) => {
  const decodedUrl = decodeURI(props.url);
  const fileName = decodedUrl.split('|').slice(-1)[0];

  return (
    <DocumentContainer>
      <File />
      <SubheadTwo $color="#67667B">{fileName}</SubheadTwo>
      <CloseEx onClick={props.onDelete} />
    </DocumentContainer>
  );
};

type Props = {
  attachmentsUrls: string[];
  onDelete: (url: string) => void;
};

function UploadedAttachments({ attachmentsUrls, onDelete }: Props) {
  const images = attachmentsUrls.filter(
    (el) => getMessageFileTypeFromUrl(el) === 'image'
  );

  const documents = attachmentsUrls.filter(
    (el) => getMessageFileTypeFromUrl(el) === 'document'
  );

  return (
    <div>
      {images.length > 0 && (
        <ImagesContainer>
          {images.map((imageUrl) => (
            <ImageAttachment
              url={imageUrl}
              onDelete={() => onDelete(imageUrl)}
              key={imageUrl}
            />
          ))}
        </ImagesContainer>
      )}
      {documents.length > 0 && (
        <DocumentsContainer>
          {documents.map((fileUrl) => (
            <DocumentAttachment
              url={fileUrl}
              onDelete={() => onDelete(fileUrl)}
              key={fileUrl}
            />
          ))}
        </DocumentsContainer>
      )}
    </div>
  );
}

export default UploadedAttachments;
