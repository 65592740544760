import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const ExerciseSetContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  position: relative;

  border: 1px solid #e1e0e5;
  border-radius: 8px;

  margin-bottom: 16px;

  padding: 8px 32px;
`;

export const TrackingFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: 80px;

  & div {
    padding-bottom: 0;
  }

  & input[type='time']::-webkit-calendar-picker-indicator {
    display: none;
    background: none;
  }
`;

export const TrackingFieldInputCSS = css`
  max-width: 60px;
  text-align: center;

  padding: 4px;
`;

export const SetButtonsContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  align-items: center;

  & svg {
    & path {
      fill: #67667b;
    }

    cursor: pointer;
  }

  & div {
    margin-right: 30px;
  }
`;
