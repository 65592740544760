import {
  ExerciseEquipmentValue,
  ExerciseDifficultyValue,
  ExerciseBodyPartValue,
} from 'types';

export const equipmentOptions: {
  value: ExerciseEquipmentValue;
  label: string;
}[] = [
  { value: 'bodyweight', label: 'Bodyweight' },
  { value: 'cables', label: 'Cables' },
  { value: 'dumbbells', label: 'Dumbbells' },
  { value: 'trx', label: 'TRX' },
];

export const difficultyOptions: {
  value: ExerciseDifficultyValue;
  label: string;
}[] = [
  { value: 'advanced', label: 'Advanced' },
  { value: 'beginner', label: 'Beginner' },
  { value: 'intermediate', label: 'Intermediate' },
];

export const bodyPartOptions: {
  value: ExerciseBodyPartValue;
  label: string;
}[] = [
  { value: 'abdominal', label: 'Abdominal' },
  { value: 'biceps', label: 'Biceps' },
  { value: 'chest', label: 'Chest' },
];
