import styled from 'styled-components';

export const RadioContainer = styled.div`
  display: flex;
  justify-self: flex-start;

  max-width: fit-content;

  & > button {
    margin-right: 14px;
  }

  margin-bottom: 12px;

  cursor: pointer;
`;
