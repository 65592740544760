import * as React from 'react';
import PropTypes from 'prop-types'

function SvgSearch({
  fill = '#67667B',
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={21}
      height={21}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.031 14.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 019 18c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 01-1.969 5.617zm-2.006-.742A6.977 6.977 0 0016 9c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 004.875-1.975l.15-.15z"
        fill={fill}
      />
    </svg>
  );
}

SvgSearch.propTypes = {
  fill: PropTypes.string,
};

export default SvgSearch;
