import * as React from 'react';

function SvgCopy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.833 5V2.5a.833.833 0 01.834-.833h10a.833.833 0 01.833.833v11.666a.833.833 0 01-.833.834h-2.5v2.5c0 .46-.375.833-.84.833H3.34A.835.835 0 012.5 17.5l.002-11.667c0-.46.376-.833.84-.833h2.491zM7.5 5h6.667v8.333h1.666v-10H7.5V5z"
        fill="#67667B"
      />
    </svg>
  );
}

export default SvgCopy;
