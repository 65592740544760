import React, { useRef, useState } from 'react';
import { Attachment, SendSubmit } from 'assets/svg';
import { Camera, Video, File } from './icons';
import { TextField } from 'components';
import { Field, Form } from 'react-final-form';
import {
  AttachmentsButton,
  SubmitButton,
  InputCSS,
  AttachmentsTypesButtons,
  AttachmentButton,
  WrapperCSS,
} from './styled';
import { SubheadTwo } from 'styled';
import { AttachmentsList } from './components';
import { useAsyncCallback } from 'hooks';
import { FormApi } from 'final-form';
import { captureException } from '@sentry/minimal';
import { isRequired } from 'utils/validation';

type FormValues = {
  body: string;
  attachments?: string[];
};

type Props = {
  onSubmit: (values: FormValues) => void;
};

function MessageForm({ onSubmit }: Props) {
  const [isAttachmentsMenuOpen, setIsAttachmentsMenuOpen] = useState(false);

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);

  const [asyncSubmit, isLoading] = useAsyncCallback(onSubmit);

  const [isAttachmentsLoading, setIsAttachmentsLoading] = useState(false);

  const submitButton = (
    <SubmitButton
      type="submit"
      margin="0 0 0 12px"
      disabled={isLoading || isAttachmentsLoading}
    >
      <SendSubmit />
    </SubmitButton>
  );

  const attachmentsButton = (
    <AttachmentsButton>
      {isAttachmentsMenuOpen && (
        <AttachmentsTypesButtons>
          <AttachmentButton onClick={() => setIsImageModalOpen(true)}>
            <Camera width="20px" height="20px" />
            <SubheadTwo>Photo</SubheadTwo>
          </AttachmentButton>
          {/* <AttachmentButton onClick={() => setIsAttachModalOpen(true)}>
            <Video />
            <SubheadTwo>Video</SubheadTwo>
          </AttachmentButton> */}
          <AttachmentButton onClick={() => setIsFileModalOpen(true)}>
            <File />
            <SubheadTwo>File</SubheadTwo>
          </AttachmentButton>
        </AttachmentsTypesButtons>
      )}
      <Attachment
        onClick={() => setIsAttachmentsMenuOpen(!isAttachmentsMenuOpen)}
      />
    </AttachmentsButton>
  );

  const handleSubmit = async (
    values: FormValues,
    formApi: FormApi<FormValues>
  ) => {
    if (!values.body && values.attachments?.length === 0) {
      return { body: isRequired(' ')(values.body) };
    }

    try {
      await asyncSubmit({
        body: values.attachments!.length > 0 && !values.body ? '' : values.body,
        attachments: values.attachments,
      });
      setTimeout(() => {
        formApi.resetFieldState('body');
        formApi.reset(undefined);
      }, 0);
    } catch (e) {
      captureException(e);
      return {};
    }
  };

  const { current: attachmentsInitialValue } = useRef<string[]>([]);

  return (
    <Form<FormValues>
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="body"
            render={(props) => (
              <TextField
                {...props}
                theme="secondary"
                placeholder="Write message"
                rightSibling={submitButton}
                leftChild={attachmentsButton}
                inputCSS={InputCSS}
                wrapperCSS={WrapperCSS}
              />
            )}
          />
          <Field
            name="attachments"
            isUploadImageModalOpen={isImageModalOpen}
            isUploadFileModalOpen={isFileModalOpen}
            onClose={() => {
              setIsImageModalOpen(false);
              setIsAttachmentsMenuOpen(false);
              setIsFileModalOpen(false);
            }}
            initialValue={attachmentsInitialValue}
            setIsLoading={setIsAttachmentsLoading}
            component={AttachmentsList}
          />
        </form>
      )}
    />
  );
}

export default MessageForm;
