import styled from 'styled-components';

export const ImagesContainer = styled.div`
  display: flex;

  overflow-x: scroll;

  padding-top: 20px;
  padding-bottom: 20px;

  & > div:not(:last-child) {
    margin-right: 24px;
  }
`;

export const ImageContainer = styled.div<{ $imageUrl: string }>`
  position: relative;

  min-width: 120px;
  max-width: 120px;
  height: 120px;
  border-radius: 8px;

  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-size: cover;
  background-position: center center;
`;

export const ImageDeleteButton = styled.div`
  position: absolute;

  right: -18px;
  top: -18px;
`;

export const DocumentsContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  & > div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const DocumentContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;

  min-width: 150px;

  overflow: hidden;

  & > svg {
    transform: scale(0.8);
    min-width: 24px;

    margin-right: 10px;

    & > path {
      fill: #a4a3b0;
    }
  }

  & > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
  }

  & > svg:last-child {
    min-width: 24px;

    cursor: pointer;

    & > path {
      fill: #67667b;
    }
  }
`;
