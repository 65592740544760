import React from 'react';
import { Container, Circle } from './styled';

type Props = {
  isActive: boolean;

  onClick: () => void;
};

function Toggler({ isActive, onClick }: Props) {
  return (
    <Container onClick={onClick}>
      <Circle $isActive={isActive} />
    </Container>
  );
}

export default Toggler;
