import * as React from 'react';

function SvgMessage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform="scale(1.2)"
      {...props}
    >
      <path
        d="M16.8 19L14 22.5 11.2 19H6a1 1 0 01-1-1V7.103a1 1 0 011-1h16a1 1 0 011 1V18a1 1 0 01-1 1h-5.2zM2 2h17v2H3v11H1V3a1 1 0 011-1z"
        fill="#2BAEE0"
      />
    </svg>
  );
}

export default SvgMessage;
