import { FlexCenter } from 'styled';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const ButtonContainer = styled(FlexCenter)`
  display: none;

  cursor: pointer;
  position: absolute;

  width: 48px;
  max-width: 48px;

  height: 48px;
  max-height: 48px;

  background-color: #e7e0f3;
  border-radius: 14px;

  top: 50%;
  left: 50%;

  transform: translateX(-50%) translateY(-50%);

  & path {
    fill: #895fc6;
  }
`;

const getBackground = (image?: string) => {
  return image ? `url(${image})` : '#f3eff9';
};

export const InputContainer = styled(FlexCenter)<{ $image?: string }>`
  width: 100%;
  height: calc(100% - 28px);

  position: relative;
  border-radius: 8px;

  background: ${({ $image }) => getBackground($image)};
  background-size: cover;
  background-position: center center;

  cursor: pointer;

  outline: none;

  &:hover {
    background-image: linear-gradient(
        to right,
        rgba(142, 7, 246, 0.4),
        rgba(142, 7, 246, 0.4)
      ),
      ${({ $image }) => getBackground($image)};

    & ${ButtonContainer} {
      display: ${({ $image }) => ($image ? 'flex' : 'none')};
    }
  }
`;

export const EditButtonContainer = styled.div`
  position: absolute;
`;
