import { ModalWrapper } from 'components';
import { ModalBaseProps } from 'interfaces/common';
import React, { useRef, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { Button } from 'UI';
import { CropContainer } from './styled';

type Props = ModalBaseProps & {
  image: string;
  ratio?: number;

  onCrop: (newImage: string) => void;
};

function CropModal({
  image: baseImage,
  ratio = 4 / 3,
  onCrop,
  ...rest
}: Props) {
  const imgRef = useRef<HTMLImageElement | null>(null);

  const [crop, setCrop] = useState<Crop>({
    aspect: ratio,
  });

  const onLoad = (img: HTMLImageElement) => {
    imgRef.current = img;
  };

  const getCroppedImg = (completedCrop: Crop) => {
    if (completedCrop.width === 0 || completedCrop.height === 0) {
      rest.onClose();
      return;
    }

    const currentImage = imgRef.current;

    if (!completedCrop || !currentImage) {
      return;
    }

    const canvas = document.createElement('canvas');

    const scaleX = currentImage.naturalWidth / currentImage.width;
    const scaleY = currentImage.naturalHeight / currentImage.height;

    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = completedCrop.width! * pixelRatio;
    canvas.height = completedCrop.height! * pixelRatio;

    if (ctx) {
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';
    }

    ctx?.drawImage(
      currentImage,
      completedCrop.x! * scaleX,
      completedCrop.y! * scaleY,
      completedCrop.width! * scaleX,
      completedCrop.height! * scaleY,
      0,
      0,
      completedCrop.width!,
      completedCrop.height!
    );

    const croppedBase = canvas.toDataURL();
    onCrop(croppedBase);
    rest.onClose();
  };

  return (
    <ModalWrapper
      width={800}
      withCloseOnClickOutside={false}
      title="Change image"
      isBigModal
      {...rest}
    >
      <CropContainer>
        <ReactCrop
          src={baseImage}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(crop) => setCrop(crop)}
        />
      </CropContainer>
      <Button text="Save" type="button" onClick={() => getCroppedImg(crop)} />
    </ModalWrapper>
  );
}

export default CropModal;
