import styled from 'styled-components';

export const Container = styled.div``;

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 652px) repeat(2, minmax(160px, 1fr));
  grid-column-gap: 32px;

  justify-content: space-between;
  align-items: center;

  & div:last-child {
    display: flex;
    justify-content: flex-end;
  }
`;

export const GridTopRow = styled(GridRow)`
  margin-bottom: 20px;

  & > div:not(:first-child):not(:last-child) {
    justify-content: center;
  }
`;

export const GridDataRow = styled(GridRow)`
  cursor: pointer;

  position: relative;

  padding-top: 18px;
  padding-bottom: 18px;

  border-radius: 14px;

  & > div:not(:first-child) {
    text-align: center;
  }

  &:hover {
    background-color: #f3eff9;
  }
`;

export const AuthorContainer = styled.div`
  position: relative;
`;

export const CoachesLogoContainer = styled.div`
  position: absolute;
  right: -35px;
  top: -20px;

  & svg {
    transform: scale(0.5);
  }
`;
