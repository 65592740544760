import styled, { css } from 'styled-components';

export const HeadContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > div:first-child {
    max-width: 110px;
    margin-right: 24px;

    & > div {
      min-height: 110px;
    }

    & svg {
      transform: scale(0.8);
    }
  }

  border-bottom: 1px solid #e1e0e5;

  margin: -24px -24px 0px;
  padding: 0 24px 8px;
`;

export const HeadInputsContainer = styled.div`
  & div {
    margin-bottom: 0 !important;
    padding-bottom: 0;
  }
`;

export const NameTextCSS = css`
  font-weight: 800;
  font-size: 32px;
  line-height: 24px;
`;

export const DescriptionTextCSS = css`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
`;

export const ExercisesContainer = styled.div`
  border-right: 1px solid #e1e0e5;

  max-width: 453px;
  min-width: 453px;

  padding-right: 38px;
  padding-top: 20px;

  padding-bottom: 16px;
`;

export const FormBodyContainer = styled.div`
  margin-bottom: -24px;

  display: flex;
`;

export const SubmitButton = styled.div`
  position: absolute;
  right: 60px;
  bottom: 18px;

  display: flex;

  & button:not(:last-child) {
    margin-right: 16px;
  }
`;
