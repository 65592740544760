import styled, { CSSProp } from 'styled-components';

export const Wrapper = styled.div<{ $CSS?: CSSProp }>`
  position: absolute;
  width: 100%;

  & .react-datepicker {
    padding: 20px;

    background: #ffffff;

    box-shadow: 0px 4px 32px rgba(32, 30, 63, 0.1);
    border-radius: 8px;
    border-color: transparent;

    width: calc(100% - 40px);
  }

  & .react-datepicker-popper {
    margin-top: 0;
    transform: none !important;

    width: 100%;
  }

  & .react-datepicker__month-container {
    width: 100%;
  }

  & .react-datepicker__triangle {
    display: none;
  }

  & .react-datepicker__header {
    background-color: white;
    border-bottom: none;
    padding-top: 0px;
  }

  & .react-datepicker__month {
    margin: 0px;
  }

  & .react-datepicker__day {
    font-family: Inter, sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: #201e3f;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 40px;

    border-radius: 4px;
    border: 1px solid #e1e0e5;

    outline: none;
  }

  & .react-datepicker__week {
    display: flex;
    justify-content: space-between;

    &:hover {
      & .react-datepicker__day {
        background-color: #f3eff9;
      }
    }
  }

  & .react-datepicker__day--selected {
    background-color: #895fc6 !important;
    border-color: #895fc6 !important;

    font-weight: bold;
    color: #ffffff;
  }

  & .react-datepicker__day--outside-month {
    background-color: #f9fbfd;
  }

  & .react-datepicker__day-names {
    display: flex;
    justify-content: space-around;
  }

  & .react-datepicker__day-name {
    font-family: Inter, sans-serif;

    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: #67667b;

    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${({ $CSS }) => $CSS}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 8px;
`;

export const HeaderText = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;

  letter-spacing: -0.0141em;
  color: #201e3f;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.button`
  cursor: pointer;
  position: relative;

  padding: 0;
  width: 24px;
  height: 24px;

  background-color: #f3eff9;
  border-radius: 50%;
  outline: none;
  border: none;

  &:first-child {
    margin-right: 5px;
  }
`;

export const ButtonIcon = styled.i<{ $isRight: boolean }>`
  /* TODO: Add hover effects */

  position: absolute;
  right: calc(50% - 2px);
  top: calc(50% - 3px);

  border: solid #895fc6;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(${({ $isRight }) => ($isRight ? '-45deg' : '135deg')});
`;
