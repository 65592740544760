import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const WrapperList = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > svg {
    margin-bottom: 20px;
  }

  & > p {
    margin-bottom: 80px;
  }

  & > button {
    margin-bottom: 80px;
  }
`;

export const AddClientButton = styled.div`
  position: absolute;

  top: -80px;
  right: 0;
`;
