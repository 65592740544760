import styled, { CSSProp } from 'styled-components';

const FontBase = styled.p<{
  $weight?: string;
  $size?: number;
  $color?: string;
  $height?: number;
  $margin?: string;
  $marginBottom?: number;
  $CSS?: CSSProp;
}>`
  font-family: Inter, sans-serif;
  font-weight: ${({ $weight }) => $weight && `${$weight} !important`};
  font-size: ${({ $size }) => $size && `${$size}px !important`};
  color: ${({ $color }) => ($color ? `${$color} !important` : '#201E3F')};
  line-height: ${({ $height }) => $height && `${$height}px !important`};
  margin: ${({ $margin }) => $margin};
  margin-bottom: ${({ $marginBottom }) =>
    $marginBottom && `${$marginBottom}px`};

  ${({ $CSS }) => $CSS}
`;

const fontFactory = (weight: string, size: number, height: number) => {
  return styled(FontBase)`
    font-weight: ${weight};
    font-size: ${size}px;
    line-height: ${height}px;
  `;
};

export const FootnoteThree = fontFactory('normal', 11, 16);
export const FootnoteTwo = fontFactory('normal', 12, 16);
export const FootnoteOne = fontFactory('bold', 12, 16);

export const SubheadTwo = fontFactory('normal', 14, 20);
export const SubheadOne = fontFactory('bold', 14, 20);

export const BodyThree = fontFactory('normal', 16, 20);
export const BodyTwo = fontFactory('600', 16, 20);
export const BodyOne = fontFactory('bold', 16, 20);

export const Headline = fontFactory('bold', 20, 24);

export const TitleFive = fontFactory('bold', 24, 32);
export const TitleFour = fontFactory('bold', 28, 36);
export const TitleThree = fontFactory('bold', 34, 44);
export const TitleTwo = fontFactory('bold', 44, 56);
export const TitleOne = fontFactory('bold', 48, 64);
