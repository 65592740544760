import React from 'react';
import { Switch } from 'react-router-dom';
import { MainLayout } from 'layouts';
import Route from './Route';
import {
  Calendar,
  ClientInformation,
  Library,
  CreateAccount,
  HomePage,
  Program,
  Clients,
  Profile,
  ProfileEdit,
  Videos,
  Video,
  Messages,
  ChatRoom,
  Subscriptions,
} from 'pages';
import {
  useChatPresence,
  useFilledAccount,
  useStripeSubscription,
} from 'hooks';
import { useChatNotifications } from 'hooks/useChatNotifications';
import { useIntercom } from 'react-use-intercom';
import { useSelector } from 'react-redux';
import { selectors } from 'store/ducks';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const public_key_stripe = process.env.REACT_APP_STRIPE_PUBLIC;
const stripePromise = loadStripe(public_key_stripe || '');

const AuthorizedRoutes = () => {
  const user = useSelector(selectors.auth.getUserInfo);

  useFilledAccount();
  useChatPresence();
  useChatNotifications();
  useStripeSubscription();

  const { boot } = useIntercom();
  boot({
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    customAttributes: { id: user.id },
  });

  return (
    <Elements stripe={stripePromise}>
      <Switch>
        <Route path="/calendar" layout={MainLayout} component={Calendar} />
        <Route
          exact
          path="/messages"
          layout={MainLayout}
          component={Messages}
        />
        <Route path="/messages/:id" layout={MainLayout} component={ChatRoom} />
        <Route exact path="/clients" layout={MainLayout} component={Clients} />
        <Route
          exact
          path="/clients/:id"
          layout={MainLayout}
          layoutProps={{ withBack: true }}
          component={ClientInformation}
        />
        <Route exact path="/library" layout={MainLayout} component={Library} />
        <Route
          path="/programs/:id"
          layout={MainLayout}
          layoutProps={{ withBack: true }}
          component={Program}
        />
        <Route exact path="/videos" layout={MainLayout} component={Videos} />
        <Route
          path="/videos/:id"
          layout={MainLayout}
          layoutProps={{ withBack: true }}
          component={Video}
        />
        <Route exact path="/profile" layout={MainLayout} component={Profile} />
        <Route
          path="/profile/edit"
          layout={MainLayout}
          layoutProps={{ withBack: true }}
          component={ProfileEdit}
        />
        {/* <Route path="/settings" layout={MainLayout} component={LearnReact} /> */}
        <Route path="/create-account" component={CreateAccount} />
        <Route path="/subscriptions" component={Subscriptions} />
        <Route path="/" component={HomePage} />
      </Switch>
    </Elements>
  );
};

export default AuthorizedRoutes;
