import * as React from 'react';
import dayjs from 'dayjs';
import { Headline, TitleFive, SubheadTwo, BodyOne } from 'styled/fonts';
import {
  Wrapper,
  ContainerInfo,
  Information,
  InformationList,
  InformationItem,
  ContainerButtons,
} from './styled';
import { CircleAvatar } from 'styled/index';
import { Button } from 'UI';
import { Archive } from 'assets/svg';
import IconButton from 'UI/IconButton';
import { DEFAULT_AVATAR } from '@constants';
import { useAsyncCallback } from 'hooks';

type Props = {
  firstName?: string;
  lastName?: string;
  avatarUrl?: string;
  dateBirth?: string;
  email?: string;
  height?: number;
  weight?: number;
  isArchived?: boolean;

  onClickButtonMessage?: () => void;
  onClickButtonArchive?: () => void;
};

function ClientInfo({
  firstName,
  lastName,
  avatarUrl,
  dateBirth,
  email,
  height,
  weight,
  isArchived,
  onClickButtonMessage,
  onClickButtonArchive,
}: Props) {
  const [onClickMessage, isClickMessageLoading] = useAsyncCallback(
    onClickButtonMessage
  );
  const [onClickArchive, isClickArchiveLoading] = useAsyncCallback(
    onClickButtonArchive
  );

  return (
    <Wrapper>
      <Headline $marginBottom={24}>Client info</Headline>

      <ContainerInfo>
        <CircleAvatar $url={avatarUrl || DEFAULT_AVATAR} $size={80} />
        <Information>
          <TitleFive>
            {firstName} {lastName}
          </TitleFive>
          {dayjs(dateBirth).isValid() && (
            <SubheadTwo $color="#67667B">
              {dayjs(dateBirth).format('MMMM D, YYYY').toLowerCase()}
            </SubheadTwo>
          )}
          <InformationList>
            {email && (
              <InformationItem>
                <BodyOne>Email</BodyOne>
                <SubheadTwo>{email}</SubheadTwo>
              </InformationItem>
            )}
            {height && (
              <InformationItem>
                <BodyOne>Height</BodyOne>
                <SubheadTwo>{height} cm</SubheadTwo>
              </InformationItem>
            )}
            {weight && (
              <InformationItem>
                <BodyOne>Weight</BodyOne>
                <SubheadTwo>{weight} kg</SubheadTwo>
              </InformationItem>
            )}
          </InformationList>
        </Information>
        <ContainerButtons>
          {onClickButtonMessage && (
            <Button
              text="Write a message"
              $margin="0 12px 0 0"
              onClick={onClickMessage}
              isLoading={isClickMessageLoading}
              disabled={isClickArchiveLoading}
            />
          )}
          {onClickButtonArchive && (
            <IconButton
              theme="secondary"
              onClick={onClickArchive}
              disabled={isClickArchiveLoading || isClickMessageLoading}
              title="Archive client"
            >
              <Archive
                style={{
                  transform: `rotate(${isArchived ? 180 : 0}deg)`,
                }}
              />
            </IconButton>
          )}
        </ContainerButtons>
      </ContainerInfo>
    </Wrapper>
  );
}

export default ClientInfo;
