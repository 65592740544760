import styled, { css, CSSProp } from 'styled-components';

const toPixels = (value?: number) => {
  if (!value) {
    return null;
  }
  return `${value}px`;
};
const indentProdiver = css<{
  $marginTop?: number;
  $marginRight?: number;
  $marginBottom?: number;
  $marginLeft?: number;
  $paddingTop?: number;
  $paddingRight?: number;
  $paddingBottom?: number;
  $paddingLeft?: number;
}>`
  margin-top: ${({ $marginTop }) => toPixels($marginTop)};
  margin-right: ${({ $marginRight }) => toPixels($marginRight)};
  margin-bottom: ${({ $marginBottom }) => toPixels($marginBottom)};
  margin-left: ${({ $marginLeft }) => toPixels($marginLeft)};

  padding-top: ${({ $paddingTop }) => toPixels($paddingTop)};
  padding-right: ${({ $paddingRight }) => toPixels($paddingRight)};
  padding-bottom: ${({ $paddingBottom }) => toPixels($paddingBottom)};
  padding-left: ${({ $paddingLeft }) => toPixels($paddingLeft)};
`;

export const DefaultContainer = styled.div`
  ${indentProdiver}
`;

export const DefaultRelativeContainer = styled(DefaultContainer)`
  position: relative;
`;

export const FlexCenter = styled(DefaultContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexStart = styled(DefaultContainer)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const FlexEnd = styled(DefaultContainer)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const JustifyBetween = styled(DefaultContainer)`
  display: flex;
  justify-content: space-between;
`;

export const CircleAvatar = styled.div<{
  $url: string;
  $size?: number;
  $marginRight?: number;
  $marginBottom?: number;
  $withPointerCursor?: boolean;
}>`
  background-image: url(${({ $url }) => $url});
  border-radius: 50%;
  background-size: cover;
  min-width: ${({ $size }) => toPixels($size ?? 24)};
  max-width: ${({ $size }) => toPixels($size ?? 24)};
  min-height: ${({ $size }) => toPixels($size ?? 24)};
  max-height: ${({ $size }) => toPixels($size ?? 24)};
  margin-right: ${({ $marginRight }) => $marginRight && `${$marginRight}px`};
  margin-bottom: ${({ $marginBottom }) =>
    $marginBottom && `${$marginBottom}px`};

  cursor: ${({ $withPointerCursor }) =>
    $withPointerCursor ? 'pointer' : 'auto'};
`;

export const FilledArrow = styled.i<{
  $isActive: boolean;
  $color: string;
  $width: number;
  $CSS?: CSSProp;
}>`
  border-left: ${({ $width }) => $width}px solid transparent;
  border-right: ${({ $width }) => $width}px solid transparent;
  border-top: ${({ $width }) => $width}px solid ${({ $color }) => $color};

  transform: rotate(${({ $isActive }) => ($isActive ? '-180deg' : '0deg')});
  transition: transform 0.3s;

  ${({ $CSS }) => $CSS}
`;

export const ternarOperator = (predicate: any, success: any, failure: any) => {
  return predicate ? success : failure;
};
