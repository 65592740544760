import React from 'react';
import { Container, ProgressFill } from './styled';

type Props = {
  progress: number;
  width: number;
};

function ProgressBar({ width, progress }: Props) {
  return (
    <Container $width={width}>
      <ProgressFill $width={progress} />
    </Container>
  );
}

export default ProgressBar;
