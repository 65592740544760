import { useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCheckoutSession, getAllSubscriptionPlans } from 'services/api';
import { actions, selectors } from 'store/ducks';
import { BodyThree } from 'styled';
import { Product } from 'types';
import { Plan, Toggler } from './components';
import image from 'assets/images/background.png';
import {
  Container,
  HeaderContainer,
  PlansContainer,
  ToggleContainer,
} from './styled';
import Toast from 'services/Toast';
import { useHistory } from 'react-router';
import { ROUTE_PATH } from '@constants';
import { AppDispatch } from 'store';

function Subscriptions() {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const [isPricesMonthly, setIsPricesMonthly] = useState(true);
  const { subscription } = useSelector(selectors.auth.getUserInfo);
  const stripe = useStripe();

  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    const fetchPlans = async () => {
      const { data } = await getAllSubscriptionPlans();
      setProducts(data);
    };

    fetchPlans();
  }, []);

  const handleCheckout = async (id: string) => {
    try {
      const { data } = await createCheckoutSession({
        priceId: id,
        successUrl: window.origin,
        cancelUrl: window.origin,
      });
      await stripe?.redirectToCheckout({
        sessionId: data.sessionId,
      });
      dispatch(actions.auth.loginUser());
    } catch (e) {
      Toast.error('Something went wrong...', 'Subscription');
    }
  };

  useEffect(() => {
    if (subscription) {
      Toast.warn(
        'You already have subscription. Please manage it from your profile',
        'Subscription'
      );
      history.push(ROUTE_PATH.calendar);
    }
  }, [subscription]);

  const renderPlans = () => {
    return products.map((product, index) => {
      const monthPrice = product.prices.find(
        (price) => price.recurringInterval === 'monthly'
      )!;
      const yearPrice = product.prices.find(
        (price) => price.recurringInterval === 'yearly'
      )!;

      if (monthPrice && yearPrice) {
        return (
          <Plan
            name={product.name}
            description={product.description}
            currency={monthPrice.currency || yearPrice.currency || 'usd'}
            monthPrice={monthPrice.amount}
            yearPrice={yearPrice.amount}
            meta={product.meta || []}
            onSelect={async () =>
              await handleCheckout(
                isPricesMonthly ? monthPrice.id : yearPrice.id
              )
            }
            isMonthPrice={isPricesMonthly}
            isSelected={product.id === subscription?.productId}
            isPopular={index === 1}
            key={product.id}
          />
        );
      }
    });
  };

  return (
    <Container $url={image}>
      <HeaderContainer>
        <h1>Choose your plan</h1>
        <h2>Take your desired plan to start working with our clients</h2>
      </HeaderContainer>
      <ToggleContainer>
        <BodyThree>Bill monthly</BodyThree>
        <Toggler
          isActive={!isPricesMonthly}
          onClick={() => setIsPricesMonthly(!isPricesMonthly)}
        />
        <BodyThree>Bill annually</BodyThree>
      </ToggleContainer>
      <PlansContainer>{renderPlans()}</PlansContainer>
    </Container>
  );
}

export default Subscriptions;
