import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  flex: 1;
  padding: 24px 0px 80px 0px;

  position: relative;
  max-height: calc(100vh - 170px);
  overflow: hidden;
  box-sizing: border-box;

  & > form {
    background-color: white;

    position: absolute;
    width: calc(100% - 48px);
    bottom: 0;
    left: 50%;

    transform: translateX(-50%);

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    margin: 0 -1px;
  }
`;

export const MessagesContainer = styled.div`
  flex: 1;
  overflow: scroll;
`;
