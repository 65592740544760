import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { ConfirmModal, SpinnerContainer, WorkoutModal } from 'components';
import {
  EditExerciseModal,
  EditProgramModal,
  EditWorkoutModal,
  Header,
  WorkoutsList,
} from './components';
import { useParams } from 'react-router-dom';
import {
  Workout,
  WorkoutExercise,
  UpdateWorkoutDto,
  UpdateProgramBody,
  UpdateWorkoutExerciseDto,
  CreateProgramWorkoutDto,
} from 'types';
import { DefaultContainer } from 'styled';
import reducer, {
  initialState,
  fetchProgram,
  deleteProgramWorkout,
  deleteExercise,
  createProgramWorkout,
  fetchPlanExercises,
  updateProgramWorkout,
  updateProgram,
  updateExercise,
} from './reducer';

function Program() {
  const { id } = useParams<{ id: string }>();

  const [state, dispatch] = useReducer(reducer, initialState);

  const [isEditProgramModalOpen, setIsEditProgramModalOpen] = useState(false);

  const [isCreateWorkoutModalOpen, setIsCreateWorkoutModalOpen] = useState(
    false
  );

  const [isEditWorkoutModalOpen, setIsEditWorkoutModalOpen] = useState(false);
  const [workoutToEdit, setWorkoutToEdit] = useState<Workout | null>(null);

  const [isDeleteWorkoutModalOpen, setIsDeleteWorkoutModalOpen] = useState(
    false
  );
  const [workoutIdToDelete, setWorkoutIdToDelete] = useState<string | null>(
    null
  );

  const [isEditExerciseModalOpen, setIsEditExerciseModalOpen] = useState(false);
  const [exerciseToEdit, setExerciseToEdit] = useState<WorkoutExercise | null>(
    null
  );

  const [isDeleteExerciseModalOpen, setIsDeleteExerciseModalOpen] = useState(
    false
  );
  const [workoutExerciseIdToDelete, setWorkoutExerciseIdToDelete] = useState<
    string | null
  >(null);

  useEffect(() => {
    fetchProgram(dispatch, id);
  }, [id]);

  const submitEditProgram = async (values: UpdateProgramBody) => {
    await updateProgram(dispatch, state.id, values);
    setIsEditProgramModalOpen(false);
  };

  const submitAddPlan = async (values: CreateProgramWorkoutDto) => {
    await createProgramWorkout(dispatch, values);
    setIsCreateWorkoutModalOpen(false);
  };

  const handleEditPlan = async (id: string) => {
    const plan = state.programWorkouts?.find((plan) => plan.workout.id === id)
      ?.workout;
    if (plan) {
      if (!state.fetchPlanExercisesStatusMap[id]) {
        const exercises = await fetchPlanExercises(dispatch, id);
        setWorkoutToEdit({ ...plan, workoutExercises: exercises });
      } else {
        const workoutExercises = state.workoutExercises.filter(
          (el) => el.workoutId === plan.id
        );
        const sortedExercises = workoutExercises.sort(
          (a, b) => a.order - b.order
        );
        const planToEdit: Workout = {
          ...plan,
          workoutExercises: sortedExercises,
        };
        setWorkoutToEdit(planToEdit);
      }
      setIsEditWorkoutModalOpen(true);
    }
  };

  const submitEditPlan = async (
    values: UpdateWorkoutDto,
    exercisesToDelete: string[]
  ) => {
    if (workoutToEdit) {
      await updateProgramWorkout(
        dispatch,
        workoutToEdit.id,
        values,
        exercisesToDelete
      );
      setWorkoutToEdit(null);
      setIsEditWorkoutModalOpen(false);
    }
  };

  const handleDeletePlan = async (id: string) => {
    const workoutId = state.programWorkouts.find((el) => el.workoutId === id)
      ?.id;
    if (workoutId) {
      setWorkoutIdToDelete(workoutId);
      setIsDeleteWorkoutModalOpen(true);
    }
  };

  const submitDeletePlan = async (id: string) => {
    await deleteProgramWorkout(dispatch, id);
    setWorkoutIdToDelete(null);
    setIsDeleteWorkoutModalOpen(false);
  };

  const handleEditExercise = (workoutExerciseId: string) => {
    const exercise = state.workoutExercises.find(
      (el) => el.id === workoutExerciseId
    );
    if (exercise) {
      setExerciseToEdit(exercise);
      setIsEditExerciseModalOpen(true);
    }
  };

  const submitEditExercise = async (values: UpdateWorkoutExerciseDto) => {
    await updateExercise(dispatch, exerciseToEdit!.id, values);
    setExerciseToEdit(null);
    setIsEditExerciseModalOpen(false);
  };

  const handleDeleteExercise = (workoutExerciseId: string) => {
    setWorkoutExerciseIdToDelete(workoutExerciseId);
    setIsDeleteExerciseModalOpen(true);
  };

  const submitDeleteExercise = async () => {
    if (workoutExerciseIdToDelete) {
      await deleteExercise(dispatch, workoutExerciseIdToDelete);
      setWorkoutExerciseIdToDelete(null);
      setIsDeleteExerciseModalOpen(false);
    }
  };

  const startFetchPlanExercises = async (planId: string) => {
    if (!state.fetchPlanExercisesStatusMap[planId]) {
      await fetchPlanExercises(dispatch, planId);
    }
  };

  const getWorkouts = useCallback(() => {
    const sorted = state.programWorkouts?.sort(
      (a, b) => (a.dayNumber ?? 0) - (b.dayNumber ?? 0)
    );

    return sorted.map((el) => el.workout) || [];
  }, [state.programWorkouts]);

  if (state.isProgramLoading) {
    return <SpinnerContainer />;
  }

  return (
    <DefaultContainer>
      <EditProgramModal
        isOpen={isEditProgramModalOpen}
        onClose={() => setIsEditProgramModalOpen(false)}
        initialEntity={state}
        onSubmit={(values) =>
          submitEditProgram({
            name: values.name,
            description: values.description,
            imageUrl: values.imageUrl,
            weekCount: +values.weekCount.value,
            // programWorkouts: values.programWorkouts
            //   ? values.programWorkouts.map((el, i) => ({
            //       __destroy: el.__destroy,
            //       id: el.id,
            //       order: i,
            //     }))
            //   : [],
          })
        }
      />
      <WorkoutModal
        isOpen={isCreateWorkoutModalOpen}
        onClose={() => setIsCreateWorkoutModalOpen(false)}
        onSubmit={(values) =>
          submitAddPlan({
            programId: id,
            weekNumber: 0,
            dayNumber: 0,
            workoutDto: {
              name: values.name,
              imageUrl: '',
              workoutExercises: values.exercises
                ? values.exercises.map((el, i) => ({
                    exerciseId: el.id.value,
                    durationSeconds: el.durationSeconds.value,
                    repetitionCount: el.repetitionCount.value,
                    order: i,
                  }))
                : [],
            },
          })
        }
      />
      {workoutToEdit && (
        <EditWorkoutModal
          isOpen={isEditWorkoutModalOpen}
          onClose={() => setIsEditWorkoutModalOpen(false)}
          onSubmit={(values) =>
            submitEditPlan(
              {
                name: values.name,
                workoutExercises: values.exercises
                  ? values.exercises.map((el, i) => ({
                      id: el.id,
                      exerciseId: el.exerciseId.value,
                      order: i,
                      __destroy: el.__destroy,
                    }))
                  : [],
              },
              values.exercises.filter((el) => el.__destroy).map((el) => el.id)
            )
          }
          initialEntity={workoutToEdit}
        />
      )}
      {workoutIdToDelete && (
        <ConfirmModal
          agreeText="Delete"
          disagreeText="Cancel"
          description="Are you sure that you want to delete this workout?"
          title="Delete workout"
          isOpen={isDeleteWorkoutModalOpen}
          onClose={() => setIsDeleteWorkoutModalOpen(false)}
          onAgree={() => submitDeletePlan(workoutIdToDelete)}
        />
      )}
      {exerciseToEdit && (
        <EditExerciseModal
          isOpen={isEditExerciseModalOpen}
          onClose={() => setIsEditExerciseModalOpen(false)}
          initialEntity={exerciseToEdit}
          onSubmit={(values) =>
            submitEditExercise({
              exerciseId: values.exercise.value,
              // durationSeconds: +values.durationSeconds.value,
              // repetitionCount: +values.repetitionCount.value,
              order: exerciseToEdit.order,
            })
          }
        />
      )}
      {workoutExerciseIdToDelete && (
        <ConfirmModal
          agreeText="Delete"
          disagreeText="Cancel"
          description="Are you sure that you want to delete this exercise?"
          title="Delete exercise"
          isOpen={isDeleteExerciseModalOpen}
          onClose={() => setIsDeleteExerciseModalOpen(false)}
          onAgree={() => submitDeleteExercise()}
        />
      )}
      <Header
        imageUrl={state.imageUrl || ''}
        name={state.name}
        description={state.description}
        weekCount={state.weekCount}
        workoutsCount={state.programWorkouts?.length || 0}
        onAddWorkout={() => setIsCreateWorkoutModalOpen(true)}
        onEdit={() => setIsEditProgramModalOpen(true)}
      />
      <WorkoutsList
        workouts={getWorkouts()}
        exercises={state.workoutExercises}
        fetchStatuses={state.fetchPlanExercisesStatusMap}
        onCreatePlan={() => setIsCreateWorkoutModalOpen(true)}
        onFetchPlanExercises={startFetchPlanExercises}
        onDeletePlan={handleDeletePlan}
        onEditPlan={handleEditPlan}
        onDeleteExercise={handleDeleteExercise}
        onEditExercise={handleEditExercise}
      />
    </DefaultContainer>
  );
}

export default Program;
