import * as React from 'react';

function SvgClientsEmpty(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={300}
      height={300}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M83.827 206.654a93.582 93.582 0 00-27.41 66.173h187.165a93.583 93.583 0 00-159.755-66.173zM79.813 97.36c0 38.778 31.409 70.187 70.187 70.187s70.186-31.409 70.186-70.187S188.778 27.173 150 27.173c-38.778 0-70.187 31.409-70.187 70.187z"
        fill="#F2F7FF"
      />
    </svg>
  );
}

export default SvgClientsEmpty;
