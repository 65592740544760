import { ROUTE_PATH } from '@constants';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectors } from 'store/ducks';

export const useStripeSubscription = () => {
  const user = useSelector(selectors.auth.getUserInfo);
  const history = useHistory();

  useEffect(() => {
    if (user.role && !user.subscription) {
      history.push(ROUTE_PATH.subscriptions);
    }
  }, [user.subscription, user.role]);
};
