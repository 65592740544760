import styled from 'styled-components';

export const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;

  padding-bottom: 16px;

  cursor: pointer;

  padding: 16px 20px;

  &:not(:last-child) {
    padding-top: 16px;
    border-bottom: 1px solid #e1e0e5;
  }

  &:hover {
    background-color: #f9fbfd;
  }

  &:first-child {
    &:hover {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
  }

  &:last-child {
    &:hover {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
`;

export const IconContainer = styled.div`
  margin-right: 12px;
`;
