import React, { useCallback } from 'react';
import {
  Container,
  GridTopRow,
  GridDataRow,
  AuthorContainer,
  CoachesLogoContainer,
} from './styled';
import { SortDirection, Workout } from 'types';
import { BodyOne, SubheadTwo } from 'styled';
import { Logo } from 'assets/svg';
import { SpinnerContainer, SortingField } from 'components';
import { useSelector } from 'react-redux';
import { selectors } from 'store/ducks';

type Props = {
  workouts: Workout[];
  activeSort: string | null;
  activeDirection: SortDirection;

  isLoading: boolean;

  onChangeSort: (sortingField: string) => void;
  onEdit: (id: string) => void;
};

function WorkoutsGrid({
  workouts,
  activeSort,
  activeDirection,
  isLoading,
  onChangeSort,
  onEdit,
}: Props) {
  const currentUser = useSelector(selectors.auth.getUserInfo);

  const renderItem = useCallback(
    (workout: Workout) => {
      const authorJsx =
        workout.authorId === currentUser.id ? (
          <SubheadTwo>{`${currentUser.firstName} ${currentUser.lastName}`}</SubheadTwo>
        ) : (
          <CoachesLogoContainer>
            <Logo transform="scale(0.6)" />
          </CoachesLogoContainer>
        );

      return (
        <GridDataRow onClick={() => onEdit(workout.id)} key={workout.id}>
          <div>
            <BodyOne>{workout.name}</BodyOne>
            <SubheadTwo>{workout.description}</SubheadTwo>
          </div>
          <div>
            <SubheadTwo>{workout.exercisesCount}</SubheadTwo>
          </div>
          <AuthorContainer>{authorJsx}</AuthorContainer>
        </GridDataRow>
      );
    },
    [currentUser, onEdit]
  );

  return (
    <Container>
      <GridTopRow>
        <SortingField
          text={`Workouts (${workouts.length})`}
          isActive={activeSort === 'name'}
          direction={activeDirection}
          onClick={() => onChangeSort('name')}
        />
        <SortingField
          text="Exercises"
          isActive={activeSort === 'exercisesCount'}
          direction={activeDirection}
          onClick={() => onChangeSort('exercisesCount')}
        />
        <SortingField
          text="Owner"
          isActive={activeSort === 'author.firstName'}
          direction={activeDirection}
          onClick={() => onChangeSort('author.firstName')}
        />
      </GridTopRow>
      {isLoading ? <SpinnerContainer /> : workouts.map(renderItem)}
    </Container>
  );
}

export default WorkoutsGrid;
