import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background: #ffffff;

  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 14px;
  overflow: hidden;
`;

export const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: auto;
  overflow: hidden;

  position: relative;
  padding-bottom: 56.25%;

  & > div iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  box-sizing: border-box;

  padding: 24px;

  & > p {
    max-width: calc(100vw - 430px);
    overflow-wrap: break-word;
  }
`;

export const AuthorContainer = styled.div`
  cursor: pointer;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

  & > div:first-child {
    margin-right: 8px;
  }

  &:hover {
    & > p {
      text-decoration: underline;
    }
  }
`;

export const ArrowCSS = css`
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  cursor: pointer;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    & > p {
      text-decoration: underline;
    }
  }

  &:first-child {
    margin-right: 40px;
  }
`;
