import React from 'react';
import { StyledToastContainer } from 'UI/Toast/styled';
import { Provider } from 'react-redux';
import { store } from './store';
import AppRouter from 'routes';

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import duration from 'dayjs/plugin/duration';
dayjs.extend(weekday);
dayjs.extend(duration);

import './assets/styles/index.css';
import { IntercomProvider } from 'react-use-intercom';
import { INTERCOM_APP_ADD } from 'config';

function App() {
  return (
    <Provider store={store}>
      <IntercomProvider appId={INTERCOM_APP_ADD!}>
        <AppRouter />
        <StyledToastContainer />
      </IntercomProvider>
    </Provider>
  );
}

export default App;
