import styled from 'styled-components';

export const Wrapper = styled.div`
  cursor: pointer;
  position: relative;

  display: flex;
  align-items: center;
`;

export const Avatar = styled.div<{ $imageUrl: string }>`
  background-image: ${({ $imageUrl }) => `url(${$imageUrl})`};
  background-size: cover;
  border-radius: 50%;

  max-width: 44px;
  max-height: 44px;
  min-width: 44px;
  min-height: 44px;

  margin-right: 15px;
`;

export const Name = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;

  color: #201e3f;
  margin-right: 30px;
`;

export const Arrow = styled.div<{ $isActive: boolean }>`
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #201e3f;

  transform: rotate(${({ $isActive }) => ($isActive ? '-180deg' : '0deg')});
  transition: transform 0.3s;
`;
