import React from 'react';
import { CloseEx } from 'assets/svg';
import { RemoveButton } from './styled';

type Props = {
  onClick: () => void;
};

function SmallRemoveButton({ onClick }: Props) {
  return (
    <RemoveButton onClick={() => onClick()} type="button">
      <CloseEx />
    </RemoveButton>
  );
}

export default SmallRemoveButton;
