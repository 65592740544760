import React, { useCallback } from 'react'
import { Archive, ClientsEmpty } from 'assets/svg'
import { useAsyncCallback } from 'hooks'
import { useEffect } from 'react'
import { useState } from 'react'
import { BodyThree, colors, FlexCenter } from 'styled'
import { RelationUsers, User } from 'types'
import { Button, Spinner } from 'UI'
import { ClientList, SearchClientForm, ClientItem } from '..'
import {
	EmptyStateContainer,
	WrapperList,
	AddClientButton,
	Container,
} from './styled'
import IconButton from 'UI/IconButton'
import {
	getUserRelations,
	updateUsersRelation,
	deleteUsersRelation,
} from 'services/api'
import Toast from 'services/Toast'
import { useHistory } from 'react-router'
import { DEFAULT_AVATAR, ROUTE_PATH } from '@constants'
import { useSelector } from 'react-redux'
import { selectors } from 'store/ducks'
import { captureException } from '@sentry/minimal'
import { InviteClientModal } from './components'
import { AxiosError } from 'axios'

function MyClients() {
	const userId = useSelector(selectors.auth.getUserId)

	const [searchValue, setSearchValue] = useState('')

	const [myClients, setMyClients] = useState<RelationUsers[] | null>(null)
	const [clientsRequests, setClientsRequests] = useState<RelationUsers[]>([])

	const [isLoadingMyClients, setLoadingMyClients] = useState(false)
	const [isLoadingNewClientRequest, setLoadingNewClientRequest] =
		useState(false)

	const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)

	const history = useHistory()

	const handleGetClients = useCallback(async () => {
		try {
			setLoadingMyClients(true)
			setLoadingNewClientRequest(true)
			if (userId) {
				const { data } = await getUserRelations()

				const myClients = data.filter(
					el => el.status === 'accept' && !el.archived
				)

				const myRequests = data.filter(
					el => el.status === 'pending' && !el.archived
				)
				setMyClients(myClients)
				setClientsRequests(myRequests)
			}
		} catch (e) {
			Toast.error()
			captureException(e)
		} finally {
			setLoadingMyClients(false)
			setLoadingNewClientRequest(false)
		}
	}, [userId])

	let filteredMyClients = myClients || []

	if (searchValue && myClients) {
		filteredMyClients = myClients.filter(client => {
			const user = client.initiatorUser || client.recipientUser

			const searchString = `${user.firstName} ${user.lastName}`.toLowerCase()
			return searchString.includes(searchValue)
		})
	}

	useEffect(() => {
		if (!myClients) {
			handleGetClients()
		}
	}, [handleGetClients])

	const [acceptUserId, setAcceptUserId] = useState('')
	const [acceptRequest, isAcceptLoading] = useAsyncCallback(
		async (acceptUserId: string) => {
			try {
				setAcceptUserId(acceptUserId)
				const { data } = await updateUsersRelation(acceptUserId, {
					status: 'accept',
					archived: false,
				})
				setClientsRequests(prev =>
					prev.filter(el => el.initiatorUserId !== acceptUserId)
				)
				setMyClients(prev => [...prev!, data])
				Toast.success('Request accepted!', 'Clients')
			} catch (e) {
				Toast.error((e as AxiosError).message || 'Something went wrong')
				captureException(e)
			} finally {
				setAcceptUserId('')
			}
		}
	)

	const [declineUserId, setDeclineUserId] = useState('')
	const [declineRequest, isDeclineLoading] = useAsyncCallback(
		async (declineUserId: string) => {
			try {
				setDeclineUserId(declineUserId)
				await deleteUsersRelation(declineUserId)
				setClientsRequests(prev =>
					prev.filter(el => el.initiatorUserId !== declineUserId)
				)
				Toast.info('Request declined!', 'Clients')
			} catch (e) {
				Toast.error((e as AxiosError).message || 'Something went wrong')
				captureException(e)
			} finally {
				setDeclineUserId('')
			}
		}
	)

	const renderNewClientItem = useCallback(() => {
		return clientsRequests.map(request => {
			const user =
				request.initiatorUserId === userId
					? request.recipientUser
					: request.initiatorUser

			return (
				<ClientItem
					firstName={user.firstName ?? ''}
					lastName={user.lastName ?? ''}
					avatarUrl={user.avatarUrl || DEFAULT_AVATAR}
					onClick={() => history.push(ROUTE_PATH.userProfile(user.id))}
					key={request.recipientUserId}
				>
					<Button
						theme='secondary'
						width='123px'
						$margin='0 8px 0 0'
						maxHeight={48}
						text='Accept'
						onClick={() => acceptRequest(user.id)}
						isLoading={isAcceptLoading && acceptUserId === user.id}
						disabled={isDeclineLoading && declineUserId === user.id}
					/>
					<Button
						backgroundColor={colors.silverDevider}
						textColor={colors.textPrimary}
						hoverColor={colors.silverDevider}
						width='123px'
						maxHeight={48}
						text='Decline'
						onClick={() => declineRequest(user.id)}
						isLoading={isDeclineLoading && declineUserId === user.id}
						disabled={isAcceptLoading && acceptUserId === user.id}
					/>
				</ClientItem>
			)
		})
	}, [
		clientsRequests,
		isAcceptLoading,
		isDeclineLoading,
		acceptUserId,
		declineUserId,
	])

	const [archiveClient, isArchiveLoading] = useAsyncCallback(
		async (archiveUserId: string) => {
			try {
				await updateUsersRelation(archiveUserId, {
					status: 'accept',
					archived: true,
				})
				setMyClients(prev =>
					prev!.filter(el => {
						const isArchivedRelationInverse =
							el.initiatorUserId === userId &&
							el.recipientUserId === archiveUserId
						const isArchivedRelationReverse =
							el.initiatorUserId === archiveUserId &&
							el.recipientUserId === userId

						if (isArchivedRelationInverse || isArchivedRelationReverse) {
							return false
						}

						return true
					})
				)
				Toast.info('Client archived!', 'Clients')
			} catch (e) {
				Toast.error((e as AxiosError).message || 'Something went wrong')
				captureException(e)
			}
		}
	)

	const renderMyClientItem = useCallback(() => {
		return filteredMyClients.map(request => {
			const user =
				request.initiatorUserId === userId
					? request.recipientUser
					: request.initiatorUser

			return (
				<ClientItem
					firstName={user.firstName ?? ''}
					lastName={user.lastName ?? ''}
					avatarUrl={user.avatarUrl ?? DEFAULT_AVATAR}
					onClick={() => history.push(ROUTE_PATH.userProfile(user.id))}
					key={user.id}
				>
					<IconButton
						theme='base'
						onClick={() => archiveClient(user.id)}
						disabled={isArchiveLoading}
					>
						<Archive width={20} height={18} fill={colors.textSecondary} />
					</IconButton>
				</ClientItem>
			)
		})
	}, [filteredMyClients, isArchiveLoading])

	const renderContent = () => {
		if (
			clientsRequests.length === 0 &&
			!searchValue &&
			filteredMyClients.length === 0
		) {
			return (
				<EmptyStateContainer>
					<ClientsEmpty />
					<BodyThree>
						No clients here yet. Search for a client to find one
					</BodyThree>
					<Button
						text='Search for a client'
						width='435px'
						onClick={() => history.push(ROUTE_PATH['clients.globalClinets'])}
					/>
				</EmptyStateContainer>
			)
		}

		return (
			<React.Fragment>
				{clientsRequests.length > 0 && (
					<ClientList title='New requests' renderItem={renderNewClientItem} />
				)}
				{filteredMyClients.length > 0 && (
					<ClientList title='Your clients' renderItem={renderMyClientItem} />
				)}
			</React.Fragment>
		)
	}

	// const handleInviteClient = (newClient: RelationUsers) => {
	//   if (myClients) {
	//     setMyClients([...myClients, newClient]);
	//   }
	// };

	return (
		<Container>
			{isInviteModalOpen && (
				<InviteClientModal
					isOpen={isInviteModalOpen}
					onClose={() => setIsInviteModalOpen(false)}
					onSuccess={() => {
						console.log('s')
					}}
				/>
			)}
			<AddClientButton>
				<Button text='Add client' onClick={() => setIsInviteModalOpen(true)} />
			</AddClientButton>
			<SearchClientForm
				searchValue={searchValue}
				setSearchValue={setSearchValue}
			/>
			<WrapperList>
				{(isLoadingMyClients || isLoadingNewClientRequest) && (
					<FlexCenter $marginTop={40} $marginBottom={40}>
						<Spinner $size={30} />
					</FlexCenter>
				)}
				{!(isLoadingMyClients || isLoadingNewClientRequest) && renderContent()}
			</WrapperList>
		</Container>
	)
}

export default MyClients
