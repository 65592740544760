import { ROUTE_PATH } from '@constants';
import {
  Calendar,
  Discuss,
  Programs,
  Settings,
  UsersGroup,
  Videos,
  Logo as LogoSvg,
} from 'assets/svg';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectors } from 'store/ducks';
import { FlexStart } from 'styled';
import { ChatRoom } from 'types';
import {
  Aside,
  Logo,
  LogoWrapper,
  NavigationWrapper,
  IconWrapper,
  Button,
  UnreadRoomsCount,
} from './styled';

interface NavigationButtonProps {
  Icon: any;
  text: string;
  link: string;
  isCurrentPage?: boolean;
  children?: React.ReactNode;
}

const NavigationButton = ({
  Icon,
  text,
  link,
  isCurrentPage,
  children,
}: NavigationButtonProps) => {
  const history = useHistory();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(link);
  };

  return (
    <Button onClick={handleClick} $isActive={isCurrentPage}>
      <FlexStart>
        <IconWrapper $isActive={isCurrentPage}>
          <Icon />
        </IconWrapper>
        {text}
      </FlexStart>
      {children}
    </Button>
  );
};

const getUnreadRoomsCount = (rooms: ChatRoom[], userId: string): number => {
  const unreadRooms = rooms.filter((room) => {
    const currentUserParticipant = room.participants.find(
      (el) => el.userId === userId
    )!;

    const isRoomHaveLastMessage = Boolean(room.lastMessage);
    if (!isRoomHaveLastMessage) {
      return false;
    }

    const isUserReadLastMessage =
      currentUserParticipant.lastReadMessageId === room.lastMessage.id;
    if (isUserReadLastMessage) {
      return false;
    }

    const isUserSendLastMessage =
      room.lastMessage.user.id === currentUserParticipant.userId;
    if (isUserSendLastMessage) {
      return false;
    }

    return true;
  });

  return unreadRooms.length;
};

function Sidebar() {
  const userId = useSelector(selectors.auth.getUserId);
  const chatRooms = useSelector(selectors.chatRooms.selectAll);

  const unreadRoomsCount = getUnreadRoomsCount(chatRooms, userId);

  const navigationLinks: NavigationButtonProps[] = [
    { Icon: Calendar, text: 'Calendar', link: ROUTE_PATH.calendar },
    {
      Icon: Discuss,
      text: 'Messages',
      link: ROUTE_PATH.messages,
      children: unreadRoomsCount > 0 && (
        <UnreadRoomsCount>{`+${unreadRoomsCount}`}</UnreadRoomsCount>
      ),
    },
    { Icon: UsersGroup, text: 'Clients', link: ROUTE_PATH.clients },
    { Icon: Programs, text: 'Library', link: ROUTE_PATH.library },
    { Icon: Videos, text: 'Videos', link: ROUTE_PATH.videos },
    { Icon: Settings, text: 'Profile settings', link: ROUTE_PATH.profile },
  ];

  const location = useLocation();
  const history = useHistory();

  return (
    <Aside>
      <LogoWrapper>
        <Logo onClick={() => history.push(ROUTE_PATH.calendar)}>
          <LogoSvg />
        </Logo>
      </LogoWrapper>
      <NavigationWrapper>
        {navigationLinks.map((el) => (
          <NavigationButton
            {...el}
            isCurrentPage={location.pathname.includes(el.link)}
            key={el.text}
          />
        ))}
      </NavigationWrapper>
    </Aside>
  );
}

export default Sidebar;
