import React, { useEffect, useState } from 'react';
import { useAsyncCallback } from 'hooks';
import {
  createWorkout,
  deleteWorkout,
  getWorkouts,
  updateWorkout,
} from 'services/api';
import Toast from 'services/Toast';
import {
  CreateWorkoutDto,
  SortDirection,
  UpdateWorkoutDto,
  Workout,
} from 'types';
import { Button } from 'UI';
import { WorkoutModal, WorkoutsGrid } from './components';
import { Container, AbsoluteButton } from './styled';
import { uploadBaseS3 } from 'utils';
import { isUrl } from 'utils/validation';
import { useSelector } from 'react-redux';
import { selectors } from 'store/ducks';
import { AxiosError } from 'axios';

function WorkoutsTab() {
  const currentUser = useSelector(selectors.auth.getUserInfo);

  const [workouts, setWorkouts] = useState<Workout[]>([]);
  const [sortField, setSortField] = useState<string>('author.firstName');
  const [sortDirection, setSortDirection] = useState<SortDirection>('ASC');
  const [image, setImage] = useState()

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [workoutToEdit, setWorkoutToEdit] = useState<Workout | null>(null);

  const [asyncFetch, isLoading] = useAsyncCallback(async () => {
    try {
      const sort = sortField ? [`${sortField},${sortDirection}`] : undefined;

      const { data } = await getWorkouts({
        join: ['workoutExercises', 'workoutExercises.exercise', 'author'],
        sort,
      });
      setWorkouts(data);
    } catch (e) {
      Toast.error();
    }
  });

  useEffect(() => {
    asyncFetch();
  }, [sortField, sortDirection]);

  const handleChangeSort = (newSort: string) => {
    if (sortField === newSort) {
      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    }

    setSortField(newSort);
  };

  const submitCreateWorkout = async (dto: CreateWorkoutDto) => {
    let image;
    try {
      const { imageUrl, ...body } = dto;
      if (imageUrl) {
        image = await uploadBaseS3(imageUrl, `${dto.name}-workout-preview`);
      }
      const { data } = await createWorkout({
        ...body,
        imageUrl: image || undefined,
      });
      Toast.success('Workout is created!', 'Workouts');
      setIsCreateModalOpen(false);
      setWorkouts([...workouts, data]);
    } catch (e) {
      Toast.error();
    }
  };

  const submitEditWorkout = async (dto: UpdateWorkoutDto) => {
    try {
      const { imageUrl, workoutExercises = [], ...body } = dto;

      const isNewImage = imageUrl && !isUrl(imageUrl);
      const image = isNewImage
        ? await uploadBaseS3(imageUrl!, `${dto.name}-workout-preview`)
        : imageUrl;

      const exercisesToDestroy =
        workoutToEdit!.workoutExercises?.filter((exercise) => {
          const isInUpdateDto = workoutExercises?.find((workoutExercise) => {
            return workoutExercise.id === exercise.id;
          });

          if (isInUpdateDto) {
            return false;
          }

          return true;
        }) || [];

      const finalExercises = [
        ...workoutExercises,
        ...exercisesToDestroy.map((exerciseToDestroy) => ({
          ...exerciseToDestroy,
          __destroy: true,
        })),
      ];

      const { data } = await updateWorkout(workoutToEdit!.id, {
        ...body,
        workoutExercises: finalExercises,
        imageUrl: image,
      });
      Toast.success('Workout is updated!', 'Workouts');
      setWorkouts(
        workouts.map((workout) =>
          workout.id === workoutToEdit?.id ? data : workout
        )
      );
      setWorkoutToEdit(null);
    } catch (e) {
      Toast.error();
    }
  };

  const submitDeleteWorkout = async (id: string) => {
    try {
      await deleteWorkout(id);
      setWorkouts(workouts.filter((workout) => workout.id !== id));
      setWorkoutToEdit(null);
      Toast.success('Workout successfully deleted!', 'Workouts');
    } catch (e) {
      Toast.error((e as AxiosError).message || 'Error on delete workout...', 'Workouts');
    }
  };

  const handleEditWorkout = (id: string) => {
    const editWorkout = workouts.find((workout) => workout.id === id)!;
    setWorkoutToEdit(editWorkout);
  };

  return (
    <Container>
      {isCreateModalOpen && (
        <WorkoutModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          onSubmit={(values) =>
            submitCreateWorkout({
              name: values.name,
              //@ts-ignore
              imageUrl: values.image,
              description: values.description,
              workoutExercises: values.workoutExercises.map((exercise) => ({
                exerciseId: exercise.exerciseId,
                order: exercise.index,
                trackingFieldsValues: exercise.trackingFieldsValues,
              })),
              exercisesNotes: values.notes,
            })
          }
        />
      )}
      {workoutToEdit && (
        <WorkoutModal
          isOpen={Boolean(workoutToEdit)}
          onClose={() => setWorkoutToEdit(null)}
          disabled={workoutToEdit.authorId !== currentUser.id}
          initialEntity={workoutToEdit}
          onSubmit={(values) =>
            submitEditWorkout({
              name: values.name,
              imageUrl: values.image,
              description: values.description,
              workoutExercises: values.workoutExercises.map((exercise) => ({
                id: exercise.id!,
                exerciseId: exercise.exerciseId,
                order: exercise.index,
                trackingFieldsValues: exercise.trackingFieldsValues,
                __destroy: false,
              })),
              exercisesNotes: values.notes,
            })
          }
          onDelete={() => submitDeleteWorkout(workoutToEdit.id)}
        />
      )}
      <WorkoutsGrid
        workouts={workouts}
        activeSort={sortField}
        activeDirection={sortDirection}
        isLoading={isLoading}
        onChangeSort={handleChangeSort}
        onEdit={handleEditWorkout}
      />
      <AbsoluteButton>
        <Button text="Add workout" onClick={() => setIsCreateModalOpen(true)} />
      </AbsoluteButton>
    </Container>
  );
}

export default WorkoutsTab;
