import React from 'react';
import { ProfileSection, ButtonContainer } from './styled';
import { DeleteAccount, ProfileInfo, Subscribtions } from './components';
import { selectors } from 'store/ducks';
import { useSelector } from 'react-redux';
import { Button } from 'UI';
import { useAuth0 } from '@auth0/auth0-react';

function Profile() {
  const user = useSelector(selectors.auth.getUserInfo);
  const { logout } = useAuth0();

  return (
    <ProfileSection>
      <ProfileInfo user={user} />
      <Subscribtions currentPlan={user.subscription} />
      <DeleteAccount userId={user.id} />
      <ButtonContainer>
        <Button
          text="Log out"
          theme="secondary"
          $margin="12px 0 0 0"
          onClick={() => logout({clientId: '', logoutParams: {returnTo: window.location.origin}})}
        />
      </ButtonContainer>
    </ProfileSection>
  );
}

export default Profile;
