import { DEFAULT_AVATAR } from '@constants'
import React, { useEffect, useRef, useState } from 'react'
import { ChatMessage, ChatMessageDto } from 'types'
import { Message, MessageForm } from './components'
import { Container, MessagesContainer } from './styled'

type Props = {
	messages: ChatMessage[]
	total: number
	selectedMessagesIds: string[]

	onSendMessage: (body: ChatMessageDto) => void
	onSelectMessage: (id: string) => void
	onLoadMessages: () => void
	onOpenVideo: (id: string) => void
}

function Messages({
	messages,
	total,
	selectedMessagesIds,
	onSendMessage,
	onSelectMessage,
	onLoadMessages,
	onOpenVideo,
}: Props) {
	const scrollMessageRef = useRef<HTMLDivElement>(null)
	const [scrollMessageId, setScrollMessageId] = useState('')

	const [isLoadingOnScroll, setIsLoadingOnScroll] = useState(false)
	const [isLoadingOnSend, setIsLoadingOnSend] = useState(false)

	console.log({ messages })

	const containerRef = useRef<HTMLDivElement>(null)

	const handleSendMessage = async (body: ChatMessageDto) => {
		await onSendMessage(body)
		setIsLoadingOnSend(true)
	}

	const handleLoadMessages = (scrollPosition: number) => {
		if (scrollPosition === 0 && messages.length < total) {
			setScrollMessageId(`${messages[0].id}`)
			setIsLoadingOnScroll(true)
			onLoadMessages()
		}
	}

	useEffect(() => {
		if (isLoadingOnSend && messages.length >= 1) {
			setScrollMessageId(`${messages[messages.length - 1].id}`)
		}
	}, [messages, isLoadingOnSend])

	useEffect(() => {
		if (isLoadingOnScroll) {
			scrollMessageRef.current?.scrollIntoView()
		}
	}, [messages, isLoadingOnScroll])

	useEffect(() => {
		if (isLoadingOnSend) {
			scrollMessageRef.current?.scrollIntoView()
		}
	}, [messages, isLoadingOnSend])

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.scrollTo(0, Number.MAX_SAFE_INTEGER)
		}
	}, [])

	return (
		<Container>
			<MessagesContainer
				ref={containerRef}
				onScroll={() =>
					handleLoadMessages(
						containerRef.current?.scrollTop ?? Number.MAX_SAFE_INTEGER
					)
				}
			>
				{messages.map(el => {
					return (
						<Message
							body={el.body}
							attachments={el.attachments.map(attach => attach.attachmentUrl)}
							avatarUrl={el.user.avatarUrl || DEFAULT_AVATAR}
							firstName={el.user.firstName || ''}
							lastName={el.user.lastName || ''}
							createdAt={el.createdAt}
							isSelected={selectedMessagesIds.includes(`${el.id}`)}
							onClick={() => onSelectMessage(`${el.id}`)}
							onOpenVideo={onOpenVideo}
							innerRef={
								`${el.id}` === scrollMessageId ? scrollMessageRef : undefined
							}
							key={el.id}
						/>
					)
				})}
			</MessagesContainer>
			<MessageForm
				onSubmit={values =>
					handleSendMessage({
						body: values.body,
						attachments:
							values.attachments?.map(el => ({ attachmentUrl: el })) ?? [],
					})
				}
			/>
		</Container>
	)
}

export default Messages
