import React from 'react';
import { FilledArrow, FootnoteTwo, SubheadOne } from 'styled';
import { Container, ErrorContainer, FieldContainer } from './styled';
import { CSSProp } from 'styled-components';
import { FieldWrapperBaseProps } from 'UI/types';

interface Props extends FieldWrapperBaseProps {
  Icon?: React.ReactNode;
  iconColor?: string;

  containerCSS?: CSSProp;
  containerRef?: React.RefObject<HTMLDivElement>;

  fieldContainerCSS?: CSSProp;

  withArrow?: boolean;
  isArrowActive?: boolean;

  children?: React.ReactNode;
  onClick?: () => void;
}

function FieldWrapper({
  label,
  Icon,
  iconColor,
  error,
  containerCSS,
  containerRef,
  fieldContainerCSS,
  withArrow,
  isArrowActive,
  children,
  disabled,
  onClick,
}: Props) {
  return (
    <Container $CSS={containerCSS}>
      {label && (
        <SubheadOne $color={disabled ? '#E1E0E5' : undefined} $marginBottom={8}>
          {label}
        </SubheadOne>
      )}
      <FieldContainer
        $CSS={fieldContainerCSS}
        $iconColor={iconColor}
        $withError={Boolean(error)}
        $disabled={disabled}
        onClick={onClick}
        ref={containerRef}
      >
        {Icon}
        {children}
        {withArrow && (
          <FilledArrow
            $color="#201E3F"
            $width={6}
            $isActive={Boolean(isArrowActive)}
          />
        )}
      </FieldContainer>
      {error && (
        <ErrorContainer>
          <FootnoteTwo $color="#FF5934">{error}</FootnoteTwo>
        </ErrorContainer>
      )}
    </Container>
  );
}

export default FieldWrapper;
