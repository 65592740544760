import styled from 'styled-components';

export const RowContainer = styled.div`
  display: flex;
  align-items: flex-start;

  & > div:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }

  & > div input {
    padding-top: 20px;
  }

  & > div:first-child {
    min-width: 411px;
  }
`;
