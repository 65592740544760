import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoadingStatus, User } from 'types';
import * as api from 'services/api';
import StorageService from 'services/Storage';
import { State } from 'store';
import { UpdateCoachDto } from 'types';
import { captureException } from '@sentry/minimal';

interface InitAuthState {
  accessToken: string | null;
  loginLoadingStatus: LoadingStatus;
  user: User;
}

const initialState: InitAuthState = {
  accessToken: null,
  loginLoadingStatus: 'idle',
  user: {
    id: '',
    email: '',
    role: 'coach',
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
  } as User,
};

const loginUser = createAsyncThunk('auth/login', async () => {
  const { data } = await api.loginUser();
  return data;
});

const updateUser = createAsyncThunk(
  'auth/userUpdate',
  async (userDto: UpdateCoachDto, thunkAPI) => {
    const store = thunkAPI.getState() as State;
    const userId = selectors.getUserId(store);
    try {
      const { data } = await api.updateUser(userDto, userId);
      return data;
    } catch (error) {
      console.log(error);
      captureException(error);
      throw error;
    }
  }
);

const slice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logout(state) {
      StorageService.removeItem('accessToken');
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loginLoadingStatus = 'pending';
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loginLoadingStatus = 'fulfilled';
      state.accessToken = StorageService.getAccessToken();
      state.user = action.payload;
    });
    builder.addCase(loginUser.rejected, (state) => {
      state.loginLoadingStatus = 'rejected';
    });
    builder.addCase(updateUser.pending, (state) => {
      state.loginLoadingStatus = 'pending';
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.loginLoadingStatus = 'fulfilled';
      state.user = action.payload;
    });
    builder.addCase(updateUser.rejected, (state) => {
      state.loginLoadingStatus = 'rejected';
    });
  },
});

export const selectors = {
  getAccessToken: (state: State) => state.auth.accessToken,
  getUserInfo: (state: State) => state.auth.user,
  getUserId: (state: State) => state.auth.user.id,
};

export const actions = {
  ...slice.actions,
  loginUser,
  updateUser,
};

export const { reducer } = slice;
