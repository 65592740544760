import { Camera, Edit, PlusSign } from 'assets/svg';
import React, { useMemo, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FootnoteTwo, SubheadOne } from 'styled';
import { ButtonContainer, Container, InputContainer } from './styled';
import { convertFileToBase } from 'utils';
import { FileRejection, useDropzone } from 'react-dropzone';
import { getLoadFileErrorMap, imageFormats } from '@constants';
import Toast from 'services/Toast';
import { CropModal } from 'components';

type Props = FieldRenderProps<string> & {
  label?: string;
  withButton?: boolean;
  cropRatio?: number;
  disabled?: boolean;
};

const MAX_FILE_SIZE = 15 * 1024 * 1024;

function ImageField({
  input,
  meta,
  label,
  withButton = true,
  disabled,
  cropRatio = 4 / 3,
}: Props) {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);

  const maxMegaBytes = Math.floor(MAX_FILE_SIZE / (1024 * 1024));

  const errorMap = useMemo(() => {
    return getLoadFileErrorMap({ maxMegaBytes });
  }, [maxMegaBytes]);

  const handleChange = async (
    fileList: File[],
    rejections: FileRejection[]
  ) => {
    if (fileList && fileList.length > 0) {
      const image = fileList[0];

      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImageSrc(reader.result as string)
      );
      reader.readAsDataURL(image);

      const base = await convertFileToBase(image!);
      input.onChange(base);
      setIsCropModalOpen(true);
    }

    if (rejections) {
      rejections.forEach((e) => {
        Toast.error(errorMap[e.errors[0].code], `File: ${e.file.name}`);
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: MAX_FILE_SIZE,
    onDrop: handleChange,
    accept: imageFormats,
    disabled,
  });

  const error = meta.touched && meta.error;

  const handleClick = (e: React.MouseEvent<any>, callback?: any) => {
    e.stopPropagation();

    if (typeof callback === 'function') {
      callback();
    }
  };

  return (
    <Container>
      {imageSrc && (
        <CropModal
          isOpen={isCropModalOpen}
          onClose={() => setIsCropModalOpen(false)}
          image={imageSrc}
          ratio={cropRatio}
          onCrop={(image) => {
            input.onChange(image);
          }}
        />
      )}
      {label && <SubheadOne $marginBottom={8}>{label}</SubheadOne>}
      <InputContainer $image={input.value} {...getRootProps()}>
        {!input.value && <Camera />}
        <label>
          {withButton ? (
            <ButtonContainer onClick={(e) => handleClick(e)}>
              <input
                type="file"
                style={{ display: 'none' }}
                {...getInputProps()}
              />
              {input.value && <Edit />}
            </ButtonContainer>
          ) : (
            <input
              type="file"
              style={{ display: 'none' }}
              {...getInputProps()}
            />
          )}
        </label>
      </InputContainer>
      {error && (
        <FootnoteTwo $color="#FF5934" $margin="4px 0px 4px 0px">
          {error}
        </FootnoteTwo>
      )}
    </Container>
  );
}

export default ImageField;
