import { FlexCenter, FlexStart, FootnoteTwo } from 'styled';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid #e1e0e5;

    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`;

export const NameCSS = css`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const OrderNumber = styled(FlexCenter)`
  width: 56px;
  height: 56px;
  border-radius: 14px;
  background: #f2f7ff;

  margin-right: 16px;
`;

export const Duration = styled(FootnoteTwo)`
  color: #d555b1;
  width: 60px;
  text-align: center;

  background: rgba(212, 85, 177, 0.08);
  border-radius: 10px;

  padding: 4px 0px;

  margin-right: 20px;
`;

export const MoreButton = styled.div`
  position: relative;

  margin-left: 20px;

  & > svg {
    cursor: pointer;
  }

  & path {
    fill: #67667b;
  }
`;

export const ButtonsContainer = styled.div`
  background: #ffffff;

  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 8px;

  padding: 18px;

  position: absolute;

  box-sizing: border-box;
  min-width: 138px;
  max-width: 138px;

  right: 10px;
  top: 0px;

  z-index: 10;
`;

export const ButtonContainer = styled(FlexStart)`
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #e1e0e5;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const ArrowCSS = css`
  cursor: pointer;
`;

export const ExercisesContainer = styled.div`
  margin-top: 16px;
  padding-left: 72px;

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const NoExercisesContainer = styled(FlexCenter)`
  height: 100px;
`;
