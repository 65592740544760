import { FlexCenter } from 'styled';
import styled from 'styled-components';

export const Radio = styled(FlexCenter)<{ $isActive: boolean }>`
  cursor: pointer;

  width: 20px;
  height: 20px;
  border-radius: 50%;

  background-color: ${({ $isActive }) => ($isActive ? '#895FC6' : '#e1e0e5')};
  outline: none;

  & > svg {
    transform: scale(0.6);
    & > path {
      stroke: white;
      stroke-width: 1;
      fill: white;
    }
  }
`;
