import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { TextInput } from 'UI';
import { TextInputBaseProps } from 'UI/types';

type Props = TextInputBaseProps & FieldRenderProps<string, HTMLInputElement>;

function TextField({ input, meta, ...rest }: Props) {
  const error = meta.touched && (meta.error || meta.submitError);
  return <TextInput {...{ ...input, ...rest }} error={error} />;
}

export default TextField;
