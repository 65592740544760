import { FlexCenter } from 'styled';
import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;

  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 14px;
  overflow: hidden;

  & > div:not(:last-child) {
    border-bottom: 1px solid #e1e0e5;
  }
`;

export const Circle = styled.div`
  width: 241px;
  height: 241px;

  border-radius: 50%;
  background: #f2f7ff;

  margin-bottom: 25px;
`;

export const NoChatsContainer = styled(FlexCenter)`
  width: 100%;
  height: calc(100% - 52px);

  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 14px;

  padding: 16px;

  display: flex;
  flex-direction: column;
`;
