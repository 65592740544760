import React, { useEffect, useRef } from 'react'
import { FieldArrayRenderProps } from 'react-final-form-arrays'
import { SubheadOne } from 'styled'
import { Exercise } from 'types'
import {
	Container,
	GroupContainer,
	GroupHeadContainer,
	ExerciseImage,
} from './styled'
import { TextButton } from 'UI'
import { Field } from 'react-final-form'
import { TextField } from 'components'
import { FormExercise } from './types'
import { ExerciseSets } from './components'

const groupExercises = (
	exercises: FormExercise[]
): Record<string, FormExercise[]> => {
	const notNull = exercises.filter(Boolean)

	const result = notNull.reduce((accumulator, currentExercise, index) => {
		const exerciseArray = accumulator[currentExercise.exerciseId]

		if (exerciseArray) {
			const newArray = [...exerciseArray, { ...currentExercise, index }]
			accumulator[currentExercise.exerciseId] = newArray
			return accumulator
		}

		accumulator[currentExercise.exerciseId] = [{ ...currentExercise, index }]
		return accumulator
	}, {})

	return result
}

type Props = FieldArrayRenderProps<FormExercise, HTMLInputElement> & {
	pickedExercises?: Exercise[]
	disabled?: boolean
}

function WorkoutRoutine({ fields, pickedExercises = [], disabled }: Props) {
	const isFirstRender = useRef<boolean>(true)

	const { push, remove, update } = fields
	const fieldsValue = fields.value

	const groupedExercises = groupExercises(fieldsValue)

	const addEmptySet = (exercise: Exercise) => {
		const { id } = exercise

		const trackingFieldsDefaultValues = exercise.trackingFields.reduce(
			(accumulator, field) => {
				accumulator[field] = null
				return accumulator
			},
			{}
		)

		const index = fieldsValue.length

		push({
			exerciseId: id,
			index,
			trackingFields: exercise.trackingFields,
			trackingFieldsValues: trackingFieldsDefaultValues,
		})
	}

	const handleSwap = (firstIndex: number, secondIndex: number) => {
		console.log('ddd')
		const first = { ...fieldsValue[firstIndex] }
		const second = { ...fieldsValue[secondIndex] }

		const tmp = first.index
		first.index = second.index
		second.index = tmp

		update(firstIndex, second)
		update(secondIndex, first)
	}

	const handleRemove = (exerciseIndexToRemove: number) => {
		const removeExerciseFieldIndex = fieldsValue.findIndex(
			val => val.index === exerciseIndexToRemove
		)
		const valueWithoutExercise = fieldsValue.filter(
			val => val.index !== exerciseIndexToRemove
		)
		const updatedIndices = valueWithoutExercise.map((val, index) => ({
			...val,
			index,
		}))

		remove(removeExerciseFieldIndex)

		updatedIndices.forEach((updated, index) => update(index, updated))
	}

	const renderGroup = (exercisesGroup: FormExercise[]) => {
		console.log({ exercisesGroup })
		if (exercisesGroup.length === 0) {
			return null
		}

		const { exerciseId } = exercisesGroup[0]
		const exercise = pickedExercises.find(
			exercise => exercise.id === exerciseId
		)!

		return (
			<GroupContainer key={exercise.id}>
				<GroupHeadContainer>
					<ExerciseImage src={exercise.images[0]} />
					<SubheadOne>{exercise.name}</SubheadOne>
				</GroupHeadContainer>
				<ExerciseSets
					exercises={exercisesGroup}
					disabled={disabled}
					onSwap={handleSwap}
					onRemove={handleRemove}
				/>
				<TextButton
					text='Add set'
					color='#D455B1'
					type='button'
					margin='0 0 5px 0'
					disabled={disabled}
					onClick={() => addEmptySet(exercise)}
				/>
				<Field
					name={`notes[${exercise.id}]`}
					label='Add note'
					parse={value => value || null}
					disabled={disabled}
					component={TextField}
				/>
			</GroupContainer>
		)
	}

	useEffect(() => {
		const exercise = pickedExercises[pickedExercises.length - 1]

		if (isFirstRender.current) {
			isFirstRender.current = false
			return
		}

		if (exercise) {
			addEmptySet(exercise)
		}
	}, [pickedExercises])

	return (
		<Container>{Object.values(groupedExercises).map(renderGroup)}</Container>
	)
}

export default WorkoutRoutine
