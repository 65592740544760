import * as React from 'react';

function SvgChatRoomsEmpty(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={300}
      height={300}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25 112.425A74.876 74.876 0 01100 37.5h100c41.412 0 75 33.688 75 74.925V262.5H100c-41.413 0-75-33.687-75-74.925v-75.15zM175 137.5v25h25v-25h-25zm-75 0v25h25v-25h-25z"
        fill="#F2F7FF"
      />
    </svg>
  );
}

export default SvgChatRoomsEmpty;
