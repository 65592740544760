import { Delete, Edit } from 'assets/svg';
import React, { MouseEvent } from 'react';
import { SubheadTwo } from 'styled';
import { ButtonContainer, ButtonsContainer } from './styled';

type Props = {
  onEdit?: () => void;
  onDelete?: () => void;
};

function ActionsPopup({ onEdit, onDelete }: Props) {
  const handleClick = (event: MouseEvent<HTMLDivElement>, callback: any) => {
    event.preventDefault();
    event.stopPropagation();
    callback();
  };

  return (
    <ButtonsContainer>
      {onEdit && (
        <ButtonContainer onClick={(e) => handleClick(e, onEdit)}>
          <Edit />
          <SubheadTwo $margin="0px 0px 0px 10px">Edit</SubheadTwo>
        </ButtonContainer>
      )}
      {onDelete && (
        <ButtonContainer onClick={(e) => handleClick(e, onDelete)}>
          <Delete />
          <SubheadTwo $margin="0px 0px 0px 10px">Delete</SubheadTwo>
        </ButtonContainer>
      )}
    </ButtonsContainer>
  );
}

export default ActionsPopup;
