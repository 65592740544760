import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;

  padding: 40px 40px 80px;
`;

export const GroupContainer = styled.div`
  width: 100%;

  border: 1px solid #e1e0e5;
  border-radius: 8px;

  padding: 16px;

  margin-bottom: 8px;
`;

export const GroupHeadContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 12px;
`;

export const ExerciseImage = styled.img`
  width: 105px;
  height: 63px;

  object-fit: cover;
  border-radius: 8px;

  margin-right: 16px;
`;
