import styled from 'styled-components';

export const Control = styled.div<{ $isMulti: boolean }>`
  background-color: transparent;

  cursor: pointer;

  display: flex;
  justify-content: space-between;

  padding: ${({ $isMulti }) => ($isMulti ? '7px' : '12px')} 8px;
  padding-right: 20px;
`;
