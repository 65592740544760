import { useState } from 'react';

type MaybePromise<R> = R | Promise<R>;

type AsyncFunction<Args extends unknown[], R> = (
  ...args: Args
) => MaybePromise<R>;

export function useAsyncCallback<Result, Args extends unknown[]>(
  callback?: AsyncFunction<Args, Result>
): [asyncSubmit: AsyncFunction<Args, Result>, submitState: boolean] {
  const [isLoading, setIsLoading] = useState(false);

  const asyncWrapper = async (...args: Args) => {
    try {
      setIsLoading(true);
      if (callback) {
        const result = await callback(...args);
        return result;
      }
      return null as any;
    } finally {
      setIsLoading(false);
    }
  };
  return [asyncWrapper, isLoading];
}
