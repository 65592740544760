import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: #3cd278;
  border-radius: 18px;

  width: 56px;
  height: 32px;
  box-sizing: border-box;

  padding: 4px;

  cursor: pointer;

  position: relative;
`;

const getAbsoluteStyles = (isActive: boolean) => {
  if (isActive) {
    return css`
      left: auto;
      right: 4px;
    `;
  }

  return css`
    right: auto;
    left: 4px;
  `;
};

export const Circle = styled.div<{ $isActive: boolean }>`
  position: absolute;

  width: 24px;
  height: 24px;

  border-radius: 50%;
  background-color: white;

  left: ${({ $isActive }) => ($isActive ? 'calc(100% - 28px)' : '4px')};
  /* right: ${({ $isActive }) => (!$isActive ? '4px' : 'auto')}; */

  transition: all 0.1s linear;
`;
