import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const WeekContainer = styled.div`
  display: flex;

  margin-bottom: 20px;

  & > div:not(:last-child) {
    margin-right: 20px;
  }
`;

export const DayContainer = styled.div`
  width: 100%;
  min-height: 280px;
  max-height: 280px;

  box-sizing: border-box;

  padding: 16px 11px;

  background: #f2f7ff;
  border-radius: 8px;

  position: relative;
  overflow: scroll;
`;

export const DayWorkoutsContainer = styled.div`
  display: flex;
  flex-direction: column;

  /* overflow-y: scroll; */
`;

export const getButtonCSS = ({ $empty }: { $empty: boolean }) => {
  if ($empty) {
    return css`
      position: absolute;
      transform: translateX(-50%) translateY(-50%);
      left: 50%;
      top: 50%;
    `;
  }

  return css`
    display: flex;
    justify-content: center;
  `;
};

export const AddWorkoutButtonContainer = styled.div<{ $empty: boolean }>`
  ${getButtonCSS}
`;

export const WorkoutCard = styled.div`
  min-height: 100px;

  cursor: pointer;

  background: #ffffff;

  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 16px;

  padding: 10px;

  margin-bottom: 10px;
`;
