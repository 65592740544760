import styled from 'styled-components';

export const MultiValueContainer = styled.div`
  border: 1px solid rgba(25, 7, 66, 0.64);
  border-radius: 8px;
  padding: 10px 16px;

  margin-bottom: 4px;

  display: flex;

  & > div > svg path {
    fill: #201e3f;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }
`;
