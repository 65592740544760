import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  height: 100%;
`;

export const Body = styled.div`
  width: 100%;
`;
