import React from 'react';
import { OptionProps } from 'react-select';
import { JustifyBetween, SubheadTwo } from 'styled';
import { RadioButton } from 'UI';
import { OptionType } from 'UI/types';
import { IconContainer, OptionContainer } from './styled';

function Option({
  children,
  data,
  selectOption,
  options,
  selectProps,
  isSelected,
  innerProps,
  ...rest
}: OptionProps<OptionType, true>) {
  const { withLeftCheckbox, withRightCheckbox } = selectProps;
  const { value, label, Icon } = data;

  return (
    <OptionContainer {...innerProps}>
      <JustifyBetween>
        {withLeftCheckbox && (
          <IconContainer>
            <RadioButton isActive={isSelected} />
          </IconContainer>
        )}
        {Icon && <IconContainer>{Icon}</IconContainer>}
        <SubheadTwo>{label}</SubheadTwo>
      </JustifyBetween>
      {withRightCheckbox && isSelected && <RadioButton isActive />}
    </OptionContainer>
  );
}

export default Option;
