import React from 'react';
import { CheckboxSubscriptions } from 'assets/svg';
import {
  FlexCenter,
  FlexEnd,
  FlexStart,
  Headline,
  JustifyBetween,
  SubheadTwo,
  TitleThree,
} from 'styled';
import { Button } from 'UI';
import {
  Container,
  PopularTextContainer,
  PriceContainer,
  YearOverlineText,
} from './styled';
import { useAsyncCallback } from 'hooks';

type Props = {
  name: string;
  description: string;
  monthPrice: number;
  yearPrice: number;
  currency: string;
  meta: string[];

  isMonthPrice: boolean;
  isSelected: boolean;
  isPopular: boolean;

  onSelect: () => void;
};

const formatPrice = (price: number, currency: string) => {
  const dollars = price / 100;
  return dollars.toLocaleString('en-US', {
    style: 'currency',
    currency,
  });
};

function Plan({
  name,
  description,
  monthPrice,
  yearPrice,
  currency,
  meta,
  isMonthPrice,
  isSelected,
  isPopular,
  onSelect,
}: Props) {
  const [asyncOnSelect, isLoading] = useAsyncCallback(onSelect);

  const renderPrice = () => {
    if (isMonthPrice) {
      return (
        <React.Fragment>
          <TitleThree>{formatPrice(monthPrice, currency)}</TitleThree>
          <SubheadTwo $color="#777777">Billed Monthly</SubheadTwo>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <FlexStart>
          <TitleThree $margin="0 8px 0 0">
            {formatPrice(yearPrice, currency)}
          </TitleThree>
          <YearOverlineText>
            {formatPrice(monthPrice * 12, currency)}
          </YearOverlineText>
        </FlexStart>
        <SubheadTwo $color="#777777">Billed Yearly</SubheadTwo>
      </React.Fragment>
    );
  };

  const renderMeta = () => {
    return meta.map((element) => {
      return (
        <FlexStart $marginBottom={14} key={element}>
          <CheckboxSubscriptions />
          <SubheadTwo $margin="0 0 0 14px" $color="#2A2A2A">
            {element}
          </SubheadTwo>
        </FlexStart>
      );
    });
  };

  return (
    <Container>
      <div>
        {isPopular && <PopularTextContainer>Popular</PopularTextContainer>}
        <Headline>{name}</Headline>
        <SubheadTwo $color="#777777" $marginBottom={28}>
          {description}
        </SubheadTwo>
        <PriceContainer>{renderPrice()}</PriceContainer>
        {renderMeta()}
      </div>
      <FlexCenter>
        <Button
          width="100%"
          text={`Buy ${name}`}
          disabled={isSelected}
          isLoading={isLoading}
          onClick={asyncOnSelect}
        />
      </FlexCenter>
    </Container>
  );
}

export default Plan;
