import { PusherEvents } from '@constants';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PusherService } from 'services/Pusher';
import { actions } from 'store/ducks';

export const useChatPresence = () => {
  const dispatch = useDispatch();
  const pusher = PusherService.pusherInstance!;

  useEffect(() => {
    const presenceChannel = pusher.subscribe('presence-channel');
    presenceChannel.bind(PusherEvents.SUBSCRIPTION_SUCCESS, (data) => {
      const members = data.members;
      const membersIds = Object.keys(members);
      dispatch(actions.onlineUsers.setAll(membersIds));
    });

    presenceChannel.bind(PusherEvents.NEW_USER_ONLINE, (data) => {
      dispatch(actions.onlineUsers.addOne(data.id));
    });

    presenceChannel.bind(PusherEvents.NEW_USER_OFFLINE, (data) => {
      dispatch(actions.onlineUsers.removeOne(data.id));
    });

    const unsubscribe = () => {
      presenceChannel.unbind();
      presenceChannel.unsubscribe();
      pusher.unsubscribe('presence-channel');
    };
    window.addEventListener('beforeunload', unsubscribe);
  }, []);
};
