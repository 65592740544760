import styled, { CSSProp } from 'styled-components';

export default styled.i<{
  $rotate?: number;
  $color?: string;
  $hoverColor?: string;
  $width?: number;
  $size?: number;
  $CSS?: CSSProp;
}>`
  border: solid ${({ $color }) => $color || '#895FC6'};
  border-width: 0 ${({ $width }) => $width || 5}px
    ${({ $width }) => $width || 5}px 0;
  display: inline-block;
  padding: 10px;
  transform: rotate(${({ $rotate }) => `${$rotate || -45}deg`})
    scale(${({ $size }) => $size || 0.4});

  transition: all 0.4s;

  &:hover {
    border-color: ${({ $hoverColor }) => $hoverColor};
  }

  ${({ $CSS }) => $CSS}
`;
