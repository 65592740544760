import { CloseEx } from 'assets/svg';
import { useOnClickOutside } from 'hooks';
import { ModalBaseProps } from 'interfaces/common';
import React, { useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import { Headline } from 'styled';
import { CircleButton } from 'UI';
import { Overlay, Modal, Header, Close, ChildrenContainer } from './styled';

export interface Props extends ModalBaseProps {
  title?: string;
  width: number;
  isBigModal?: boolean;
  withCloseOnClickOutside?: boolean;

  children?: React.ReactNode;
}

const handleClose = () => {
  document.body.style.overflow = 'scroll';
};

const handleOpen = () => {
  document.body.style.overflow = 'hidden';
};

function ModalWrapper({
  title,
  width,
  isBigModal = false,
  withCloseOnClickOutside = true,
  isOpen,
  onClose,
  children,
}: Props) {
  const modalRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(modalRef, (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (withCloseOnClickOutside) {
      onClose();
    }
  });

  return (
    <ReactModal
      overlayElement={(_, content) => <Overlay>{content}</Overlay>}
      contentElement={(_, children) => (
        <Modal $width={width} $isBig={isBigModal} ref={modalRef}>
          {children}
        </Modal>
      )}
      isOpen={isOpen}
      ariaHideApp={false}
      onAfterOpen={() => handleOpen()}
      onAfterClose={() => handleClose()}
    >
      {title && (
        <Header>
          <Headline $size={20}>{title}</Headline>
        </Header>
      )}
      <Close>
        <CircleButton color="#D555B1" size={36} onClick={onClose}>
          <CloseEx />
        </CircleButton>
      </Close>
      <ChildrenContainer $isBig={isBigModal}>
        {isOpen && children}
      </ChildrenContainer>
    </ReactModal>
  );
}

export default ModalWrapper;
