import * as React from 'react';

function SvgPrograms(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 5.833a6.667 6.667 0 110 13.333 6.667 6.667 0 010-13.333zm0 2.917l-1.103 2.233-2.464.359 1.783 1.737-.42 2.455L10 14.375l2.204 1.158-.421-2.454 1.783-1.738-2.464-.358L10 8.75zm.833-7.084H15v2.5l-1.136.949a8.279 8.279 0 00-3.031-.908V1.666zm-1.667 0v2.542a8.279 8.279 0 00-3.03.906L5 4.167v-2.5l4.166-.001z"
        fill="#67667B"
      />
    </svg>
  );
}

export default SvgPrograms;
