import styled, { css, CSSProp } from 'styled-components';

export const Container = styled.div<{ $CSS?: CSSProp }>`
  width: 100%;

  position: relative;
  padding-bottom: 20px;

  ${({ $CSS }) => $CSS}
`;

const getErrorStyles = (withError?: boolean) => {
  if (withError) {
    return css`
      background-color: #fff8f7;
      border-color: #ff5934;
    `;
  }
};

export const getDisabledStyles = (disabled?: boolean) => {
  if (disabled) {
    return css`
      opacity: 0.6;
    `;
  }
};
export const FieldContainer = styled.div<{
  $iconColor?: string;
  $withError?: boolean;
  $disabled?: boolean;
  $CSS?: CSSProp;
}>`
  background: #f2f7ff;
  border-radius: 8px;

  display: flex;
  align-items: center;
  padding: 20px;

  & > svg > path {
    fill: ${(props) => props.$iconColor};
  }

  & > svg {
    margin-right: 14px;
  }

  border: 1px solid transparent;

  ${({ $withError }) => getErrorStyles($withError)}
  ${({ $disabled }) => getDisabledStyles($disabled)}

  ${({ $CSS }) => $CSS}
`;

export const ErrorContainer = styled.div`
  position: absolute;
  bottom: 0;
`;
