import React, { useCallback, useEffect, useState } from 'react';
import {
  ProgramModal,
  ConfirmModal,
  ProgramCard,
  SpinnerContainer,
} from 'components';
import { CreateProgramBody, Program, UpdateProgramBody } from 'types';
import { Button } from 'UI';
import {
  AbsoluteButton,
  Container,
  ProgramCSS,
  ProgramsList,
  EmptyProgramsContainer,
} from './styled';
import {
  createProgram,
  deleteProgram,
  getPrograms,
  updateProgram,
} from 'services/api';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATH } from '@constants';
import Toast from 'services/Toast';
import { convertBaseToFile, uniqueArray, uploadFileS3 } from 'utils';
import { BodyThree, FlexCenter } from 'styled';
import { captureException } from '@sentry/minimal';
import { ProgramsEmpty } from 'assets/svg';
import { ProgramInfo } from './components';
import { useQuery } from 'hooks';

const PROGRAMS_LIMIT = 9;

function ProgramsTab() {
  const history = useHistory();
  const query = useQuery();

  const selectedProgramId = query.get('id');

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [programToEdit, setProgramToEdit] = useState<Program | null>(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [programToDelete, setProgramToDelete] = useState<Program | null>(null);

  const [programs, setPrograms] = useState<Program[] | null>(null);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  const fetchPrograms = useCallback(async () => {
    setIsLoading(true);

    const { data } = await getPrograms({ page, limit: PROGRAMS_LIMIT });

    setTotal(data.total);
    setPrograms((prev) => {
      if (!prev) {
        return data.data;
      }
      const programs = [...prev, ...data.data];
      const unique = uniqueArray(programs, (a, b) => a.id === b.id);
      return unique;
    });

    setIsLoading(false);
  }, [page]);

  useEffect(() => {
    fetchPrograms();
  }, [fetchPrograms]);

  if (!programs) {
    return <SpinnerContainer />;
  }

  const submitCreateProgram = async (values: CreateProgramBody) => {
    try {
      const imageUrl = await uploadFileS3(
        await convertBaseToFile(
          values.imageUrl,
          `${values.name}-program-preview`
        )
      );
      const { data } = await createProgram({ ...values, imageUrl });
      setPrograms([...programs, data]);
      setIsCreateModalOpen(false);
      Toast.success('Program created!');
    } catch (err) {
      Toast.error();
      captureException(err);
    }
  };

  const handleChangeWorkoutsCount = (programId: string, newCount: number) => {
    setPrograms(
      programs.map((program) =>
        program.id === programId
          ? { ...program, workoutsCount: newCount }
          : program
      )
    );
  };

  const handleEdit = (id: string) => {
    setProgramToEdit(programs.find((program) => program.id === id)!);
    setIsEditModalOpen(true);
  };

  const submitEdit = async (id: string, values: UpdateProgramBody) => {
    try {
      const isImageUpdated = values.imageUrl !== programToEdit?.imageUrl;
      let imageUrl = programToEdit?.imageUrl as string;
      if (isImageUpdated) {
        imageUrl = await uploadFileS3(
          await convertBaseToFile(
            values.imageUrl!,
            `${values.name}-program-preview`
          )
        );
      }
      const { data } = await updateProgram(id, { ...values, imageUrl });

      setPrograms(
        programs.map((pr) => (pr.id === id ? (data as Program) : pr))
      );
      setProgramToEdit(null);
      setIsEditModalOpen(false);
      Toast.success('Program edited!');
    } catch (err) {
      Toast.error();
      captureException(err);
    }
  };

  const handleDelete = (id: string) => {
    setProgramToDelete(programs.find((program) => program.id === id)!);
    setIsDeleteModalOpen(true);
  };

  const submitDelete = async (id: string) => {
    try {
      await deleteProgram(id);
      setPrograms(programs.filter((pr) => pr.id !== id));
      await fetchPrograms();
      setIsDeleteModalOpen(false);
      Toast.success('Program deleted!');
    } catch (err) {
      Toast.error();
      captureException(err);
    }
  };

  const renderContent = () => {
    if (programs.length === 0) {
      return (
        <EmptyProgramsContainer>
          <ProgramsEmpty />
          <BodyThree $marginBottom={80}>
            No programs yet. Let’s create one to start!
          </BodyThree>
          <Button
            text="Create a program"
            width="435px"
            onClick={() => setIsCreateModalOpen(true)}
          />
        </EmptyProgramsContainer>
      );
    }

    const handleShowSpecificProgram = (id: string) => {
      history.push(ROUTE_PATH['library.programs.id'](id));
    };

    if (selectedProgramId) {
      return (
        <ProgramInfo
          programId={selectedProgramId}
          onChangeWorkoutsCount={handleChangeWorkoutsCount}
        />
      );
    }

    return (
      <ProgramsList>
        {programs.map((program) => (
          <ProgramCard
            name={program.name}
            workoutsCount={program.workoutsCount}
            weeksCount={program.weekCount}
            image={program.imageUrl || 'https://picsum.photos/400/400'}
            onClick={() => handleShowSpecificProgram(program.id)}
            onEdit={() => handleEdit(program.id)}
            onDelete={() => handleDelete(program.id)}
            containerCSS={ProgramCSS}
            key={program.id}
          />
        ))}
      </ProgramsList>
    );
  };

  return (
    <Container>
      {programToDelete && (
        <ConfirmModal
          isOpen={isDeleteModalOpen}
          title="Delete program"
          description="Are you sure you want to delete this program?"
          agreeText="Delete"
          disagreeText="Cancel"
          onClose={() => setIsDeleteModalOpen(false)}
          onAgree={() => submitDelete(programToDelete.id)}
          onDisagree={() => setIsDeleteModalOpen(false)}
        />
      )}
      {isCreateModalOpen && (
        <ProgramModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          onSubmit={(values) =>
            submitCreateProgram({
              imageUrl: values.image,
              name: values.name,
              description: values.description,
              weekCount: values.weekCount.value,
            })
          }
        />
      )}
      {programToEdit && (
        <ProgramModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={(values) =>
            submitEdit(programToEdit.id, {
              imageUrl: values.image,
              name: values.name,
              description: values.description,
              weekCount: values.weekCount.value,
            })
          }
          initialValues={{
            name: programToEdit.name,
            image: programToEdit.imageUrl!,
            weekCount: {
              value: programToEdit.weekCount!,
              label: `${programToEdit.weekCount!}`,
            },
            description: programToEdit.description,
          }}
        />
      )}
      <AbsoluteButton>
        <Button text="Add program" onClick={() => setIsCreateModalOpen(true)} />
      </AbsoluteButton>
      {renderContent()}
      {total > programs.length && (
        <FlexCenter>
          <Button
            text="Show more"
            onClick={() => setPage((prev) => prev + 1)}
            isLoading={isLoading}
          />
        </FlexCenter>
      )}
    </Container>
  );
}

export default ProgramsTab;
