import React from 'react';
import { StyledTextButton } from './styled';
import { ButtonBaseProps } from 'UI/types';

type Props = ButtonBaseProps & {
  text: string;
  color?: string;
  margin?: string;
};

function TextButton({ text, color = '#201E3F', margin, ...rest }: Props) {
  return (
    <StyledTextButton $color={color} $margin={margin} as="button" {...rest}>
      {text}
    </StyledTextButton>
  );
}

export default TextButton;
