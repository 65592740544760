import { SelectField } from 'components';
import React, { useCallback } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { RowContainer, ButtonsContainer, Container } from './styled';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { OptionsType } from 'react-select';
import { OptionType } from 'UI/types';
import { Field } from 'react-final-form';
import { CloseEx, Drag } from 'assets/svg';
import { repetitionsCountOptions, secondsDurationOptions } from '@constants';
import { isRequired } from 'utils/validation';

type ExerciseFieldValue = {
  id: string;
  exerciseId: OptionsType<OptionType>;
  repetitionCount: OptionsType<OptionType>;
  durationSeconds: OptionsType<OptionType>;
  __destroy: boolean;
};

type Props = FieldArrayRenderProps<ExerciseFieldValue, HTMLInputElement> & {
  exercisesOptions: ExerciseFieldValue['exerciseId'];
};

function ExercisesList({ fields, exercisesOptions }: Props) {
  const handleDropEnd: OnDragEndResponder = useCallback((result, _) => {
    const sourceIndex = result.source.index;
    const destinationIndex =
      result.destination?.index === undefined
        ? sourceIndex
        : result.destination?.index;

    fields.swap(sourceIndex, destinationIndex);
  }, []);

  const droppableId = 'exercise-plan-modal';

  if (fields.length === 0) {
    return null;
  }

  return (
    <DragDropContext onDragEnd={handleDropEnd}>
      <Droppable droppableId={droppableId}>
        {(provided, _) => (
          <Container ref={provided.innerRef} {...provided.droppableProps}>
            {fields.map((name, index) =>
              fields.value[index].__destroy ? null : (
                <Draggable draggableId={`${index}`} index={index} key={name}>
                  {(provided, __) => (
                    <RowContainer
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <Field name={`${name}.id`} component={() => null} />
                      <Field
                        name={`${name}.exerciseId`}
                        label="Exercises"
                        subscription={{
                          value: true,
                          error: true,
                          touched: true,
                        }}
                        validate={isRequired()}
                        options={exercisesOptions}
                        component={SelectField}
                      />
                      <Field
                        name={`${name}.repetitionCount`}
                        label="Repetitions"
                        options={repetitionsCountOptions}
                        validate={isRequired()}
                        component={SelectField}
                      />
                      <Field
                        name={`${name}.durationSeconds`}
                        label="Time"
                        options={secondsDurationOptions}
                        validate={isRequired()}
                        component={SelectField}
                      />
                      <Field
                        name={`${name}.__destroy`}
                        render={({ input }) => (
                          <ButtonsContainer>
                            <i {...provided.dragHandleProps}>
                              <Drag />
                            </i>
                            <CloseEx
                              onClick={() => {
                                input.onChange(true);
                              }}
                            />
                          </ButtonsContainer>
                        )}
                      />
                    </RowContainer>
                  )}
                </Draggable>
              )
            )}
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default ExercisesList;
