import { ROUTE_PATH } from '@constants';
import { useQuery } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { BlockTabs } from 'UI';
import { Tab } from 'UI/types';
import { buildQuery } from 'utils';
import { ExercisesTab, ProgramsTab, WorkoutsTab } from './components';
import { Container, TabContainer } from './styled';

enum PageTabs {
  Programs = 'programs',
  Workouts = 'workouts',
  Exercises = 'exercises',
}

const tabs: Tab<PageTabs>[] = [
  { label: 'Programs', value: PageTabs.Programs },
  { label: 'Workouts', value: PageTabs.Workouts },
  { label: 'Exercises', value: PageTabs.Exercises },
];

function Programs() {
  const history = useHistory();
  const query = useQuery();

  const activeTab: PageTabs =
    (query.get('activeTab') as PageTabs) ?? PageTabs.Programs;

  useEffect(() => {
    const query = buildQuery({ activeTab });
    history.push(`${ROUTE_PATH.library}${query}`);
  }, [activeTab]);

  const setActiveTab = (newTab: PageTabs) => {
    const query = buildQuery({ activeTab: newTab });
    history.push(`${ROUTE_PATH.library}${query}`);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case PageTabs.Programs:
        return <ProgramsTab />;
      case PageTabs.Workouts:
        return <WorkoutsTab />;
      case PageTabs.Exercises:
        return <ExercisesTab />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <TabContainer>
        <BlockTabs
          containerWidth={585}
          tabs={tabs}
          activeTabValue={activeTab}
          onChange={setActiveTab}
        />
      </TabContainer>
      {renderTabContent()}
    </Container>
  );
}

export default Programs;
