import React, { useRef, useState } from 'react';
import { CalendarEventModal, Datepicker } from 'components';
import {
  Wrapper,
  DateFieldWrapper,
  DatepickerInput,
  ArrowContainer,
  DatepickerWrapper,
} from './styled';
import dayjs from 'dayjs';
import { Arrow, Button, CircleButton } from 'UI';
import { capitalizeFirstLetter } from 'utils';
import { useOnClickOutside } from 'hooks';
import { Headline } from 'styled';
import { CreateCalendarEventBody } from 'types';

interface DateFieldProps {
  date: string;
  onChange: (newDate: string) => void;
}

function DateField({ date, onChange }: DateFieldProps) {
  const [isOpen, setIsOpen] = useState(false);

  const getLabel = () => {
    const selectedDate = dayjs(date);

    const weekLastDay = selectedDate.weekday(6).format('D MMMM');
    const [lastDayNumber, lastDayMonthName] = weekLastDay.split(' ');
    const lastDayMonthNameCapitalize = capitalizeFirstLetter(lastDayMonthName);

    const weekFirstDay = selectedDate.weekday(0).format('D MMMM');
    const [firstDayNumber, firstDayMonthName] = weekFirstDay.split(' ');
    const firstDayMonthNameCapitalize = capitalizeFirstLetter(
      firstDayMonthName
    );

    const firstPart = `${firstDayNumber}${
      firstDayMonthNameCapitalize === lastDayMonthNameCapitalize
        ? ''
        : `\u00A0${firstDayMonthNameCapitalize}`
    }`;

    const secondPart = `${lastDayNumber}\u00A0${lastDayMonthNameCapitalize}`;

    return `${firstPart} - ${secondPart}`;
  };

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => setIsOpen(false));

  const handleNextWeek = () => {
    const nextWeek = dayjs(date).add(1, 'week');
    onChange(nextWeek.toISOString());
  };

  const handlePrevWeek = () => {
    const nextWeek = dayjs(date).subtract(1, 'week');
    onChange(nextWeek.toISOString());
  };

  return (
    <div ref={ref}>
      <DateFieldWrapper>
        <CircleButton
          color="#F3EFF9"
          hoverColor="#F5F2FA"
          size={28}
          onClick={handlePrevWeek}
        >
          <Arrow $rotate={135} $color="#895FC6" $width={2} $size={0.3} />
        </CircleButton>
        <DatepickerInput onClick={() => setIsOpen(!isOpen)}>
          <Headline $margin="0px 18px 0px 0px" $weight="bold" $size={20}>
            {getLabel()}
          </Headline>
          <ArrowContainer>
            <Arrow
              $rotate={isOpen ? -135 : 45}
              $color="#201E3F"
              $width={6}
              $size={0.3}
            />
          </ArrowContainer>
        </DatepickerInput>
        <CircleButton
          color="#F3EFF9"
          hoverColor="#F5F2FA"
          size={28}
          onClick={handleNextWeek}
        >
          <Arrow $rotate={-45} $color="#895FC6" $width={2} $size={0.3} />
        </CircleButton>
      </DateFieldWrapper>
      <DatepickerWrapper>
        <Datepicker
          isOpen={isOpen}
          date={date}
          onChange={(newDate) => {
            onChange(newDate);
            setIsOpen(false);
          }}
        />
      </DatepickerWrapper>
    </div>
  );
}

interface Props {
  date: string;

  onChangeDate: (newDate: string) => void;
  onAddWorkout: (body: CreateCalendarEventBody) => Promise<void>;
}

function TableHead({ date, onChangeDate, onAddWorkout }: Props) {
  const [isAddWorkoutOpen, setIsAddWorkoutOpen] = useState(false);

  const handleSubmitAddWorkout = async (
    formValues: CreateCalendarEventBody
  ) => {
    await onAddWorkout(formValues);
    setIsAddWorkoutOpen(false);
  };

  return (
    <Wrapper>
      <CalendarEventModal
        isOpen={isAddWorkoutOpen}
        onClose={() => setIsAddWorkoutOpen(false)}
        onSubmit={(values) =>
          handleSubmitAddWorkout({
            clientId: values.clientId.value,
            workoutId: values.workoutId.value,
            startDateTime: values.startDate,
            endDateTime: values.endDate,
            workoutMetaDto: values.workoutMeta,
          })
        }
      />
      <DateField date={date} onChange={onChangeDate} />
      <Button
        text="Add a workout"
        theme="primary"
        onClick={() => setIsAddWorkoutOpen(true)}
      />
    </Wrapper>
  );
}

export default TableHead;
