import React, { useCallback } from 'react';
import {
  Container,
  GridTopRow,
  GridDataRow,
  Image,
  AuthorContainer,
  CoachesLogoContainer,
} from './styled';
import { Exercise, SortDirection } from 'types';
import { BodyOne, FlexCenter, FlexStart, SubheadTwo } from 'styled';
import { Logo } from 'assets/svg';
import { SpinnerContainer, SortingField } from 'components';
import { capitalizeWord } from 'utils';
import { useSelector } from 'react-redux';
import { selectors } from 'store/ducks';

type Props = {
  exercises: Exercise[];
  total: number;
  activeSort: string | null;
  activeDirection: SortDirection;

  isLoading: boolean;

  onChangeSort: (sortingField: string) => void;
  onEdit: (id: string) => void;
};

function ExercisesGrid({
  exercises,
  total,
  activeSort,
  activeDirection,
  isLoading,
  onChangeSort,
  onEdit,
}: Props) {
  const currentUser = useSelector(selectors.auth.getUserInfo);

  const renderItem = useCallback(
    (exercise: Exercise) => {
      const authorJsx =
        exercise.authorId === currentUser.id ? (
          <SubheadTwo>{`${currentUser.firstName} ${currentUser.lastName}`}</SubheadTwo>
        ) : (
          <CoachesLogoContainer>
            <Logo />
          </CoachesLogoContainer>
        );

      const imageUrl = exercise.images ? exercise.images[0] : '';

      return (
        <GridDataRow onClick={() => onEdit(exercise.id)} key={exercise.id}>
          <FlexStart>
            <Image src={imageUrl} />
            <BodyOne>{exercise.name}</BodyOne>
          </FlexStart>
          <FlexCenter>
            <SubheadTwo>{capitalizeWord(exercise.bodyPart)}</SubheadTwo>
          </FlexCenter>
          <FlexCenter>
            <SubheadTwo>{capitalizeWord(exercise.difficulty)}</SubheadTwo>
          </FlexCenter>
          <AuthorContainer>{authorJsx}</AuthorContainer>
        </GridDataRow>
      );
    },
    [currentUser, onEdit]
  );

  return (
    <Container>
      <GridTopRow>
        <SortingField
          text={`Exercise (${total})`}
          isActive={activeSort === 'name'}
          direction={activeDirection}
          onClick={() => onChangeSort('name')}
        />
        <SortingField
          text="Primary focus"
          isActive={activeSort === 'bodyPart'}
          direction={activeDirection}
          onClick={() => onChangeSort('bodyPart')}
        />
        <SortingField
          text="Difficulty"
          isActive={activeSort === 'difficulty'}
          direction={activeDirection}
          onClick={() => onChangeSort('difficulty')}
        />
        <SortingField
          text="Made by"
          isActive={activeSort === 'author.firstName'}
          direction={activeDirection}
          onClick={() => onChangeSort('author.firstName')}
        />
      </GridTopRow>
      {isLoading ? <SpinnerContainer /> : exercises.map(renderItem)}
    </Container>
  );
}

export default ExercisesGrid;
