import { TextAreaField, TextField } from 'components';
import ModalWrapper from 'components/ModalWrapper';
import { useAsyncCallback } from 'hooks';
import { ModalWithForm } from 'interfaces/common';
import React, { useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { FlexStart } from 'styled';
import { Video } from 'types';
import { Button } from 'UI';
import { isRequired } from 'utils/validation';

type FormValues = {
  name: string;
  description: string;
};

type Props = ModalWithForm<FormValues> & {
  initialEntity: Video;
};

function EditVideoModal({ onSubmit, initialEntity, ...rest }: Props) {
  const [asyncSubmit, isLoading] = useAsyncCallback(onSubmit);

  const initialValues = useMemo<FormValues>(() => {
    return { name: initialEntity.name, description: initialEntity.description };
  }, [initialEntity]);

  if (!onSubmit) {
    return null;
  }

  return (
    <ModalWrapper title="Edit video" width={628} {...rest}>
      <Form<FormValues>
        onSubmit={asyncSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, hasValidationErrors, dirty }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="name"
              label="Name"
              validate={isRequired()}
              component={TextField}
            />
            <Field
              name="description"
              label="Description"
              validate={isRequired()}
              component={TextAreaField}
            />
            <FlexStart>
              <Button
                text="Save"
                type="submit"
                isLoading={isLoading}
                disabled={hasValidationErrors || !dirty}
                $margin="0 12px 0 0"
              />
              <Button
                text="Cancel"
                theme="secondary"
                type="button"
                onClick={rest.onClose}
                disabled={isLoading}
              />
            </FlexStart>
          </form>
        )}
      />
    </ModalWrapper>
  );
}

export default EditVideoModal;
