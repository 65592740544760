import styled, { css } from 'styled-components';

export const ContainerCSS = css``;

export const FieldContainerCSS = css`
  cursor: pointer;
  position: relative;
`;

export const Container = styled.div`
  width: 100%;

  display: flex;
`;
