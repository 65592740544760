import React from 'react';
import { FlexCenter, Headline } from 'styled';
import { Spinner } from 'UI';
import { StyledClientList } from './styled';

interface ClientListProps {
  title?: string;
  renderItem: () => React.ReactNode;
  isLoading?: boolean;
}
function ClientList({ title, renderItem, isLoading }: ClientListProps) {
  return (
    <StyledClientList>
      {title && <Headline>{title}</Headline>}

      {isLoading && (
        <FlexCenter>
          <Spinner $size={30} />
        </FlexCenter>
      )}
      {!isLoading && renderItem()}
    </StyledClientList>
  );
}

export default ClientList;
