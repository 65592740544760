import { PusherEvents } from '@constants';
import { useEffect } from 'react';
import { PusherService } from 'services/Pusher';
import { ChatMessage } from 'types';

type Params = {
  channelId?: string;
  onReceiveMessage: (newMessage: ChatMessage) => void;
};

export const useChatSubscription = ({
  channelId,
  onReceiveMessage,
}: Params) => {
  useEffect(() => {
    if (channelId) {
      const pusher = PusherService.pusherInstance!;

      const channel = pusher.subscribe(channelId);

      channel.bind(PusherEvents.MESSAGE_CHATROOM, (data) => {
        onReceiveMessage(data);
      });

      return () => {
        channel.unbind_all();
        channel.unsubscribe();
      };
    }
  }, [channelId]);
};
