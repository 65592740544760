import styled from 'styled-components';

export const Container = styled.section`
  padding: 24px;
  background: #ffffff;

  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 16px;
`;
