import { Logo } from 'assets/svg';
import { ModalWrapper, SortingField, SpinnerContainer } from 'components';
import { useAsyncCallback } from 'hooks';
import { ModalBaseProps } from 'interfaces/common';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getWorkouts, NestCrudParams } from 'services/api';
import Toast from 'services/Toast';
import { selectors } from 'store/ducks';
import { BodyOne, FlexStart, SubheadTwo } from 'styled';
import { GridDataRow, GridTopRow, WorkoutRowsContainer } from './styled';
import { SortDirection, Workout } from 'types';
import { Button } from 'UI';

type Props = ModalBaseProps & {
  onPick: (workoutId: string) => void;
};

function PickWorkoutModal({ onPick, ...rest }: Props) {
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState<SortDirection>('DESC');

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const [workouts, setWorkouts] = useState<Workout[]>([]);

  const [asyncPick, isPickLoading] = useAsyncCallback(onPick);

  const [fetchWorkouts, isLoading] = useAsyncCallback(
    async (params: NestCrudParams) => {
      try {
        const { data } = await getWorkouts(params);
        setWorkouts(data);
      } catch (e) {
        Toast.error();
      }
    }
  );

  useEffect(() => {
    fetchWorkouts({
      join: ['author'],
      sort: [`${sortField},${sortDirection}`],
    });
  }, [sortField, sortDirection]);

  const onChangeSort = (newSort: string) => {
    if (sortField === newSort) {
      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    }

    setSortField(newSort);
  };

  const currentUser = useSelector(selectors.auth.getUserInfo);

  const renderItem = useCallback(
    (workout: Workout) => {
      const authorJsx =
        workout.authorId === currentUser.id ? (
          <SubheadTwo>{`${currentUser.firstName} ${currentUser.lastName}`}</SubheadTwo>
        ) : (
          <Logo transform="scale(0.6)" />
        );

      const exercisesCount = workout.exercisesCount;

      return (
        <GridDataRow
          $active={workout.id === selectedId}
          onClick={() => setSelectedId(workout.id)}
          key={workout.id}
        >
          <div>
            <BodyOne>{workout.name}</BodyOne>
            <SubheadTwo>{workout.description}</SubheadTwo>
          </div>
          <div>
            <SubheadTwo>{exercisesCount}</SubheadTwo>
          </div>
          <div>{authorJsx}</div>
        </GridDataRow>
      );
    },
    [currentUser, selectedId]
  );

  return (
    <ModalWrapper width={1062} title="Add workout" {...rest}>
      <GridTopRow>
        <SortingField
          text={`Workouts (${workouts.length})`}
          isActive={sortField === 'name'}
          direction={sortDirection}
          onClick={() => onChangeSort('name')}
        />
        <SortingField
          text="Exercises"
          isActive={sortField === 'exercisesCount'}
          direction={sortDirection}
          onClick={() => onChangeSort('exercisesCount')}
        />
        <SortingField
          text="Owner"
          isActive={sortField === 'author.firstName'}
          direction={sortDirection}
          onClick={() => onChangeSort('author.firstName')}
        />
      </GridTopRow>
      <WorkoutRowsContainer>
        {isLoading ? <SpinnerContainer /> : workouts.map(renderItem)}
      </WorkoutRowsContainer>
      <FlexStart>
        <Button
          text="Add workout"
          $margin="0 12px 0 0"
          disabled={!selectedId}
          isLoading={isPickLoading}
          onClick={() => asyncPick(selectedId!)}
        />
        <Button text="Cancel" theme="secondary" onClick={rest.onClose} />
      </FlexStart>
    </ModalWrapper>
  );
}

export default PickWorkoutModal;
