import * as React from 'react';

function SvgWarn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={36}
      height={36}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={18} cy={18} r={15} fill="#FEBC2C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.015 19.5a1.5 1.5 0 01-1.5-1.5v-6a1.5 1.5 0 013 0v6a1.5 1.5 0 01-1.5 1.5zM19.515 24a1.5 1.5 0 01-1.5 1.5H18a1.5 1.5 0 010-3h.015a1.5 1.5 0 011.5 1.5z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgWarn;
