import { routesNamesMap } from '@constants';
import { Back } from 'assets/svg';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TitleThree } from 'styled';
import { Profile } from './components';
import { PageNameContainer, Wrapper, BackArrowContainer } from './styled';

interface Props {
  withBack?: boolean;
}

function Header({ withBack }: Props) {
  const location = useLocation();
  const history = useHistory();

  const pageName = location.pathname.split('/')[1];

  return (
    <Wrapper>
      <PageNameContainer>
        <TitleThree>{routesNamesMap[pageName]}</TitleThree>
        {withBack && (
          <BackArrowContainer>
            <Back onClick={history.goBack} />
          </BackArrowContainer>
        )}
      </PageNameContainer>
      <Profile />
    </Wrapper>
  );
}

export default Header;
