import * as React from 'react';

function SvgSuccess(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={40}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 40C8.954 40 0 31.046 0 20S8.954 0 20 0s20 8.954 20 20-8.954 20-20 20zm0-4a16 16 0 100-32 16 16 0 000 32zm-1.994-8L9.52 19.514l2.828-2.828 5.658 5.658L29.318 11.03l2.83 2.828L18.006 28z"
        fill="#895FC6"
      />
    </svg>
  );
}

export default SvgSuccess;
