import React, { useEffect, useState } from 'react';
import { ModalWrapper, SpinnerContainer } from 'components';
import { ModalBaseProps } from 'interfaces/common';
import { Video } from 'types';
import { getVideo } from 'services/api';
import Vimeo from '@u-wave/react-vimeo';
import {
  VideoContainer,
  AuthorContainer,
  DescriptionContainer,
  ArrowCSS,
  Container,
} from './styled';
import {
  CircleAvatar,
  FlexCenter,
  JustifyBetween,
  SubheadOne,
  SubheadTwo,
  TitleFive,
} from 'styled';
import { DEFAULT_AVATAR, ROUTE_PATH } from '@constants';
import { Arrow } from 'UI';
import { useHistory } from 'react-router';
import { AxiosError } from 'axios';

type Props = ModalBaseProps & {
  id: string;
};

function VideoModal({ id, ...rest }: Props) {
  const history = useHistory();

  const [video, setVideo] = useState<Video | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const { data } = await getVideo(id);
        setVideo(data);
      } catch (e) {
        setError((e as AxiosError).message || 'Video is unavailable');
      }
    };

    fetchVideo();
  }, [id]);

  const renderContent = () => {
    if (error) {
      return (
        <FlexCenter>
          <TitleFive>{error}</TitleFive>
        </FlexCenter>
      );
    }

    if (!video) {
      return <SpinnerContainer />;
    }

    return (
      <Container>
        <VideoContainer>
          <Vimeo video={video.videoUrl!.replace('/videos/', '')} />
        </VideoContainer>
        <DescriptionContainer>
          <AuthorContainer
            onClick={() => history.push(ROUTE_PATH.userProfile(video.authorId))}
          >
            <CircleAvatar
              $url={video.author?.avatarUrl || DEFAULT_AVATAR}
              $size={24}
              $withPointerCursor
            />
            <SubheadTwo>{`${video.author?.firstName} ${video.author?.lastName}`}</SubheadTwo>
          </AuthorContainer>
          <JustifyBetween $marginBottom={16}>
            <SubheadOne>{video.name}</SubheadOne>
            <Arrow
              $rotate={isDescriptionVisible ? 225 : 45}
              $color="#67667B"
              $CSS={ArrowCSS}
              onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}
            />
          </JustifyBetween>
          {isDescriptionVisible && (
            <SubheadTwo $color="#67667B" $marginBottom={12}>
              {video.description}
            </SubheadTwo>
          )}
        </DescriptionContainer>
      </Container>
    );
  };

  return (
    <ModalWrapper {...rest} isBigModal={!error} width={1062}>
      {renderContent()}
    </ModalWrapper>
  );
}

export default VideoModal;
