import * as React from 'react';

function SvgEdit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="#67667B"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.702 15.833H17.5V17.5h-15v-3.536l8.25-8.25 3.535 3.537-6.583 6.582zm4.226-11.296l1.768-1.769a.833.833 0 011.178 0l2.358 2.358a.833.833 0 010 1.178l-1.769 1.768-3.535-3.535z"
        fill="inherit"
        opacity={0.6}
      />
    </svg>
  );
}

export default SvgEdit;
