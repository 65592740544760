import React from 'react';
import ReactDatepicker, { ReactDatePickerProps } from 'react-datepicker';
import {
  Wrapper,
  Header,
  HeaderText,
  ButtonContainer,
  ButtonIcon,
  Buttons,
} from './styled';
import dayjs from 'dayjs';
import { CSSProp } from 'styled-components';

const renderCustomHeader: ReactDatePickerProps['renderCustomHeader'] = ({
  date,
  increaseMonth,
  decreaseMonth,
}) => {
  const selectedDate = dayjs(date);
  return (
    <Header>
      <HeaderText>{selectedDate.format('MMMM YYYY')}</HeaderText>
      <Buttons>
        <ButtonContainer onClick={decreaseMonth}>
          <ButtonIcon $isRight={false} />
        </ButtonContainer>
        <ButtonContainer onClick={increaseMonth}>
          <ButtonIcon $isRight />
        </ButtonContainer>
      </Buttons>
    </Header>
  );
};

interface Props {
  date: string;
  isOpen: boolean;
  onChange: (newDate: string) => void;

  containerCSS?: CSSProp;
}

function Datepicker({ date, isOpen, onChange, containerCSS }: Props) {
  return (
    <Wrapper $CSS={containerCSS}>
      <ReactDatepicker
        open={isOpen}
        selected={new Date(date)}
        onChange={(_, __) => undefined}
        onSelect={(date) => {
          onChange((date as Date).toISOString());
        }}
        customInput={<div />}
        renderCustomHeader={renderCustomHeader}
        shouldCloseOnSelect
        disabledKeyboardNavigation
        useWeekdaysShort
      />
    </Wrapper>
  );
}

export default Datepicker;
