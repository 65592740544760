import { Programs } from 'assets/svg';
import React from 'react';
import { SubheadOne } from 'styled';
import { CSSProp } from 'styled-components';
import { Tab } from 'UI/types';
import { Containter, TabContainer } from './styled';

type Props<TabValue> = {
  tabs: Tab<TabValue>[];

  activeTabValue: TabValue;
  onChange: (newTabValue: TabValue) => void;

  tabVerticalPadding?: number;
  containerWidth?: number;
  containerCSS?: CSSProp;
  tabCSS?: CSSProp;
};

function BlockTabs<TabValue = string>({
  tabs,
  activeTabValue,
  onChange,
  tabVerticalPadding,
  containerWidth,
  containerCSS,
  tabCSS,
}: Props<TabValue>) {
  return (
    <Containter $width={containerWidth} $CSS={containerCSS}>
      {tabs.map((tab) => {
        const isActiveTab = activeTabValue === tab.value;

        return (
          <TabContainer
            $isActive={isActiveTab}
            onClick={() => onChange(tab.value)}
            $verticalPadding={tabVerticalPadding}
            $CSS={tabCSS}
            key={`${tab.value}`}
          >
            <SubheadOne $color={isActiveTab ? 'white' : '#67667B'}>
              {tab.label}
            </SubheadOne>
          </TabContainer>
        );
      })}
    </Containter>
  );
}

export default BlockTabs;
