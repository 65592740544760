import React from 'react';
import { ButtonBaseProps } from 'UI/types';

import { Button } from './styled';

interface Props extends ButtonBaseProps {
  color?: string;
  hoverColor?: string;
  size?: number;
  children?: React.ReactNode;
}

function CircleButton({
  color = '#F3EFF9',
  hoverColor,
  size = 20,
  children,
  ...rest
}: Props) {
  return (
    <Button $size={size} $color={color} $hoverColor={hoverColor} {...rest}>
      {children}
    </Button>
  );
}

export default CircleButton;
