import * as React from 'react';

function SvgSettings(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.445 3.788A8.325 8.325 0 017.396 2.08a3.327 3.327 0 004.048.923c.45-.217.847-.532 1.159-.923 1.091.359 2.096.94 2.952 1.707a3.329 3.329 0 001.225 3.965c.413.282.884.467 1.379.542a8.369 8.369 0 010 3.408 3.33 3.33 0 00-2.824 3.044c-.038.499.037 1 .22 1.465a8.324 8.324 0 01-2.952 1.706 3.328 3.328 0 00-4.048-.923c-.45.217-.847.532-1.159.923a8.325 8.325 0 01-2.951-1.706 3.328 3.328 0 00-1.226-3.965 3.328 3.328 0 00-1.379-.542 8.369 8.369 0 010-3.41 3.328 3.328 0 002.824-3.043 3.328 3.328 0 00-.22-1.464zm6.805 8.376a2.5 2.5 0 10-2.472-4.347 2.5 2.5 0 002.472 4.347z"
        fill="#67667B"
      />
    </svg>
  );
}

export default SvgSettings;
