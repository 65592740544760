import { ROUTE_PATH } from '@constants';
import { useQuery } from 'hooks';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { BlockTabs } from 'UI';
import { Tab } from 'UI/types';
import { buildQuery } from 'utils';
import { MyClients, ArchiveClients, GlobalClients } from './components';
import { Wrapper } from './styled';

enum PageTabs {
  MyClients = 'myClients',
  Archive = 'archive',
  Global = 'global',
}

const tabs: Tab<PageTabs>[] = [
  { label: 'My  Clients', value: PageTabs.MyClients },
  { label: 'Archive Clients', value: PageTabs.Archive },
  { label: 'Global Clients', value: PageTabs.Global },
];

function Clients() {
  const history = useHistory();
  const query = useQuery();
  const activeTab: PageTabs =
    (query.get('activeTab') as PageTabs) ?? PageTabs.MyClients;

  useEffect(() => {
    const query = buildQuery({ activeTab });
    history.push(`${ROUTE_PATH.clients}${query}`);
  }, [activeTab]);

  const setActiveTab = (newTab: PageTabs) => {
    const query = buildQuery({ activeTab: newTab });
    history.push(`${ROUTE_PATH.clients}${query}`);
  };

  return (
    <Wrapper>
      <BlockTabs
        activeTabValue={activeTab}
        onChange={setActiveTab}
        tabs={tabs}
        containerWidth={590}
      />
      {activeTab === PageTabs.MyClients && <MyClients />}
      {activeTab === PageTabs.Archive && <ArchiveClients />}
      {activeTab === PageTabs.Global && <GlobalClients />}
    </Wrapper>
  );
}

export default Clients;
