import { StylesConfig } from 'react-select';
import { css } from 'styled-components';
import { OptionType } from 'UI/types';

export const FieldContainerCSS = css`
  padding: 0px;
`;

export default {
  menuList: (base) => ({
    ...base,
    paddingTop: '0px',
    paddingBottom: '0px',
  }),
} as StylesConfig<OptionType, true, any>;
