import styled from 'styled-components';

export const Container = styled.div``;

export const AbsoluteButton = styled.div`
  position: absolute;
  right: 0;
  top: calc(-24px - 56px);
`;

export const EmptyExercisesContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  & > svg {
    margin-bottom: 20px;
  }
`;
