import { ModalWrapper } from 'components';
import { useAsyncCallback } from 'hooks';
import { ModalBaseProps } from 'interfaces/common';
import React from 'react';
import { BodyThree, FlexStart } from 'styled';
import { Button } from 'UI';

type Props = ModalBaseProps & {
  title: string;
  description: string;
  agreeText: string;
  disagreeText: string;

  onAgree: () => void;
  onDisagree?: () => void;
};

function ConfirmModal({
  isOpen,
  onClose,
  title,
  description,
  agreeText,
  disagreeText,
  onAgree,
  onDisagree = onClose,
}: Props) {
  const [asyncSubmit, isLoading] = useAsyncCallback(onAgree);

  return (
    <ModalWrapper title={title} width={410} isOpen={isOpen} onClose={onClose}>
      <BodyThree $marginBottom={24}>{description}</BodyThree>
      <FlexStart>
        <Button
          text={agreeText}
          textColor="#FF5934"
          backgroundColor="#FFEEEB"
          hoverColor="#FFEEEB"
          $margin="0px 12px 0px 0px"
          isLoading={isLoading}
          onClick={asyncSubmit}
        />
        <Button
          text={disagreeText}
          theme="secondary"
          disabled={isLoading}
          onClick={onDisagree}
        />
      </FlexStart>
    </ModalWrapper>
  );
}

export default ConfirmModal;
