import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 24px;

  background: #ffffff;

  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 16px;
`;

export const ContainerInfo = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const Information = styled.div`
  flex-grow: 1;
  margin: 0 20px;
  padding-top: 16px;

  @media (max-width: 900px) {
    width: 100%;
    margin: 0;
  }
`;

export const InformationList = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  margin: 0;
  padding: 0;
  margin-top: 24px;
  list-style: none;
`;

export const InformationItem = styled.li`
  position: relative;
  padding: 0 24px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;

    &::before {
      content: none;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    height: 20px;
    width: 1px;
    background-color: #e1e0e5;

    transform: translateY(-50%);
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 16px;
`;
