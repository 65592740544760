import React, { MouseEvent } from 'react';
import {
  CircleAvatar,
  FootnoteOne,
  FootnoteThree,
  JustifyBetween,
} from 'styled';
import dayjs from 'dayjs';
import { Arrow } from 'UI';
import {
  ClientNameCSS,
  TrainingTitleCSS,
  Container,
  JustifyInfo,
} from './styled';

interface Props {
  startDate: string;
  endDate: string;
  clientName: string;
  trainingTitle: string;
  clientAvatar: string;

  height: number;
  width: number;
  marginTop: number;

  onCardClick?: () => void;
  onArrowClick?: () => void;
}

const formatDate = (date: string) => dayjs(date).format('hh:mm');

function WorkoutCard({
  startDate,
  endDate,
  clientName,
  trainingTitle,
  clientAvatar,
  height,
  width,
  marginTop,
  onCardClick,
  onArrowClick,
}: Props) {
  const handleClick = (e: MouseEvent<HTMLElement>, callback?: () => void) => {
    e.stopPropagation();
    e.preventDefault();

    if (callback) {
      callback();
    }
  };

  return (
    <Container
      $width={width}
      $height={height}
      $marginTop={marginTop}
      onClick={(e) => handleClick(e, onCardClick)}
    >
      <div>
        <FootnoteThree $color="#67667B" $marginBottom={8}>{`${formatDate(
          startDate
        )}-${formatDate(endDate)}`}</FootnoteThree>
        <FootnoteOne $CSS={ClientNameCSS}>{clientName}</FootnoteOne>
        <FootnoteThree $marginBottom={8} $CSS={TrainingTitleCSS}>
          {trainingTitle}
        </FootnoteThree>
      </div>
      <JustifyBetween>
        <CircleAvatar
          $url={clientAvatar}
          onClick={(e) => handleClick(e, onArrowClick)}
          $withPointerCursor
        />
        <Arrow
          $color="rgba(25, 7, 66, 0.64)"
          $hoverColor="#895FC6"
          $width={5}
          $size={0.3}
          $rotate={-45}
          onClick={(e) => handleClick(e, onArrowClick)}
        />
      </JustifyBetween>
    </Container>
  );
}

export default WorkoutCard;
