import styled, { css } from 'styled-components';

export interface Props {
  theme?: 'primary' | 'secondary' | 'delete';
  maxHeight?: number;
  backgroundColor?: string;
  textColor?: string;
  hoverColor?: string;
  $margin?: string;
  width?: string;
}

export const StyledButton = styled.button<Props>`
  max-height: ${({ maxHeight }) => maxHeight && `${maxHeight}px`};
  cursor: pointer;
  outline: none;

  border-radius: 12px;
  padding: 18px 0px;

  font-weight: bold;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  transition: background-color 0.3s;

  ${({ theme }) => getThemeStyles(theme)}

  ${({ backgroundColor, textColor, hoverColor, width }) =>
    getCustomStyles({ backgroundColor, textColor, hoverColor, width })}

  margin: ${({ $margin }) => $margin}
`;

StyledButton.defaultProps = {
  width: '165px',
};

function getThemeStyles(theme: Props['theme']) {
  switch (theme) {
    case 'primary':
      return css`
        background-color: #895fc6;
        color: white;

        &:disabled {
          cursor: auto;
          background-color: #e1e0e5;
          color: #201e3f;
        }

        &:enabled:hover {
          background-color: #a07fd1;
        }
      `;
    case 'secondary':
      return css`
        background-color: #f3eff9;
        color: #895fc6;

        &:disabled {
          cursor: auto;
          background-color: #e1e0e5;
          color: #201e3f;
        }

        &:enabled:hover {
          background-color: #f5f2fa;
        }
      `;
    case 'delete':
      return css`
        background-color: #ffeeeb;
        color: #ff5934;

        &:enabled:hover {
          opacity: 0.8;
        }

        &:disabled {
          cursor: auto;
          background-color: #e1e0e5;
          color: #201e3f;
        }
      `;
  }
}

function getCustomStyles({
  backgroundColor,
  textColor,
  hoverColor,
  width,
}: Omit<Props, 'theme'>) {
  return css`
    width: ${width};
    background-color: ${backgroundColor};
    color: ${textColor};

    &:enabled:hover {
      background-color: ${hoverColor};
    }
  `;
}
