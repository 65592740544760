import * as React from 'react'

function SvgCloseSq(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			width='50px'
			height='50px'
			viewBox='-2.4 -2.4 28.80 28.80'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			transform='rotate(0)matrix(1, 0, 0, 1, 0, 0)'
			stroke='#d9d9d9'
			{...props}
		>
			<g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
			<g
				id='SVGRepo_tracerCarrier'
				strokeLinecap='round'
				strokeLinejoin='round'
				stroke='#CCCCCC'
				strokeWidth='0.048'
			/>

			<g id='SVGRepo_iconCarrier'>
				{' '}
				<path
					opacity='0.5'
					d='M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z'
					stroke='#6d6969'
					strokeWidth='1.5'
				/>{' '}
				<path
					d='M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5'
					stroke='#6d6969'
					strokeWidth='1.5'
					strokeLinecap='round'
				/>{' '}
			</g>
		</svg>
	)
}

export default SvgCloseSq
