import React from 'react';
import { IndicatorProps } from 'react-select';
import { FilledArrow } from 'styled';
import { OptionType } from 'UI/types';

function DropdownIndicator({
  innerProps,
  selectProps,
}: IndicatorProps<OptionType, true>) {
  return (
    <FilledArrow
      $width={6}
      $color="#201E3F"
      $isActive={selectProps.menuIsOpen}
      {...innerProps}
    />
  );
}

export default DropdownIndicator;
