import React, { useCallback, useMemo } from 'react';
import * as customComponents from './components';
import AsyncSelect from 'react-select/async';
import { AsyncSelectProps, OptionType } from 'UI/types';
import FieldWrapper from 'UI/FieldWrapper';
import styles, { FieldContainerCSS } from './styles';
import debounce from 'debounce-promise';

function AsyncSelectDropdown<ResponseType>({
  label,
  error,
  getOptions,
  normalizeOptions,
  value,
  onChange,
  isMulti = false,
  isClearable,
  hideSelectedOptions = false,
  withLeftCheckbox,
  withRightCheckbox,
  closeOnSelect,
  disabled,
  menuPlacement,
}: AsyncSelectProps<ResponseType>) {
  const loadOptions = useCallback(
    async (
      inputValue: string,
      getOptions: AsyncSelectProps<any>['getOptions'],
      normalizeOptions: (raw: any) => OptionType[]
    ) => {
      const { data: rawOptions } = await getOptions();

      const normalizedOptions = normalizeOptions(rawOptions);
      const filteredOptions = normalizedOptions.filter((opt) =>
        opt.label.includes(inputValue)
      );

      return filteredOptions;
    },
    []
  );

  const debouncedLoadOptions = useMemo(() => debounce(loadOptions, 1000), [
    loadOptions,
  ]);

  const fetchFunc = async (input: string) =>
    debouncedLoadOptions(input, getOptions, normalizeOptions);

  return (
    <FieldWrapper
      label={label}
      error={error}
      disabled={disabled}
      fieldContainerCSS={FieldContainerCSS}
    >
      <AsyncSelect<OptionType, true>
        defaultOptions
        loadOptions={disabled ? undefined : fetchFunc}
        value={value}
        onChange={onChange}
        placeholder="Select an option..."
        noOptionsMessage={() => 'No options :('}
        styles={styles}
        components={customComponents}
        isMulti={isMulti || undefined}
        hideSelectedOptions={hideSelectedOptions}
        withLeftCheckbox={withLeftCheckbox}
        withRightCheckbox={withRightCheckbox}
        closeMenuOnSelect={closeOnSelect}
        isDisabled={disabled}
        maxMenuHeight={200}
        menuPlacement={menuPlacement}
        isClearable={isClearable}
      />
    </FieldWrapper>
  );
}

export default AsyncSelectDropdown;
