import { PlusSign } from 'assets/svg';
import React, { useMemo } from 'react';
import { FootnoteOne, FootnoteThree, SubheadOne } from 'styled';
import { ProgramWorkout } from 'types';
import { IconButton } from 'UI';
import {
  Container,
  WeekContainer,
  DayContainer,
  DayWorkoutsContainer,
  AddWorkoutButtonContainer,
  WorkoutCard,
} from './styled';

type Props = {
  workouts: ProgramWorkout[];
  weekCount: number;

  onOpenCreateModal: (week: number, day: number) => void;
};

const daysCount = 7;
const days = [...new Array(daysCount)].map((_, index) => index);

function WorkoutsGrid({ workouts, weekCount, onOpenCreateModal }: Props) {
  const weeks = useMemo(
    () => [...new Array(weekCount)].map((_, index) => index),
    [weekCount]
  );

  const getWorkouts = (week: number, day: number) => {
    const filtered = workouts.filter(
      (workout) => workout.weekNumber === week && workout.dayNumber === day
    );

    return filtered;
  };

  const renderWorkoutItem = (programWorkout: ProgramWorkout) => {
    const { workout } = programWorkout;

    return (
      <WorkoutCard key={programWorkout.id}>
        <FootnoteOne>{workout.name}</FootnoteOne>
        <FootnoteThree>{workout.description}</FootnoteThree>
      </WorkoutCard>
    );
  };

  const renderDayContent = (week: number, day: number) => {
    const workouts = getWorkouts(week, day);

    return (
      <React.Fragment>
        {workouts.map(renderWorkoutItem)}
        <AddWorkoutButtonContainer $empty={workouts.length === 0}>
          <IconButton
            backgroundColor="#F3EFF9"
            hoverColor="#E7E7F9"
            onClick={() => onOpenCreateModal(week, day)}
          >
            <PlusSign />
          </IconButton>
        </AddWorkoutButtonContainer>
      </React.Fragment>
    );
  };

  return (
    <Container>
      {weeks.map((weekNumber) => (
        <WeekContainer key={weekNumber}>
          {days.map((dayNumber) => (
            <DayContainer key={dayNumber}>
              <SubheadOne $color="#67667B" $marginBottom={8}>{`Day ${
                dayNumber + 1
              }`}</SubheadOne>
              <DayWorkoutsContainer>
                {renderDayContent(weekNumber, dayNumber)}
              </DayWorkoutsContainer>
            </DayContainer>
          ))}
        </WeekContainer>
      ))}
    </Container>
  );
}

export default WorkoutsGrid;
