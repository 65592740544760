import ModalWrapper from 'components/ModalWrapper';
import { Button } from 'UI';
import { ModalWithForm } from 'interfaces/common';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FlexStart, JustifyBetween } from 'styled';
import { ImageField, SelectField, TextField } from 'components';
import { RightFieldsContainer } from './styled';
import { OptionType } from 'UI/types';
import { isRequired } from 'utils/validation';
import { useAsyncCallback } from 'hooks';
import { weekCountOptions } from '@constants';

type ProgramFormValues = {
  name: string;
  weekCount: OptionType;
  description: string;
  image: string;
};

type Props = ModalWithForm<ProgramFormValues> & {
  initialValues?: ProgramFormValues;
};

function ProgramModal({ isOpen, onClose, onSubmit, initialValues }: Props) {
  const [asyncSubmit, isLoading] = useAsyncCallback(onSubmit);
  if (!onSubmit) {
    return null;
  }

  const isEditModal = Boolean(initialValues);

  return (
    <ModalWrapper
      title={isEditModal ? 'Edit program' : 'Add program'}
      isOpen={isOpen}
      onClose={onClose}
      withCloseOnClickOutside={false}
      width={1062}
    >
      <Form<ProgramFormValues>
        onSubmit={asyncSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, hasValidationErrors, dirty }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <JustifyBetween $marginBottom={26}>
                <Field
                  name="image"
                  label="Cover"
                  validate={isRequired()}
                  cropRatio={16 / 9}
                  component={ImageField}
                />
                <RightFieldsContainer>
                  <Field
                    name="name"
                    label="Name"
                    validate={isRequired()}
                    component={TextField}
                  />
                  <Field
                    name="weekCount"
                    label="Weeks"
                    validate={isRequired()}
                    options={weekCountOptions}
                    component={SelectField}
                  />
                  <Field
                    name="description"
                    label="Description"
                    validate={isRequired()}
                    component={TextField}
                  />
                </RightFieldsContainer>
              </JustifyBetween>
              <FlexStart>
                <Button
                  $margin="0px 12px 0px 0px"
                  text={isEditModal ? 'Save' : 'Add program'}
                  type="submit"
                  isLoading={isLoading}
                  disabled={hasValidationErrors || (isEditModal && !dirty)}
                />
                <Button
                  text="Cancel"
                  theme="secondary"
                  type="button"
                  disabled={isLoading}
                  onClick={onClose}
                />
              </FlexStart>
            </div>
          </form>
        )}
      />
    </ModalWrapper>
  );
}

export default ProgramModal;
