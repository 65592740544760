import styled, { css, CSSProp } from 'styled-components';

export interface Props {
  theme?: 'primary' | 'secondary' | 'base';
  backgroundColor?: string;
  colorIcon?: string;
  hoverColor?: string;
  margin?: string;
  $CSS?: CSSProp;
}

export const StyledButton = styled.button<Props>`
  cursor: pointer;
  outline: none;

  border-radius: 12px;
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.3s;

  ${({ theme }) => getThemeStyles(theme)}

  ${({ backgroundColor, colorIcon, hoverColor }) =>
    getCustomStyles({ backgroundColor, colorIcon, hoverColor })}

  margin: ${({ margin }) => margin};

  ${({ $CSS }) => $CSS}
`;

function getThemeStyles(theme: Props['theme']) {
  switch (theme) {
    case 'primary':
      return css`
        background-color: #895fc6;

        &:disabled {
          background-color: #e1e0e5;
        }

        &:enabled:hover {
          background-color: #a07fd1;
        }
      `;
    case 'secondary':
      return css`
        background-color: #f3eff9;

        &:disabled {
          background-color: #e1e0e5;
        }

        &:enabled:hover {
          background-color: #f5f2fa;
        }
      `;
    case 'base':
      return css`
        padding: 5px;
        background-color: transparent;
      `;
  }
}

function getCustomStyles({
  backgroundColor,
  colorIcon,
  hoverColor,
}: Omit<Props, 'theme'>) {
  return css`
    background-color: ${backgroundColor};

    &:enabled:hover {
      background-color: ${hoverColor};
    }

    & > svg path {
      fill: ${colorIcon};
    }
  `;
}
