import { CloseEx, Edit } from 'assets/svg';
import React from 'react';
import { FlexStart, SubheadOne } from 'styled';
import { ButtonsContainer, Container, Duration, Preview } from './styled';

type Props = {
  name: string;
  image: string;
  duration?: string;
  withHover?: boolean;

  onEdit: () => void;
  onDelete: () => void;
};

function ExerciseCard({
  name,
  image,
  duration,
  withHover = true,
  onEdit,
  onDelete,
}: Props) {
  return (
    <Container $withHover={withHover}>
      <FlexStart>
        <Preview $url={image} />
        <SubheadOne>{name}</SubheadOne>
      </FlexStart>
      <ButtonsContainer>
        {duration && <Duration>{duration}</Duration>}
        <Edit onClick={onEdit} />
        <CloseEx transform="scale(1.3)" onClick={onDelete} />
      </ButtonsContainer>
    </Container>
  );
}

export default ExerciseCard;
