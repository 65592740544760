import React from 'react';
import dayjs from 'dayjs';
import { Field, FieldRenderProps } from 'react-final-form';
import {
  BottomButtonsContainer,
  ButtonsContainer,
  Container,
  Input,
  InputContainer,
  MeridianButton,
  PopupArrowCSS,
  PopupContainer,
  TopArrowCSS,
  BottomArrowCSS,
  Wrapper,
} from './styled';
import { BodyThree, FilledArrow, FootnoteOne, JustifyBetween } from 'styled';
import { Arrow, TextButton } from 'UI';
import { FormApi } from 'final-form';

function TimeField({ input, meta, label }: FieldRenderProps<string>) {
  const error = meta.error;

  const meridian = dayjs(input.value).format('A') as 'AM' | 'PM';

  const convertToAM = () => {
    if (meridian !== 'AM') {
      const currentDate = dayjs(input.value);
      const currentHour = currentDate.hour();
      const newDate = currentDate.hour(currentHour - 12);
      input.onChange(newDate.toISOString());
    }
  };

  const convertToPM = () => {
    if (meridian !== 'PM') {
      const currentDate = dayjs(input.value);
      const currentHour = currentDate.hour();
      const newDate = currentDate.hour(currentHour + 12);
      input.onChange(newDate.toISOString());
    }
  };

  const renderRadioGroup = (
    <ButtonsContainer>
      <MeridianButton
        onClick={convertToAM}
        $isActive={meridian === 'AM'}
        type="button"
      >
        <BodyThree $color={meridian === 'AM' ? 'white' : '#201E3F'}>
          AM
        </BodyThree>
      </MeridianButton>
      <MeridianButton
        onClick={convertToPM}
        $isActive={meridian === 'PM'}
        type="button"
      >
        <BodyThree $color={meridian === 'PM' ? 'white' : '#201E3F'}>
          PM
        </BodyThree>
      </MeridianButton>
    </ButtonsContainer>
  );

  const handleChangeTime = (newTime: string) => {
    if (newTime) {
      const [hour, minutes] = newTime.split(':');

      const currentDate = dayjs(input.value);
      const newDate = currentDate.hour(+hour).minute(+minutes);
      input.onChange(newDate.toISOString());
    }
  };

  return (
    <Wrapper>
      <FootnoteOne $marginBottom={8}>{label}</FootnoteOne>
      <Container>
        <InputContainer>
          <Arrow
            $color="#201E3F"
            $size={0.4}
            $width={5}
            $rotate={225}
            $CSS={TopArrowCSS}
            onClick={() =>
              input.onChange(dayjs(input.value).add(1, 'hour').toISOString())
            }
          />
          <Input
            as="input"
            type="time"
            value={dayjs(input.value).format('hh:mm')}
            onChange={(e) => handleChangeTime(e.target.value)}
          />
          <Arrow
            $color="#201E3F"
            $size={0.4}
            $width={5}
            $rotate={45}
            $CSS={BottomArrowCSS}
            onClick={() =>
              input.onChange(
                dayjs(input.value).subtract(1, 'hour').toISOString()
              )
            }
          />
        </InputContainer>
        {renderRadioGroup}
      </Container>
      {error && (
        <FootnoteOne $color="#FF5934" $margin="8px 0px 0px 0px">
          {error}
        </FootnoteOne>
      )}
    </Wrapper>
  );
}

type Props = {
  startFieldName: string;
  endFieldName: string;
  formApi: FormApi;

  onSelect: () => void;
};

function TimePopup({ startFieldName, endFieldName, formApi, onSelect }: Props) {
  const resetFields = () => {
    const currentDate = dayjs();

    const startDate = currentDate.toISOString();
    const endDate = currentDate.add(1, 'hour').toISOString();

    formApi.change(startFieldName, startDate);
    formApi.change(endFieldName, endDate);
  };

  return (
    <PopupContainer>
      <FilledArrow $isActive $color="white" $width={8} $CSS={PopupArrowCSS} />
      <JustifyBetween>
        <Field name={startFieldName} label="From" component={TimeField} />
        <Field name={endFieldName} label="To" component={TimeField} />
      </JustifyBetween>
      <BottomButtonsContainer>
        <TextButton
          text="Clear"
          color="#FF5934"
          margin="0 20px 0 0"
          type="reset"
          onClick={resetFields}
        />
        <TextButton
          text="Select"
          color="#885FC6"
          type="button"
          onClick={onSelect}
        />
      </BottomButtonsContainer>
    </PopupContainer>
  );
}

export default TimePopup;
