import React, { useCallback, useEffect, useState } from 'react'
import { DEFAULT_AVATAR } from '@constants'
import { captureException } from '@sentry/minimal'
import { ReportModal, SpinnerContainer } from 'components'
import dayjs from 'dayjs'
import { useChatSubscription } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt, useParams } from 'react-router'
import {
	createUserReport,
	getChatRoomMessages,
	getOneChatRoom,
	sendMessageChatRoom,
	updateLastViewedMessageChatRoom,
} from 'services/api'
import Toast from 'services/Toast'
import { actions, selectors } from 'store/ducks'
import {
	ChatMessage,
	ChatMessageDto,
	ChatRoom as ChatRoomType,
	ReportType,
} from 'types'
import { Head, Messages, VideoModal } from './components'
import { Container } from './styled'
import { AxiosError } from 'axios'

const MESSAGES_LIMIT = 20

function ChatRoom() {
	const dispatch = useDispatch()

	const user = useSelector(selectors.auth.getUserInfo)
	const { id } = useParams<{ id: string }>()

	const [isLoading, setIsLoading] = useState(false)
	const [messages, setMessages] = useState<ChatMessage[]>([])
	const [chatRoom, setChatRoom] = useState<ChatRoomType | null>(null)

	const [isLastMessageUpdated, setIsLastMessageUpdated] = useState(false)

	const [page, setPage] = useState(1)
	const [total, setTotal] = useState(0)

	const [selectedMessagesIds, setSelectedMessagesIds] = useState<string[]>([])

	const onlineUsersIds = useSelector(selectors.onlineUsers.selectAll)

	const [isReportModalOpen, setIsReportModalOpen] = useState(false)

	const [modalVideoId, setModalVideoId] = useState<string | null>(null)
	const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)

	useChatSubscription({
		channelId: chatRoom?.channel,
		onReceiveMessage: newMessage => {
			setMessages(messages => [...messages, newMessage])
		},
	})

	const fetchMessages = useCallback(async () => {
		try {
			const { data } = await getChatRoomMessages(id, {
				limit: MESSAGES_LIMIT,
				page,
			})
			setMessages(prev => [...prev, ...data.data])
			setTotal(data.total)
		} catch (e) {
			Toast.error()
			captureException(e)
		}
	}, [id, page])

	const fetchRoom = useCallback(async () => {
		try {
			const { data } = await getOneChatRoom(id)
			setChatRoom(data)
		} catch (e) {
			Toast.error()
			captureException(e)
		}
	}, [id])

	useEffect(() => {
		const doFetch = async () => {
			try {
				setIsLoading(true)
				await fetchMessages()
				await fetchRoom()
			} catch (e) {
				Toast.error()
				captureException(e)
			} finally {
				setIsLoading(false)
			}
		}

		doFetch()
	}, [])

	const sortedMessages = messages.sort(
		(a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix()
	)

	useEffect(() => {
		const lastMessage = sortedMessages.slice(-1)[0]
		if (lastMessage && !isLastMessageUpdated) {
			dispatch(
				actions.chatRooms.updateLastReadMessage({
					chatRoomId: parseInt(id),
					userId: user.id,
					messageId: lastMessage.id,
				})
			)
			setIsLastMessageUpdated(true)
		}
	}, [sortedMessages, id, user.id])

	useEffect(() => {
		if (total > messages.length) {
			fetchMessages()
		}
	}, [fetchMessages])

	const updateLastViewedMessage = useCallback(async () => {
		const lastMessage = messages[messages.length - 1]
		if (lastMessage && chatRoom) {
			await updateLastViewedMessageChatRoom(chatRoom.id, {
				lastReadMessageId: lastMessage.id,
			})
		}
	}, [messages, chatRoom])

	if (isLoading || !chatRoom) {
		return <SpinnerContainer />
	}

	const sendMessage = async (body: ChatMessageDto) => {
		try {
			await sendMessageChatRoom(id, body)
		} catch (e) {
			Toast.error(
				(e as AxiosError).message || 'Something went wrong',
				'Messages'
			)
		}
	}

	const handleSelectMessage = (id: string) => {
		console.log('dddd')
		if (selectedMessagesIds.includes(id)) {
			setSelectedMessagesIds(selectedMessagesIds.filter(el => el !== id))
		} else {
			setSelectedMessagesIds([...selectedMessagesIds, id])
		}
	}

	const handleCopyMessage = () => {
		const messageToCopy = messages.find(
			el => `${el.id}` === selectedMessagesIds[0]
		)
		if (messageToCopy) {
			navigator.clipboard.writeText(messageToCopy?.body)
			setSelectedMessagesIds([])
		}
	}

	const handleDeleteMessages = () => {
		console.log('delete')
	}

	const handleReport = () => {
		setIsReportModalOpen(true)
	}

	const { user: recipient } = chatRoom!.participants.find(
		el => el.userId !== user.id
	)!

	const submitReport = async (type: ReportType) => {
		try {
			await createUserReport({
				recipientUserId: recipient.id,
				[type]: true,
			})
			setIsReportModalOpen(false)
			Toast.success('Report submitted, thank you!', 'Report')
		} catch (e) {
			Toast.error()
		}
	}

	return (
		<Container>
			<Prompt
				when={true}
				message={() => {
					updateLastViewedMessage()
					return true
				}}
			/>
			{modalVideoId && (
				<VideoModal
					id={modalVideoId}
					isOpen={isVideoModalOpen}
					onClose={() => {
						setIsVideoModalOpen(false)
						setModalVideoId(null)
					}}
				/>
			)}
			<ReportModal
				isOpen={isReportModalOpen}
				onClose={() => setIsReportModalOpen(false)}
				onSubmit={submitReport}
			/>
			<Head
				id={recipient.id}
				userAvatarUrl={recipient.avatarUrl || DEFAULT_AVATAR}
				userName={`${recipient.firstName} ${recipient.lastName}`}
				onlineStatus={
					onlineUsersIds.includes(recipient.id) ? 'Online' : 'Offline'
				}
				selectedMessagesCount={selectedMessagesIds.length}
				onCancelSelect={() => setSelectedMessagesIds([])}
				onCopy={handleCopyMessage}
				onDelete={handleDeleteMessages}
				onReport={handleReport}
			/>
			<Messages
				messages={sortedMessages}
				total={total}
				selectedMessagesIds={selectedMessagesIds}
				onSendMessage={sendMessage}
				onSelectMessage={handleSelectMessage}
				onLoadMessages={() => setPage(page + 1)}
				onOpenVideo={id => {
					setModalVideoId(id)
					setIsVideoModalOpen(true)
				}}
			/>
		</Container>
	)
}

export default ChatRoom
