import styled, { css } from 'styled-components';

export const getPositionStyles = (isTop: boolean) => {
  if (isTop) {
    return css`
      bottom: 65px;
      left: -5px;
    `;
  }

  return css`
    transform: translateY(16px);
  `;
};

export const StyledMenu = styled.div<{ $isTop: boolean }>`
  position: absolute;

  ${({ $isTop }) => getPositionStyles($isTop)}

  width: 100%;
  z-index: 3;

  background: #ffffff;

  box-shadow: 0px 4px 32px rgba(32, 30, 63, 0.1);
  border-radius: 8px;

  overflow: hidden;
`;
