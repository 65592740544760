import styled from 'styled-components';

export const Button = styled.button<{
  $size: number;
  $color: string;
  $hoverColor?: string;
}>`
  cursor: pointer;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0;
  width: ${({ $size }) => `${$size}px`};
  height: ${({ $size }) => `${$size}px`};

  background-color: ${({ $color }) => $color};
  border-radius: 50%;
  outline: none;
  border: none;

  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ $hoverColor }) => $hoverColor};
  }
`;
