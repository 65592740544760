import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;

  position: relative;
`;

export const AbsoluteButton = styled.div`
  position: absolute;
  right: 0;
  top: calc(-24px - 56px);
`;

export const ProgramsList = styled.div`
  flex: 1;

  display: flex;

  margin: -8px;
  flex-wrap: wrap;

  margin-bottom: 16px;
`;

export const ProgramCSS = css`
  flex-basis: calc(33% - 32px);
  margin: 8px;
`;

export const EmptyProgramsContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  & > svg {
    margin-bottom: 20px;
  }
`;
