import React from 'react';
import { FootnoteOne, Headline, SubheadTwo } from 'styled';
import { Container } from './styled';
import { Button } from 'UI';
import Toast from 'services/Toast';
import { User } from 'types';
import { useHistory } from 'react-router';
import { ROUTE_PATH } from '@constants';
import { formatUnix } from 'utils/dayjs';
import { createCustomerPortalSession } from 'services/api';

interface Props {
  currentPlan: User['subscription'];
}

function Subscribtions({ currentPlan }: Props) {
  const history = useHistory();

  const handleChoosePlan = async () => {
    try {
      history.push(ROUTE_PATH.subscriptions);
    } catch (e) {
      Toast.error();
    }
  };

  const handleManagePlan = async () => {
    try {
      const { data: sessionUrl } = await createCustomerPortalSession({
        returnUrl: window.origin,
      });
      window.location.href = sessionUrl;
    } catch (e) {
      Toast.error();
    }
  };

  const renderPlan = () => {
    if (currentPlan) {
      return (
        <React.Fragment>
          <SubheadTwo $marginBottom={16}>
            Your current plan is: {currentPlan?.product.name}
          </SubheadTwo>
          <FootnoteOne $marginBottom={16}>
            {`Next payment at: 
            ${formatUnix(currentPlan?.currentPeriodEnd, 'DD:MM:YYYY')}`}
          </FootnoteOne>
        </React.Fragment>
      );
    }

    return (
      <SubheadTwo $marginBottom={16}>
        You dont have any plan. Get one!
      </SubheadTwo>
    );
  };

  return (
    <Container>
      <Headline $marginBottom={16}>Subscription management</Headline>
      {renderPlan()}
      <Button
        text={currentPlan ? 'Manage plan' : 'Choose plan'}
        theme="secondary"
        onClick={currentPlan ? handleManagePlan : handleChoosePlan}
      />
    </Container>
  );
}

export default Subscribtions;
