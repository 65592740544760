import React, { useCallback, useEffect, useState } from 'react';
import { ArchiveUp } from 'assets/svg';
import { BodyThree, colors, FlexCenter } from 'styled';
import { RelationUsers } from 'types';
import { Spinner } from 'UI';
import { ClientItem, ClientList, SearchClientForm } from '..';
import IconButton from 'UI/IconButton';
import { DEFAULT_AVATAR, ROUTE_PATH } from '@constants';
import { useHistory } from 'react-router';
import Toast from 'services/Toast';
import { getUserRelations, updateUsersRelation } from 'services/api';
import { useSelector } from 'react-redux';
import { selectors } from 'store/ducks';
import { useAsyncCallback } from 'hooks';
import { captureException } from '@sentry/minimal';
import { AxiosError } from 'axios';

function ArchiveClients() {
  const userId = useSelector(selectors.auth.getUserId);

  const [isLoading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [clients, setClients] = useState<RelationUsers[] | null>(null);

  const history = useHistory();

  const handleGetClients = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getUserRelations();
      setClients(data.filter((el) => el.archived));
    } catch (e) {
      Toast.error();
      captureException(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!clients) {
      handleGetClients();
    }
  }, []);

  const [unarchiveClient, isUnrchiveLoading] = useAsyncCallback(
    async (unarchiveUserId: string) => {
      try {
        await updateUsersRelation(unarchiveUserId, {
          status: 'accept',
          archived: false,
        });
        setClients((prev) =>
          prev!.filter((el) => {
            const a =
              el.initiatorUserId === userId &&
              el.recipientUserId === unarchiveUserId;
            const b =
              el.initiatorUserId === unarchiveUserId &&
              el.recipientUserId === userId;

            if (a || b) {
              return false;
            }

            return true;
          })
        );
        Toast.info('Client unarchived!', 'Clients');
      } catch (e) {
        Toast.error((e as AxiosError).message || 'Something went wrong');
        captureException(e);
      }
    }
  );

  const renderMyClientItem = () => {
    if (clients) {
      return clients.map((client) => {
        const secondUser =
          client.initiatorUserId === userId
            ? client.recipientUser
            : client.initiatorUser;

        return (
          <ClientItem
            firstName={secondUser.firstName ?? ''}
            lastName={secondUser.lastName ?? ''}
            avatarUrl={secondUser.avatarUrl ?? DEFAULT_AVATAR}
            onClick={() => history.push(ROUTE_PATH.userProfile(secondUser.id))}
            key={secondUser.id}
          >
            <IconButton
              theme="base"
              onClick={() => unarchiveClient(secondUser.id)}
              disabled={isUnrchiveLoading}
            >
              <ArchiveUp width={20} height={18} fill={colors.textSecondary} />
            </IconButton>
          </ClientItem>
        );
      });
    }
  };

  const renderContent = () => {
    if (clients && clients.length === 0) {
      return (
        <FlexCenter>
          <BodyThree $weight="normal" $margin="24px 0 24px 0">
            {searchValue
              ? 'No archive clients with that name'
              : 'There are no archived clients'}
          </BodyThree>
        </FlexCenter>
      );
    }

    return (
      <ClientList title="Archived clients" renderItem={renderMyClientItem} />
    );
  };

  return (
    <>
      <SearchClientForm
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      {isLoading && (
        <FlexCenter $marginBottom={30} $marginTop={30}>
          <Spinner $size={30} />
        </FlexCenter>
      )}
      {!isLoading && renderContent()}
    </>
  );
}

export default ArchiveClients;
