import * as React from 'react';

function SvgDiscuss(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 15.833l-2.334 2.917-2.333-2.917H5A.834.834 0 014.166 15V5.92A.833.833 0 015 5.085h13.333a.833.833 0 01.833.833V15a.834.834 0 01-.833.833H14zM1.666 1.667h14.167v1.666H2.5V12.5H.833v-10a.833.833 0 01.833-.833z"
        fill="#67667B"
      />
    </svg>
  );
}

export default SvgDiscuss;
