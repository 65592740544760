import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: -48px -24px auto;

  z-index: 1;
`;

export const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: auto;
  overflow: hidden;

  position: relative;
  padding-bottom: 56.25%;
  margin-bottom: 24px;

  & > div iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const DescriptionContainer = styled.div`
  padding: 0px 24px;
  width: 100%;
  box-sizing: border-box;

  & > p {
    max-width: calc(100vw - 430px);
    overflow-wrap: break-word;
  }
`;

export const AuthorContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

  & > div:first-child {
    margin-right: 8px;
  }

  &:hover {
    & > p {
      text-decoration: underline;
    }
  }
`;

export const ArrowCSS = css`
  cursor: pointer;
`;
