import React from 'react';
import { Spinner } from 'UI';
import { Container } from './styled';

function SpinnerContainer() {
  return (
    <Container>
      <Spinner $size={40} />
    </Container>
  );
}

export default SpinnerContainer;
