import styled from 'styled-components';

export const Container = styled.main<{ $url: string }>`
  position: absolute;
  box-sizing: border-box;
  min-height: 100%;
  width: 100%;

  background: linear-gradient(
      to right,
      rgba(25, 7, 66, 0.64),
      rgba(25, 7, 66, 0.64)
    ),
    url(${({ $url }) => $url});
  backdrop-filter: blur(12px);

  background-size: cover;

  width: 100%;

  padding: 120px 30px 30px 30px;
`;

export const HeaderContainer = styled.header`
  color: white;
  text-align: center;

  & > h1 {
    margin-bottom: 10px;

    font-weight: bold;
    font-size: 54px;
    line-height: 68px;
  }

  & > h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }

  margin-bottom: 34px;
`;

export const ToggleContainer = styled.div`
  & > p {
    color: white;
  }

  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto 56px auto;
  grid-gap: 16px;

  margin-bottom: 34px;
`;

export const PlansContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
