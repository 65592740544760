import styled from 'styled-components';

export const Container = styled.div`
  min-height: 502px;
  max-width: 440px;
  min-width: calc(33% - 60px);

  background: #ffffff;
  box-shadow: 0px 24px 42px rgba(148, 148, 148, 0.12);
  border-radius: 28px;

  padding: 60px 32px 28px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;

  margin-bottom: 24px;
`;

export const PriceContainer = styled.div`
  margin-bottom: 34px;
`;

export const PopularTextContainer = styled.div`
  background: #9b51e0;
  border-radius: 16px;

  text-align: center;
  width: 80px;
  padding: 8px;
  box-sizing: border-box;

  color: white;

  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  position: absolute;
  right: 28px;
  top: 28px;
`;

export const YearOverlineText = styled.p`
  display: inline;
  font-weight: normal;
  font-size: 18px;
  line-height: 44px;
  color: #777777;

  position: relative;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    border-top: 2px solid;
    border-color: inherit;

    -webkit-transform: rotate(-8deg);
    -moz-transform: rotate(-8deg);
    -ms-transform: rotate(-8deg);
    -o-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }
`;
