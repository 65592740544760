import React, { FC } from 'react';
import { Route as ReactRouterRoute, Switch, } from 'react-router-dom';

export interface MyRouteProps {
  layout?: any;
  layoutProps?: { withBack?: boolean; [key: string]: any };
  component?: any;
  children?: any;
  [key: string]: any;
}

const PassThrough: FC = ({ children }) => <>{children}</>;

export const Route: FC<MyRouteProps> = ({
  layout: Layout = PassThrough,
  layoutProps = {},
  component: Component = PassThrough,
  children,
  ...rest
}) => {
  return (
    <ReactRouterRoute
      {...rest}
      render={(props) => (
        <Layout {...props} {...layoutProps}>
          <Component {...props}>
            <Switch>{children}</Switch>
          </Component>
        </Layout>
      )}
    />
  );
};

export default Route;
