import React from 'react';
import { toast, ToastOptions, Bounce } from 'react-toastify';
import { CircleButton, Toast } from 'UI';
import { CloseEx, Error, Info, Message, Success, Warn } from 'assets/svg';
import { ButtonClose } from 'UI/Toast/styled';
import { colors } from 'styled';

const ButtonCircleClose = () => {
  return (
    <ButtonClose>
      <CircleButton color={colors.secondaryPink} size={24}>
        <CloseEx />
      </CircleButton>
    </ButtonClose>
  );
};

const toastOptions: ToastOptions = {
  position: 'bottom-left',
  autoClose: 1500,
  hideProgressBar: true,
  pauseOnHover: true,
  draggable: true,
  transition: Bounce,
  closeButton: ButtonCircleClose,
};

const getToast = (
  type: string,
  content: string,
  title?: string,
  onClick?: () => void
) => {
  const iconMap = {
    success: <Success />,
    info: <Info />,
    error: <Error />,
    warn: <Warn />,
    message: <Message />,
  };

  return (
    <Toast
      title={title}
      message={content}
      IconComponent={iconMap[type]}
      onClick={onClick}
    />
  );
};

class ToastService {
  success = (content: string, title?: string) =>
    toast.success(getToast('success', content, title), toastOptions);

  info = (content: string, title = 'Information') =>
    toast.info(getToast('info', content, title), toastOptions);

  error = (content = 'Something went wrong', title = 'Error') =>
    toast.error(getToast('error', content, title), toastOptions);

  warn = (content: string, title = 'Warning!') =>
    toast.warn(getToast('info', content, title), toastOptions);

  message = (content: string, title?: string, onClick?: () => void) =>
    toast.success(getToast('message', content, title, onClick), toastOptions);
}

export default new ToastService();
