import { FlexCenter, ternarOperator } from 'styled';
import styled, { CSSProp } from 'styled-components';

export const Containter = styled.div<{ $width?: number; $CSS?: CSSProp }>`
  border: 1px solid #e1e0e5;
  border-radius: 12px;

  width: ${({ $width }) => ($width ? `${$width}px` : '100%')};

  display: flex;
  justify-content: space-between;

  ${({ $CSS }) => $CSS}
`;

const getPadding = (padding?: number) => {
  return padding ? `${padding}px` : '18px';
};

export const TabContainer = styled(FlexCenter)<{
  $isActive: boolean;
  $verticalPadding?: number;
  $CSS?: CSSProp;
}>`
  cursor: pointer;
  width: 100%;

  padding-top: ${({ $verticalPadding }) => getPadding($verticalPadding)};
  padding-bottom: ${({ $verticalPadding }) => getPadding($verticalPadding)};

  border-radius: 12px;

  background-color: ${({ $isActive }) =>
    ternarOperator($isActive, '#D555B1', 'white')};

  box-shadow: ${({ $isActive }) =>
    ternarOperator($isActive, '0px 8px 25px rgba(212, 85, 177, 0.28)', 'auto')};

  border-color: ${({ $isActive }) =>
    ternarOperator($isActive, '#D555B1', 'transparent')};

  margin: ${({ $isActive }) => ternarOperator($isActive, '-1px', '0px')};
  z-index: ${({ $isActive }) => ternarOperator($isActive, '2', '1')};

  transition: background-color 0.2s;
  s & > p {
    color: ${({ $isActive }) => ($isActive ? 'white' : '#67667B')};
  }

  ${({ $CSS }) => $CSS}
`;
