import React, { useCallback, useMemo, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Camera, CloseEx } from 'assets/svg';
import { convertFileToBase } from 'utils';
import {
  LoadButton,
  RootBlock,
  AnnotationText,
  ImageBlock,
  LoadBlock,
  CircleButton,
  ImageContainer,
  Image,
} from './styles';
import Toast from 'services/Toast';
import { getLoadFileErrorMap } from '@constants';
import { CropModal } from 'components';

type Props<FieldValue> = FieldRenderProps<FieldValue> & {
  maxSize?: number;
  accept?: string[];
  cropRatio?: number;
};

const EmptyImage = () => (
  <ImageContainer>
    <Camera style={{ transform: 'scale(0.75)' }} />
  </ImageContainer>
);

function LoadImageField({ input, maxSize, accept, cropRatio }: Props<string>) {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);

  const textBtn = {
    newPhoto: 'Upload photo',
    replacePhoto: 'Upload new photo',
  };

  const maxMegaBytes = Math.floor(maxSize ?? 0 / (1024 * 1024));

  const errorMap = useMemo(() => {
    return getLoadFileErrorMap({ maxMegaBytes, accept });
  }, [maxMegaBytes, accept]);

  const onDrop = useCallback(
    (acceptedFiles: File[], rejections: FileRejection[]) => {
      if (acceptedFiles.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          setImageSrc(reader.result as string)
        );
        reader.readAsDataURL(acceptedFiles[0]);

        if ((maxSize && maxSize >= acceptedFiles[0].size) || !maxSize) {
          convertFileToBase(acceptedFiles[0]).then((res) =>
            input.onChange(res)
          );
          setIsCropModalOpen(true);
        }
      }

      if (rejections) {
        rejections.forEach((e) => {
          Toast.error(errorMap[e.errors[0].code], `File: ${e.file.name}`);
        });
      }
    },
    [input]
  );

  const avatarContent = (url?: string) =>
    url ? (
      <>
        <Image src={url} />
        <CircleButton
          color="#D555B1"
          size={36}
          type="button"
          onClick={() => input.onChange(undefined)}
        >
          <CloseEx />
        </CircleButton>
      </>
    ) : (
      <EmptyImage />
    );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize,
    accept,
  });
  return (
    <RootBlock>
      {imageSrc && (
        <CropModal
          isOpen={isCropModalOpen}
          onClose={() => setIsCropModalOpen(false)}
          image={imageSrc}
          ratio={cropRatio}
          onCrop={(image) => {
            input.onChange(image);
          }}
        />
      )}
      <ImageBlock>{avatarContent(input.value)}</ImageBlock>
      <LoadBlock>
        <AnnotationText color="#67667b">
          JPG, GIF or PNG. The maximum file size is 15 MB.
        </AnnotationText>
        <LoadButton {...getRootProps()}>
          {input.value ? textBtn.replacePhoto : textBtn.newPhoto}
          <input {...getInputProps()} />
        </LoadButton>
      </LoadBlock>
    </RootBlock>
  );
}

export default LoadImageField;
