import React, { useEffect, useState } from 'react';
import { DEFAULT_AVATAR, ROUTE_PATH } from '@constants';
import { ConfirmModal, SpinnerContainer } from 'components';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { actions, selectors } from 'store/ducks';
import { BodyThree } from 'styled';
import { Room } from './components';
import { Container, NoChatsContainer } from './styled';
import { ChatRoom } from 'types';
import { ChatRoomsEmpty } from 'assets/svg';
import { Button } from 'UI';
import { AppDispatch } from 'store';

const getChatRoomReadStatus = (room: ChatRoom, userId: string): boolean => {
  const currentUserParticipant = room.participants.find(
    (el) => el.userId === userId
  )!;

  const isRoomHaveLastMessage = Boolean(room.lastMessage);
  if (!isRoomHaveLastMessage) {
    return false;
  }

  const isUserReadLastMessage =
    currentUserParticipant.lastReadMessageId === room.lastMessage.id;
  if (isUserReadLastMessage) {
    return false;
  }

  const isUserSendLastMessage =
    room.lastMessage.user.id === currentUserParticipant.userId;
  if (isUserSendLastMessage) {
    return false;
  }

  return true;
};

const getChatRoomLastMessage = (room: ChatRoom): string => {
  let lastMessage = 'No messages yet';

  if (room.lastMessage?.body) {
    lastMessage = room.lastMessage.body;
  }

  if (
    room.lastMessage?.attachments &&
    room.lastMessage?.attachments.length > 0
  ) {
    const lastAttachment = room.lastMessage.attachments.slice(-1)[0];
    lastMessage = lastAttachment.attachmentUrl;
  }

  return lastMessage;
};

function Messages() {
  const dispatch = useDispatch<AppDispatch>();
  const userId = useSelector(selectors.auth.getUserId);
  const history = useHistory();

  const isFetchLoading = useSelector(selectors.chatRooms.selectIsFetchLoading);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [roomIdToDelete, setRoomIdToDelete] = useState<string | null>(null);

  useEffect(() => {
    dispatch(actions.chatRooms.fetchChatRooms());
  }, []);

  const chatRooms = useSelector(selectors.chatRooms.selectAll);

  const handleDelete = (id: string) => {
    setRoomIdToDelete(id);
    setIsDeleteModalOpen(true);
  };

  const submitDelete = async () => {
    if (roomIdToDelete) {
      dispatch(actions.chatRooms.removeChatRoom(roomIdToDelete));
      setRoomIdToDelete(null);
      setIsDeleteModalOpen(false);
    }
  };

  if (isFetchLoading) {
    return <SpinnerContainer />;
  }

  if (chatRooms.length === 0) {
    return (
      <NoChatsContainer>
        <ChatRoomsEmpty />
        <BodyThree $marginBottom={70}>
          No chats yet. Find a client to start messaging
        </BodyThree>
        <Button
          width="435px"
          text="Search for a client"
          onClick={() => history.push(ROUTE_PATH.clients)}
        />
      </NoChatsContainer>
    );
  }

  const sortedChatRooms = chatRooms.sort(
    (a, b) => dayjs(b.updatedAt).unix() - dayjs(a.updatedAt).unix()
  );

  return (
    <Container>
      <ConfirmModal
        title="Delete chat"
        description="Do you really want to delete this chat?"
        agreeText="Delete"
        disagreeText="Cancel"
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onAgree={submitDelete}
      />
      {sortedChatRooms.map((room) => {
        const { user: recipient } = room.participants.find(
          (el) => el.userId !== userId
        )!;

        const isRoomUnread = getChatRoomReadStatus(room, userId);

        const lastMessage = getChatRoomLastMessage(room);

        return (
          <Room
            lastMessage={lastMessage}
            updatedAt={room.updatedAt}
            userAvatarUrl={recipient.avatarUrl || DEFAULT_AVATAR}
            userName={`${recipient.firstName} ${recipient.lastName}`}
            isCurrentUserLastMessage={room.lastMessage?.user.id === userId}
            onClick={() => history.push(ROUTE_PATH.chatRoom(`${room.id}`))}
            onDelete={() => handleDelete(`${room.id}`)}
            isUnread={isRoomUnread}
            key={room.id}
          />
        );
      })}
    </Container>
  );
}

export default Messages;
