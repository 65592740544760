import { LoadVideoField, TextAreaField, TextField } from 'components';
import ModalWrapper from 'components/ModalWrapper';
import { useAsyncCallback } from 'hooks';
import { ModalWithForm } from 'interfaces/common';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FlexStart } from 'styled';
import { Button } from 'UI';
import {
  composeValidators,
  isRequired,
  isRequiredLength,
} from 'utils/validation';
import { FieldsContainer } from './styled';

type FormValues = {
  link: string;
  name: string;
  description: string;
};

type Props = ModalWithForm<FormValues>;

function UploadVideoModal({ onSubmit, ...rest }: Props) {
  const [asyncSubmit, isLoading] = useAsyncCallback(onSubmit);

  if (!onSubmit) {
    return null;
  }

  return (
    <ModalWrapper title="Upload video" width={1062} {...rest}>
      <Form
        onSubmit={asyncSubmit}
        render={({ handleSubmit, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            <FieldsContainer>
              <Field
                name="link"
                validate={isRequired()}
                component={LoadVideoField}
              />
              <div>
                <Field
                  name="name"
                  validate={composeValidators(
                    isRequired(),
                    isRequiredLength(100, 1)
                  )}
                  label="Name"
                  component={TextField}
                />
                <Field
                  name="description"
                  validate={isRequired()}
                  label="Description"
                  component={TextAreaField}
                />
              </div>
            </FieldsContainer>
            <FlexStart>
              <Button
                type="submit"
                text="Upload video"
                theme="primary"
                $margin="0px 12px 0px 0px"
                isLoading={isLoading}
                disabled={hasValidationErrors}
              />
              <Button
                type="button"
                text="Cancel"
                theme="secondary"
                onClick={rest.onClose}
                disabled={isLoading}
              />
            </FlexStart>
          </form>
        )}
      />
    </ModalWrapper>
  );
}

export default UploadVideoModal;
