import { FlexCenter } from 'styled';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #e1e0e5;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;

  & > div:not(:last-child) {
    margin-right: 24px;
    margin-bottom: 0;
  }

  & > div:first-child input {
    padding-top: 16px;
    padding-bottom: 18px;
  }

  & > div:first-child {
    min-width: 411px;
  }
`;

export const ButtonsContainer = styled(FlexCenter)`
  height: 56px;

  & i {
    margin-right: 30px;
  }

  & svg {
    cursor: pointer;

    min-width: 18px;
    min-height: 18px;
  }

  & svg > path {
    fill: #67667b;
  }
`;
