import { CloseEx } from 'assets/svg';
import React from 'react';
import { SubheadTwo } from 'styled';
import { ProgressBar } from 'UI';
import { Container } from './styled';

type Props = {
  name: string;
  progress: number;

  onCancel: () => void;
};

function LoadingAttachment({ name, progress, onCancel }: Props) {
  return (
    <Container>
      <ProgressBar width={150} progress={progress} />
      <SubheadTwo $color="#67667B" $margin="0 12px">
        {name}
      </SubheadTwo>
      <CloseEx onClick={onCancel} />
    </Container>
  );
}

export default LoadingAttachment;
