import * as React from 'react';

function SvgSendSubmit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.126 2.547c-.229-.5-.027-.696.47-.425l17.665 9.63c.49.268.48.69 0 .936L3.578 21.706c-.49.25-.71.04-.496-.466l3.468-8.143 9.944-1.062L6.65 10.27 3.126 2.547z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgSendSubmit;
