import * as React from 'react';

function SvgProgramsEmpty(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={300}
      height={300}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M150 87.5a100 100 0 110 200 100 100 0 010-200zm0 43.75l-16.537 33.5-36.963 5.375 26.75 26.063-6.313 36.825L150 215.625 183.062 233l-6.312-36.812 26.75-26.075-36.963-5.375L150 131.25zm12.5-106.262L225 25v37.5l-17.038 14.225A124.186 124.186 0 00162.5 63.113V24.988zm-25 0v38.125a124.186 124.186 0 00-45.45 13.6L75 62.5V25l62.5-.012z"
        fill="#F2F7FF"
      />
    </svg>
  );
}

export default SvgProgramsEmpty;
