import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectors } from 'store/ducks/auth';
import { Spinner } from 'UI';
import { FlexCenter } from 'styled';

function HomePage() {
  const user = useSelector(selectors.getUserInfo);
  console.log(user);
  const history = useHistory();
  useEffect(() => {
    if (user.createdAt === user.updatedAt && user.createdAt !== '') {
      history.push('/create-account');
    } else if (user.createdAt !== '') {
      history.push('/calendar');
    }
  }, [user]);
  return (
    <FlexCenter
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <Spinner $size={60} />
    </FlexCenter>
  );
}

export default HomePage;
