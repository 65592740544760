import { SubheadOne } from 'styled';
import styled from 'styled-components';

export const StyledTextButton = styled(SubheadOne)`
  background-color: transparent;
  outline: none;

  cursor: pointer;

  transition: opacity 0.2s;
  &:hover {
    opacity: 0.7;
  }
`;
