import styled from 'styled-components';

export const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    flex-basis: calc(50% - 8px);
  }

  & > div:not(:last-child):not(:nth-last-child(2)) {
    margin-bottom: 8px;
  }

  & > div:nth-child(2n + 1) {
    padding-right: 8px;
  }

  & > div:nth-child(2n) {
    padding-left: 8px;
  }

  margin-bottom: 16px;
`;
