import React, { ForwardRefRenderFunction, HTMLAttributes, UIEventHandler, forwardRef } from 'react';
import styled, { CSSProp, css } from 'styled-components';
/* eslint-disable react/prop-types */
type BackgroundType = 'white' | 'gray';

type ScrollbarContainerProps = {
  backgroundType?: BackgroundType;
  onScroll?: UIEventHandler<HTMLDivElement>;
  mainCSS?: CSSProp;
  isHorisontal?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const ScrollbarContainer: ForwardRefRenderFunction<HTMLDivElement, ScrollbarContainerProps> = (
  { backgroundType = 'white', mainCSS, children, isHorisontal = false, ...props },
  ref,
) => {
  return (
    <Container {...props} $mainCSS={mainCSS} $isHorisontal={isHorisontal} $backgroundType={backgroundType} ref={ref}>
      {children}
    </Container>
  );
};

export default forwardRef<HTMLDivElement, ScrollbarContainerProps>(ScrollbarContainer);

const Container = styled.div<{ $backgroundType: BackgroundType; $mainCSS?: CSSProp; $isHorisontal?: boolean }>`
  ${({ $isHorisontal }) => css`
    ${$isHorisontal ? 'overflow-x: scroll;' : 'overflow-y: scroll;'}
  `};

  ${({ $mainCSS }) => $mainCSS}
`;