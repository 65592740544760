import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { TextInput } from 'UI';
import { TextInputBaseProps } from 'UI/types';

const passThrough = (val: any) => val;

type Props = TextInputBaseProps &
  FieldRenderProps<string, HTMLInputElement> & {
    validateValue?: (value: string) => boolean;
    format?: (value: string) => string;
  };

function TextField({
  input,
  meta,
  validateValue,
  format = passThrough,
  ...rest
}: Props) {
  const { onChange, ...restInput } = input;
  const error = meta.touched && (meta.error || meta.submitError);

  const handleChange = (value: string) => {
    const isValid = validateValue ? validateValue(value) : true;

    if (!isValid) {
      return;
    }

    const formattedValue = format(value);
    onChange(formattedValue);
  };

  return (
    <TextInput
      {...{ ...restInput, ...rest }}
      onChange={(e) => handleChange(e.target.value)}
      error={error}
    />
  );
}

export default TextField;
