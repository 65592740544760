import React from 'react'
import { ImageField, ModalWrapper, TextField } from 'components'
import arrayMutators from 'final-form-arrays'
import { useAsyncCallback } from 'hooks'
import { ModalWithForm } from 'interfaces/common'
import { useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { TitleFive } from 'styled'
import { Exercise, Workout } from 'types'
import { Button } from 'UI'
import { isRequired } from 'utils/validation'
import { ExercisesList, WorkoutRoutine } from './components'
import { FormExercise } from './components/WorkoutRoutine/types'
import {
	DescriptionTextCSS,
	ExercisesContainer,
	FormBodyContainer,
	HeadContainer,
	HeadInputsContainer,
	NameTextCSS,
	SubmitButton,
} from './styled'

type FormValues = {
	name: string
	description?: string
	image: string | undefined;
	workoutExercises: FormExercise[]
	notes: Record<string, string>
}

export type Props = ModalWithForm<FormValues> & {
	disabled?: boolean
	initialEntity?: Workout

	onDelete?: () => void
}

const fieldArrayDefaultValue = []

function WorkoutModal({
	onSubmit,
	disabled = false,
	initialEntity,
	onDelete,
	...rest
}: Props) {
	const [pickedExercises, setPickedExercises] = useState<Exercise[]>([])

	const [asyncSubmit, isSubmitLoading] = useAsyncCallback(onSubmit)

	const [asyncDelete, isDeleteLoading] = useAsyncCallback(onDelete)

	const handlePickExercise = (exercise: Exercise) => {
		console.log({ exercise })
		if (!disabled) {
			console.log('1')
			setPickedExercises([...pickedExercises, exercise])
		}
	}

	const initialValues = useMemo<FormValues | undefined>(() => {
		if (initialEntity) {
			const exercises = initialEntity.workoutExercises?.map(
				workoutExercise => workoutExercise.exercise
			)
			setPickedExercises(exercises || [])

			return {
				name: initialEntity.name,
				description: initialEntity.description,
				image: initialEntity.imageUrl,
				notes: initialEntity.exercisesNotes || {},
				workoutExercises:
					initialEntity.workoutExercises?.map(workoutExercise => ({
						id: workoutExercise.id,
						exerciseId: workoutExercise.exerciseId,
						index: workoutExercise.order,
						trackingFields: workoutExercise.exercise.trackingFields,
						trackingFieldsValues: workoutExercise.trackingFieldsValues,
					})) || [],
			}
		}
	}, [])

	const isEditModal = Boolean(initialValues)

	return (
		<ModalWrapper
			width={1270}
			isBigModal
			withCloseOnClickOutside={false}
			{...rest}
		>
			<Form<FormValues>
				onSubmit={asyncSubmit}
				mutators={{ ...arrayMutators }}
				initialValues={initialValues}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<HeadContainer>
							<Field
								name='image'
								// withButton={false}
								disabled={disabled}
								component={ImageField}
							/>
							<HeadInputsContainer>
								<Field
									name='name'
									theme='transparent'
									maxLength={100}
									placeholder='Your workout name...'
									validate={isRequired()}
									inputCSS={NameTextCSS}
									disabled={disabled}
									component={TextField}
								/>
								<Field
									name='description'
									theme='transparent'
									placeholder='Description...'
									inputCSS={DescriptionTextCSS}
									disabled={disabled}
									component={TextField}
								/>
							</HeadInputsContainer>
						</HeadContainer>
						<FormBodyContainer>
							<ExercisesContainer>
								<TitleFive $marginBottom={20}>Exercises</TitleFive>
								<ExercisesList onPickExercise={handlePickExercise} />
							</ExercisesContainer>
							<FieldArray
								name='workoutExercises'
								defaultValue={fieldArrayDefaultValue}
								pickedExercises={pickedExercises}
								disabled={disabled}
								component={WorkoutRoutine}
							/>
						</FormBodyContainer>
						<SubmitButton>
							<Button
								text='Save'
								disabled={false}
								type='submit'
								isLoading={isSubmitLoading}
							/>
							{isEditModal && (
								<Button
									text='Delete'
									theme='delete'
									disabled={disabled || isSubmitLoading}
									onClick={asyncDelete}
									isLoading={isDeleteLoading}
									type='button'
								/>
							)}
						</SubmitButton>
					</form>
				)}
			/>
		</ModalWrapper>
	)
}

export default WorkoutModal
