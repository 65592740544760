import styled from 'styled-components';

export const RemoveButton = styled.button`
  position: absolute;
  background-color: #895fc6;
  color: white;

  right: -7px;
  top: -7px;

  border-radius: 50%;
  width: 14px;
  height: 14px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  & > svg {
    transform: scale(0.6);
  }
`;
