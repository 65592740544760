import {
  PUSHER_APP_CLUSTER,
  PUSHER_APP_KEY,
  PUSHER_AUTH_ROUTE,
  BASE_URL,
} from 'config';
import Pusher from 'pusher-js';

export class PusherService {
  static pusherInstance: Pusher | null = null;

  constructor(accessToken: string) {
    if (!PusherService.pusherInstance) {
      PusherService.pusherInstance = new Pusher(PUSHER_APP_KEY!, {
        cluster: PUSHER_APP_CLUSTER!,
        forceTLS: true,
        authEndpoint: `${BASE_URL}/api/v1/${PUSHER_AUTH_ROUTE}`,
        auth: { headers: { Authorization: `Bearer ${accessToken}` } },
      });
    }
  }
}
