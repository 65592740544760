import React from 'react';
import { LayoutProps } from 'interfaces/common';
import { Root, Body } from './styled';

function GuestLayout({ children }: LayoutProps) {
  return (
    <Root>
      <Body>{children}</Body>
    </Root>
  );
}

export default GuestLayout;
