import React, { RefObject, useState } from 'react'
import dayjs from 'dayjs'
import { CircleAvatar, SubheadOne, SubheadTwo } from 'styled'
import { RadioButton } from 'UI'
import {
	Container,
	ContentContainer,
	CheckboxContainer,
	AttachmentsContainer,
	ImagesContainer,
	Image,
	DocumentsContainer,
	Document,
	VideoContainer,
} from './styled'
import { getMessageFileTypeFromUrl, parseShareVideoUrl } from 'utils'
import { Attachment, CloseSq, PlayVideo } from 'assets/svg'
import ReactModal from 'react-modal'

type Props = {
	body: string
	attachments?: string[]
	avatarUrl: string
	firstName: string
	lastName: string
	createdAt: string

	isSelected?: boolean

	onClick: () => void
	onOpenVideo: (id: string) => void

	innerRef?: RefObject<HTMLDivElement>
}

function Message({
	body,
	attachments = [],
	avatarUrl,
	firstName,
	lastName,
	createdAt,
	isSelected = false,
	onClick,
	onOpenVideo,
	innerRef,
}: Props) {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [selectedImage, setSelectedImage] = useState('')

	const openImageModal = (imageUrl: string) => {
		setSelectedImage(imageUrl)
		setIsModalOpen(true)
	}

	const closeModal = () => {
		setIsModalOpen(false)
		setSelectedImage('')
	}

	const renderAttachments = () => {
		const images = attachments.filter(
			attach => getMessageFileTypeFromUrl(attach) === 'image'
		)
		const documents = attachments.filter(
			attach => getMessageFileTypeFromUrl(attach) === 'document'
		)

		const videos = attachments.filter(
			attach => getMessageFileTypeFromUrl(attach) === 'video'
		)
		const video = videos.length > 0 && parseShareVideoUrl(videos[0])

		return (
			<React.Fragment>
				{video && (
					<VideoContainer
						$imageUrl={video.previewUrl}
						onClick={e => {
							e.stopPropagation()
							e.preventDefault()
							onOpenVideo(video.id)
						}}
					>
						<PlayVideo />
					</VideoContainer>
				)}
				<ImagesContainer>
					{images.map(el => (
						<Image
							src={el}
							key={el}
							onClick={e => {
								e.stopPropagation()
								openImageModal(el)
							}}
						/>
					))}
				</ImagesContainer>
				<DocumentsContainer>
					{documents.map(el => {
						const decodedUrl = decodeURI(el)
						const fileName = decodedUrl.split('|').slice(-1)[0]

						return (
							<Document key={el}>
								<Attachment />
								<SubheadTwo
									$color='#67667B'
									as='a'
									href={el}
									onClick={e => e.stopPropagation()}
								>
									{fileName}
								</SubheadTwo>
							</Document>
						)
					})}
				</DocumentsContainer>
			</React.Fragment>
		)
	}

	return (
		<Container $isSelected={isSelected} onClick={onClick} ref={innerRef}>
			<ContentContainer>
				<CircleAvatar $url={avatarUrl} $size={46} $marginRight={12} />
				<div>
					<SubheadOne
						$marginBottom={8}
					>{`${firstName} ${lastName}`}</SubheadOne>
					<SubheadTwo $color='#67667B'>{body}</SubheadTwo>
					{attachments.length > 0 && (
						<AttachmentsContainer>{renderAttachments()}</AttachmentsContainer>
					)}
				</div>
			</ContentContainer>
			<SubheadTwo>{dayjs(createdAt).format('hh:mm')}</SubheadTwo>
			{isSelected && (
				<CheckboxContainer>
					<RadioButton isActive={isSelected} />
				</CheckboxContainer>
			)}
			<ReactModal
				isOpen={isModalOpen}
				onRequestClose={closeModal}
				contentLabel='Image Modal'
				style={{
					content: {
						padding: 0,
						border: 'none',
						background: 'none',
						// margin: '50% auto',
					},
				}}
			>
				<CloseSq
					onClick={closeModal}
					style={{ position: 'absolute', cursor: 'pointer' }}
				/>
				<img
					src={selectedImage}
					alt='Attachment'
					style={{
						width: '100%',
						height: '100%',
					}}
				/>
			</ReactModal>
		</Container>
	)
}

export default Message
