import styled, { css } from 'styled-components';
import { IconButton } from 'UI';

export const SubmitButton = styled(IconButton)`
  padding: 14px;
`;

export const AttachmentsButton = styled.div`
  position: relative;
  margin-right: 12px;

  & svg {
    cursor: pointer;
  }
`;

export const InputCSS = css`
  padding-left: 56px;
`;

export const WrapperCSS = css`
  padding-bottom: 0;
`;

export const AttachmentsTypesButtons = styled.div`
  position: absolute;
  bottom: 45px;
  left: -20px;

  padding: 16px;

  width: 138px;
  box-sizing: border-box;

  background-color: white;
  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 8px;
`;

export const AttachmentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  cursor: pointer;

  & > svg {
    margin-right: 10px;
  }

  &:hover {
    & {
      text-decoration: underline;
    }
  }

  &:not(:last-child) {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #e1e0e5;
  }
`;
