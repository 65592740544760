import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { TextArea } from 'UI';
import { TextAreaBaseProps } from 'UI/types';

type Props = TextAreaBaseProps & FieldRenderProps<string, HTMLTextAreaElement>;

function TextAreaField({ input, meta, ...rest }: Props) {
  const error = meta.touched && (meta.error || meta.submitError);

  return <TextArea {...{ ...input, ...rest }} error={error} />;
}

export default TextAreaField;
