import * as React from 'react';

function SvgFile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={28}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.5 10.5h7a1.167 1.167 0 001.167-1.166v-7h11.669c.643 0 1.164.53 1.164 1.157v21.018a1.159 1.159 0 01-1.159 1.158H4.66A1.166 1.166 0 013.5 24.492V10.5zm0-2.333l5.833-5.83v5.83H3.5z"
        fill="#895FC6"
        opacity={0.8}
      />
    </svg>
  );
}

export default SvgFile;
