import {
  fileFormats,
  FITNESS_APP_VIDEO_PREFIX,
  imageFormats,
  videoFormats,
} from '@constants';
import { CancelToken } from 'axios';
import { getUploadUrl, uploadFile } from 'services/api';
import { UpdateCoachDto } from 'types';
import { v4 } from 'uuid';

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function buildQuery(query: { [key: string]: string }) {
  const keys = Object.keys(query);
  const queryString = keys.reduce((final, cur, idx) => {
    return final.concat(
      `${cur}=${query[cur]}`,
      idx !== keys.length - 1 ? '&' : ''
    );
  }, '?');
  return queryString;
}

export const setValuesUpdateCoach = (
  values: UpdateCoachDto
): UpdateCoachDto => {
  return {
    firstName: values.firstName || null,
    avatarUrl: values.avatarUrl || null,
    description: values.description || null,
    lastName: values.lastName || null,
    experience: values.experience ? +values.experience : undefined,
  };
};

export const uploadBaseS3 = async (
  base: string,
  fileName: string,
  callback?: (progress: number) => void,
  cancelToken?: CancelToken
) => {
  const file = await convertBaseToFile(base, fileName);

  return uploadFileS3(file, callback, cancelToken);
};

export const uploadBaseS3Bulk = async (
  bases: string[],
  fileName: string,
  callback?: (progress: number) => void,
  cancelToken?: CancelToken
) => {
  if (bases.length === 0) {
    return [];
  }

  const files = await Promise.all(
    bases.map(async (base) => convertBaseToFile(base, fileName))
  );

  const urls = await Promise.all(
    files.map(async (file) => uploadFileS3(file, callback, cancelToken))
  );

  return urls;
};

export const uploadFileS3 = async (
  file: File,
  callback?: (progress: number) => void,
  cancelToken?: CancelToken
) => {
  const id = v4();
  const fileKey = id.concat(`|${file.name}`);

  const { data: uploadUrl } = await getUploadUrl({
    fileKey,
    contentType: file.type,
  });

  await uploadFile(uploadUrl, file, callback, cancelToken);

  return uploadUrl.match(/.{0,}[?]/g)![0].replace('?', '');
};

export const convertFileToBase = async (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const convertBaseToFile = async (
  base: string,
  fileName: string
): Promise<File> => {
  const mime = base.match(/:(.*?);/);
  const type = (mime && mime[0].slice(1, mime[0].length - 1)) || '*';

  return fetch(base)
    .then((res) => res.blob())
    .then((blob) => new File([blob], fileName, { type }));
};

type MessageFileType = 'text' | 'image' | 'document' | 'video';

export const getMessageFileTypeFromUrl = (url: string): MessageFileType => {
  const lowerCase = decodeURI(url.toLowerCase());

  if (
    videoFormats.find((format) => lowerCase.includes(format)) ||
    lowerCase.includes(FITNESS_APP_VIDEO_PREFIX)
  ) {
    return 'video';
  }
  if (imageFormats.find((format) => lowerCase.includes(format))) {
    return 'image';
  }
  if (fileFormats.find((format) => lowerCase.includes(format))) {
    return 'document';
  }
  return 'text';
};

export function uniqueArray<T>(array: T[], compare: (a: T, b: T) => boolean) {
  const unique: T[] = [];
  array.forEach((a) => {
    if (unique.find((b) => compare(a, b))) {
      return;
    }
    unique.push(a);
  });
  return unique;
}

export const buildShareVideoUrl = (
  id: string,
  vimeoId: string,
  previewUrl: string
): string => {
  const query = new URLSearchParams(FITNESS_APP_VIDEO_PREFIX);

  query.append('id', id);
  query.append('vimeoId', vimeoId);
  query.append('previewUrl', previewUrl);

  return query.toString();
};

export const parseShareVideoUrl = (
  url: string
): { id: string; vimeoId: string; previewUrl: string } => {
  const query = new URLSearchParams(url);

  const id = query.get('id');
  const vimeoId = query.get('vimeoId');
  const previewUrl = query.get('previewUrl');

  if (id && vimeoId && previewUrl) {
    return { id, vimeoId, previewUrl };
  }

  throw new Error('Invalid share video string');
};

export const capitalizeWord = (word: string) => {
  if (word.length === 0) {
    return word;
  }

  const firstLetter = word[0];
  const rest = word.slice(1, word.length);

  return firstLetter.toUpperCase() + rest;
};
