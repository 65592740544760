import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  cursor: pointer;

  display: flex;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 23px;
  padding-left: 0;
`;

export const FieldWrapperCSS = css`
  position: relative;

  & > div {
    padding-bottom: 0;
    padding-right: 0;
    padding-top: 0;
  }
`;

export const DatepickerCSS = css`
  left: 0px;
  bottom: -16px;

  width: 100%;
  max-width: 390px;
`;
