import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  height: fit-content;

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
`;

export const RootColor = styled.div`
  background: rgba(25, 7, 66, 0.64);
  backdrop-filter: blur(8px);

  flex: 1;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 28px 80px;
`;

export const Logo = styled.a`
  color: #ffffff;
  font-weight: bold;
  font-size: 34px;
  line-height: 44px;
  text-decoration: none;
  cursor: pointer;
`;

export const Main = styled.main`
  display: flex;
  justify-content: center;
`;
