import { FlexStart, JustifyBetween } from 'styled';
import styled, { css } from 'styled-components';

export const Container = styled(FlexStart)`
  align-items: center;

  padding: 16px 24px;
  border-bottom: 1px solid #e1e0e5;
`;

export const LeftIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding-right: 20px;
  margin-right: 20px;

  border-right: 1px solid #e1e0e5;
  width: 45px;
  height: 20px;
  box-sizing: border-box;

  & > svg {
    cursor: pointer;

    &:hover {
      & > path {
        fill: #201e3f;
      }
    }
  }

  & path {
    transition: all 0.2s;
    fill: #79788c;
  }
`;

export const JustifyWrapper = styled(JustifyBetween)`
  width: 100%;
`;

export const RightButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  flex: 1;
`;

export const RecipientNameCSS = css`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const CopyButtonCSS = css`
  padding: 12px;
  background-color: #f2f7ff;

  &:enabled:hover {
    background-color: #f2f7ff;
  }

  & path {
    fill: #67667b;
  }
`;

export const DeleteButtonCSS = css`
  padding: 12px;
  margin-left: 12px;

  background-color: #ffeeeb;

  &:enabled:hover {
    background-color: #ffeeeb;
  }

  & path {
    opacity: 1;
    fill: #ff5934;
  }
`;

export const AlertButtonCSS = css`
  padding: 12px;

  background-color: #f3eff9;

  &:enabled:hover {
    background-color: #f3eff9;
  }
`;
