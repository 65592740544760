import styled from 'styled-components';

export const CropContainer = styled.div`
  & > div {
    width: 100%;

    & > img {
      width: 100%;
      object-fit: cover;
    }
  }

  margin-bottom: 16px;
`;
