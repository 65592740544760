import * as React from 'react';

function SvgImage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 3.75H3v10.5l6.969-6.97a.75.75 0 011.06 0L15 11.258V3.75zM1.5 2.995a.75.75 0 01.744-.745h13.512c.411 0 .744.334.744.745v12.01a.75.75 0 01-.744.745H2.244a.745.745 0 01-.744-.745V2.995zM6 8.25a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
        fill="#67667B"
        opacity={0.3}
      />
    </svg>
  );
}

export default SvgImage;
