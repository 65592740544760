import styled from 'styled-components';

export const ProfileInfoSection = styled.section`
  padding: 24px;
  background: #ffffff;

  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 16px;
`;

export const InfoContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const Info = styled.div`
  flex-grow: 1;
  padding-top: 12px;
  margin-left: 20px;
  overflow: hidden;
`;

export const DescriptionContainer = styled.div`
  margin-top: 24px;

  overflow: hidden;
  max-width: 100%;

  & > p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const ButtonContainer = styled.div`
  padding-top: 12px;
`;
