import styled from 'styled-components';

export const ImageWrapper = styled.div<{ $image }>`
  background-image: url(${({ $image }) => $image});
  background-size: cover;
  background-position: center center;

  min-height: 284px;

  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding: 24px;
  box-sizing: border-box;

  margin-bottom: 24px;
`;

export const BackdropContainer = styled.div`
  padding: 8px 16px;

  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 14px;

  margin-bottom: 12px;
  width: fit-content;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
