import styled from 'styled-components';

export const RightFieldsContainer = styled.div`
  width: 100%;

  margin-left: 24px;

  & > div:not(:last-child) {
    margin-bottom: 8px;
  }
`;
