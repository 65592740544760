import React from 'react';
import { Field } from 'react-final-form';
import { SubheadOne } from 'styled';
import { CreateWorkoutDtoExercise, TrackingFieldsValue } from 'types';
import { FormExercise } from '../../types';
import { trackingFieldNameMapping } from '../../constants';
import {
  Container,
  ExerciseSetContainer,
  TrackingFieldContainer,
  TrackingFieldInputCSS,
} from './styled';
import { MaskedTextField } from 'components';
import { numberRegExp } from 'utils/validation';

type Props = {
  exercises: FormExercise[];
};

export const getTrackingFieldOptions = (field: TrackingFieldsValue) => {
  switch (field) {
    case 'time': {
      return {
        component: MaskedTextField,
        theme: 'transparent',
        inputCSS: TrackingFieldInputCSS,
        type: 'time',
        parse: (value: any) => value || null,
      };
    }
    default:
      return {
        component: MaskedTextField,
        theme: 'transparent',
        placeholder: '10',
        inputCSS: TrackingFieldInputCSS,
        validateValue: (value: string) =>
          !value || numberRegExp.test(value) ? true : false,
        parse: (value: any) => value || null,
      };
  }
};

function ExerciseSets({ exercises }: Props) {
  const renderTrackingFields = (
    trackingFields: CreateWorkoutDtoExercise['trackingFieldsValues'],
    index: number
  ) => {
    if (trackingFields) {
      const activeFields = Object.keys(trackingFields) as TrackingFieldsValue[];

      return activeFields.map((field) => (
        <TrackingFieldContainer key={`${index}${field}`}>
          <SubheadOne>{trackingFieldNameMapping[field]}</SubheadOne>
          <Field
            name={`workoutMeta[${index}].trackingFieldsValues[${field}]`}
            {...getTrackingFieldOptions(field)}
          />
        </TrackingFieldContainer>
      ));
    }

    return null;
  };

  return (
    <Container>
      {exercises.map((exercise) => (
        <ExerciseSetContainer key={exercise.id}>
          {renderTrackingFields(exercise.trackingFieldsValues, exercise.index)}
        </ExerciseSetContainer>
      ))}
    </Container>
  );
}

export default ExerciseSets;
