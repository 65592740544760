import React from 'react';
import { FootnoteTwo, SubheadOne } from 'styled';
import { TextInputBaseProps } from 'UI/types';
import {
  Container,
  ErrorContainer,
  Input,
  InputContainer,
  LeftChildContainer,
  RightChildContainer,
  RightSiblingContainer,
  Wrapper,
} from './styled';

function TextInput({
  theme = 'primary',
  label,
  error,
  disabled,
  rightSibling,
  leftChild,
  rightChild,
  wrapperCSS = { width: '100%' },
  containerCSS = { width: '100%' },
  inputCSS,
  ...rest
}: TextInputBaseProps) {
  return (
    <Wrapper $CSS={wrapperCSS}>
      <Container $CSS={containerCSS}>
        {label && (
          <SubheadOne
            $color={disabled ? '#E1E0E5' : '#201E3F'}
            $marginBottom={8}
          >
            {label}
          </SubheadOne>
        )}
        <InputContainer>
          {leftChild && <LeftChildContainer>{leftChild}</LeftChildContainer>}
          <Input
            {...rest}
            disabled={disabled}
            $theme={theme}
            $withError={Boolean(error)}
            $withLeftChild={Boolean(leftChild)}
            $withRightChild={Boolean(rightChild)}
            $CSS={inputCSS}
          />
          {rightChild && (
            <RightChildContainer>{rightChild}</RightChildContainer>
          )}
        </InputContainer>
        {theme !== 'transparent' && error && (
          <ErrorContainer>
            <FootnoteTwo $color="#FF5934">{error}</FootnoteTwo>
          </ErrorContainer>
        )}
      </Container>
      {rightSibling && (
        <RightSiblingContainer>{rightSibling}</RightSiblingContainer>
      )}
    </Wrapper>
  );
}

export default TextInput;
