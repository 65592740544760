import { Delete, Edit, More } from 'assets/svg';
import { ActionsPopup } from 'components';
import { useOnClickOutside } from 'hooks';
import React, { useRef, useState } from 'react';
import { FlexStart, FootnoteTwo, JustifyBetween, SubheadOne } from 'styled';
import { CSSProp } from 'styled-components';
import { Container, Image, MoreButton } from './styled';

type Props = {
  image: string;
  name: string;
  weeksCount: number;
  workoutsCount: number;

  onClick: () => void;
  onEdit: () => void;
  onDelete: () => void;

  containerCSS?: CSSProp;
};

function ProgramCard({
  image,
  name,
  weeksCount,
  workoutsCount,
  onClick,
  onEdit,
  onDelete,
  containerCSS,
}: Props) {
  const [isButtonsVisible, setIsButtonsVisible] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsButtonsVisible(false));
  return (
    <Container $CSS={containerCSS}>
      <Image $url={image} onClick={onClick} />
      <JustifyBetween $paddingLeft={8} $paddingRight={8}>
        <div>
          <SubheadOne $marginBottom={4}>{name}</SubheadOne>
          <FlexStart>
            <FootnoteTwo $margin="0px 4px 0px 0px">{`${weeksCount} weeks`}</FootnoteTwo>
            <FootnoteTwo $size={35}>&middot;</FootnoteTwo>
            <FootnoteTwo $margin="0px 0px 0px 4px">{`${workoutsCount} workouts`}</FootnoteTwo>
          </FlexStart>
        </div>
        <MoreButton ref={ref} onClick={() => setIsButtonsVisible(true)}>
          <More />
          {isButtonsVisible && (
            <ActionsPopup onEdit={onEdit} onDelete={onDelete} />
          )}
        </MoreButton>
      </JustifyBetween>
    </Container>
  );
}

export default ProgramCard;
