import styled from 'styled-components';

export const ProfileEditSection = styled.section`
  margin-top: 24px;
  padding: 24px;
  background: #ffffff;

  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 14px;
`;

export const FormEditContainer = styled.div`
  max-width: 713px;
`;

export const FieldImageWrapper = styled.div`
  margin-bottom: 24px;
`;

export const FlexFieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 12px;

  & > div {
    width: 48.66%;
    margin-bottom: 0 !important;
  }
`;
