import ModalWrapper from 'components/ModalWrapper';
import { useAsyncCallback } from 'hooks';
import { ModalWithForm } from 'interfaces/common';
import React, { useState } from 'react';
import { BodyThree, FlexStart } from 'styled';
import { ReportType } from 'types';
import { Button, RadioButton } from 'UI';
import { RadioContainer } from './styled';

type Props = ModalWithForm<ReportType>;

function ReportModal({ onSubmit, ...rest }: Props) {
  const [selectedType, setSelectedType] = useState<ReportType>('spam');
  const [asyncSubmit, isLoading] = useAsyncCallback(onSubmit);

  if (!onSubmit) {
    return null;
  }

  const radioTypes: { text: string; type: ReportType }[] = [
    { text: 'Spam', type: 'spam' },
    { text: 'Inappropriate material', type: 'inappropriateMaterial' },
    { text: 'Insults or threats', type: 'harmfulDangerousActions' },
  ];
  return (
    <ModalWrapper title="Report" width={410} {...rest}>
      <div>
        {radioTypes.map((el) => (
          <RadioContainer
            key={el.type}
            onClick={() => setSelectedType(el.type)}
          >
            <RadioButton isActive={selectedType === el.type} />
            <BodyThree $margin="0 0 0 14px">{el.text}</BodyThree>
          </RadioContainer>
        ))}
      </div>
      <FlexStart>
        <Button
          text="Send"
          $margin="0 12px 0 0"
          width="115px"
          isLoading={isLoading}
          onClick={async () => await asyncSubmit(selectedType)}
        />
        <Button
          text="Cancel"
          theme="secondary"
          width="128px"
          disabled={isLoading}
          onClick={rest.onClose}
        />
      </FlexStart>
    </ModalWrapper>
  );
}

export default ReportModal;
