import { BodyThree, JustifyBetween } from 'styled';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  &:first-child {
    margin-right: 10px;
  }
`;

export const Container = styled(JustifyBetween)`
  position: relative;

  min-height: 80px;
  width: 100%;
`;

export const PopupContainer = styled.div`
  z-index: 5;

  position: absolute;
  width: calc(100% - 40px);

  left: 0;
  top: calc(64px + 16px);

  padding: 20px;

  background-color: white;
  box-shadow: 0px 4px 32px rgba(32, 30, 63, 0.1);
  border-radius: 8px;
`;

export const PopupArrowCSS = css`
  position: absolute;
  top: -8px;
`;

export const InputContainer = styled.div`
  position: relative;
  background: #f2f7ff;
  border-radius: 8px;

  padding-left: 40px;
  display: flex;
  align-items: center;

  width: 100%;
  cursor: auto;
`;

export const TopArrowCSS = css`
  position: absolute;
  left: 50px;

  top: 8px;

  cursor: pointer;
`;

export const BottomArrowCSS = css`
  position: absolute;
  left: 51px;

  bottom: 8px;

  cursor: pointer;
`;

export const Input = styled(BodyThree)`
  width: 100%;
  display: flex;

  background-color: transparent;
  border: none;
  outline: none;
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  right: 0;
  height: 100%;

  display: flex;
  flex-direction: column;

  border-radius: 8px;
  overflow: hidden;
`;

export const MeridianButton = styled.button<{ $isActive: boolean }>`
  cursor: pointer;
  padding: 10px 12px;
  outline: none;

  background-color: ${({ $isActive }) => ($isActive ? '#885FC6' : '#E6EFFF')};
  color: ${({ $isActive }) => ($isActive ? 'white' : '#201E3F')};
`;

export const BottomButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-top: 16px;
`;
