import styled from 'styled-components';

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 652px) repeat(2, minmax(98px, 1fr));
  grid-column-gap: 32px;

  justify-content: space-between;

  padding-right: 80px;
`;

export const GridTopRow = styled(GridRow)`
  margin-bottom: 20px;
  justify-content: center;

  & > div:not(:first-child) {
    justify-content: center;
  }
`;

export const WorkoutRowsContainer = styled.div`
  max-height: 400px;
  overflow-y: scroll;

  margin-bottom: 16px;
`;

export const GridDataRow = styled(GridRow)<{ $active: boolean }>`
  position: relative;

  padding-top: 18px;
  padding-bottom: 18px;

  &:not(:last-child) {
    border-bottom: 0.5px solid #000000;
  }

  & > div:not(:first-child) {
    text-align: center;
  }

  background-color: ${({ $active }) => ($active ? '#f3eff9' : 'transparent')};

  cursor: pointer;
`;

export const MoreButtonContainer = styled.div`
  cursor: pointer;

  position: absolute;

  right: 40px;
  top: 50%;
  transform: translateY(-50%);

  & > svg {
    & path {
      fill: #c1c1c1;
    }
  }
`;

export const PopupContainer = styled.div`
  z-index: 3;

  & > div {
    top: 45px;
    right: 40px;
  }
`;
