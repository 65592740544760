import * as React from 'react';

function SvgCamera(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={64}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 8h16l5.334 5.333H56A2.667 2.667 0 0158.667 16v37.333A2.667 2.667 0 0156 56H8a2.667 2.667 0 01-2.667-2.667V16A2.667 2.667 0 018 13.333h10.667L24 8zm8 42.667a16 16 0 100-32 16 16 0 000 32zm0-5.334A10.667 10.667 0 1132 24a10.667 10.667 0 010 21.333z"
        fill="#895FC6"
      />
    </svg>
  );
}

export default SvgCamera;
