import * as React from 'react';

function SvgAlert(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.722 2.5l7.938 13.75a.834.834 0 01-.721 1.25H2.062a.834.834 0 01-.722-1.25L9.278 2.5a.833.833 0 011.444 0zM9.167 13.334V15h1.667v-1.666H9.167zm0-5.834v4.167h1.667V7.5H9.167z"
        fill="#895FC6"
      />
    </svg>
  );
}

export default SvgAlert;
