import React, { useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import StorageService from 'services/Storage';
import { actions } from 'store/ducks';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { AuthorizedRoutes } from './components';
import { selectors } from 'store/ducks';
import { PusherService } from 'services/Pusher';
import { captureException } from '@sentry/minimal';
import Toast from 'services/Toast';
import { AppDispatch } from 'store';

function AppRouter() {
  const dispatch = useDispatch<AppDispatch>();

  const { getAccessTokenSilently, isAuthenticated, logout } = useAuth0();
  useEffect(() => {
    getAccessTokenSilently({
      authorizationParams: {
        audience: `https://${process.env.REACT_APP_DOMAIN}/api/v2/`,
        scope: 'openid profile email read:current_user',
      } 
    })
      .then((res) => {
        StorageService.setItem('accessToken', res);
        dispatch(actions.auth.loginUser());
      })
      .catch((err) => {
        console.log(err);
        captureException(err);
      });
  }, [isAuthenticated]);

  const { role } = useSelector(selectors.auth.getUserInfo);

  if (role && role !== 'coach') {
    logout({clientId: '', logoutParams: {
      returnTo: window.location.origin
    }});
    Toast.error('User is not coach');
  }

  const accessToken = useSelector(selectors.auth.getAccessToken);
  if (accessToken) {
    new PusherService(accessToken);
  }

  useEffect(() => {
    if (accessToken) {
      dispatch(actions.chatRooms.fetchChatRooms());
    }
  }, [accessToken]);

  return (
    <BrowserRouter>
      <Switch>{accessToken && <AuthorizedRoutes />}</Switch>
    </BrowserRouter>
  );
}

export default withAuthenticationRequired(AppRouter);
