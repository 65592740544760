import { captureException } from '@sentry/minimal';
import { Delete, Edit, More } from 'assets/svg';
import { ExerciseCard, SpinnerContainer } from 'components';
import dayjs from 'dayjs';
import { useOnClickOutside } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import Toast from 'services/Toast';
import {
  BodyOne,
  FlexStart,
  FlexEnd,
  FootnoteOne,
  Headline,
  JustifyBetween,
  SubheadTwo,
} from 'styled';
import { WorkoutExercise } from 'types';
import { Arrow } from 'UI';
import {
  Duration,
  OrderNumber,
  Container,
  ButtonsContainer,
  MoreButton,
  ButtonContainer,
  NameCSS,
  ExercisesContainer,
  ArrowCSS,
  NoExercisesContainer,
} from './styled';

type Props = {
  exercises: WorkoutExercise[] | null;
  index: number;
  name: string;

  id: string;

  fetchExercises: () => void;

  onEdit: () => void;
  onDelete: () => void;

  onDeleteExercise: (exerciseTrainingPlanIdToDelete: string) => void;
  onEditExercise: (exerciseTrainingPlanIdToDelete: string) => void;
};

function TrainingPlanCard({
  id,
  exercises,
  index,
  name,
  fetchExercises,
  onEdit,
  onDelete,
  onDeleteExercise,
  onEditExercise,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const [isExercisesLoading, setIsExercisesLoading] = useState(false);
  useEffect(() => {
    if (isOpen && !exercises) {
      const doFetch = async () => {
        try {
          setIsExercisesLoading(true);
          await fetchExercises();
        } catch (error) {
          Toast.error();
          captureException(error);
        } finally {
          setIsExercisesLoading(false);
        }
      };

      doFetch();
    }
  }, [isOpen]);

  const [isButtonsVisible, setIsButtonsVisible] = useState(false);
  const moreButtonRef = useRef(null);
  useOnClickOutside(moreButtonRef, () => setIsButtonsVisible(false));
  const moreButtons = (
    <ButtonsContainer>
      <ButtonContainer onClick={async () => onEdit()}>
        <Edit />
        <SubheadTwo $margin="0px 0px 0px 10px">Edit</SubheadTwo>
      </ButtonContainer>
      <ButtonContainer onClick={onDelete}>
        <Delete />
        <SubheadTwo $margin="0px 0px 0px 10px">Delete</SubheadTwo>
      </ButtonContainer>
    </ButtonsContainer>
  );

  const renderExercises = () => {
    if (exercises) {
      if (exercises.length === 0) {
        return (
          <NoExercisesContainer>
            <Headline>No exercises</Headline>
          </NoExercisesContainer>
        );
      }

      const sortedExercises = exercises.sort((a, b) => a.order - b.order);
      return (
        <ExercisesContainer>
          {sortedExercises.map((el) => (
            <ExerciseCard
              name={el.exercise.name}
              image={''}
              duration={dayjs.duration(666, 'seconds').format('mm:ss')}
              withHover={false}
              onEdit={() => onEditExercise(el.id)}
              onDelete={() => onDeleteExercise(el.id)}
              key={el.id}
            />
          ))}
        </ExercisesContainer>
      );
    }

    return null;
  };

  const handleShowExercises = () => {
    if (!isExercisesLoading) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <Container>
      <JustifyBetween>
        <FlexStart>
          <OrderNumber>
            <Headline>{index}</Headline>
          </OrderNumber>
          <div>
            <BodyOne $CSS={NameCSS} onClick={handleShowExercises}>
              {name}
            </BodyOne>
          </div>
        </FlexStart>
        <FlexEnd>
          <Arrow
            $rotate={isOpen ? 225 : 45}
            $color="#67667B"
            $CSS={ArrowCSS}
            onClick={handleShowExercises}
          />
          <MoreButton
            ref={moreButtonRef}
            onClick={() => setIsButtonsVisible(true)}
          >
            <More />
            {isButtonsVisible && moreButtons}
          </MoreButton>
        </FlexEnd>
      </JustifyBetween>
      {isOpen && (
        <div>
          {isExercisesLoading && <SpinnerContainer />}
          {!isExercisesLoading && renderExercises()}
        </div>
      )}
    </Container>
  );
}

export default TrainingPlanCard;
