import { FlexStart, JustifyBetween, ternarOperator } from 'styled';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  & p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Preview = styled.img<{ $withVideo: boolean }>`
  cursor: ${({ $withVideo }) => ternarOperator($withVideo, 'pointer', 'auto')};

  object-fit: cover;
  width: 100%;
  max-height: 177px;
  min-height: 177px;

  margin-bottom: 18px;
  border-radius: 20px;
`;

export const InfoContainer = styled(JustifyBetween)`
  overflow: hidden;

  align-items: center;

  margin-bottom: 8px;
`;

export const MoreButton = styled.div`
  position: relative;

  & > svg {
    cursor: pointer;
  }

  & path {
    fill: #67667b;
  }
`;

export const ButtonsContainer = styled.div`
  background: #ffffff;

  box-shadow: 0px 4px 8px rgba(34, 32, 65, 0.05),
    0px 0px 4px rgba(34, 32, 65, 0.1);
  border-radius: 8px;

  padding: 18px;

  position: absolute;

  box-sizing: border-box;
  min-width: 138px;
  max-width: 138px;

  right: 10px;
  top: 0px;
`;

export const ButtonContainer = styled(FlexStart)`
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #e1e0e5;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }

  &:hover {
    text-decoration: underline;
  }
`;
