import {
  User,
  UpdateCoachDto,
  Exercise,
  CreateExerciseDto,
  UpdateExerciseDto,
  Program,
  CreateProgramBody,
  UpdateProgramBody,
  CreateCalendarEventBody,
  CalendarEvent,
  ResponseCalendarEventDto,
  CreateWorkoutDto,
  Workout,
  WorkoutExercise,
  UpdateCalendarEventBody,
  RelationUsers,
  CreateRelationUsersDto,
  UpdateRelationUsersDto,
  ChatRoom,
  ChatMessage,
  ChatMessageDto,
  UpdateWorkoutDto,
  LoadFileDto,
  GetManyProgramResponseDto,
  GetManyExerciseResponseDto,
  UpdateWorkoutExerciseDto,
  UpdateLastReadMessageDto,
  CreateReportsUserDto,
  ReportUser,
  VideoHostingDto,
  Video,
  GetManyVideoDto,
  UpdateVideoDto,
  Product,
  CreateCheckoutSessionDto,
  CreateCheckoutSessionResponseDto,
  CreateCustomerPortalSessionDto,
  CreateProgramWorkoutDto,
  ProgramWorkout,
  CreateInviteBodyDto,
  CreateInviteResDto,
  GetManyWorkoutResponseDto,
} from 'types';
import api, { CancelToken } from './AxiosApiService';

type GetCalendarEventsQuery = {
  startSearchDate?: string;
  endSearchDate?: string;
  clientId?: string;
};

type PaginationQuery = {
  limit: number;
  page: number;
  offset?: number;
};

export type NestCrudParams = Partial<PaginationQuery> & {
  join?: string[];
  filter?: string[];
  sort?: string[];
  s?: string;
};

type GetVideosQuery = PaginationQuery & {
  authorId?: string;
  videoName?: string;
};

export const loginUser = async () => {
  return api.post<User>('auth/login');
};

export const deleteUser = async (id: string) => {
  return api.delete(`users/${id}`);
};

export const getExercises = async (params?: NestCrudParams) => {
  return api.get<GetManyExerciseResponseDto>('exercises', { params });
};

export const createExercise = async (dto: CreateExerciseDto) => {
  return api.post<Exercise>('exercises', dto);
};

export const updateExercise = async (id: string, values: UpdateExerciseDto) => {
  return api.patch(`exercises/${id}`, values);
};

export const deleteExercise = async (id: string) => {
  return api.delete(`exercises/${id}`);
};

export const getPrograms = async (params?: PaginationQuery) => {
  return api.get<GetManyProgramResponseDto>('programs/coach', { params });
};

export const getOneProgram = async (id: string) => {
  return api.get<Program>(`programs/${id}`);
};

export const getOneProgramWithWorkouts = async (id) => {
  return api.get<Program>(`programs/${id}/coach`);
};

export const createProgram = (dto: CreateProgramBody) => {
  return api.post<Program>('programs', dto);
};

export const updateProgram = (id: string, dto: UpdateProgramBody) => {
  return api.patch<Program>(`programs/${id}`, dto);
};

export const deleteProgram = (id: string) => {
  return api.delete(`programs/${id}`);
};

export const updateUser = async (body: UpdateCoachDto, id: string) => {
  return api.patch<User>(`users/coach`, body, { params: { coachId: id } });
};

export const getClients = async () => {
  return api.get<User[]>('users/clients');
};

export const getProgramWorkouts = async (programId: string) => {
  return api.get<Workout[]>(`programs/${programId}/workouts`);
};

export const getWorkouts = async (params?: NestCrudParams) => {
  return api.get<Workout[]>('workouts', {
    params,
  });
};

export const getOneWorkout = async (id: string, params?: NestCrudParams) => {
  return api.get<Workout>(`workouts/${id}`, { params });
};

export const createWorkout = async (body: CreateWorkoutDto) => {
  return api.post<Workout>('workouts', body);
};

export const updateWorkout = async (id: string, body: UpdateWorkoutDto) => {
  return api.patch<Workout>(`workouts/${id}`, body);
};

export const deleteWorkout = async (id: string) => {
  return api.delete(`workouts/${id}`);
};

export const createProgramWorkout = async (body: CreateProgramWorkoutDto) => {
  return api.post<ProgramWorkout>('programWorkouts', body);
};

export const getManyUsers = async (query?: any) => {
  return api.get<User[]>('users', { params: query });
};

export const createCalendarEvent = (body: CreateCalendarEventBody) => {
  return api.post<ResponseCalendarEventDto>('calendarEvents', body);
};

export const updateCalendarEvent = (
  id: string,
  body: UpdateCalendarEventBody
) => {
  return api.patch<ResponseCalendarEventDto>(`calendarEvents/${id}`, body);
};

export const getCoachCalendarEvents = (query: GetCalendarEventsQuery) => {
  return api.get<CalendarEvent[]>('calendarEvents/coach', { params: query });
};

export const deleteCalendarEvent = (eventId: string) => {
  return api.delete(`calendarEvents/${eventId}`);
};

export const getExercisesForWorkout = async (workoutId: string) => {
  return api.get<WorkoutExercise[]>(`workouts/${workoutId}/exercises`);
};

export const getOneUser = async (id: string) => {
  return api.get<User>(`users/${id}`);
};

export const getClientsUsers = async (params: {
  clientName: string;
  limit: number;
  page: number;
}) => {
  return await api.get<{
    data: User[];
    count: number;
    page: number;
    pageCount: number;
    total: number;
  }>('users/clients', { params });
};

export const getUserRelations = async () => {
  return api.get<RelationUsers[]>('relationUsers');
};

export const getOneUserRelation = async (userId: string) => {
  return api.get<RelationUsers>(`relationUsers/user/${userId}`);
};

export const createUsersRelation = async (body: CreateRelationUsersDto) => {
  return api.post<RelationUsers>('relationUsers', body);
};

export const updateUsersRelation = async (
  userId: string,
  body: UpdateRelationUsersDto
) => {
  return api.patch<RelationUsers>(`relationUsers/user/${userId}`, body);
};

export const deleteUsersRelation = async (userId: string) => {
  return api.delete(`relationUsers/user/${userId}`);
};

export const initiateChat = async (userId: string) => {
  return api.post<ChatRoom>(`chat/initiate-chat/${userId}`);
};

export const getManyChatRooms = async () => {
  return api.get<{ data: ChatRoom[] }>('chat');
};

export const deleteChatRoom = async (id: string) => {
  return api.delete(`chat/${id}`);
};

export const hideChatRoom = async (id: string) => {
  return api.patch(`chat/${id}/hidden`);
};

export const getOneChatRoom = async (id: string) => {
  return api.get<ChatRoom>(`chat/${id}`);
};

export const getChatRoomMessages = async (
  id: string,
  params?: PaginationQuery
) => {
  return api.get<{
    data: ChatMessage[];
    count: number;
    page: number;
    pageCount: number;
    total: number;
  }>(`chat/${id}/messages`, { params });
};

export const sendMessageChatRoom = async (
  chatRoomId: string,
  body: ChatMessageDto
) => {
  return api.post<ChatMessage>(`chat/send-message/${chatRoomId}`, body);
};

export const sendShareMessage = async (
  userId: string,
  body: ChatMessageDto
) => {
  return api.post<ChatMessage>(`chat/share/${userId}`, body);
};

export const updateLastViewedMessageChatRoom = async (
  chatId: number,
  body: UpdateLastReadMessageDto
) => {
  return api.patch(`chat/${chatId}/last-message`, body);
};

export const updateWorkoutExercise = async (
  workoutExerciseId: string,
  body: UpdateWorkoutExerciseDto
) => {
  return api.patch<WorkoutExercise>(
    `workoutsExercises/${workoutExerciseId}`,
    body
  );
};

export const deleteWorkoutExercise = async (workoutExerciseId: string) => {
  return api.delete(`workoutsExercises/${workoutExerciseId}`);
};

export const deleteProgramWorkout = async (programWorkoutId: string) => {
  return api.delete(`programsWorkouts/${programWorkoutId}`);
};

export const getUploadUrl = async (body: LoadFileDto) => {
  return api.post<string>('sign-url-file', body);
};

export const uploadFile = async (
  url: string,
  values: Blob,
  callback?: (progress: number) => void,
  cancelToken?: CancelToken
) => {
  const file = new Blob([values], { type: values.type });
  return api.put(url, file, {
    headers: {
      'Content-Type': values.type,
      'Access-Control-Allow-Origin': '*',
      Accept: '*/*',
    },
    onUploadProgress: (progressEvent) => {
      const percent = Math.round((progressEvent.loaded * 100) / values.size);
      callback && callback(percent > 100 ? 100 : percent);
    },
    withCredentials: false,
    cancelToken: cancelToken,
  });
};

export const createUserReport = async (body: CreateReportsUserDto) => {
  return api.post<ReportUser>('reportsUser', body);
};

export const getVideos = async (params?: GetVideosQuery) => {
  return api.get<GetManyVideoDto>('videos', { params });
};

export const getVideo = async (id: string) => {
  return api.get<Video>(`videos/${id}`);
};

export const createVideo = async (body: VideoHostingDto) => {
  return api.post<Video>('videos', body);
};

export const updateVideo = async (id: string, body: UpdateVideoDto) => {
  return api.patch<Video>(`videos/${id}`, body);
};

export const deleteVideo = async (id: string) => {
  return api.delete(`videos/${id}`);
};

export const getAllSubscriptionPlans = async () => {
  return api.get<Product[]>('products');
};

export const createCheckoutSession = async (body: CreateCheckoutSessionDto) => {
  return api.post<CreateCheckoutSessionResponseDto>(
    'subscriptions/create-checkout-session',
    body
  );
};

export const createCustomerPortalSession = async (
  body: CreateCustomerPortalSessionDto
) => {
  return api.post<string>('subscriptions/create-customer-portal-session', body);
};

export const createInvite = async (body: CreateInviteBodyDto) => {
  return api.post<CreateInviteResDto>('invitations', body);
};
