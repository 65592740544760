import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { TextField, TextAreaField, LoadImageField } from 'components';
import { JustifyBetween } from 'styled/utils';
import { actions } from 'store/ducks/auth';
import { FormComponent, Root, HeadForm } from './styled';
import { Button } from 'UI';
import {
	isRequired,
	composeValidators,
	name,
	isNumber,
	isRequiredLength,
} from 'utils/validation';
import { convertBaseToFile, setValuesUpdateCoach, uploadFileS3 } from 'utils';
import { User, UpdateCoachDto } from 'types';
import { FormApi } from 'final-form';
import { captureException } from '@sentry/minimal';
import { AppDispatch } from 'store';

type Props = {
	user?: User;
};

const stylesField = { marginTop: '12px' };

const MAX_IMAGE_SIZE = 15 * 1024 * 1024;

export function CreateAccountForm({ user }: Props) {
	const dispatch = useDispatch<AppDispatch>();
	const { push } = useHistory();

	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async (
		values: UpdateCoachDto,
		form: FormApi<UpdateCoachDto>
	) => {
		try {
			setIsLoading(true);
			const isAvatarNew =
				form.getFieldState('avatarUrl')?.dirty && !!values.avatarUrl;
			let avatarUrl = values.avatarUrl;
			if (isAvatarNew) {
				avatarUrl = await uploadFileS3(
					await convertBaseToFile(
						values.avatarUrl as string,
						`${values.firstName}-avatar`
					)
				);
			}
			await dispatch(
				actions.updateUser(setValuesUpdateCoach({ ...values, avatarUrl }))
			);
			push('/calendar');
		} catch (error) {
			captureException(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Root>
			<HeadForm as='h2'>Create account</HeadForm>
			<Form<UpdateCoachDto>
				onSubmit={onSubmit}
				initialValues={{
					firstName: user?.firstName,
					lastName: user?.lastName,
					experience: user?.experience ? +user.experience : undefined,
					avatarUrl: user?.avatarUrl,
					description: user?.description,
				}}
				render={({ handleSubmit, hasValidationErrors }) => (
					<FormComponent onSubmit={handleSubmit}>
						<Field
							name='avatarUrl'
							component={LoadImageField}
							maxSize={MAX_IMAGE_SIZE}
							accept={['image/*']}
						/>
						<JustifyBetween>
							<Field
								wrapperCSS={{
									...stylesField,
									width: '49%',
									marginBottom: '0px !important',
								}}
								label='First name*'
								name='firstName'
								validate={composeValidators(name, isRequired())}
								component={TextField}
							/>
							<Field
								label='Last name*'
								wrapperCSS={{ ...stylesField, width: '49%' }}
								name='lastName'
								validate={composeValidators(name, isRequired())}
								component={TextField}
							/>
						</JustifyBetween>
						<Field
							label='Experience, years*'
							wrapperCSS={stylesField}
							validate={composeValidators(isNumber, isRequired())}
							name='experience'
							component={TextField}
						/>
						<Field
							label='About you'
							subLabel='(at least 80 characters)'
							name='description'
							wrapperCSS={stylesField}
							inputCSS={{ height: '189px' }}
							validate={isRequiredLength(400, 80)}
							component={TextAreaField}
						/>
						<Button
							text='Done'
							theme='primary'
							type='submit'
							$margin='24px 0px 0px 0px'
							isLoading={isLoading}
						/>
					</FormComponent>
				)}
			/>
		</Root>
	);
}
