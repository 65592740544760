import {
  DEFAULT_AVATAR,
  FITNESS_APP_VIDEO_PREFIX,
  ROUTE_PATH,
} from '@constants';
import Vimeo from '@u-wave/react-vimeo';
import { Delete, Edit, Share } from 'assets/svg';
import {
  ConfirmModal,
  EditVideoModal,
  ShareModal,
  SpinnerContainer,
} from 'components';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  deleteVideo,
  getVideo,
  sendShareMessage,
  updateVideo,
} from 'services/api';
import Toast from 'services/Toast';
import { selectors } from 'store/ducks';
import {
  CircleAvatar,
  FlexStart,
  JustifyBetween,
  SubheadOne,
  SubheadTwo,
} from 'styled';
import {
  ChatMessageDto,
  ShareVideoDto,
  UpdateVideoDto,
  Video as VideoType,
} from 'types';
import { Arrow } from 'UI';
import { buildShareVideoUrl } from 'utils';
import {
  ArrowCSS,
  AuthorContainer,
  ButtonContainer,
  Container,
  DescriptionContainer,
  VideoContainer,
} from './styled';

function Video() {
  const history = useHistory();

  const userId = useSelector(selectors.auth.getUserId);

  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [video, setVideo] = useState<VideoType | null>(null);

  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const fetchVideo = async (id: string) => {
    setIsLoading(true);
    const { data } = await getVideo(id);
    setVideo(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchVideo(id);
  }, [id]);

  if (isLoading || !video) {
    return <SpinnerContainer />;
  }

  const handleShare = () => {
    setIsShareModalOpen(true);
  };

  const submitShare = async (values: ShareVideoDto) => {
    try {
      const attachmentVideoUrl = buildShareVideoUrl(
        values.id,
        values.vimeoId,
        values.previewUrl
      );

      const message: ChatMessageDto = {
        body: values.body || '',
        attachments: [{ attachmentUrl: attachmentVideoUrl }],
      };

      await Promise.all(
        values.usersIds.map(async (chatRoomId) => {
          await sendShareMessage(`${chatRoomId}`, message);
        })
      );

      setIsShareModalOpen(false);
      Toast.success('Video shared', 'Videos');
    } catch (e) {
      Toast.error();
    }
  };

  const handleEdit = () => {
    setIsEditModalOpen(true);
  };

  const submitEdit = async (values: UpdateVideoDto) => {
    try {
      const { data } = await updateVideo(id, values);
      setVideo(data);
      Toast.success('Video updated', 'Videos');
    } catch (e) {
      Toast.error();
    }
  };

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const submitDelete = async () => {
    try {
      await deleteVideo(id);
      setIsDeleteModalOpen(false);
      history.push(ROUTE_PATH.videos);
    } catch (e) {
      Toast.error();
    }
  };

  return (
    <Container>
      <ShareModal
        title="Share video"
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        onSubmit={async (values) =>
          await submitShare({
            usersIds: values.users.map((el) => el.value),
            body: values.body,
            id: video.id,
            vimeoId: video.videoUrl!,
            previewUrl: video.videoImageUrl!,
          })
        }
      />
      <ConfirmModal
        title="Delete video"
        description="Do you really want to delete this video?"
        agreeText="Delete"
        disagreeText="Cancel"
        isOpen={isDeleteModalOpen}
        onAgree={submitDelete}
        onClose={() => setIsDeleteModalOpen(false)}
      />
      <EditVideoModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        initialEntity={video}
        onSubmit={submitEdit}
      />
      {video.videoUrl && (
        <VideoContainer>
          <Vimeo video={video.videoUrl.replace('/videos/', '')} />
        </VideoContainer>
      )}
      <DescriptionContainer>
        <AuthorContainer
          onClick={() => history.push(ROUTE_PATH.userProfile(video.authorId))}
        >
          <CircleAvatar
            $url={video.author?.avatarUrl || DEFAULT_AVATAR}
            $size={24}
            $withPointerCursor
          />
          <SubheadTwo>{`${video.author?.firstName} ${video.author?.lastName}`}</SubheadTwo>
        </AuthorContainer>
        <JustifyBetween $marginBottom={16}>
          <SubheadOne>{video.name}</SubheadOne>
          <Arrow
            $rotate={isDescriptionVisible ? 225 : 45}
            $color="#67667B"
            $CSS={ArrowCSS}
            onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}
          />
        </JustifyBetween>
        {isDescriptionVisible && (
          <SubheadTwo $color="#67667B" $marginBottom={12}>
            {video.description}
          </SubheadTwo>
        )}
        <JustifyBetween>
          <ButtonContainer onClick={handleShare}>
            <Share />
            <SubheadTwo $margin="0 0 0 12px">Share</SubheadTwo>
          </ButtonContainer>
          {userId && video.authorId && (
            <FlexStart>
              <ButtonContainer onClick={handleEdit}>
                <Edit />
                <SubheadTwo $margin="0 0 0 12px">Edit</SubheadTwo>
              </ButtonContainer>
              <ButtonContainer onClick={handleDelete}>
                <Delete />
                <SubheadTwo $margin="0 0 0 12px">Delete</SubheadTwo>
              </ButtonContainer>
            </FlexStart>
          )}
        </JustifyBetween>
      </DescriptionContainer>
    </Container>
  );
}

export default Video;
